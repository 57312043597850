import React, { useEffect, useRef, useState } from "react";
import { Modal, message, Button } from "antd";
import Cookies from "js-cookie";
import { format } from "date-fns";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const DocumentVault = () => {
  const [sortBy, setSortBy] = useState("default");
  const [documents, setDocuments] = useState([]);
  const [folders, setFolders] = useState([]);
  const [sorteddocuments, setSortedDocuments] = useState([]);
  const [openedId, setOpenedId] = useState("");
  const [openedFolders, setOpenFolders] = useState(null);
  const [financialSummary, setFinancialSummary] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(false);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger the hidden input click
  };
  function groupFilesByFileId(files) {
    const groupedFiles = { Platform: [] };

    files.forEach((file) => {
      if (file.type === null) {
        groupedFiles["Platform"].push(file);
      } else {
        if (!groupedFiles[file.file_id]) {
          groupedFiles[file.file_id] = [];
        }
        groupedFiles[file.file_id].push(file);
      }
    });

    return groupedFiles;
  }

  const getAllDocument = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/file/geAllDocumentVault`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.ok) {
        const json = await response.json();
        setFolders(groupFilesByFileId(json.data));
        // setDocuments(json.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const UniversaleUploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "Manual");
    formData.append("file_id", "User");
    // console.log(file);
    try {
      const response1 = await fetch(
        "https://www.smart-lend.com/api/file/uploadUniversalFile",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: formData,
        }
      );
      if (response1.status === 200) {
        const data = await response1.json();
        const blobUrl = data.url;
        getAllDocument();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided

    const date = new Date(dateString); // Create a Date object from the date string
    const formattedDate = format(date, "dd MMMM yyyy"); // Use date-fns to format the date in the desired format

    return formattedDate;
  };

  useEffect(() => {
    getAllDocument();
  }, []);

  useEffect(() => {
    if (!Cookies.get("vaultTour")) {
      setIsTourOpen(true);
      Cookies.set("vaultTour", true);
    }
  }, []);

  useEffect(() => {
    let sortedDocs = [...documents];

    if (sortBy === "name") {
      sortedDocs.sort((a, b) => a.file_name.localeCompare(b.file_name));
    } else if (sortBy === "type") {
      sortedDocs.sort((a, b) => {
        const getFileExtension = (fileName) => {
          const parts = fileName.split(".");
          return parts.length > 1 ? parts.pop().toLowerCase() : "unknown"; // Handle files with no extension
        };

        return getFileExtension(a.file_name).localeCompare(
          getFileExtension(b.file_name)
        );
      });
    } else {
      sortedDocs.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      ); // Newest first
    }

    setSortedDocuments(sortedDocs);
  }, [documents, sortBy]);

  return (
    <div
      style={{
        height:
          window.innerWidth < 1200
            ? `calc(${window.innerHeight}px - 80px)`
            : "calc(100% - 80px)",
        width: window.innerWidth < 1200 ? "100%" : "calc(100% - 40px)",
        marginLeft: window.innerWidth < 1200 ? "0px" : "20px",
        background:
          window.innerWidth < 1200
            ? "transparent"
            : "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        gap: "5px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        color: "#142C44CC",
      }}
    >
      <Modal
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={isTourOpen}
        onCancel={() => {
          setIsTourOpen(false);
        }}
        width={"60vh"}
        footer={null}
      >
        <div
          style={{
            color: "grey",
            fontFamily: "Manrope,sans-serif",
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontFamily: "Manrope,sans-serif",
              fontWeight: "800",
              fontSize: "30px",
            }}
          >
            Unlock Your Financial Control!
          </div>
          <div style={{ marginTop: "15px" }}>
            Say goodbye to Loan Document chaos. Effortlessly access all your
            loans from multiple lenders in one place. Lost track of your loan
            offer letters or installment dates? Simply upload your offers to
            consolidate every loan commitment across all lenders in Singapore.
          </div>
          <div style={{ marginTop: "15px" }}>
            ✨ Stay on top of your company’s finances with real-time tracking,
            progress monitoring, and complete control.
          </div>
          <div style={{ marginTop: "15px" }}>
            {" "}
            Ready to experience seamless financial management?
          </div>
          <div style={{ marginTop: "20px", display: "flex" }}>
            <div
              className="mui_button"
              style={{
                borderRadius: "20px",
                fontSize: "14px",
                fontWeight: "700",
                fontFamily: "Poppins, sans-serif",
                padding: "8px",
                background: "rgb(14, 90, 165)",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
              onClick={() => {
                setIsTourOpen(false);
              }}
            >
              <div
                style={{
                  marginLeft: "20px",
                  marginRight: "10px",
                }}
              >
                Get Started
              </div>
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  background: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <NavigateNextIcon
                  style={{
                    color: "rgb(14, 90, 165)",
                    fontSize: "24px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            color: "#142C44CC",
            fontFamily: "Manrope,sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {openedFolders === null ? (
            window.innerWidth < 1200 ? (
              "Documents"
            ) : (
              "Document Vault"
            )
          ) : (
            <div
              style={{ display: "flex", cursor: "pointer" }}
              onClick={() => {
                setOpenedId("");
                setOpenFolders(null);
              }}
            >
              <div>
                <ArrowBackIosIcon style={{ color: "#142C44CC" }} />
              </div>
              {openedId}
            </div>
          )}
          <div style={{ display: "flex" }}>
            <input
              onChange={(e) => {
                UniversaleUploadFile(e.target.files[0]);
              }}
              ref={fileInputRef}
              style={{ display: "none" }}
              type="file"
            />
            <div
              onClick={handleButtonClick}
              style={{
                color: "rgb(14, 90, 165)",
                border: "2px solid rgb(14, 90, 165)",
                fontWeight: 500,
                fontSize: "14px",
                padding: "5px 15px",
                borderRadius: "7px",
                marginLeft: "10px",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              Upload
            </div>
            <div
              className="special_button"
              style={{
                color: "white",
                fontWeight: 500,
                fontSize: "14px",
                padding: "5px 15px",
                borderRadius: "7px",
                marginLeft: "10px",
                // background:
                //   "transparent linear-gradient(450deg, rgba(14, 90, 165, 0.4) 0%, rgb(14, 90, 165) 100%) 0% 0% no-repeat padding-box",
              }}
            >
              Financial Summary
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: window.innerWidth < 1200 ? "auto" : "250px",
          }}
        >
          <label
            htmlFor="sort-by"
            style={{
              marginRight: "7px",
              marginLeft: "10px",
              color: "#142C44CC",
              fontWeight: "800",
              fontSize: "13px",
              fontFamily: "Manrope, sans-serif",
            }}
          >
            Sort by:
          </label>
          <div style={{ width: "60%" }}>
            <select
              id="sort-by"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              style={{
                width: "100%",
                borderRadius: "30px",
                color: "#0E5AA5",
                backgroundColor: "#ECF0F5",
                border: "1px solid #3E536733",
                fontWeight: "600",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              }}
            >
              <option value="default">Date & Time</option>
              <option value="name">File Name</option>
              <option value="type">File Type</option>
            </select>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          height:
            sorteddocuments && sorteddocuments.length > 0 ? "auto" : "100%",
          maxHeight:
            sorteddocuments && sorteddocuments.length > 0 ? "100%" : "100%",
          width: "100%",
          overflowY: "auto",
        }}
      >
        {openedFolders === null
          ? Object.entries(folders).map(([fileId, fileGroup]) => (
              <div
                key={fileId}
                className="border_change_hover"
                onClick={() => {
                  setOpenFolders(fileGroup);
                  setOpenedId(fileId);
                }}
                style={{
                  padding: "20px",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width:
                    window.innerWidth < 1200
                      ? `${window.innerWidth / 2 - 30}px`
                      : "120px",
                  height: "120px",
                  textAlign: "center",
                  border: "1px solid #1557a93A",
                  cursor: "pointer",
                }}
              >
                <div>
                  <FolderIcon
                    style={{ color: "#1557a99A", fontSize: "35px" }}
                  />
                </div>
                <div style={{ fontSize: "12px" }}>
                  {fileId?.length < 10
                    ? fileId
                    : fileId?.slice(0, 10) + "..." || "Unnamed"}
                </div>
              </div>
            ))
          : null}
        {openedFolders === null
          ? sorteddocuments && sorteddocuments.length > 0
            ? sorteddocuments
                .filter((doc) => doc.type === null)
                .map((doc, index) => (
                  <div
                    className="border_change_hover"
                    style={{
                      padding: "20px",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width:
                        window.innerWidth < 1200
                          ? `${window.innerWidth / 2 - 30}px`
                          : "120px",
                      height: "120px",
                      textAlign: "center",
                      border: "1px solid #1557a93A",
                      cursor: "pointer",
                    }}
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(doc.url, "_blank");
                    }}
                  >
                    <div>
                      <FileCopyIcon
                        style={{ color: "#1557a94A", fontSize: "24px" }}
                      />
                    </div>
                    <div style={{ fontSize: "12px" }}>
                      {doc?.file_name?.length < 10
                        ? doc?.file_name
                        : doc?.file_name?.slice(0, 10) + "..." || "Unnamed"}
                    </div>
                    <div style={{ fontSize: "9px" }}>
                      {formatDate(doc.created_at)}
                    </div>
                  </div>
                ))
            : null
          : openedFolders.map((doc, index) => (
              <div
                className="border_change_hover"
                style={{
                  padding: "20px",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width:
                    window.innerWidth < 1200
                      ? `${window.innerWidth / 2 - 30}px`
                      : "120px",
                  height: "120px",
                  textAlign: "center",
                  border: "1px solid #1557a93A",
                  cursor: "pointer",
                }}
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(doc.url, "_blank");
                }}
              >
                <div>
                  <FileCopyIcon
                    style={{ color: "#1557a94A", fontSize: "24px" }}
                  />
                </div>
                <div style={{ fontSize: "12px" }}>
                  {doc?.file_name?.length < 10
                    ? doc?.file_name
                    : doc?.file_name?.slice(0, 10) + "..." || "Unnamed"}
                </div>
                <div style={{ fontSize: "9px" }}>
                  {formatDate(doc.created_at)}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default DocumentVault;
