import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import profilePicture from "../../assets/img/profile-picture.jpg";
import React, { useState, useEffect } from "react";
import { Popover, List, Avatar, message } from "antd";
import Cookies from "js-cookie";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import SettingsIcon from "@mui/icons-material/Settings";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";

const PopoverContent = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/user/notification/viewNotification",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setNotifications(data["Notifications"]);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return (
    <List
      style={{
        height: "350px",
        width: "250px",
        overflowY: "scroll",
        marginLeft: "10px",
      }}
      itemLayout="horizontal"
      dataSource={notifications}
      renderItem={(item) => (
        <div
          className="notification_item"
          onClick={() => console.log(item.id)}
          style={{ cursor: "pointer" }}
        >
          <a
            href="#"
            onClick={() => console.log(item.id)}
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              fontSize: "13px",
              color: "rgba(0,0,0,0.6)",
            }}
          >
            {item.subject}
          </a>
          <div
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              fontSize: "12px",
              color: "rgba(0,0,0,0.4)",
            }}
          >
            {item.message}
          </div>
        </div>

        // <List.Item>

        //   <List.Item.Meta
        //     title={
        //       <a href="#" onClick={() => console.log(item.id)}>
        //         {item.subject}
        //       </a>
        //     }
        //     description={item.message}
        //   />
        // </List.Item>
      )}
    />
  );
};

const Topbar = ({ userDetails }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [visible, setVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [data, setData] = useState([]);

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  useEffect(() => {
    // Function to get the value of a URL parameter by name
    const getUrlParameter = (name) => {
      name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
      const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      const results = regex.exec(window.location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    // Get the token parameter from the URL
    const tokenParam = getUrlParameter("token");

    // Check if a token is present in the URL
    if (tokenParam) {
      // Store the token in cookies
      console.log(tokenParam);
      Cookies.set("yourTokenCookieName", tokenParam, { expires: 720 }); // Set an expiration if needed
    }

    async function fetchData() {
      // const profilePictureUrl =
      //   (await loadprofilepicture()) || Cookies.get("profile_picture");
      // if (profilePictureUrl) {
      //   setImageUrl(profilePictureUrl);
      //   console.log(profilePictureUrl);
      // }
    }
    fetchData();
  }, []);
  const history = useHistory();
  const location = useLocation();

  const redirectToUrl = (path) => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    let redirectTo = path;

    if (code && state) {
      redirectTo += `?code=${code}&state=${state}`;
    }

    history.push(redirectTo);
  };

  const [initialName, setInitialName] = useState("SL");

  useEffect(() => {
    if (userDetails) {
      const textToSplit = userDetails.entity_name
        ? userDetails.entity_name.split(" ")
        : userDetails.principal_name
        ? userDetails.principal_name.split(" ")
        : null;
      if (textToSplit !== null) {
        const initials =
          textToSplit?.[0]?.[0].toUpperCase() +
          (textToSplit?.[1]?.[0].toUpperCase() || "");
        setInitialName(initials);
      }
    }
  }, [userDetails]);

  const loadprofilepicture = async () => {
    console.log("getting profile picture");
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/viewInfoProfilePicture",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (response.status === 401) {
        if (!Cookies.get("expressauth")) {
          Cookies.remove("token");
          // window.location.href = "/auth/signin";

          try {
            const response = await fetch(
              "https://www.smart-lend.com/api/user/logout",
              {
                method: "POST",
                redirect: "manual", // don't follow the redirect
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Cookies.get("token")}`,
                },
              }
            );
            console.log("response status value", response.status);
            if (response.status === 200) {
              // Clear user session and navigate to login page
              Cookies.remove("token");
              window.location.href = "/auth/signin";
            } else if (response.status === 0) {
              window.location.href = "/auth/signin";
            }
            // if (!response.ok) {
            //   throw new Error("Logout failed");
            // }
          } catch (error) {
            console.error(error);
            // Display error message to user
            alert("Login failed. Please try again.");
          }
        } else {
          redirectToUrl("/director-authorization");
        }
      } else if (!response.ok) {
        throw new Error("Failed to fetch profile picture");
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      console.log("Profile picture retrieved", imageUrl);
      return imageUrl;
    } catch (error) {
      console.error("Error retrieving profile picture", error);
      return null;
    }
  };

  const [screenWidth, setScreenWidth] = useState("1920");

  const [changeProfileIsOpen, setChangeProfileIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [accounts, setAccounts] = useState([]);
  const [myuserId, setMyUserId] = useState("");

  const getLinkedAccounts = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/checkOtherLinkAccounts",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (response.ok) {
        const json = await response.json();
        setAccounts(json.accounts);
        setMyUserId(json.user_id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLinkedAccounts();
  }, []);

  const createBorrowerLinkedAccount = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/createLinkedBorrowerAccount",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (response.ok) {
        const json = await response.json();
        message.success("Linked account created!");
        setAccounts([...accounts, json.newUser]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const vpnToAccount = async (user_id) => {
    try {
      const formData = new FormData();
      formData.append("userId", user_id);

      const response = await fetch(
        "https://www.smart-lend.com/api/admin/vpnToUserAccount",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            Accept: "application/json",
          },
          body: formData,
        }
      );

      const json = await response.json();
      if (response.status === 200) {
        Cookies.set("token", json.token);
        Cookies.set("user_account_type", "borrower");
        window.location.href = "https://smart-lend.com/b-dashboard";
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {changeProfileIsOpen ? (
        <div
          style={{
            position: "fixed",
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
            background: "rgba(256,256,256,0.5)",
            backdropFilter: "blur(8px)",
            zIndex: 1000,
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "35px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: "45px",
              }}
            >
              {accounts.map((acc, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "14px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      background:
                        myuserId === acc.user_id
                          ? "rgb(17, 92, 166)"
                          : "rgb(140, 140, 170)",
                      height: "120px",
                      width: "120px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "700",
                      fontSize: "40px",
                    }}
                    onClick={() => {
                      vpnToAccount(acc.user_id);
                    }}
                  >
                    {acc.entity_name
                      ? acc.entity_name.split(" ")?.[0]?.[0] +
                        acc.entity_name.split(" ")?.[1]?.[0]
                      : acc.principal_name
                      ? acc.principal_name.split(" ")?.[0]?.[0] +
                        acc.principal_name.split(" ")?.[1]?.[0]
                      : "SL"}
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      color: "grey",
                      fontSize: "14px",
                      lineHeight: "15px",
                      fontWeight: 300,
                      width: "150px",
                    }}
                  >
                    {acc.entity_name
                      ? acc.entity_name
                      : acc.principal_name
                      ? acc.principal_name
                      : "New profile"}
                  </div>
                </div>
              ))}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  createBorrowerLinkedAccount();
                }}
              >
                <div
                  style={{
                    background: "rgb(140, 140, 170, 0.4)",
                    height: "120px",
                    width: "120px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "700",
                    fontSize: "40px",
                  }}
                >
                  <AddIcon style={{ color: "white", fontSize: "60px" }} />
                </div>
              </div>
            </div>
            <div>
              <CancelIcon
                style={{
                  cursor: "pointer",
                  color: "rgba(0,0,0,0.3)",
                  fontSize: "50px",
                }}
                onClick={() => {
                  setChangeProfileIsOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      {screenWidth > 1200 ? (
        <Box display="flex" justifyContent="space-between" p={2}>
          {/* SEARCH BAR */}
          <Box
            display="flex"
            backgroundColor={colors.grey[910]}
            // colors={colors.grey[910]}
            opacity="1.00"
            borderRadius="10px"
            width="74.5%"
          >
            <IconButton type="button" sx={{ p: 1, color: "black" }}>
              <SearchIcon
                style={{ color: "rgba(0,0,0,0.6)", fontSize: "25px" }}
              />
            </IconButton>
            <InputBase
              className="input_base"
              sx={{ ml: 2, flex: 1, color: "black" }}
              placeholder="Search In Page"
            />
          </Box>

          {/* ICONS */}
          <Box display="flex">
            {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}
            {/* <Popover
              content={<PopoverContent />}
              title="Notifications"
              trigger="click"
              visible={visible}
              onVisibleChange={handleVisibleChange}
            >
              <IconButton>
                <NotificationsIcon
                  style={{ color: "#707070", marginRight: "-5px" }}
                />
              </IconButton>
            </Popover> */}
            <IconButton style={{ marginRight: "8px" }}>
              <SettingsIcon
                style={{ color: "#707070" }}
                onClick={() => {
                  window.location.href = "/stg-b-profile";
                }}
              />
            </IconButton>
            <div
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
                background: "rgb(140,140,170)",
                marginLeft: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setChangeProfileIsOpen(true);
              }}
            >
              {initialName}
            </div>
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" p={2}>
          {/* SEARCH BAR */}
          <Box
            display="flex"
            backgroundColor={colors.grey[910]}
            opacity="1.00"
            borderRadius="10px"
            width="70%"
            marginLeft="50PX"
            style={{
              boxShadow:
                window.innerWidth < 1200
                  ? "0px 0px 10px rgba(0,0,0,0.1)"
                  : "none",
            }}
          >
            <IconButton type="button" sx={{ p: 1, color: "black" }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 2, flex: 1, color: "black", fontSize: "11px" }}
              placeholder="Search In Pages"
            />
          </Box>

          {/* ICONS */}

          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              background: "rgb(140,140,170)",
              marginLeft: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              boxShadow:
                window.innerWidth < 1200
                  ? "0px 0px 10px rgba(0,0,0,0.1)"
                  : "none",
            }}
            onClick={() => {
              setChangeProfileIsOpen(true);
            }}
          >
            {initialName}
          </div>
        </Box>
      )}
    </>
  );
};

export default Topbar;
