import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../components/Header";
import TableLender from "../../src/components/TableUserLender";
import TableBorrower from "../../src/components/TableUserBorrower";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import warn_ico from "../assets/img/exclamation.png";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Avatar,
  Upload,
  Card,
  Tabs,
  notification,
  DatePicker,
  Space,
  InputNumber,
  Modal,
  Button,
  message,
} from "antd";
import {
  EyeTwoTone,
  EyeInvisibleOutlined,
  UserOutlined,
} from "@ant-design/icons";

const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [channel, setChannel] = useState("system");
  const [data, setData] = useState([]);
  const [subject, setSubject] = useState("");
  // const [message, setMessage] = useState("");
  const { TextArea } = Input;
  const [selectedBorrowerIds, setselectedBorrowerIds] = useState([]);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [principal_name, setPrincipalName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [error, setError] = useState("");
  const [reloadTable, setReloadTable] = useState(0);
  // const [reloadTable, setReloadTable] = useState(false); // Assuming initial value is false
  const [reloadTableBorrower, setReloadTableBorrower] = useState(0);
  const [screenWidth, setScreenWidth] = useState("1920");
  const [dataActivityLog, setActivityLog] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const ContainerRow1 = ({
    onClick,
    icon,
    title,
    subtitle,
    label,
    description,
  }) => (
    <div
      onClick={onClick} // The onClick event handler
      style={{
        width: screenWidth > 1200 ? "100%" : "100%",
        textAlign: "center",
        margin: "0px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "rgba(255,255,255,0.7)",
        cursor: "pointer",
        padding: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "800",
            fontSize: "17px",
            color: "#142C44CC",
            textAlign: "left",
            margin: "0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "200",
            fontSize: "8px",
            color: "#142C44CC",
            textAlign: "right",
            margin: "0px",
          }}
        >
          {subtitle}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginTop: "13px",
        }}
      >
        <div
          style={{
            width: "55px",
            height: "55px",
            background: "rgba(0,0,0,0.03)",
            borderRadius: "35px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0px",
          }}
        >
          <img
            src={icon}
            alt={title}
            style={{
              background: "#f0f0f0",
              width: "30px",
              height: "30px",
              objectFit: "contain",
            }}
          />
        </div>
        <div
          style={{
            width: "calc(100% - 70px)",
            margin: "0px 0px 0px 10px",
          }}
        >
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "800",
              fontSize: "20px",
              textAlign: "left",
              color: "#142C44CC",
              margin: "0px",
            }}
          >
            {label}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              textAlign: "left",
              margin: "0px",
              lineHeight: "11px",
            }}
          >
            {description}
          </p>
        </div>
      </div>
      {/* <p>{description}</p> */}
    </div>
  );

  console.log("ParentComponent rendering", reloadTable);

  const handleRowClick = async (record) => {
    const userId = record.user_id;
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/viewUserDetail?user_id=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      setData(json["User"]);
    } catch (error) {
      console.error("Error retrieving User:", error);
    }
  };

  const handleCreateLender = async () => {
    if (password !== passwordConfirmation) {
      setError("Passwords do not match");
      return; // Exit early
    }

    try {
      let item = {
        email: email,
        principal_name: principal_name,
        user_account_type: "lender",
        password: password,
        password_confirmation: passwordConfirmation,
      };

      const response = await fetch(
        "https://www.smart-lend.com/api/user/createLender",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );

      const data = await response.json();
      console.log("Response status:", response.status);
      console.log("Lender created:", data);

      if (response.status === 200) {
        if (data.error) {
          setError(data.error); // Handle backend error messages
          return;
        }

        const userId = data.user.user_id;
        if (selectedFile) {
          handleUploadProfilePicture(selectedFile, userId);
          setSelectedFile(null);
        }

        message.success("Lender Created Successfully");
        setReloadTable((prev) => prev + 1);
      } else {
        // Handle other non-200 status codes
        setError(`Unexpected response: ${response.status}`);
      }
    } catch (error) {
      console.error("Error during fetch operation:", error);
      setError("An error occurred while creating the lender.");
    }
  };

  const handleUploadProfilePicture = async (file, userId) => {
    console.log("uploading profile picture");
    try {
      const formData = new FormData();
      formData.append("profile_picture", file);
      formData.append("user_id", userId); // Add user_id to the formData

      const response = await fetch(
        "https://www.smart-lend.com/api/profile/uploadProfilePicture",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: formData,
        }
      );

      const data = await response.json();

      console.log("Profile picture created", data);

      // Update the imageUrl state with the new image URL
      if (data.profile_picture) {
        setImageUrl(data.profile_picture);
        Cookies.set("profile_picture", data.profile_picture);
        // message.success("Profile picture updated");
      } else if (data.error && data.error.profile_picture) {
        // If the profile picture failed to upload
        throw new Error(data.error.profile_picture[0]);
      }
    } catch (error) {
      console.error("Error Uploading Picture", error);
      setErrorMessage(error.message);
    }
  };

  const handleSelectionChange = (selectedBorrowerIds) => {
    console.log("Selected loan IDs:", selectedBorrowerIds);
    setselectedBorrowerIds(selectedBorrowerIds);
  };

  const handleSubmit = async (borrower_id) => {
    console.log("loanIds value in handleSubmit", borrower_id); // Debug statement

    try {
      let item = {
        user_id: borrower_id.join(","),
        channel: channel,
        subject: subject,
        message: message,
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/user/notification/sendNotification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();
      console.log("Offer created:", data);
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };

  const getInitials = (name) => {
    const textToSplit = name.split(" ");
    const initials =
      textToSplit?.[0]?.[0].toUpperCase() +
      (textToSplit?.[1]?.[0].toUpperCase() || "");
    return initials;
  };

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "7px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
        padding: "10px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            background: "rgb(140,140,170)",
            marginRight: "10px",
            marginLeft: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: 20,
            fontWeight: 600,
            position: "relative",
          }}
        >
          {getInitials(title)}
        </div>
        <div style={{ width: "calc(100% - 75px)" }}>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "13px",
              color: "#435669",
              margin: "0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              margin: "0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const ContainerActivity2 = ({ noData }) => (
    <div
      style={{
        width: "100%",
        padding: "0px 0px",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "0px 15px",
          background: "rgba(255,255,255,0.7)",
          borderRadius: "10px",
          height: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Manrope, sans-serif",
          fontSize: "0.8em",
          fontWeight: "600",
          color: "#00000066",
        }}
      >
        No activity log records
      </div>
    </div>
  );

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  const [modalStates, setModalStates] = useState({});
  const [allOfferedLoan, setAllOfferedLoan] = useState([]);
  const [label1, setLabel1] = useState(0);
  const [label2, setLabel2] = useState(0);
  const [label3, setLabel3] = useState(0);

  const calculateTotalOfferedAmount = (flatData) => {
    // Get the current date
    const currentDate = new Date();
    console.log(flatData);
    // Initialize a variable to store the total offered amount
    let totalOfferedAmount = 0;

    // Iterate through the array of loan offers
    flatData.forEach((offers) => {
      // Parse the loan's creation date
      const creationDate = new Date(offers.loan_details.created_at);

      // Check if the status is "ongoing" and the creation date is in the current month
      if (
        offers.status === "ongoing" &&
        creationDate.getMonth() === currentDate.getMonth() &&
        creationDate.getFullYear() === currentDate.getFullYear()
      ) {
        // Add the offer amount to the total
        totalOfferedAmount += offers.offer_amount;
      }
    });

    const formattedTotal = totalOfferedAmount.toLocaleString("en-US", {
      style: "currency",
      currency: "SGD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true, // Enable thousands/millions grouping
    });

    return formattedTotal;
  };

  const calculateTotalInterestProfit = (flatData) => {
    // Get the current date
    const currentDate = new Date();
    console.log(flatData);
    // Initialize a variable to store the total offered amount
    let totalOfferedAmount = 0;

    // Iterate through the array of loan offers
    flatData.forEach((offers) => {
      // Parse the loan's creation date
      const creationDate = new Date(offers.loan_details.created_at);

      // Check if the status is "ongoing" and the creation date is in the current month
      if (
        offers.status === "ongoing" &&
        creationDate.getMonth() === currentDate.getMonth() &&
        creationDate.getFullYear() === currentDate.getFullYear()
      ) {
        // Add the offer amount to the total
        totalOfferedAmount +=
          offers.loan_details.monthly_installment *
          offers.loan_details.interest_rate;
      }
    });

    const formattedTotal = totalOfferedAmount.toLocaleString("en-US", {
      style: "currency",
      currency: "SGD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true, // Enable thousands/millions grouping
    });

    return formattedTotal;
  };

  const calculateTotalLoanRequest = (flatData) => {
    // Get the current date
    const currentDate = new Date();
    console.log(flatData);
    // Initialize a variable to store the total offered amount
    let totalOfferedAmount = 0;

    // Iterate through the array of loan offers
    flatData.forEach((offers) => {
      // Parse the loan's creation date
      const creationDate = new Date(offers.created_at);

      // Check if the status is "ongoing" and the creation date is in the current month
      if (
        creationDate.getMonth() === currentDate.getMonth() &&
        creationDate.getFullYear() === currentDate.getFullYear()
      ) {
        // Add the offer amount to the total
        totalOfferedAmount += 1;
      }
    });

    return totalOfferedAmount;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/viewLoanRequest",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        const flatData = json["Requested Loan"].flat();
        setLabel3(calculateTotalLoanRequest(flatData));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/loanOffer/viewAllOfferedLoan",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        const flatData = json["Loan offers"].flat();
        setAllOfferedLoan(flatData);
        setLabel1(calculateTotalOfferedAmount(flatData));
        setLabel2(calculateTotalInterestProfit(flatData));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const initialStates = {};
    data.forEach((item) => {
      initialStates[item.user_id] = false;
    });
    setModalStates(initialStates);
  }, [data]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewActivityLog",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setActivityLog(data.all_activity_log);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const [imageUrl, setImageUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleBeforeUpload = (file) => {
    const imageUrl = URL.createObjectURL(file);
    setImageUrl(imageUrl);
    console.log("Photo ready to upload", file);
    setSelectedFile(file);
    return false; // Prevent file from being uploaded automatically
  };

  const [open, setOpen] = useState(false);
  const [openBorrowerIds, setOpenBorrowerIds] = useState([]);

  const showModal = (user_id, event) => {
    event.preventDefault();
    console.log("user_id modal", user_id);
    setOpenBorrowerIds((prevStates) => [...prevStates, ...user_id]);
  };

  const handleClose = (selectedUserId) => {
    setOpenBorrowerIds((prevStates) =>
      prevStates.filter((id) => id !== selectedUserId)
    );
  };

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 16,
    },
    vertical: true,
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const tailLayout = {
    wrapperCol: {
      offset: 9,
      span: 24,
    },
  };

  const ContainerTransaction = ({ title, label }) => (
    <div
      style={{
        width: "45%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginLeft: "30px",
      }}
    >
      <div style={{ flex: 1, textAlign: "left", marginLeft: "0px" }}>
        <h3
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            fontSize: "17px",
            color: "#142C44CC",
            flex: 1,
            marginTop: "5px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "800",
            fontSize: "20px",
            color: "#435669",
            marginTop: "5px",
          }}
        >
          {label}
        </p>
      </div>
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
        <Box m="4px 20px 20px 20px">
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
            <Box
              gridColumn="span 9"
              gridRow="span 2"
              height="100vh"
              width="100%"
              borderRadius="10px"
              border="0px solid "
              opacity="0.95"
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
                background:
                  "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="95%"
                borderRadius="10px"
                margin="20px 20px 20px 20px"
                style={{ gap: "5px" }}
              >
                <div style={{ width: "32%" }}>
                  <ContainerRow1
                    key={0}
                    icon={require("../assets/img/customersicon.png")}
                    title="Total Loan Amount"
                    label={label1}
                    description={"Loan processed this month"}
                  />
                </div>
                <div style={{ width: "32%" }}>
                  <ContainerRow1
                    key={0}
                    icon={require("../assets/img/businessanalyticsicon.png")}
                    title="Interest Profit"
                    label={label2}
                    description={"Made this month"}
                  />
                </div>
                <div style={{ width: "32%" }}>
                  <ContainerRow1
                    key={0}
                    icon={require("../assets/img/applicationicon.png")}
                    title="Loan Request"
                    label={label3}
                    description={"Request made this month"}
                  />
                </div>
              </Box>
              <Box
                display="flex"
                alignItems="flex-start"
                width="100%"
                borderRadius="10px"
                margin="20px 20px 20px 20px"
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  Create Lender
                </Typography>
              </Box>

              <Box
                gridColumn="span 2"
                gridRow="span 2"
                background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
                border-radius="20px"
                opacity="0.95"
                paddingBottom="120px"
                marginLeft="20px"
              >
                <Card
                  style={{
                    overflowY: "scroll",
                    minHeight: "10.7vh",
                    width: "98%",
                    justifyContent: "center",
                  }}
                >
                  <Form
                    style={{ minHeight: "10.7vh" }}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    {/* <Row style={{ marginBottom: "10px" }}>
                      <Col span={12}>
                        <Upload
                          beforeUpload={handleBeforeUpload}
                          showUploadList={false}
                          fileList={selectedFile ? [selectedFile] : []}
                        >
                          {imageUrl ? (
                            <img
                              src={imageUrl}
                              // alt="Profile picture"
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50px",
                                // marginTop:"-3vh",
                                // marginBottom:"0.5vh"
                              }}
                            />
                          ) : (
                            <Avatar
                              className="profile-picture-lender"
                              icon={<UserOutlined />}
                            />
                          )}
                        </Upload>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Name">
                          <Input
                            style={{ width: "100%" }}
                            type="text"
                            value={principal_name}
                            onChange={(e) => setPrincipalName(e.target.value)}
                            required
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Email" name="email">
                          <Input
                            style={{ width: "100%" }}
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Password" name="password">
                          <Input
                            style={{ width: "100%" }}
                            type={visible ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            suffix={
                              <span
                                style={{
                                  position: "absolute",
                                  right: 5,
                                  bottom: 8,
                                }}
                              >
                                {visible ? (
                                  <EyeTwoTone
                                    onClick={() => setVisible(false)}
                                  />
                                ) : (
                                  <EyeInvisibleOutlined
                                    onClick={() => setVisible(true)}
                                  />
                                )}
                              </span>
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="Confirm Password"
                          name="confirm-password"
                        >
                          <Input
                            style={{ width: "100%" }}
                            type={visible2 ? "text" : "password"}
                            value={passwordConfirmation}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            suffix={
                              <span
                                style={{
                                  position: "absolute",
                                  right: 5,
                                  bottom: 8,
                                }}
                              >
                                {visible2 ? (
                                  <EyeTwoTone
                                    onClick={() => setVisible2(false)}
                                  />
                                ) : (
                                  <EyeInvisibleOutlined
                                    onClick={() => setVisible2(true)}
                                  />
                                )}
                              </span>
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <hr
                      style={{
                        color: "#142C44CC",
                        height: "2px",
                        width: "96.5%",
                      }}
                    />

                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => handleCreateLender()}
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "14px",
                          fontWeight: 600,
                          marginLeft: "10px",
                          borderRadius: "10px",
                          width: "25%",
                          background:
                            "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)",
                          height: "40px",
                        }}
                      >
                        Submit
                      </Button>
                      {error && (
                        <p style={{ color: "red", marginLeft: "1.3vw" }}>
                          {error}
                        </p>
                      )}
                    </Form.Item>
                  </Form>
                </Card>
              </Box>
            </Box>

            <Box
              gridColumn="span 3"
              gridRow="span 2"
              height="89vh"
              borderRadius="20px"
              border="0px solid white"
              //  style={{
              //    background:
              //      "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              //  }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // borderBottom={`4px solid ${colors.primary[500]}`}
                colors={colors.grey[910]}
                marginTop="-15px"
                marginBottom="10px"
                p="15px"
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  User Activity Log
                </Typography>
              </Box>
              {/* <Box
       display="flex"
       flexDirection="column"
       backgroundColor="#FFFFFF"
       borderRadius="20px"
       margin="-10px 10px"
       p="15px"
       height="83.7vh"
       style={{
         overflowX: "scroll",
         overflowY: "scroll",
       }}
     >
       {data && data.length > 0 ? (
         data.map((item) => (
         
           <Box
             key={item.id}
             display="flex"
             justifyContent="center"
             alignItems="flex-start" // Updated to align to the top
             colors={colors.grey[910]}
             // borderBottom={`1px solid ${colors.primary[500]}`}
             width="45%"
             textAlign="center"
             margin="1px 1px 1px 30px"
             flexDirection="column"
           >
             <ContainerActivity
               icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg" //map to data laterwards
               title={item.principal_name || "-"}
               subtitle={
                 item.user_account_type.charAt(0).toUpperCase() +
                   item.user_account_type.slice(1) || "-"
               }
             />
             <div
               style={{
                 borderBottom: "2px solid black",
                 width: "190%",
                 opacity: "0.3",
               }}
             />
           </Box>
         ))
       ) : (
         <Box
           display="flex"
           justifyContent="center"
           alignItems="center"
           colors={colors.grey[910]}
           width="100%"
           textAlign="center"
           margin="1px 1px 1px 0px"
           flexDirection="column"
           height="80vh"
         >
           <ContainerActivity
             icon={warn_ico}
             title="Please Click on Table Row to View Details"
           />
         </Box>
       )}
       {!data && (
         <Box
           display="flex"
           justifyContent="center"
           alignItems="center"
           colors={colors.grey[910]}
           width="45%"
           textAlign="center"
           margin="1px 1px 1px 30px"
           flexDirection="column"
         >
           <ContainerActivity
             icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg"
             title="NO VALUE HERE"
             subtitle="NO VALUE HERE"
           />
           <div
             style={{
               borderBottom: "2px solid black",
               width: "190%",
               opacity: "0.3",
             }}
           />
         </Box>
       )}

       <Box
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         colors={colors.grey[910]}
         marginLeft="0px"
       >
         {data &&
           data.length > 0 &&
           data.map((item) => (
             <ContainerTransaction
               title="Email"
               label={item.email || "-"}
             />
           ))}
       </Box>
       <Box
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         colors={colors.grey[910]}
         marginLeft="0px"
         marginTop="0px"
       >
         {data &&
           data.length > 0 &&
           data.map((item) => (
             <ContainerTransaction title="NRIC" label={item.nric || "-"} />
           ))}
       </Box>
       <Box
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         colors={colors.grey[910]}
         marginLeft="0px"
         marginTop="0px"
       >
         {data &&
           data.length > 0 &&
           data.map((item) => (
             <ContainerTransaction title="Sex" label={item.sex || "-"} />
           ))}
       </Box>
       <Box
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         colors={colors.grey[910]}
         marginLeft="0px"
         marginTop="0px"
       >
         {data &&
           data.length > 0 &&
           data.map((item) => (
             <ContainerTransaction title="Race" label={item.race || "-"} />
           ))}
       </Box>

       <Box
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         colors={colors.grey[910]}
         marginLeft="0px"
         marginTop="0px"
       >
         {data &&
           data.length > 0 &&
           data.map((item) => (
             <ContainerTransaction
               title="Date of Birth"
               label={item.date_of_birth || "-"}
             />
           ))}
       </Box>
       <Box
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         colors={colors.grey[910]}
         marginLeft="0px"
         marginTop="0px"
       >
         {data &&
           data.length > 0 &&
           data.map((item) => (
             <ContainerTransaction
               title="Status"
               label={ item.status ?
                 item.status.charAt(0).toUpperCase() +
                   item.status.slice(1) : "-"
               }
             />
           ))}
       </Box>
       <Box
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         colors={colors.grey[910]}
         marginTop="0px"
       >
         {data &&
           data.length > 0 &&
           data.map((item) => (
             <ContainerTransaction
               title="Residential  Status"
               label={item.residential_status || "-"}
             />
           ))}
       </Box>
       <Box
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         colors={colors.grey[910]}
         marginLeft="0px"
         marginTop="0px"
       >
         {data &&
           data.length > 0 &&
           data.map((item) => (
             <ContainerTransaction
               title="Nationality"
               label={item.nationality || "-"}
             />
           ))}
       </Box>
       <Box
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         colors={colors.grey[910]}
         marginLeft="0px"
         marginTop="0px"
       >
         {data &&
           data.length > 0 &&
           data.map((item) => (
             <ContainerTransaction
               title="Country of Birth"
               label={item.country_of_birth || "-"}
             />
           ))}
       </Box>
       <Box
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         colors={colors.grey[910]}
         marginLeft="0px"
         marginTop="0px"
       >
         {data &&
           data.length > 0 &&
           data.map((item) => (
             <ContainerTransaction
               title="Bank Name"
               label={item.bank_name || "-"}
             />
           ))}
       </Box>
       <Box
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         colors={colors.grey[910]}
         marginLeft="0px"
         marginTop="0px"
       >
         {data &&
           data.length > 0 &&
           data.map((item) => (
             <ContainerTransaction
               title="Bank Account No."
               label={item.bank_account_no || "-"}
             />
           ))}
       </Box>
       <Box
         display="flex"
         justifyContent="space-between"
         alignItems="center"
         colors={colors.grey[910]}
         marginLeft="0px"
         marginTop="0px"
       >
         {data &&
           data.length > 0 &&
           data.map((item) => (
             <ContainerTransaction
               title="Credit Score"
               label={item.credit_score || "-"}
             />
           ))}
       </Box>
       {data && data.length > 0 ? (
         data.map((item) => (
           <div
             style={{
               display: "flex",
               flexDirection: "row",
               marginTop: "10px",
               marginLeft: "30px",
             }}
           >
           
           </div>
         ))
       ) : (
         <></>
       )}
     </Box> */}
              <>
                {dataActivityLog && dataActivityLog.length > 0 ? (
                  dataActivityLog.slice(0, 8).map((item, index) => (
                    <Box
                      gridColumn="span 1"
                      display="flex"
                      alignItems="left"
                      justifyContent="left"
                      // style={{
                      //     opacity: index === 4 ? 0.3 : index === 5 ? 0.2 : 1,
                      //     transitionDelay: `${index * 0.1}s`,
                      // }}
                    >
                      <ContainerActivity
                        key={index}
                        icon={item.icon} // use the icon URL from the processed data
                        title={
                          item.user_id[0] === "L"
                            ? item.principal_name
                            : item.entity_name
                            ? item.entity_name
                            : item.principal_name || "-"
                        }
                        subtitle={item.description || "-"}
                        index={index}
                      />
                    </Box>
                  ))
                ) : (
                  <Box
                    gridColumn="span 1"
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                  >
                    <ContainerActivity2 noData="No User Activity Data" />
                  </Box>
                )}
              </>
            </Box>
          </Box>
        </Box>
  );
};

export default Users;
