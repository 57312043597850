import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Checkbox, InputNumber, Modal } from "antd";
import singlogo from "../../pages/smart-lend-frontend/src/img/singpass_logo.png";
import { CircularProgress } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import GradingIcon from "@mui/icons-material/Grading";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import OutboxIcon from "@mui/icons-material/Outbox";
import "../../assets/css/app.css";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";

const PropertyTermLoan = () => {
  const [submissionSteps, setSubmissionSteps] = useState(0);
  const [propTermRequest, setPropTermRequest] = useState([]);
  const [requestSelected, setRequestSelected] = useState(null);
  const typesOfLoans = [
    {
      loanName: "First Charge Equity Term Loan",
      sub: [
        { value: "Refinancing Outstanding Loan from Current Financier" },
        { value: "Interest Range between 6 - 10% p.a." },
        { value: "Tenor up to 25 years regardless of Age" },
        { value: "Higher LTV up to 90%" },
        { value: "Processing Fee of 0.5 - 1% applied from Financer" },
      ],
    },
    {
      loanName: "Bridging Loan",
      sub: [
        {
          value:
            "No requirement to refinance Outstanding loan from Current Financier",
        },
        { value: "Interest Range between 1.5 - 2.5% per month" },
        { value: "Tenor up to 5 years regardless of Age" },
        { value: "Higher LTV up to 90%" },
        { value: "Processing Fee of 0.5 - 1% applied from Financer" },
      ],
    },
  ];

  const [userDetails, setUserDetails] = useState(null);
  const [singpassNotification, setSingpassNotification] = useState(0);
  const [findMyHomeValuationForm, setFindMyHomeValuaionForm] = useState({
    property: null,
    addressShownData: null,
    propertyOwnership: "",
    otherOwners: [],
    cpfUsage: 0,
    accruedInterest: 0,
    valuation: null,
    propertyType: null,
    oustandingLoan: 0,
    loanAmount: 0,
    originalLoanOutstanding: 0,
    originalcpfUsage: 0,
    validationNeeded: false,
    documentForLoanOustanding: [],
    documentForCpfUsage: [],
    loanPaymentType: "",
    selectedLoanType: 0,
    loanRepaymentSchedule: [],
  });
  const [loadingResults, setLoadingResults] = useState(false);
  const [selectedLoanType, setSelectedLoanType] = useState(null);

  const [agreed, setAgreed] = useState(false);
  const [agreedError, setAgreedError] = useState(false);
  const [possibleResults, setPossibleResults] = useState(0);

  const updateRepaymentSchedule = (selectedLoanType) => {
    let numberOfMonths = selectedLoanType === 0 ? (25 * 12) : (5 * 12);
    let interest =
      findMyHomeValuationForm.loanAmount *
      (selectedLoanType === 0 ? 0.0083 : 0.025);
    let principal = findMyHomeValuationForm.loanAmount / numberOfMonths;

    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1); // Start from next month

    let loanBalance = findMyHomeValuationForm.loanAmount; // Start from full loan amount

    let newSchedule = Array.from({ length: numberOfMonths }, (_, index) => {
      let monthYear = currentDate.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      currentDate.setMonth(currentDate.getMonth() + 1); // Move to next month

      let currentPrincipal =
        findMyHomeValuationForm.loanPaymentType === "interest"
          ? index === numberOfMonths - 1
            ? findMyHomeValuationForm.loanAmount
            : 0
          : principal;

      let currentTotal =
        findMyHomeValuationForm.loanPaymentType === "interest"
          ? index === numberOfMonths - 1
            ? findMyHomeValuationForm.loanAmount + interest
            : interest
          : currentPrincipal + interest;

      let currentLoanBalance = loanBalance; // Store current balance before deduction
      loanBalance -= currentPrincipal; // Deduct principal for next month

      return {
        month: monthYear,
        principal: currentPrincipal,
        interest: interest,
        total: currentTotal,
        loanBalance: currentLoanBalance, // Loan balance before the payment
      };
    });

    setFindMyHomeValuaionForm({
      ...findMyHomeValuationForm,
      selectedLoanType: selectedLoanType,
      loanRepaymentSchedule: newSchedule,
    });
  };

  const UniversaleUploadFile = async (file, type) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "ploan");
    formData.append("file_id", "");
    // console.log(file);
    try {
      const response1 = await fetch(
        "https://www.smart-lend.com/api/file/uploadUniversalFile",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: formData,
        }
      );
      if (response1.status === 200) {
        const data = await response1.json();
        const blobUrl = data.url; // Assuming this is the URL you want to store
        // console.log(blobUrl);
        if (type === "loan") {
          setFindMyHomeValuaionForm({
            ...findMyHomeValuationForm,
            documentForLoanOustanding: [
              ...findMyHomeValuationForm.documentForLoanOustanding,
              { name: data.original_filename, url: blobUrl },
            ],
          });
        } else if (type === "cpf") {
          setFindMyHomeValuaionForm({
            ...findMyHomeValuationForm,
            documentForCpfUsage: [
              ...findMyHomeValuationForm.documentForCpfUsage,
              { name: data.original_filename, url: blobUrl },
            ],
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchuserDetails = async () => {
    let userInformation = null;
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/getUserDetails",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        const userDetailsToEnter = {
          ...json.User,
          credit_report_data: JSON.parse(json.User.credit_report_data),
          singpass_data: JSON.parse(json.User.singpass_data),
        };

        if (!userDetailsToEnter.singpass_last_sync) {
          setSingpassNotification(1);
        } else if (userDetailsToEnter.singpass_last_sync) {
          const lastUpdateDate = new Date(
            userDetailsToEnter.singpass_last_sync
          );
          const currentDate = new Date();
          const oneMonthAgo = new Date();
          oneMonthAgo.setMonth(currentDate.getMonth() - 1);
          if (lastUpdateDate < oneMonthAgo) {
            setSingpassNotification(2);
          }
        }
        console.log(userDetailsToEnter);
        setUserDetails(userDetailsToEnter);

        const privateResidentialLoans =
          userDetailsToEnter.credit_report_data?.NoteworthyOngoingLoans.filter(
            (tram) => tram.includes("private residential purchase")
          );

        if (privateResidentialLoans.length === 1) {
          setFindMyHomeValuaionForm({
            ...findMyHomeValuationForm,
            oustandingLoan: parseFloat(
              privateResidentialLoans?.[0]
                ?.split("   ")
                [privateResidentialLoans?.[0]?.split("   ").length - 4].replace(
                  /,/g,
                  ""
                )
            ),
            originalLoanOutstanding: parseFloat(
              privateResidentialLoans?.[0]
                ?.split("   ")
                [privateResidentialLoans?.[0]?.split("   ").length - 4].replace(
                  /,/g,
                  ""
                )
            ),
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchuserDetails();
  }, []);

  const startSeachResults = async (toSearch) => {
    // Initialize possibleResults array with empty slots
    setLoadingResults(true);
    try {
      let item2 = {
        propertyTypeCode: "land",
        postalCode: toSearch.address.postal.value,
        addressUnit: ``,
        addressNumber: "",
        addressStreet: "",
        valuationType: "sale",
        projectName: "",
      };

      const response2 = await fetch(
        `https://www.smart-lend.com/api/property/getPropertyValuation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item2),
        }
      );
      let item3 = {
        propertyTypeCode: "condo",
        postalCode: toSearch.address.postal.value,
        addressUnit: ``,
        addressNumber: "",
        addressStreet: "",
        valuationType: "sale",
        projectName: "",
      };

      const response3 = await fetch(
        `https://www.smart-lend.com/api/property/getPropertyValuation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item3),
        }
      );

      //   let item4 = {
      //     propertyTypeCode: "office",
      //     postalCode: toSearch.address.postal.value,
      //     addressUnit: ``,
      //     addressNumber: "",
      //     addressStreet: "",
      //     valuationType: "sale",
      //     projectName: "",
      //   };

      //   const response4 = await fetch(
      //     `https://www.smart-lend.com/api/property/getPropertyValuation`,
      //     {
      //       method: "POST",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify(item4),
      //     }
      //   );

      const json2 = await response2.json();
      const json3 = await response3.json();
      //   const json4 = await response4.json();

      // Store the result in the correct index
      const updatingpossibleResults = [
        ...json2.data,
        ...json3.data,
        // ...json4.data,
      ];

      const floorUnit = `${toSearch.address.floor.value}-${toSearch.address.unit.value}`;
      console.log(updatingpossibleResults);
      if (!updatingpossibleResults || updatingpossibleResults.length === 0) {
        console.log(0);
        setPossibleResults(0);
        setLoadingResults(false);
      } else if (updatingpossibleResults.length === 1) {
        console.log({
          value: updatingpossibleResults?.[0]?.saleValuation,
          type: updatingpossibleResults?.[0]?.propertyTypeCode,
        });
        setPossibleResults({
          value: updatingpossibleResults?.[0]?.saleValuation,
          type: updatingpossibleResults?.[0]?.propertyTypeCode,
        });
        setLoadingResults(false);
      } else {
        const matchingResults = updatingpossibleResults.filter(
          (result) => result.addressUnit === floorUnit
        );

        if (matchingResults.length > 1) {
          console.log(0);
          setPossibleResults(0);
          setLoadingResults(false);
        } else if (matchingResults.length === 1) {
          console.log({
            value: matchingResults?.[0]?.saleValuation,
            type: matchingResults?.[0]?.propertyTypeCode,
          });
          setPossibleResults({
            value: matchingResults?.[0]?.saleValuation,
            type: matchingResults?.[0]?.propertyTypeCode,
          });
          setLoadingResults(false);
        } else {
          console.log(0);
          setPossibleResults(0);
          setLoadingResults(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMyPropLoans = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/propTermLoan/getMyPropLoans",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            "Content-Type": "application/json", // Add this
          },
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        setPropTermRequest(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getMyPropLoans();
  }, []);

  const createPropLoan = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/propTermLoan/createPropLoan",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            "Content-Type": "application/json", // Add this
          },
          body: JSON.stringify({
            loan_data: JSON.stringify(findMyHomeValuationForm),
          }),
        }
      );

      const data = await response.json();

      if (response.status === 201) {
        setSubmissionSteps(0);
        getMyPropLoans();
        setRequestSelected(data.propTermLoanRequest.id);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (findMyHomeValuationForm.property) {
      setPossibleResults(0);
      startSeachResults(findMyHomeValuationForm.property);
    }
  }, [findMyHomeValuationForm.property]);

  return (
    <div
      style={{
        height:
          window.innerWidth < 1200
            ? `calc(${window.innerHeight}px - 80px)`
            : "calc(100% - 80px)",
        width: window.innerWidth < 1200 ? "100%" : "calc(100% - 40px)",
        marginLeft: window.innerWidth < 1200 ? "0px" : "20px",
        background:
          window.innerWidth < 1200
            ? "transparent"
            : "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        gap: "5px",
        position: "relative",
        overflowY: "auto",
      }}
    >
      {/* <Modal
        width="50%"
        style={{ padding: "20px 40px" }}
        footer={null}
        open={singpassNotification !== 0}
        // onOk={handleOk}
        onCancel={() => {
          setSingpassNotification(0);
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "rgba(20, 44, 68, 0.8)",
            fontWeight: 800,
            fontFamily: "Manrope, sans-serif",
            fontSize: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {singpassNotification === 2 ? (
              <div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "10%",
                    textAlign: "center",
                    lineHeight: "24px",
                  }}
                >
                  Your singpass data is outdated
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: 300,
                  }}
                >
                  Please resync your account to proceed applying for a loan.
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "10%",
                    textAlign: "center",
                    lineHeight: "24px",
                  }}
                >
                  Singpass Data not found.
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: 300,
                  }}
                >
                  Please Sync your account with Singpass to proceed applying for
                  a loan.
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <div
                onClick={() => {
                  window.location.href = "/stg-b-profile";
                }}
                style={{
                  border: "2px solid rgba(20, 44, 68, 0.8)",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  cursor: "pointer",
                  fontSize: 15,
                }}
              >
                Sync With
                <img
                  src={singlogo}
                  style={{
                    objectFit: "cover",
                    width: "100px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
      {submissionSteps === 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
          }}
        >
          {propTermRequest.length > 0 ? (
            <div
              style={{
                height: "80vh",
                background: "rgba(256,256,256,0.6)",
                width: requestSelected ? "20%" : "30.5%",
                borderRadius: "8px",
                padding: "10px",
                color: "#142C44CC",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontWeight: "700" }}>Loan Requests</div>
                <div
                  className="mui_button"
                  style={{
                    borderRadius: "20px",
                    fontSize: "12px",
                    fontWeight: "700",
                    fontFamily: "Poppins, sans-serif",
                    padding: "4px 7px",
                    background: "rgb(14, 90, 165)",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "7px",
                  }}
                  onClick={() => {
                    setRequestSelected(null)
                  }}
                >
                  <div
                    style={{
                      marginLeft: "20px",
                      marginRight: "10px",
                    }}
                  >
                    Create
                  </div>
                  <div
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      background: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AddIcon
                      style={{
                        color: "rgb(14, 90, 165)",
                        fontSize: "24px",
                      }}
                    />
                  </div>
                </div>
              </div>
              {propTermRequest.map((pro, index) => (
                <div
                  key={index}
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    marginTop: "10px",
                    fontSize: "12px",
                    color: requestSelected === pro.id ? "white" : "#142C44CC",
                    background:
                      requestSelected === pro.id
                        ? "rgb(14, 90, 165)"
                        : "transparent",
                  }}
                  onClick={() => {
                    setRequestSelected(pro.id);
                  }}
                  className="mui_button"
                >
                  <div style={{ fontSize: "10px", opacity: 0.6 }}>
                    Loan Amount
                  </div>
                  <div>
                    {pro.loan_data.loanAmount?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "SGD",
                    })}
                  </div>
                  <div
                    style={{
                      fontSize: "10px",
                      opacity: 0.6,
                      marginTop: "10px",
                    }}
                  >
                    Property
                  </div>
                  <div>
                    {(pro.loan_data.property.address.unit?.value !==
                      undefined &&
                    pro.loan_data.property.address.unit?.value !== null
                      ? `Unit ${pro.loan_data.property.address.unit?.value}, `
                      : "") +
                      (pro.loan_data.property.address.floor?.value !==
                        undefined &&
                      pro.loan_data.property.address.floor?.value !== null
                        ? `Floor ${pro.loan_data.property.address.floor?.value}, `
                        : "") +
                      (pro.loan_data.property.address.block?.value !==
                        undefined &&
                      pro.loan_data.property.address.block?.value !== null
                        ? `Block ${pro.loan_data.property.address.block?.value}, `
                        : "") +
                      (pro.loan_data.property.address.street?.value !==
                      undefined
                        ? `${pro.loan_data.property.address.street?.value}, `
                        : "") +
                      (pro.loan_data.property.address.postal?.value !==
                      undefined
                        ? `${pro.loan_data.property.address.postal?.value}, `
                        : "") +
                      (pro.loan_data.property.address.country?.desc !==
                      undefined
                        ? `${pro.loan_data.property.address.country?.desc}.`
                        : "")}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {requestSelected ? (
            <div
              style={{
                width: "70%",
                marginLeft: "20px",
                color: "#142C44CC",
              }}
            >
              <div
                style={{
                  height: "calc(30vh - 10px)",
                  background: "rgba(256,256,256,0.6)",
                  width: "100%",
                  borderRadius: "8px",
                  padding: "10px",
                  color: "#142C44CC",
                  overflowY: "auto",
                }}
              >
                <div style={{ fontWeight: "700" }}>Loan Details</div>
                <div style={{ marginTop: "20px" }}>
                  <div style={{ fontSize: "10px", opacity: 0.7 }}>
                    Property Selected:
                  </div>
                  <div>
                    {(propTermRequest.filter(
                      (prop) => prop.id === requestSelected
                    )?.[0]?.loan_data?.property.address.unit?.value !==
                      undefined &&
                    propTermRequest.filter(
                      (prop) => prop.id === requestSelected
                    )?.[0]?.loan_data?.property.address.unit?.value !== null
                      ? `Unit ${
                          propTermRequest.filter(
                            (prop) => prop.id === requestSelected
                          )?.[0]?.loan_data?.property.address.unit?.value
                        }, `
                      : "") +
                      (propTermRequest.filter(
                        (prop) => prop.id === requestSelected
                      )?.[0]?.loan_data?.property.address.floor?.value !==
                        undefined &&
                      propTermRequest.filter(
                        (prop) => prop.id === requestSelected
                      )?.[0]?.loan_data?.property.address.floor?.value !== null
                        ? `Floor ${
                            propTermRequest.filter(
                              (prop) => prop.id === requestSelected
                            )?.[0]?.loan_data?.property.address.floor?.value
                          }, `
                        : "") +
                      (propTermRequest.filter(
                        (prop) => prop.id === requestSelected
                      )?.[0]?.loan_data?.property.address.block?.value !==
                        undefined &&
                      propTermRequest.filter(
                        (prop) => prop.id === requestSelected
                      )?.[0]?.loan_data?.property.address.block?.value !== null
                        ? `Block ${
                            propTermRequest.filter(
                              (prop) => prop.id === requestSelected
                            )?.[0]?.loan_data?.property.address.block?.value
                          }, `
                        : "") +
                      (propTermRequest.filter(
                        (prop) => prop.id === requestSelected
                      )?.[0]?.loan_data?.property.address.street?.value !==
                      undefined
                        ? `${
                            propTermRequest.filter(
                              (prop) => prop.id === requestSelected
                            )?.[0]?.loan_data?.property.address.street?.value
                          }, `
                        : "") +
                      (propTermRequest.filter(
                        (prop) => prop.id === requestSelected
                      )?.[0]?.loan_data?.property.address.postal?.value !==
                      undefined
                        ? `${
                            propTermRequest.filter(
                              (prop) => prop.id === requestSelected
                            )?.[0]?.loan_data?.property.address.postal?.value
                          }, `
                        : "") +
                      (propTermRequest.filter(
                        (prop) => prop.id === requestSelected
                      )?.[0]?.loan_data?.property.address.country?.desc !==
                      undefined
                        ? `${
                            propTermRequest.filter(
                              (prop) => prop.id === requestSelected
                            )?.[0]?.loan_data?.property.address.country?.desc
                          }.`
                        : "")}
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ width: "50%" }}>
                    <div style={{ fontSize: "10px", opacity: 0.7 }}>
                      Property Valuation:
                    </div>
                    <div>
                      {propTermRequest
                        .filter((prop) => prop.id === requestSelected)?.[0]
                        ?.loan_data?.valuation?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div style={{ fontSize: "10px", opacity: 0.7 }}>
                      Property LTV:
                    </div>
                    <div>
                      {(
                        ((propTermRequest.filter(
                          (prop) => prop.id === requestSelected
                        )?.[0]?.loan_data?.oustandingLoan +
                          propTermRequest.filter(
                            (prop) => prop.id === requestSelected
                          )?.[0]?.loan_data?.cpfUsage +
                          propTermRequest.filter(
                            (prop) => prop.id === requestSelected
                          )?.[0]?.loan_data?.accruedInterest) /
                          propTermRequest.filter(
                            (prop) => prop.id === requestSelected
                          )?.[0]?.loan_data?.valuation) *
                        100
                      ).toFixed(2)}{" "}
                      %
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div style={{ fontSize: "10px", opacity: 0.7 }}>
                      Loan Amount:
                    </div>
                    <div>
                      {propTermRequest
                        .filter((prop) => prop.id === requestSelected)?.[0]
                        ?.loan_data?.loanAmount?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ width: "50%" }}>
                    <div style={{ fontSize: "10px", opacity: 0.7 }}>
                      Loan Type:
                    </div>
                    <div>
                      {propTermRequest.filter(
                        (prop) => prop.id === requestSelected
                      )?.[0]?.loan_data?.loanPaymentType === "interest"
                        ? "Interest Only"
                        : "Interest and principal"}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div style={{ fontSize: "10px", opacity: 0.7 }}>
                      Disbursement Amount:
                    </div>
                    <div>
                      {(
                        propTermRequest.filter(
                          (prop) => prop.id === requestSelected
                        )?.[0]?.loan_data?.loanAmount * 0.99
                      )?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "SGD",
                      })}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div style={{ fontSize: "10px", opacity: 0.7 }}>
                      Processing Fee:
                    </div>
                    <div>
                      {(
                        propTermRequest.filter(
                          (prop) => prop.id === requestSelected
                        )?.[0]?.loan_data?.loanAmount * 0.01
                      )?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "SGD",
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "calc(50vh - 10px)",
                  background: "rgba(256,256,256,0.6)",
                  width: "100%",
                  borderRadius: "8px",
                  padding: "10px",
                  color: "#142C44CC",
                  overflowY: "auto",
                  marginTop: "20px",
                }}
              >
                <div style={{ fontWeight: "700" }}>Loan Offers</div>
                {propTermRequest.filter(
                  (prop) => prop.id === requestSelected
                )?.[0]?.offers.length > 0 ? (
                  propTermRequest
                    .filter((prop) => prop.id === requestSelected)?.[0]
                    ?.offers?.map((off, index) => <div key={index}> </div>)
                ) : (
                  <div
                    style={{
                      height: "90%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AccessTimeIcon
                      style={{ color: "grey", fontSize: "80px" }}
                    />
                    <div
                      style={{
                        width: "200px",
                        fontSize: "12px",
                        color: "grey",
                        textAlign: "center",
                        marginTop: "15px",
                      }}
                    >
                      You currently do not have any offers for your loan
                      request.
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
              }}
            >
              {singpassNotification !== 0 ? (
                <div
                  style={{
                    width: "50%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "rgba(20, 44, 68, 0.8)",
                    fontWeight: 800,
                    fontFamily: "Manrope, sans-serif",
                    fontSize: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    {singpassNotification === 2 ? (
                      <div>
                        <div
                          style={{
                            width: "80%",
                            marginLeft: "10%",
                            textAlign: "center",
                            lineHeight: "24px",
                          }}
                        >
                          Your singpass data is outdated
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: 300,
                          }}
                        >
                          Please resync your account to proceed applying for a
                          loan.
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div
                          style={{
                            width: "80%",
                            marginLeft: "10%",
                            textAlign: "center",
                            lineHeight: "24px",
                          }}
                        >
                          Singpass Data not found.
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: 300,
                          }}
                        >
                          Please Sync your account with Singpass to proceed
                          applying for a loan.
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        onClick={() => {
                          window.location.href = "/stg-b-profile";
                        }}
                        style={{
                          border: "2px solid rgba(20, 44, 68, 0.8)",
                          borderRadius: "10px",
                          padding: "10px 20px",
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                          cursor: "pointer",
                          fontSize: 15,
                        }}
                      >
                        Sync With
                        <img
                          src={singlogo}
                          style={{
                            objectFit: "cover",
                            width: "100px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : userDetails && userDetails.singpass_data ? (
                <div
                  style={{
                    width: "45%",
                    height: "80vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    color: "#142C44CC",
                    marginLeft: "30px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "30px",
                      fontWeight: 700,
                      lineHeight: "34px",
                      width: "80%",
                      marginTop: "5px",
                    }}
                  >
                    Find My Home Valuation
                  </div>
                  <div style={{ width: "80%", marginTop: "10px" }}>
                    <div style={{ width: "100%" }}>Select your property</div>
                    <div style={{ width: "100%" }}>
                      <select
                        value={
                          JSON.stringify(findMyHomeValuationForm.property) || ""
                        }
                        onChange={(e) => {
                          if (e.target.value !== "") {
                            const data = JSON.parse(e.target.value);
                            const addressToInsert = data.address.floor.value
                              ? (data.address?.unit?.value !== undefined
                                  ? `Unit ${data.address?.unit?.value}, `
                                  : "") +
                                (data.address?.floor?.value !== undefined
                                  ? `Floor ${data.address?.floor?.value}, `
                                  : "") +
                                (data.address?.block?.value !== undefined
                                  ? `Block ${data.address?.block?.value}, `
                                  : "") +
                                (data.address?.street?.value !== undefined
                                  ? `${data.address?.street?.value}, `
                                  : "") +
                                (data.address?.postal?.value !== undefined
                                  ? `${data.address?.postal?.value}, `
                                  : "") +
                                (data.address?.country?.desc !== undefined
                                  ? `${data.address?.country?.desc}.`
                                  : "")
                              : (data.address?.block?.value !== undefined
                                  ? `${data.address?.block?.value}, `
                                  : "") +
                                (data.address?.street?.value !== undefined
                                  ? `${data.address?.street?.value}, `
                                  : "") +
                                (data.address?.postal?.value !== undefined
                                  ? `${data.address?.postal?.value}, `
                                  : "") +
                                (data.address?.country?.desc !== undefined
                                  ? `${data.address?.country?.desc}.`
                                  : "");
                            setFindMyHomeValuaionForm({
                              ...findMyHomeValuationForm,
                              property: data,
                              addressShownData: addressToInsert,
                              cpfUsage: data.principalwithdrawalamt.value,
                              originalcpfUsage:
                                data.principalwithdrawalamt.value,
                              accruedInterest: data.accruedinterestamt.value,
                            });
                          } else {
                            setFindMyHomeValuaionForm({
                              ...findMyHomeValuationForm,
                              property: null,
                              addressShownData: null,
                            });
                          }
                        }}
                        style={{
                          outline: "none",
                          border: "1px solid lightgrey",
                          borderRadius: "8px",
                          fontSize: "12px",
                          padding: "5px 10px",
                          width: "100%",
                        }}
                      >
                        <option value={""}>Select a property</option>
                        {userDetails?.singpass_data?.person?.cpfhousingwithdrawal?.withdrawaldetails?.map(
                          (withdrawal, index) => (
                            <option
                              key={index}
                              value={JSON.stringify(withdrawal)}
                            >
                              {withdrawal.address.floor.value
                                ? (withdrawal.address?.unit?.value !== undefined
                                    ? `Unit ${withdrawal.address?.unit?.value}, `
                                    : "") +
                                  (withdrawal.address?.floor?.value !==
                                  undefined
                                    ? `Floor ${withdrawal.address?.floor?.value}, `
                                    : "") +
                                  (withdrawal.address?.block?.value !==
                                  undefined
                                    ? `Block ${withdrawal.address?.block?.value}, `
                                    : "") +
                                  (withdrawal.address?.street?.value !==
                                  undefined
                                    ? `${withdrawal.address?.street?.value}, `
                                    : "") +
                                  (withdrawal.address?.postal?.value !==
                                  undefined
                                    ? `${withdrawal.address?.postal?.value}, `
                                    : "") +
                                  (withdrawal.address?.country?.desc !==
                                  undefined
                                    ? `${withdrawal.address?.country?.desc}.`
                                    : "")
                                : (withdrawal.address?.block?.value !==
                                  undefined
                                    ? `${withdrawal.address?.block?.value}, `
                                    : "") +
                                  (withdrawal.address?.street?.value !==
                                  undefined
                                    ? `${withdrawal.address?.street?.value}, `
                                    : "") +
                                  (withdrawal.address?.postal?.value !==
                                  undefined
                                    ? `${withdrawal.address?.postal?.value}, `
                                    : "") +
                                  (withdrawal.address?.country?.desc !==
                                  undefined
                                    ? `${withdrawal.address?.country?.desc}.`
                                    : "")}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  {loadingResults ? (
                    <div
                      style={{
                        width: "90%",
                        height: "20vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <CircularProgress
                        style={{ color: "rgba(41, 75, 110, 0.5)" }}
                        size={50}
                        thickness={5} // Adjust thickness as needed
                      />
                    </div>
                  ) : findMyHomeValuationForm.property ? (
                    <div style={{ width: "80%", marginTop: "5px" }}>
                      {possibleResults === 0 ? (
                        <div>
                          No valuation found. Please contact your{" "}
                          <span
                            style={{
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                          >
                            account manager
                          </span>{" "}
                          for further instructions.
                        </div>
                      ) : (
                        <div style={{ width: "100%", marginTop: "5px" }}>
                          <div style={{ width: "100%" }}>
                            Property outstanding loan amount
                          </div>
                          <div style={{ width: "100%" }}>
                            <InputNumber
                              style={{
                                width: "100%",
                                borderRadius: "8px",
                                fontSize: "12px",
                                outline: "none",
                              }}
                              min={0}
                              value={findMyHomeValuationForm.oustandingLoan}
                              formatter={
                                (value) =>
                                  `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  ) // Adds commas
                              }
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              } // Removes formatting for storage
                              onChange={(value) => {
                                setFindMyHomeValuaionForm({
                                  ...findMyHomeValuationForm,
                                  oustandingLoan: value,
                                });
                                console.log(value);
                              }}
                            />
                          </div>
                          <div style={{ width: "100%", marginTop: "10px" }}>
                            CPF Housing Withdrawal Usage
                          </div>
                          <div style={{ width: "100%" }}>
                            <InputNumber
                              style={{
                                width: "100%",
                                borderRadius: "8px",
                                fontSize: "12px",
                                outline: "none",
                              }}
                              min={0}
                              value={findMyHomeValuationForm.cpfUsage}
                              formatter={
                                (value) =>
                                  `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  ) // Adds commas
                              }
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, "")
                              } // Removes formatting for storage
                              onChange={(value) => {
                                setFindMyHomeValuaionForm({
                                  ...findMyHomeValuationForm,
                                  cpfUsage: value,
                                });
                                console.log(value);
                              }}
                            />
                          </div>

                          <div style={{ width: "100%", marginTop: "10px" }}>
                            <div style={{ width: "100%" }}>
                              Property Ownership
                            </div>
                            <select
                              onChange={(e) => {
                                if (e.target.value === "Single") {
                                  setFindMyHomeValuaionForm({
                                    ...findMyHomeValuationForm,
                                    propertyOwnership: e.target.value,
                                    otherOwners: [],
                                  });
                                } else {
                                  setFindMyHomeValuaionForm({
                                    ...findMyHomeValuationForm,
                                    propertyOwnership: e.target.value,
                                    otherOwners: [
                                      { name: "", email: "", phone: "" },
                                    ],
                                  });
                                }
                              }}
                              value={findMyHomeValuationForm.propertyOwnership}
                              style={{
                                outline: "none",
                                border: "1px solid lightgrey",
                                borderRadius: "8px",
                                fontSize: "12px",
                                padding: "5px 10px",
                                width: "100%",
                              }}
                            >
                              <option value={""}>
                                Select an ownership type
                              </option>
                              <option value={"Single"}>Single Owner</option>
                              <option value={"Joint"}>Joint Ownership</option>
                              <option value={"JointS"}>
                                Joint Ownership (Unable to provide
                                authentication)
                              </option>
                            </select>
                          </div>

                          {findMyHomeValuationForm.propertyOwnership ===
                          "Joint" ? (
                            <div style={{ width: "100%", marginTop: "10px" }}>
                              <div style={{ width: "100%" }}>
                                Other owners information
                              </div>
                              {findMyHomeValuationForm.otherOwners.map(
                                (oto, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      marginTop: index !== 0 ? "15px" : "0px",
                                      position: "relative",
                                    }}
                                  >
                                    {index !== 0 ? (
                                      <div
                                        className="mui_button"
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "calc(100% + 10px)",
                                          transform: "translate(0, -50%)",
                                        }}
                                        onClick={() => {
                                          const updatingFindmyHome = {
                                            ...findMyHomeValuationForm,
                                          };
                                          updatingFindmyHome.otherOwners.splice(
                                            index,
                                            1
                                          );
                                          setFindMyHomeValuaionForm(
                                            updatingFindmyHome
                                          );
                                        }}
                                      >
                                        <RemoveCircleOutlineIcon
                                          style={{ color: "grey" }}
                                        />
                                      </div>
                                    ) : null}
                                    <div>
                                      <input
                                        placeholder="Full name"
                                        value={
                                          findMyHomeValuationForm.otherOwners[
                                            index
                                          ].name
                                        }
                                        onChange={(e) => {
                                          const updatingFindmyHomevaluation = {
                                            ...findMyHomeValuationForm,
                                          };
                                          updatingFindmyHomevaluation.otherOwners[
                                            index
                                          ].name = e.target.value;
                                          setFindMyHomeValuaionForm(
                                            updatingFindmyHomevaluation
                                          );
                                        }}
                                        style={{
                                          outline: "none",
                                          border: "1px solid lightgrey",
                                          borderRadius: "8px",
                                          fontSize: "12px",
                                          padding: "5px 10px",
                                          width: "100%",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        gap: "10px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      <input
                                        placeholder="Email address"
                                        type="email"
                                        value={
                                          findMyHomeValuationForm.otherOwners[
                                            index
                                          ].email
                                        }
                                        onChange={(e) => {
                                          const updatingFindmyHomevaluation = {
                                            ...findMyHomeValuationForm,
                                          };
                                          updatingFindmyHomevaluation.otherOwners[
                                            index
                                          ].email = e.target.value;
                                          setFindMyHomeValuaionForm(
                                            updatingFindmyHomevaluation
                                          );
                                        }}
                                        style={{
                                          outline: "none",
                                          border: "1px solid lightgrey",
                                          borderRadius: "8px",
                                          fontSize: "12px",
                                          padding: "5px 10px",
                                          width: "50%",
                                        }}
                                      />
                                      <input
                                        placeholder="Phone number"
                                        type="phone"
                                        value={
                                          findMyHomeValuationForm.otherOwners[
                                            index
                                          ].phone
                                        }
                                        onChange={(e) => {
                                          const updatingFindmyHomevaluation = {
                                            ...findMyHomeValuationForm,
                                          };
                                          updatingFindmyHomevaluation.otherOwners[
                                            index
                                          ].phone = e.target.value;
                                          setFindMyHomeValuaionForm(
                                            updatingFindmyHomevaluation
                                          );
                                        }}
                                        style={{
                                          outline: "none",
                                          border: "1px solid lightgrey",
                                          borderRadius: "8px",
                                          fontSize: "12px",
                                          padding: "5px 10px",
                                          width: "50%",
                                        }}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                              <div
                                style={{ display: "flex", marginTop: "5px" }}
                              >
                                <div
                                  className="mui_button"
                                  style={{
                                    borderRadius: "20px",
                                    padding: "4px 25px",
                                    color: "rgb(14, 90, 165)",
                                    border: "2px solid rgb(14, 90, 165)",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "10px",
                                  }}
                                  onClick={() => {
                                    setFindMyHomeValuaionForm({
                                      ...findMyHomeValuationForm,
                                      otherOwners: [
                                        ...findMyHomeValuationForm.otherOwners,
                                        { name: "", email: "", phone: "" },
                                      ],
                                    });
                                  }}
                                >
                                  Add Owners
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {findMyHomeValuationForm.property &&
                          findMyHomeValuationForm.cpfUsage &&
                          findMyHomeValuationForm.oustandingLoan &&
                          (findMyHomeValuationForm.propertyOwnership ===
                            "Single" ||
                            findMyHomeValuationForm.propertyOwnership ===
                              "JointS" ||
                            (findMyHomeValuationForm.propertyOwnership ===
                              "Joint" &&
                              findMyHomeValuationForm.otherOwners.length > 0 &&
                              findMyHomeValuationForm.otherOwners.every(
                                (owner) =>
                                  owner.name.trim() !== "" &&
                                  owner.email.trim() !== "" &&
                                  owner.phone.trim() !== ""
                              ))) ? (
                            <div>
                              <div
                                style={{ display: "flex", marginTop: "20px" }}
                              >
                                <div
                                  className="mui_button"
                                  style={{
                                    borderRadius: "20px",
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    fontFamily: "Poppins, sans-serif",
                                    padding: "8px",
                                    background: "rgb(14, 90, 165)",
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "10px",
                                  }}
                                  onClick={() => {
                                    if (agreed) {
                                      if (
                                        findMyHomeValuationForm.originalLoanOutstanding !==
                                          findMyHomeValuationForm.oustandingLoan ||
                                        findMyHomeValuationForm.cpfUsage !==
                                          findMyHomeValuationForm.originalcpfUsage
                                      ) {
                                        setSubmissionSteps(0.5);
                                        setFindMyHomeValuaionForm({
                                          ...findMyHomeValuationForm,
                                          validationNeeded: true,
                                          valuation:
                                            possibleResults.type === "land"
                                              ? possibleResults.value * 0.9
                                              : possibleResults.value * 0.8,
                                          propertyType: possibleResults.type,
                                        });
                                      } else {
                                        setSubmissionSteps(1);
                                        console.log("proceeed");
                                      }
                                    } else {
                                      setAgreedError(true);
                                    }
                                  }}
                                >
                                  <div
                                    style={{
                                      marginLeft: "20px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Apply Now
                                  </div>
                                  <div
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      borderRadius: "50%",
                                      background: "white",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <NavigateNextIcon
                                      style={{
                                        color: "rgb(14, 90, 165)",
                                        fontSize: "24px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <Checkbox
                                onChange={(e) => {
                                  setAgreedError(false);
                                  setAgreed(e.target.checked);
                                }}
                                style={{ fontSize: "10px", marginTop: "5px" }}
                              >
                                I confirm that the information provided above is
                                up to date and correct.
                              </Checkbox>
                              {agreedError ? (
                                <div
                                  style={{
                                    color: "red",
                                    marginTop: "5px",
                                    fontSize: "10px",
                                  }}
                                >
                                  Please check the above disclaimer before
                                  proceeding.
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                          {/* {possibleResults?.value?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "SGD",
                  })} */}
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}

              <div
                style={{
                  width: "50%",
                  height: "80vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  color: "#142C44CC",
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: 300 }}>
                  Property Term Loan
                </div>
                <div
                  style={{
                    fontSize: "35px",
                    fontWeight: 700,
                    lineHeight: "34px",
                    width: "80%",
                    marginTop: "5px",
                  }}
                >
                  Get up to 90% of your home equity in cash for your business
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    width: "80%",
                    marginTop: "10px",
                  }}
                >
                  Property loans help business unlock the value of their
                  residential of commercial properties, which could provide
                  additional cash flow for operations or new investments.
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    width: "80%",
                    marginTop: "5px",
                  }}
                >
                  Business—No TDSR Assessment Required! Unlock the potential of
                  your property with our Property Equity Term Loan! You can
                  access up to 85% of your home equity in cash to fuel your
                  business growth, all without
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    width: "80%",
                    marginTop: "20px",
                  }}
                >
                  The hassle TDSR assessment.
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    width: "80%",
                    marginTop: "5px",
                  }}
                >
                  Instant Equity Access at Your FingertipsWith our Smart
                  Valuation tools, determining your cash-out equity is a breeze.
                  Simply scan your SingPass, and get an instant assessment of
                  how much you can unlock from your property.
                </div>
              </div>
            </div>
          )}
        </div>
      ) : submissionSteps === 0.5 ? (
        <div style={{ color: "#142C44CC" }}>
          <div
            style={{
              fontSize: "30px",
              fontWeight: 700,
              lineHeight: "34px",
              width: "80%",
              marginTop: "5px",
            }}
          >
            Property Information Validation
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: 300,
              width: "80%",
              marginTop: "10px",
            }}
          >
            Our information of your property doesnt align with what you've
            updated, we would like to request documentation or any form of
            screenshot showing the information you've provided is accurate.
          </div>
          {findMyHomeValuationForm.originalLoanOutstanding !==
          findMyHomeValuationForm.oustandingLoan ? (
            <div style={{ marginTop: "20px" }}>
              <div>
                Documentation or snapshot of Loan Oustanding on property
              </div>
              <input
                onChange={(e) => {
                  UniversaleUploadFile(e.target.files[0], "loan");
                }}
                style={{
                  outline: "none",
                  border: "1px solid lightgrey",
                  borderRadius: "8px",
                  fontSize: "12px",
                  padding: "5px 10px",
                  width: "50%",
                }}
                type="file"
              />
              <div>
                {findMyHomeValuationForm.documentForLoanOustanding.map(
                  (fi, index) => (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: "10px",
                        padding: "5px 10px",
                        borderRadius: "8px",
                        border: "1px solid lightgrey",
                        width: "50%",
                        marginTop: "5px",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <FileCopyIcon style={{ fontSize: "18px" }} />
                        {fi.name}
                      </div>
                      <div style={{ display: "flex", marginTop: "5px" }}>
                        <div
                          className="mui_button"
                          style={{
                            borderRadius: "20px",
                            padding: "2px 25px",
                            color: "rgb(14, 90, 165)",
                            border: "2px solid rgb(14, 90, 165)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                          onClick={() => {
                            const updatingFindmyHome = {
                              ...findMyHomeValuationForm,
                            };
                            updatingFindmyHome.documentForLoanOustanding.splice(
                              index,
                              1
                            );
                            setFindMyHomeValuaionForm(updatingFindmyHome);
                          }}
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}
          {findMyHomeValuationForm.cpfUsage !==
          findMyHomeValuationForm.originalcpfUsage ? (
            <div style={{ marginTop: "20px" }}>
              <div>Documentation or snapshot of CPF Usage on property</div>
              <input
                onChange={(e) => {
                  UniversaleUploadFile(e.target.files[0], "cpf");
                }}
                style={{
                  outline: "none",
                  border: "1px solid lightgrey",
                  borderRadius: "8px",
                  fontSize: "12px",
                  padding: "5px 10px",
                  width: "50%",
                }}
                type="file"
              />
              <div>
                {findMyHomeValuationForm.documentForCpfUsage.map(
                  (fi, index) => (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: "10px",
                        padding: "5px 10px",
                        borderRadius: "8px",
                        border: "1px solid lightgrey",
                        width: "50%",
                        marginTop: "5px",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <FileCopyIcon style={{ fontSize: "18px" }} />
                        {fi.name}
                      </div>
                      <div style={{ display: "flex", marginTop: "5px" }}>
                        <div
                          className="mui_button"
                          style={{
                            borderRadius: "20px",
                            padding: "2px 25px",
                            color: "rgb(14, 90, 165)",
                            border: "2px solid rgb(14, 90, 165)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                          onClick={() => {
                            const updatingFindmyHome = {
                              ...findMyHomeValuationForm,
                            };
                            updatingFindmyHome.documentForCpfUsage.splice(
                              index,
                              1
                            );
                            setFindMyHomeValuaionForm(updatingFindmyHome);
                          }}
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}
          {(findMyHomeValuationForm.originalLoanOutstanding !==
          findMyHomeValuationForm.oustandingLoan
            ? findMyHomeValuationForm.documentForLoanOustanding.length > 0
            : true) &&
          (findMyHomeValuationForm.cpfUsage !==
          findMyHomeValuationForm.originalcpfUsage
            ? findMyHomeValuationForm.documentForCpfUsage.length > 0
            : true) ? (
            <div style={{ display: "flex", marginTop: "20px" }}>
              <div
                className="mui_button"
                style={{
                  borderRadius: "20px",
                  fontSize: "14px",
                  fontWeight: "700",
                  fontFamily: "Poppins, sans-serif",
                  padding: "8px",
                  background: "rgb(14, 90, 165)",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
                onClick={() => {
                  setSubmissionSteps(1);
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    marginRight: "10px",
                  }}
                >
                  Apply Now
                </div>
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    background: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <NavigateNextIcon
                    style={{
                      color: "rgb(14, 90, 165)",
                      fontSize: "24px",
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : submissionSteps === 1 ? (
        <div style={{ color: "#142C44CC" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              fontSize: "12px",
              lineHeight: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
              className="mui_button"
              onClick={() => setSubmissionSteps(0)}
            >
              <div
                style={{
                  background: "rgb(14, 90, 165)",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                }}
              >
                <GradingIcon style={{ color: "white" }} />
              </div>
              <div style={{ width: "50px", marginTop: "5px" }}>
                Application details
              </div>
            </div>
            <div
              style={{
                height: "5px",
                width: "30px",
                borderRadius: "10px",
                background: "rgb(14, 90, 165)",
                marginTop: "-25px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  background: "rgba(256,256,256,0.5)",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <DocumentScannerIcon
                  className={submissionSteps === 1 ? "blink_property_icon" : ""}
                  //   style={{ color: "rgb(14, 90, 165)" }}
                />
              </div>
              <div
                className={submissionSteps === 1 ? "blink_property_text" : ""}
                style={{ width: "50px", marginTop: "5px" }}
              >
                Loan Selection
              </div>
            </div>
            <div
              style={{
                height: "5px",
                width: "30px",
                borderRadius: "10px",
                background: "rgba(256,256,256,0.5)",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                marginTop: "-25px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "rgba(256,256,256,0.5)",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <OutboxIcon
                  className={submissionSteps === 2 ? "blink_property_icon" : ""}
                  style={{ color: "rgb(14, 90, 165)" }}
                />
              </div>
              <div
                className={submissionSteps === 2 ? "blink_property_text" : ""}
                style={{ width: "50px", marginTop: "5px", textAlign: "center" }}
              >
                Loan Submission
              </div>
            </div>
          </div>
          <div style={{ fontSize: "15px", fontWeight: 500, marginTop: "30px" }}>
            Congrats! You are eligible for loans up to
          </div>
          {/* <div>
            {"Property valued at " +
              possibleResults.value?.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              }) +
              ", taking value at " +
              (possibleResults.type === "land"
                ? possibleResults.value * 0.9
                : possibleResults.value * 0.8
              )?.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              }) +
              ", with an outstanding loan of " +
              findMyHomeValuationForm.oustandingLoan?.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              }) +
              ", and total cpf usage of " +
              findMyHomeValuationForm.cpfUsage?.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              }) +
              ", and total accrued interest of " +
              findMyHomeValuationForm.accruedInterest?.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}
          </div> */}
          <div style={{ fontSize: "30px", fontWeight: 700 }}>
            {(
              (possibleResults.type === "land"
                ? possibleResults.value * 0.9
                : possibleResults.value * 0.8) -
              findMyHomeValuationForm.oustandingLoan -
              findMyHomeValuationForm.cpfUsage -
              findMyHomeValuationForm.accruedInterest
            )?.toLocaleString("en-US", {
              style: "currency",
              currency: "SGD",
            })}
          </div>
          <div style={{ marginTop: "20px" }}>
            <div>Select the loan you'd like to proceed with.</div>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              {typesOfLoans.map((tpl, index) => (
                <div
                  key={index}
                  style={{
                    width: "400px",
                    padding: "15px 15px 0px 15px",
                    borderRadius: "8px",
                    boxShadow:
                      selectedLoanType === index
                        ? "1.5px 1.5px 4px rgb(0,0,0,0.3), inset 3px 3px 4px rgba(256,256,256,0.2)"
                        : "inset 4px 4px 5px rgba(0,0,0,0.2)",
                    color: selectedLoanType === index ? "white" : "#142C44CC",
                    background:
                      selectedLoanType === index
                        ? index === 0
                          ? "transparent linear-gradient(450deg, rgba(14, 90, 165, 0.4) 0%, rgb(14, 90, 165) 100%) 0% 0% no-repeat padding-box"
                          : "transparent linear-gradient(450deg, #edda80 0%, #ebc313 100%) 0% 0% no-repeat padding-box"
                        : "white",
                    transition: "0.3s all ease-in-out",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "20px",
                  }}
                  className="mui_button"
                  onClick={() => {
                    setSelectedLoanType(index);
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        padding: "8px",
                        borderRadius: "7px",
                        background:
                          selectedLoanType === index
                            ? "rgba(256,256,256,0.8)"
                            : "transparent",
                        boxShadow:
                          selectedLoanType === index
                            ? "inset 2px 2px 3px rgba(0,0,0,0.35)"
                            : "",
                        transition: "0.3s all ease-in-out",
                      }}
                    >
                      {index === 0 ? (
                        <HomeWorkIcon
                          style={{
                            color: "rgb(14, 90, 165)",
                            fontSize: "50px",
                          }}
                        />
                      ) : (
                        <AccountBalanceIcon
                          style={{
                            color: "#ebc313",
                            fontSize: "50px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: "18px",
                        color:
                          selectedLoanType === index
                            ? "rgba(256,256,256, 1)"
                            : "#142C44CC",
                        transition: "0.3s all ease-in-out",
                      }}
                    >
                      {tpl.loanName}
                    </div>
                    <ul
                      style={{
                        fontWeight: 300,
                        fontSize: "10px",
                        lineHeight: "10px",
                        marginTop: "10px",
                        color:
                          selectedLoanType === index
                            ? "rgba(256,256,256, 0.8)"
                            : "#142C44CC",
                        transition: "0.3s all ease-in-out",
                      }}
                    >
                      {tpl.sub.map((tpls, index1) => (
                        <li style={{ marginBottom: "7px" }} key={index1}>
                          {tpls.value}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <div>Loan Amount interested</div>
            <div style={{ width: "100%" }}>
              <InputNumber
                style={{
                  width: "400px",
                  borderRadius: "8px",
                  fontSize: "12px",
                  outline: "none",
                }}
                min={0}
                value={findMyHomeValuationForm.loanAmount}
                formatter={
                  (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Adds commas
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")} // Removes formatting for storage
                onChange={(value) => {
                  setFindMyHomeValuaionForm({
                    ...findMyHomeValuationForm,
                    loanAmount: value,
                  });
                  console.log(value);
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <div>Loan payment type</div>
            <select
              value={findMyHomeValuationForm.loanPaymentType}
              style={{
                outline: "none",
                border: "1px solid lightgrey",
                borderRadius: "8px",
                fontSize: "12px",
                padding: "5px 10px",
                width: "400px",
              }}
              onChange={(e) => {
                setFindMyHomeValuaionForm({
                  ...findMyHomeValuationForm,
                  loanPaymentType: e.target.value,
                });
              }}
            >
              <option value={""}>Select a payment type</option>
              <option value={"interest"}>Interest Only</option>
              <option value={"normal"}>Interest and principal</option>
            </select>
            <div
              style={{ width: "400px", fontSize: "10px", marginTop: "10px" }}
            >
              {findMyHomeValuationForm.loanPaymentType === "interest"
                ? "This payment type requires that the borrower return only the interest for the loan tenor and upon the loan tenor expiring, the principal must be paid in full."
                : findMyHomeValuationForm.loanPaymentType === "normal"
                ? "The payment type selected means during the tenor, your monthly installment consist of paying a portion of your principal and interest on the loan."
                : null}
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <div
                className="mui_button"
                style={{
                  borderRadius: "20px",
                  fontSize: "14px",
                  fontWeight: "700",
                  fontFamily: "Poppins, sans-serif",
                  padding: "8px",
                  background: "rgb(14, 90, 165)",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
                onClick={() => {
                  setSubmissionSteps(2);
                  updateRepaymentSchedule(selectedLoanType);
                  console.log(findMyHomeValuationForm);
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    marginRight: "10px",
                  }}
                >
                  Proceed
                </div>
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    background: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <NavigateNextIcon
                    style={{
                      color: "rgb(14, 90, 165)",
                      fontSize: "24px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : submissionSteps === 2 ? (
        <div style={{ color: "#142C44CC" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              fontSize: "12px",
              lineHeight: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
              className="mui_button"
              onClick={() => setSubmissionSteps(0)}
            >
              <div
                style={{
                  background: "rgb(14, 90, 165)",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                }}
              >
                <GradingIcon style={{ color: "white" }} />
              </div>
              <div style={{ width: "50px", marginTop: "5px" }}>
                Application details
              </div>
            </div>
            <div
              style={{
                height: "5px",
                width: "30px",
                borderRadius: "10px",
                background: "rgb(14, 90, 165)",
                marginTop: "-25px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
              className="mui_button"
              onClick={() => setSubmissionSteps(1)}
            >
              <div
                style={{
                  background: "rgb(14, 90, 165)",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                }}
              >
                <DocumentScannerIcon style={{ color: "white" }} />
              </div>
              <div style={{ width: "50px", marginTop: "5px" }}>
                Loan Selection
              </div>
            </div>
            <div
              style={{
                height: "5px",
                width: "30px",
                borderRadius: "10px",
                background: "rgba(256,256,256,0.5)",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                marginTop: "-25px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "rgba(256,256,256,0.5)",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <OutboxIcon
                  className={submissionSteps === 2 ? "blink_property_icon" : ""}
                  style={{ color: "rgb(14, 90, 165)" }}
                />
              </div>
              <div
                className={submissionSteps === 2 ? "blink_property_text" : ""}
                style={{ width: "50px", marginTop: "5px", textAlign: "center" }}
              >
                Loan Submission
              </div>
            </div>
          </div>
          <div>
            <div style={{ marginTop: "20px" }}>
              <div style={{ fontSize: "10px", opacity: 0.7 }}>
                Property Selected:
              </div>
              <div>
                {(findMyHomeValuationForm.property.address.unit?.value !==
                  undefined &&
                findMyHomeValuationForm.property.address.unit?.value !== null
                  ? `Unit ${findMyHomeValuationForm.property.address.unit?.value}, `
                  : "") +
                  (findMyHomeValuationForm.property.address.floor?.value !==
                    undefined &&
                  findMyHomeValuationForm.property.address.floor?.value !== null
                    ? `Floor ${findMyHomeValuationForm.property.address.floor?.value}, `
                    : "") +
                  (findMyHomeValuationForm.property.address.block?.value !==
                    undefined &&
                  findMyHomeValuationForm.property.address.block?.value !== null
                    ? `Block ${findMyHomeValuationForm.property.address.block?.value}, `
                    : "") +
                  (findMyHomeValuationForm.property.address.street?.value !==
                  undefined
                    ? `${findMyHomeValuationForm.property.address.street?.value}, `
                    : "") +
                  (findMyHomeValuationForm.property.address.postal?.value !==
                  undefined
                    ? `${findMyHomeValuationForm.property.address.postal?.value}, `
                    : "") +
                  (findMyHomeValuationForm.property.address.country?.desc !==
                  undefined
                    ? `${findMyHomeValuationForm.property.address.country?.desc}.`
                    : "")}
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <div style={{ width: "50%" }}>
                <div style={{ fontSize: "10px", opacity: 0.7 }}>
                  Property Valuation:
                </div>
                <div>
                  {possibleResults.value?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "SGD",
                  })}
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ fontSize: "10px", opacity: 0.7 }}>
                  Property LTV:
                </div>
                <div>
                  {(
                    ((findMyHomeValuationForm.oustandingLoan +
                      findMyHomeValuationForm.cpfUsage +
                      findMyHomeValuationForm.accruedInterest) /
                      possibleResults.value) *
                    100
                  ).toFixed(2)}{" "}
                  %
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <div style={{ width: "50%" }}>
                <div style={{ fontSize: "10px", opacity: 0.7 }}>
                  Loan Amount:
                </div>
                <div>
                  {findMyHomeValuationForm.loanAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "SGD",
                  })}
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ fontSize: "10px", opacity: 0.7 }}>Loan Type:</div>
                <div>
                  {findMyHomeValuationForm.loanPaymentType === "interest"
                    ? "Interest Only"
                    : "Interest and principal"}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <div style={{ width: "50%" }}>
                <div style={{ fontSize: "10px", opacity: 0.7 }}>
                  Disbursement Amount:
                </div>
                <div>
                  {(findMyHomeValuationForm.loanAmount * 0.99)?.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "SGD",
                    }
                  )}
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ fontSize: "10px", opacity: 0.7 }}>
                  Processing Fee:
                </div>
                <div>
                  {(findMyHomeValuationForm.loanAmount * 0.01)?.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "SGD",
                    }
                  )}
                </div>
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <div style={{ fontSize: "10px", opacity: 0.7 }}>
                Loan Repayment Schedule:
              </div>
              <div
                style={{
                  width: "90%",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  marginTop: "10px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: "#142C442A",
                  }}
                >
                  <div
                    style={{
                      width: "20%",
                      padding: "6px 10px",
                      fontWeight: "600",
                    }}
                  >
                    Month
                  </div>
                  <div
                    style={{
                      width: "20%",
                      padding: "6px 10px",
                      fontWeight: "600",
                    }}
                  >
                    Principal
                  </div>
                  <div
                    style={{
                      width: "20%",
                      padding: "6px 10px",
                      fontWeight: "600",
                    }}
                  >
                    Interest
                  </div>
                  <div
                    style={{
                      width: "20%",
                      padding: "6px 10px",
                      fontWeight: "600",
                    }}
                  >
                    Total
                  </div>
                  <div
                    style={{
                      width: "20%",
                      padding: "6px 10px",
                      fontWeight: "600",
                    }}
                  >
                    Loan Balance
                  </div>
                </div>
                {findMyHomeValuationForm.loanRepaymentSchedule.map(
                  (repay, index) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: "12px",
                        background:
                          index % 2 ? "rgba(256,256,256,0.4)" : "transparent",
                      }}
                      key={index}
                    >
                      <div style={{ width: "20%", padding: "4px 10px" }}>
                        {repay.month}
                      </div>
                      <div style={{ width: "20%", padding: "4px 10px" }}>
                        {repay.principal?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </div>
                      <div style={{ width: "20%", padding: "4px 10px" }}>
                        {repay.interest?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </div>
                      <div style={{ width: "20%", padding: "4px 10px" }}>
                        {repay.total?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </div>
                      <div style={{ width: "20%", padding: "4px 10px" }}>
                        {repay.loanBalance?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <div
                className="mui_button"
                style={{
                  borderRadius: "20px",
                  fontSize: "14px",
                  fontWeight: "700",
                  fontFamily: "Poppins, sans-serif",
                  padding: "8px",
                  background: "rgb(14, 90, 165)",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
                onClick={() => {
                  createPropLoan();
                }}
              >
                <div
                  style={{
                    marginLeft: "20px",
                    marginRight: "10px",
                  }}
                >
                  Submit Loan
                </div>
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    background: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <NavigateNextIcon
                    style={{
                      color: "rgb(14, 90, 165)",
                      fontSize: "24px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PropertyTermLoan;
