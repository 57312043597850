import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ForgotImg from "../../assets/img/vector/forgot-password.png";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { message } from "antd";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  async function SendLink(event) {
    console.log("Bearer " + token);
    event.preventDefault();
    if (password !== password_confirmation) {
      setError("Passwords do not match");
    } else {
      setError("");
      const response = await fetch(
        "https://www.smart-lend.com/api/auth/forgotPassword/reset",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            token,
            email,
            password,
            password_confirmation,
          }),
        }
      )
        .then((response) => {
          console.log("response value", response);
          if (response.status === 200) {
            Cookies.remove("token");
            message.success("Password reset successfully");
            history.push("/auth/signin");
            return response.json();
          } else if (response.status === 404) {
            setError("User with given email does not exist");
          } else {
            setError(
              "Password reset failed, ensure that the link was only used once and that you are using the latest link sent to your email."
            );
          }
        })
        .then((data) => {
          console.log("data value", data);
        })
        .catch((error) => {
          setError("An error occurred. Please try again later.");
        });

      // Make API call to confirm password
      // setIsConfirmed(true);
    }
  }

  return (
    <React.Fragment>
      <div className="auth-header">
        <div className="auth-header-logo forgot-img">
          <img src={ForgotImg} alt="" className="auth-header-logo-img" />
        </div>
      </div>
      <div className="auth-body">
        <form className="auth-form-validation">
          <h1 className="auth-header-title-reset-header">Reset Password</h1>
          {/* <p className="auth-header-subtitle-forgot-subtitle">
          Enter your email and we'll send you intructions to reset your password
        </p> */}

          <div className="input-field">
            <label htmlFor="email" className="input-label">
              Email address
            </label>
            <input
              type="email"
              className="input-control"
              id="email"
              placeholder="example@gmail.com"
              autoComplete="off"
              onChange={(event) => setEmail(event.target.value)}
              required
            />
          </div>
          <div className="input-field">
            <label htmlFor="email" className="input-label">
              Password
            </label>
            <input
              type="password"
              className="input-control"
              id="password"
              placeholder=" Password"
              autoComplete="off"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
              suffix={
                <span style={{ position: "absolute", right: 5, bottom: 8 }}>
                  {visible ? (
                    <EyeTwoTone onClick={() => setVisible(false)} />
                  ) : (
                    <EyeInvisibleOutlined onClick={() => setVisible(true)} />
                  )}
                </span>
              }
            />
          </div>
          <div className="input-field">
            <label htmlFor="email" className="input-label">
              Password Confirmation
            </label>
            <input
              type="password"
              className="input-control"
              id="passwordConfirmation"
              placeholder=" Password Confirmation"
              autoComplete="off"
              value={password_confirmation}
              onChange={(event) => setConfirmPassword(event.target.value)}
              required
              suffix={
                <span style={{ position: "absolute", right: 5, bottom: 8 }}>
                  {visible2 ? (
                    <EyeTwoTone onClick={() => setVisible2(false)} />
                  ) : (
                    <EyeInvisibleOutlined onClick={() => setVisible2(true)} />
                  )}
                </span>
              }
            />
          </div>
          <div
            className="input-field"
            style={{ display: "flex", flexDirection: "column", }}
          >
            <button
              type="submit"
              className="btn-submit-forgot-password"
              style={{
                padding: "8px 25px",
                width: window.innerWidth < 1200 ? "100%" : "520px",
              }}
              onClick={SendLink}
            >
              Reset Password
            </button>
            {error && <p style={{ color: "red", width: window.innerWidth < 1200 ? "100%" : "520px", marginTop: "20px" }}>{error}</p>}
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
