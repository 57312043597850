import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Icon,
  FormControl,
  FormLabel,
  Slider,
} from "@mui/material";
import { tokens } from "../../src/theme";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { mockTransactions } from "../../src/data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
} from "@material-ui/core";
import { border } from "@mui/system";
import { format } from "date-fns";
import { Progress, Button, message, Avatar, Modal, Tooltip } from "antd";
import Cookies from "js-cookie";
import React from "react";
import { useHistory } from "react-router-dom";
import adspot_ico from "../../src/assets/img/businessman-receiving-money-after-contract-signing.png";
import HalfPieChartBorrower from "../components/HalfPieChartBorrower";
import TinyLineChartBorrower from "../components/TinyLineChartBorrower";
import PaidPrincipalBorrower from "../components/PaidPrincipalBorrower";
import InterestPaidBorrower from "../components/InterestPaidBorrower";
import {
  UserOutlined,
  UploadOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import axios from "axios";
import TableOfferedLoan from "../components/TableOfferedLoan";
import LoanDetails from "./loan/loan-details";
import DashboardAds from "./dashboardads";

const useStyles = makeStyles({
  formControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ##141414`,
  },
});

const getInitials = (name) => {
  const textToSplit = name.split(" ");
  const initials =
    textToSplit?.[0]?.[0].toUpperCase() +
    (textToSplit?.[1]?.[0].toUpperCase() || "");
  return initials;
};

const ContainerActivity = ({ title, subtitle }) => (
  <div
    style={{
      width: "100%",
      textAlign: "left",
      marginBottom: "7px",
      border: "none",
      borderRadius: "10px",
      backgroundColor: "#FFFFFFB3",
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px",
      }}
    >
      <div
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          background: "rgb(140,140,170)",
          marginLeft: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          color: "white",
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        {title ? getInitials(title) : "SL"}
      </div>
      <div style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}>
        <h3
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: "13px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            margin: "0px",
          }}
        >
          {subtitle}
        </p>
      </div>
    </div>
  </div>
);

// const ContainerLineChart = () => (
//   <div style={{ width: "100%", height: "200px", marginRight: "0px", borderRadius: "20px" }}>
//     <TinyLineChart title="$12,000" subtitle="Net profit to date" />
//   </div>
// );

const formatDate = (dateString) => {
  if (!dateString) return "-"; // Return a default value if the date string is not provided

  const date = new Date(dateString); // Create a Date object from the date string
  const formattedDate = format(date, "MMM dd, yyyy"); // Use date-fns to format the date in the desired format

  return formattedDate;
};

const Dashboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [acceptedLoanData, setAcceptedLoan] = useState([]);
  const [dataRequestedLoan, setRequestedLoan] = useState([]);
  const [dataActivityLog, setActivityLog] = useState([]);
  const [dataSummary, setSummary] = useState([]);
  const [loanPeriod, setLoanPeriod] = useState(0);
  const [showAllTransactions, setShowAllTransactions] = useState(false);
  const [offer_amount, setLoanAmount] = useState(0);
  const [offer_period, setSelectedLoanPeriod] = useState(0);
  const [offer_principal, setLoanPrincipal] = useState(0);
  const [offer_monthly_installment, setExpectedMonthlyInstallment] =
    useState(0);
  const [offer_revenue, setGuaranteedRevenue] = useState(0);
  const [type, setLoanType] = useState("simple_loan");
  const [offer_interest_rate, setInterestRate] = useState(0);
  const [total_admin_fee, setTotalAdminFee] = useState(0);
  const [admin_fee, setAdminFee] = useState(0);
  const history = useHistory();
  const [loanDetails, setLoanDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loanBalances, setLoanBalances] = useState([]);
  const [totalLoanBalance, setTotalLoanBalance] = useState(0);
  const [totalLoan, setTotalLoan] = useState(null);
  const [countRemainingTerm, setCountRemainingTerm] = useState([]);
  const [sumMaxRemainingTerm, setMaxRemainingTerm] = useState(0);
  const [countInterestRate, setCountInterestRate] = useState([]);
  const [totalCountInterestRate, setTotalCountInterestRate] = useState(0);
  const [makePayment, setMakePayment] = useState();
  const [refreshData, setRefreshData] = useState(false);
  const [offeredLoanDetail, setOfferedLoanDetail] = useState(null);
  const [lenderId, setLenderId] = useState(null);
  const [typeApplicationLoan, setType] = useState(null);
  const [principal, setPrincipal] = useState(null);
  const [amount, setAmount] = useState(null);
  const [period, setPeriod] = useState(null);
  const [monthly_installment, setMonthlyInstallment] = useState(null);
  const [revenue, setRevenue] = useState(null);
  const [express, setExpress] = useState(null);
  const [category, setCategory] = useState(null);
  const [interest_rate, setInterestRateAcceptedLoan] = useState(null);
  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);
  const [statusMessage, setStatusMessage] = useState({
    message: "",
    color: "",
  });
  const [hoveredApplyNow, setHoveredApplyNow] = useState(false);

  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      console.log("Token from cookie:", token);
      // Now you can use the token for making authenticated API requests,
      // storing it in your app's state, etc.
    }

    axios.get("https://smart-lend.com/auth/phone-number", {
      withCredentials: true,
    });
  }, []);

  // if (isMobile) {
  //   return <DashboardMobile />;
  // }

  const handleApplyLoan = (event) => {
    event.preventDefault();
    history.push("/b-apply-loan");
  };

  const handleAcceptedLoan = (event) => {
    event.preventDefault();
    history.push("/b-accepted-loans");
  };

  const resetLoanDetail = () => {
    setOfferedLoanDetail([]);
  };

  const handleRowClick = async (loan_id, offer_id) => {
    console.log("loan_id", loan_id);
    console.log("offer_id", offer_id);

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/pendingLoan/viewOfferedLoanDetail?loan_id=${loan_id}&offer_id=${offer_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      const loanDetail = json["Offered Loans Detail"][0];
      setOfferedLoanDetail(json["Offered Loans Detail"]);
      setLenderId(loanDetail.lender_id); // Set the lender_id in the state
      setType(loanDetail.typeApplicationLoan); // Set the type in the state
      setPrincipal(loanDetail.offer_principal); // Set the principal
      setAmount(loanDetail.offer_amount); // Set the amount in the
      setAdminFee(loanDetail.admin_fee); // Set the admin  fee
      setInterestRateAcceptedLoan(loanDetail.offer_interest_rate); // Set the interest rate
      setPeriod(loanDetail.offer_period); // Set the period
      setMonthlyInstallment(loanDetail.offer_monthly_installment);
      setRevenue(loanDetail.offer_revenue);
      setExpress(loanDetail.express);
      setTotalAdminFee(loanDetail.total_admin_fee);
    } catch (error) {
      console.error("Error retrieving Offered Loans Detail:", error);
    }
  };

  const handleAcceptOffer = async (loan_id) => {
    try {
      let item = {
        loan_id: loan_id,
        lender_id: lenderId,
        type: type,
        express: express,
        category: category,
        principal: principal,
        amount: amount,
        admin_fee: admin_fee,
        total_admin_fee: total_admin_fee,
        interest_rate: interest_rate,
        period: period,
        monthly_installment: monthly_installment,
        revenue: revenue,
      };

      const response = await fetch(
        `https://www.smart-lend.com/api/loan/pendingLoan/acceptLoan`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(item),
        }
      );
      const json = await response.json();

      if (response.status === 200) {
        // Here, when the response status is 200, close the modal
        setModalStates((prevStates) => ({
          ...prevStates,
          [loan_id]: false,
        }));

        // Display the message from the Ant Design library
        message.success(json.message);
      } else if (response.status === 400) {
        setStatusMessage({
          message: "You have already accepted this loan.",
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error retrieving Requested Loan Details:", error);
      if (error) {
        setStatusMessage({
          message: "Please click the table row.",
          color: "red",
        });
      }
    }
  };

  const reformatDateString = (originalDateString) => {
    // Create a Date object from the original date string
    const originalDate = new Date(originalDateString);

    // Define options for formatting the date
    const options = { year: "numeric", month: "short", day: "numeric" };

    // Format the date into "Mon dd, yyyy" format
    const formattedDate = originalDate.toLocaleDateString("en-US", options);

    return formattedDate;
  };

  const ContainerPieChart = () => (
    <div
      style={{
        width: "100%",
        height: isMobile ? "auto" : "200px",
        marginRight: "0px",
        color: "grey",
        borderRadius: "10px",
      }}
    >
      <HalfPieChartBorrower alltheFreakingLoanData={alltheFreakingLoanData} />
    </div>
  );

  const ContainerLineChart = () => (
    <div
      style={{
        width: "100%",
        height: isMobile ? "auto" : "200px",
        marginRight: "0px",
        borderRadius: "10px",
      }}
    >
      <TinyLineChartBorrower />
    </div>
  );

  const ContainerPrincipalPaid = () => (
    <div
      style={{
        width: "100%",
        height: "15em",
        marginRight: "0px",
        color: "grey",
        borderRadius: "10px",
      }}
    >
      <PaidPrincipalBorrower alltheFreakingLoanData={alltheFreakingLoanData} />
    </div>
  );

  const ContainerInterestPaid = () => (
    <div
      style={{
        width: "100%",
        height: "15em",
        marginRight: "0px",
        color: "grey",
        borderRadius: "10px",
      }}
    >
      <InterestPaidBorrower alltheFreakingLoanData={alltheFreakingLoanData} />
    </div>
  );
  const Container2 = ({
    icon,
    title,
    item,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    label3,
    description3,
    index,
    label4,
    repay_id,
  }) => {
    return (
      <div
        style={{
          width: screenWidth < 800 ? "280px" : "27.31vw",
          textAlign: "center",
          border: "1px solid none",
          borderRadius: "10px",
          backgroundColor: "#FFFFFFB3",
          height: screenWidth < 800 ? "auto" : "220px",
          marginRight: "6px",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: screenWidth < 800 ? "column" : "row",
            width: "100%",
            alignItems: screenWidth ? "flex-start" : "center",
            height: screenWidth < 800 ? "auto" : "190px",
          }}
        >
          <div
            style={{
              height: "160px",
              width: screenWidth < 800 ? "100%" : "68%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: screenWidth < 800 ? "30px" : "40px",
                textAlign: "left",
                color: "#142C44",
                margin: "0px 0px 10px 0px",
                lineHeight: "40px",
              }}
            >
              {description1}
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: screenWidth < 800 ? "column" : "row",
              }}
            >
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                  }}
                >
                  {label1}
                </p>
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.7)",
                  }}
                >
                  {item.repay_principal.toLocaleString("en-US", {
                    style: "currency",
                    currency: "SGD",
                  })}
                </p>
              </div>
              {screenWidth > 800 ? (
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                    margin: "0px 5px",
                  }}
                >
                  |
                </p>
              ) : null}
              <div
                style={{
                  display: "flex",
                  marginTop: screenWidth < 800 ? "-15px" : "0px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                  }}
                >
                  {label2}
                </p>
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.7)",
                  }}
                >
                  {description2}
                </p>
              </div>
            </div>
            <div style={{ textAlign: "left" }}>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                }}
              >
                {label4}
              </p>
            </div>
          </div>
          <div
            style={{
              height: screenWidth < 800 ? "auto" : "160px",
              textAlign: "left",
              width: screenWidth < 800 ? "100%" : "30%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              marginTop: screenWidth < 800 ? "20px" : "0px",
            }}
          >
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                margin: "0px",
              }}
            >
              {label3}
            </p>
            <p
              style={{
                fontWeight: "700",
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                color: "#142C44CC",
              }}
            >
              {description3 ? reformatDateString(description3) : null}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const Container4 = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    label3,
    description3,
    label4,
    description4,
    label5,
    description5,
    index,
    loan_id,
    open_modal,
    cancel_modal,
    onClickModal,
    createdAt,
  }) => {
    const shouldAddMarginTop = index > 3;
    const [hovered, setHovered] = useState(false);

    return (
      <div style={{ width: screenWidth < 800 ? "220px" : "198px" }}>
        <div
          style={{
            width: screenWidth > 1200 ? "100%" : "220px",
            textAlign: "center",
            border: "1px solid white",
            borderRadius: "10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 15px 0px 15px",
            }}
          >
            {/* <img src={icon} alt={title} style={{ width: "55px", height: "55px", borderRadius: "1000px", border: "2px solid #f0f0f0" }} /> */}
            <div
              style={{
                marginLeft: "15px",
                marginBottom: "10px",
                marginTop: "7px",
                marginRight: "1px",
              }}
            >
              <h3
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: "18px",
                  textAlign: "left",
                  marginTop: "18px",
                }}
              >
                {title}
              </h3>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "11px",
                  textAlign: "left",
                  marginTop: "-10px",
                  opacity: ".6",
                  color: "rgb(20, 44, 68)",
                }}
              >
                {subtitle}
              </p>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              marginLeft: "10px",
              margin: "0px 15px 0px 15px",
            }}
          >
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
                fontSize: "10px",
                opacity: ".6",
                marginTop: "-3px",
              }}
            >
              {label5}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "18px",
                color: "rgb(20, 44, 68)",
                marginTop: "-17px",
              }}
            >
              {description5}
            </p>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
                fontSize: "10px",
                opacity: ".6",
                marginTop: "-3px",
              }}
            >
              {label}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "18px",
                color: "rgb(20, 44, 68)",
                marginTop: "-17px",
              }}
            >
              {description}
            </p>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
                fontSize: "10px",
                opacity: ".6",
                marginTop: "-3px",
              }}
            >
              {label1}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "18px",
                color: "rgb(20, 44, 68)",
                marginTop: "-17px",
              }}
            >
              {description1}
            </p>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
                fontSize: "10px",
                opacity: ".6",
                marginTop: "-3px",
              }}
            >
              {label4}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "18px",
                color: "rgb(20, 44, 68)",
                marginTop: "-17px",
              }}
            >
              {description4}
            </p>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
                fontSize: "10px",
                opacity: ".6",
                marginTop: "-3px",
              }}
            >
              {label2}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "18px",
                color: "rgb(20, 44, 68)",
                marginTop: "-17px",
              }}
            >
              {description2}
            </p>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
                fontSize: "10px",
                opacity: ".6",
                marginTop: "-3px",
              }}
            >
              {label3}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "18px",
                marginTop: "-17px",
                color: "rgb(20, 44, 68)",
              }}
            >
              {description3}
            </p>
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
                fontSize: "10px",
                opacity: ".6",
                marginTop: "-3px",
              }}
            >
              Created At
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "18px",
                marginTop: "-17px",
                color: "rgb(20, 44, 68)",
              }}
            >
              {formatDate(createdAt)}
            </p>
          </div>
          {/* <div>
            <button
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                fontSize: "15px",
                color: hovered ? "#ffffff" : "#0E5AA5",
                textAlign: "center",
                background: hovered
                  ? "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)"
                  : "none",
                border: "1px solid #707070",
                borderRadius: "1000px",
                width: "90%",
                height: "40px",
                marginTop: "5px",
                marginBottom: "15px",
              }}
              onMouseEnter={() => setHovered(true)} // set the state hovered to true  when mouse enter
              onMouseLeave={() => setHovered(false)} // set the state hovered to false  when mouse leave
              onClick={(event) => showModal(loan_id, event)}
            >
              View Offered Loan
            </button>
          </div> */}
        </div>
      </div>
    );
  };

  const Container2Summary = ({ noData }) => {
    return (
      <>
        <div
          style={{
            width: "23.5%",
            textAlign: "center",
            marginRight: "0px",
            marginLeft: "15px",
            border: "1px solid none",
            borderRadius: "20px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
              fontSize: "18px",
              color: "#142C44CC",
              textAlign: "center",
            }}
          >
            {noData}
          </p>
        </div>
      </>
    );
  };

  const Container3 = ({ noData }) => {
    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "96%" : "100%",
            textAlign: "center",
            height: "240px",
            marginRight: "-15px",
            marginLeft: screenWidth > 1200 ? "25px" : "0px",
            border: "1px solid none",
            borderRadius: "10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: screenWidth < 800 ? "400" : "600",
              fontSize: screenWidth < 800 ? "12px" : "18px",
              color: "#142C44CC",
              textAlign: "center",
            }}
          >
            {noData}
          </p>
        </div>
      </>
    );
  };

  const Container3Transaction = ({ noData }) => {
    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "96%" : "100%",
            textAlign: "center",
            height: "240px",
            marginRight: "-15px",
            marginLeft: screenWidth > 1200 ? "25px" : "0px",
            border: "1px solid none",
            borderRadius: screenWidth > 1200 ? "10px" : "0px 0px 10px 10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: screenWidth < 800 ? "400" : "600",
              fontSize: screenWidth < 800 ? "12px" : "18px",
              color: "#142C44CC",
              textAlign: "center",
            }}
          >
            {noData}
          </p>
        </div>
      </>
    );
  };

  const ContainerActivity2 = ({ noData }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "1px solid none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
        height: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p
        style={{
          fontFamily: "Poppins, sans-serif",
          fontWeight: screenWidth < 800 ? "400" : "600",
          fontSize: screenWidth < 800 ? "12px" : "16px",
          color: "#142C44CC",
          textAlign: "center",
          margin: "0px",
        }}
      >
        {noData}
      </p>
    </div>
  );

  // useEffect(() => {
  //   // To redirect user if not authenticated
  //   const isAuthenticated = Cookies.get("token");
  //   const user_account_type = Cookies.get("user_account_type");

  //   if (!isAuthenticated) {
  //     history.push("/auth/signin");
  //   } else if (user_account_type === "lender") {
  //     history.push("/dashboard");
  //   }

  //   // Checking response of profile picture API
  //   fetch("https://www.smart-lend.com/api/profile/viewInfoProfilePicture")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.message === "Unauthenticated.") {
  //         history.push("/auth/signin");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }, [history]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/dashboard/viewBorrowerTransaction",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setData(data["Latest Transaction"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [refreshData]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/dashboard/viewLoanBalance",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        // setLoanBalances(data["loan balances"]);
        calculateTotalLoanBalance(data["loan balances"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/pendingLoan/viewAcceptedLoanBorrower",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setAcceptedLoan(data["Accepted Loan"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);
  const [alltheFreakingLoanData, setAllTheFreakingLoanData] = useState(null);

  function calculateTotalLoanBalance(loanData) {
    let sum = 0;
    console.log(loanData);
    setAllTheFreakingLoanData(loanData);
    loanData.forEach((loan) => {
      if (loan.loan_balance !== null) {
        // Check if loan_balance is not null
        // sum += loan.loan_balance;
        console.log(loan);
        for (const rema of loan.remaining) {
          if (rema.status !== "done") {
            sum += rema.repay_amount;
          }
        }
      }
    });
    setTotalLoanBalance(sum);
  }

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/dashboard/countTotalLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setTotalLoan(data["Total Loan"][0].total_loan);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/dashboard/countRemainingTerm",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setCountRemainingTerm(data["Remaining Term"]);
        calculateMaxRemainingTerm(data["Remaining Term"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  function calculateMaxRemainingTerm(loanData) {
    let maxTerm = 0;
    loanData.forEach((loan) => {
      if (loan.remaining_term > maxTerm) {
        maxTerm = loan.remaining_term;
      }
    });
    setMaxRemainingTerm(maxTerm);
  }

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/dashboard/countInterestRate",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        console.log("Received data: ", data);

        setCountInterestRate(data["Interest Rate"]);
        calculateTotalInterestRate(data["Interest Rate"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  function calculateTotalInterestRate(loanData) {
    let sum = 0;
    loanData.forEach((loan) => {
      sum += loan.interest_rate * 100;
    });
    const average = sum / loanData.length;
    console.log("Calculated average: ", average);
    setTotalCountInterestRate(average.toFixed(1));
  }

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewActivityLog",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        const reversedData = data.reverse();

        setActivityLog(reversedData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/ongoingLoan/viewOngoingLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setSummary(data["Ongoing Loan"]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const calculateLoan = () => {
      if (type === "simple_loan") {
        const offer_monthly_installment =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            1.05 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          (offer_amount / offer_period +
            offer_amount * (offer_interest_rate / 100)) *
            0.6 || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee = 0.05;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      } else if (type === "effective_loan") {
        const offer_monthly_installment =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            1.05 || "0";
        const offer_principal = offer_monthly_installment * offer_period || "0";
        const total_admin_fee =
          ((offer_amount *
            (offer_interest_rate / 1200) *
            (1 + offer_interest_rate / 1200) ** offer_period) /
            ((1 + offer_interest_rate / 1200) ** offer_period - 1)) *
            0.6 || "0";
        const offer_revenue =
          offer_principal - offer_amount - total_admin_fee || "0";
        const admin_fee = 0.05;
        setAdminFee(admin_fee);
        setLoanPrincipal(offer_principal);
        setExpectedMonthlyInstallment(offer_monthly_installment);
        setGuaranteedRevenue(offer_revenue);
        setTotalAdminFee(total_admin_fee);
      }
    };
    calculateLoan();
  }, [
    offer_amount,
    offer_interest_rate,
    offer_period,
    type,
    offer_principal,
    offer_monthly_installment,
    offer_revenue,
    total_admin_fee,
    admin_fee,
  ]);

  const fetchLoanDetails = async (loan_id) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/loanRequest/viewLoanRequestDetail?loan_id=${loan_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setLoanDetails(data);
    } catch (error) {
      console.error(error);
    }
  };
  const [modalStates, setModalStates] = useState({});

  useEffect(() => {
    const initialStates = {};
    data.forEach((item) => {
      initialStates[item.repay_id] = false;
    });
    setModalStates(initialStates);
  }, [data]);

  const [open, setOpen] = useState(false);
  const [openLenderId, setOpenLenderId] = useState(null);

  const showModal = (repay_id, event, lender_id) => {
    event.preventDefault();
    setOpenLenderId(lender_id);
    console.log("repay_id modal", repay_id);
    setModalStates((prevStates) => ({
      ...prevStates,
      [repay_id]: true,
    }));
  };

  const handleMakePayment = async (repay_id, lender_id) => {
    console.log("value of repay_id", repay_id);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/makePayment?repay_id=${repay_id}&lender_id=${lender_id}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setMakePayment(data);

      // Display the message from the Ant Design library
      message.success(data.message);

      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/ongoingLoan/viewOngoingLoan",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setSummary(data["Ongoing Loan"]);
      } catch (error) {
        console.error(error);
      }

      // If the payment was successful
      // if(data.message === "Payment made successfully!") {
      //   try {
      //     let item = {
      //       user_id: user_id,
      //       channel: "system",
      //       subject: "Lender offer",
      //       message: "Lender had make an  offer",
      //     };
      //     const response = await fetch(
      //       "https://www.smart-lend.com/api/user/notification/sendNotification",
      //       {
      //         method: "POST",
      //         headers: {
      //           "Content-Type": "application/json",
      //           Authorization: "Bearer " + Cookies.get("token"),
      //         },
      //         body: JSON.stringify(item),
      //       }
      //     );
      //     const data = await response.json();
      //     console.log("Offer created:", data);
      //   } catch (error) {
      //     console.error("Error creating offer:", error);
      //   }
      // }

      if (
        data.message === "Payment made successfully!" ||
        "Payment has already been made for this repayment."
      ) {
        setRefreshData((prevState) => !prevState); // Toggle the state
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLoanAmountChange = (e) => {
    setLoanAmount(e.target.value);
  };

  const handleGridPath = () => {
    // history.push('../loan-request-grid')
  };

  const handleListPath = () => {
    history.push("../loan-request");
  };

  const handleInterestRateChange = (e) => {
    setInterestRate(e.target.value);
  };

  const handleLoanPeriodChange = (e) => {
    setLoanPeriod(e.target.value);
  };

  const handleLoanTypeChange = (e) => {
    setLoanType(e.target.value);
  };

  const isWithinSevenDays = (repayDueDate) => {
    const today = new Date();
    const dueDate = new Date(repayDueDate);

    // Calculate the difference in milliseconds
    const differenceInMs = dueDate - today;

    // Calculate the difference in days
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

    // Check if the due date is within 7 days from today
    console.log(differenceInDays);
    return differenceInDays <= 7;
  };

  const handleMakeOffer = async (loan_id) => {
    console.log("loanId value in handleMakeOffer", loan_id); // Debug statement
    // rest of the code
    try {
      let path = "";
      let item = {
        loan_id,
        offer_amount: parseFloat(offer_amount),
        offer_interest_rate: parseFloat(offer_interest_rate),
        offer_period: parseInt(offer_period),
        type,
        offer_principal: parseFloat(offer_principal),
        offer_monthly_installment: parseFloat(offer_monthly_installment),
        offer_revenue: parseFloat(offer_revenue),
        total_admin_fee: parseFloat(total_admin_fee),
        admin_fee: parseFloat(admin_fee),
      };
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/createLoan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item),
        }
      );
      const data = await response.json();
      path = "/pending-acceptance";

      console.log("Offer created:", data);
      return history.push(path);
      // Update your UI or close the modal
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };
  const buttonStyleExpress = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    color: "#FFFFFF",
    textAlign: "center",
    background: "#FED065",
    border: "none",
    borderRadius: "1000px",
    width: "90%",
    height: "40px",
    marginTop: "5px",
    marginBottom: "15px",
  };

  const buttonStyleBorrower = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    color: "#FFFFFF",
    textAlign: "center",
    background:
      "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)",
    border: "none",
    borderRadius: "1000px",
    width: "90%",
    height: "40px",
    marginTop: "5px",
    marginBottom: "15px",
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box m={isMobile ? "4px 0px 20px 0px" : "4px 20px 20px 20px"}>
      {/* HEADER */}
      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

      </Box> */}

      {/* GRID & CHARTS */}

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}

        {/* ROW 2 */}
        <Box
          gridColumn={isMobile ? "12 span" : "span 9"}
          gridRow="span 2"
          borderRadius="10px"
          border="0px solid "
          opacity="0.95"
          style={{
            overflowY: "scroll",
            height: isMobile ? `calc(${window.innerHeight}px - 80px)` : "90vh",
            overflowX: "hidden",
            background: !isMobile
              ? "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
              : "transparent",
            padding: isMobile ? "0px 5px 0px 10px" : "0px 20px 120px 20px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            style={{ alignItems: isMobile ? "flex-start" : "default" }}
            alignItems="center"
            colors={colors.grey[910]}
            opacity="1.00"
            p="25px 0px 0px 15px"
          >
            <Typography
              color={colors.grey[920]}
              variant="h5"
              fontWeight="800"
              fontFamily="Manrope, sans-serif"
              fontSize="18px"
              style={{ marginTop: !isMobile ? "20px" : "10px" }}
            >
              Loan Balance
            </Typography>
            <button
              className="all_button"
              style={{
                color: hoveredApplyNow ? "white" : "#142C44",
                border: "none",
                backgroundColor: hoveredApplyNow ? "#115CA6" : "#FFFFFFB3",
                borderRadius: "20px",
                fontSize: "14px",
                fontWeight: "700",
                fontFamily: "Poppins, sans-serif",
                padding: "5px 25px",
                marginRight: "25px",
                boxShadow:
                  window.innerWidth < 1200
                    ? "0px 0px 10px rgba(0,0,0,0.1)"
                    : "none",
              }}
              onClick={(event) => handleApplyLoan(event)}
              onMouseEnter={() => setHoveredApplyNow(true)} // set the state hovered to true  when mouse enter
              onMouseLeave={() => setHoveredApplyNow(false)} // set the state hovered to false  when mouse leave
            >
              Apply Now
            </button>
          </Box>
          <Box mt="10px">
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                padding: "0px 25px 0px 15px",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "30%" }}>
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                    fontSize: "38px",
                    textAlign: "left",
                    color: "#142C44",
                    marginTop: isMobile ? "-10px" : "-10px",
                  }}
                >
                  {totalLoanBalance
                    ? totalLoanBalance.toLocaleString("en-US", {
                        style: "currency",
                        currency: "SGD",
                      })
                    : "$0.00"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  width: isMobile ? "100%" : "700px",
                  justifyContent: isMobile ? "space-between" : "flex-end",
                  gap: isMobile ? "10px" : "0px",
                }}
              >
                <div
                  style={{
                    width: isMobile ? "33%" : "28%",
                    display: "flex", // Use flex container
                    flexDirection: "column", // Stack the items vertically
                    alignItems: isMobile ? "flex-start" : "center", // Center items horizontally
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: isMobile ? "12px" : "14px",
                        color: "rgba(0,0,0,0.4)",
                        marginTop: isMobile ? "10px" : "0px",
                      }}
                    >
                      Total Loan
                    </p>
                  </div>

                  <div style={{}}>
                    <p
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: "18px",
                        textAlign: "center",
                        color: "#142C44",
                        marginTop: "-20px",
                      }}
                    >
                      {totalLoan || "$0.00"}
                    </p>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: isMobile ? 5 : 10,
                    }}
                  >
                    <Tooltip
                      overlayStyle={{ fontSize: "10px", zIndex: 9999 }} // Adjust font size here
                      mouseEnterDelay={0} // Show immediately on hover
                      mouseLeaveDelay={0} // Hide immediately on mouse leave
                      style={{ position: "absolute", right: 0, top: 0 }}
                      placement="topRight"
                      title="This represents the total loan of the latest loan taken up,
                   Click to show all loans and view detailed information about 
                   each loan's."
                    >
                      <button
                        className="all_button"
                        style={{
                          borderRadius: "15px",
                          height: "15px",
                          width: "15px",
                          background: "transparent",
                          border: "1px solid rgba(0,0,0,0.6)",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={(event) => handleAcceptedLoan(event)}
                      >
                        <QuestionOutlined style={{ fontSize: "8px" }} />
                      </button>
                    </Tooltip>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "60px",
                    marginLeft: isMobile ? "10px" : "20px",
                    marginRight: "0px",
                  }}
                >
                  <div
                    style={{
                      borderLeft: "2px solid grey",
                      height: "100%",
                      opacity: ".4",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    width: isMobile ? "33%" : "28%",

                    display: "flex", // Use flex container
                    flexDirection: "column", // Stack the items vertically
                    alignItems: isMobile ? "flex-start" : "center", // Center items horizontally
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: isMobile ? "12px" : "14px",
                        color: "rgba(0,0,0,0.4)",
                        marginTop: isMobile ? "10px" : "0px",
                      }}
                    >
                      Remaining Term
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: "18px",
                        textAlign: "center",
                        color: "#142C44",
                        marginTop: "-20px",
                      }}
                    >
                      {sumMaxRemainingTerm + " mo." || "-"}
                    </p>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: isMobile ? 5 : 10,
                    }}
                  >
                    <Tooltip
                      overlayStyle={{ fontSize: "10px", zIndex: 9999 }} // Adjust font size here
                      mouseEnterDelay={0} // Show immediately on hover
                      mouseLeaveDelay={0} // Hide immediately on mouse leave
                      style={{ position: "absolute", right: 0, top: 0 }}
                      placement="topRight"
                      title="This represents the remaining duration of the latest loan taken up,
                   based on the tenor or repayment period.
                   Click to show all loans and view detailed information about 
                   each loan's remaining time."
                    >
                      <button
                        className="all_button"
                        style={{
                          borderRadius: "15px",
                          height: "15px",
                          width: "15px",
                          background: "transparent",
                          border: "1px solid rgba(0,0,0,0.6)",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={(event) => handleAcceptedLoan(event)}
                      >
                        <QuestionOutlined style={{ fontSize: "8px" }} />
                      </button>
                    </Tooltip>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "60px",
                    marginLeft: isMobile ? "10px" : "20px",
                    marginRight: "0px",
                  }}
                >
                  <div
                    style={{
                      borderLeft: "2px solid grey",
                      height: "100%",
                      opacity: ".4",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    width: isMobile ? "33%" : "28%",
                    display: "flex", // Use flex container
                    flexDirection: "column", // Stack the items vertically
                    alignItems: isMobile ? "flex-start" : "center", // Center items horizontally
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: isMobile ? "12px" : "14px",
                        color: "rgba(0,0,0,0.4)",
                        marginTop: isMobile ? "10px" : "0px",
                      }}
                    >
                      Current Interest
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: "18px",
                        textAlign: "center",
                        color: "#142C44",
                        marginTop: "-20px",
                      }}
                    >
                      {totalCountInterestRate + " %" || "-"}
                    </p>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: isMobile ? 5 : 10,
                    }}
                  >
                    <Tooltip
                      overlayStyle={{ fontSize: "10px", zIndex: 9999 }} // Adjust font size here
                      mouseEnterDelay={0} // Show immediately on hover
                      mouseLeaveDelay={0} // Hide immediately on mouse leave
                      style={{ position: "absolute", right: 0, top: 0 }}
                      placement="topRight"
                      title="This represents the interest rates of the latest loan taken up.
                   Click to show all loans and view detailed information
                  about each loan's interest rates payable."
                    >
                      <button
                        className="all_button"
                        style={{
                          borderRadius: "15px",
                          height: "15px",
                          width: "15px",
                          background: "transparent",
                          border: "1px solid rgba(0,0,0,0.6)",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={(event) => handleAcceptedLoan(event)}
                      >
                        <QuestionOutlined style={{ fontSize: "8px" }} />
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                width: "100%",
                marginTop: "20px",
                justifyContent: "space-between",
                padding: "10px",
                gap: "0px 0px",
              }}
            >
              <div style={{ width: isMobile ? "50%" : "25%", padding: "5px" }}>
                <ContainerPieChart />
              </div>
              <div style={{ width: isMobile ? "50%" : "25%", padding: "5px" }}>
                <ContainerLineChart />
              </div>
              <div style={{ width: isMobile ? "50%" : "25%", padding: "5px" }}>
                <ContainerPrincipalPaid />
              </div>
              <div style={{ width: isMobile ? "50%" : "25%", padding: "5px" }}>
                <ContainerInterestPaid />
              </div>
            </div>
          </Box>

          <div style={{ marginTop: "-60px" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              // borderBottom={`4px solid ${colors.primary[500]}`}
              colors={colors.grey[910]}
              opacity="1.00"
              marginTop="25px"
              marginBottom="10px"
              marginLeft="15px"
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="18px"
                style={{ marginTop: "20px" }}
              >
                Accepted Loan
              </Typography>
            </Box>

            <div
              style={{
                display: "flex",
                overflowX: "scroll",
                color: "grey",
                width: "100%",
                marginBottom: "1%",
                gap: "10px",
                padding: "1% 15px",
              }}
            >
              {acceptedLoanData && acceptedLoanData.length > 0 ? (
                acceptedLoanData.map((item, index) => (
                  <div
                    style={{
                      width: screenWidth > 1200 ? "200px" : "70%",
                    }}
                  >
                    <Container4
                      key={index}
                      label5="Loan ID"
                      description5={item.loan_id || "-"}
                      label="Loan Amount"
                      description={
                        item.amount
                          ? item.amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            })
                          : "-"
                      }
                      label1="Monthly Amount"
                      description1={
                        item.monthly_installment
                          ? item.monthly_installment.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            })
                          : "-"
                      }
                      label2="Period"
                      description2={item.period + " Months" || "-"}
                      label3="Loan Status"
                      description3={
                        item.status
                          ? item.status.charAt(0).toUpperCase() +
                            item.status.slice(1)
                          : "-"
                      }
                      label4="Interest Rate"
                      description4={item.interest_rate * 100 + " %" || "-"}
                      createdAt={item.created_at}
                      index={index} // pass the index prop to each Container instance
                      loan_id={item.loan_id} // pass the loan_id prop to each Container instance
                      open_modal={modalStates[item.loan_id]} // pass the modal state to each Container instance
                      onClickModal={(event) => showModal(item.loan_id, event)} // pass the showModal function to each Container instance
                      cancel_modal={() => handleClose(item.loan_id)} // pass the cancelModal function to each Container instance
                    />
                    <Modal
                      width="50vw"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                      open={modalStates[item.loan_id]}
                      onCancel={() => {
                        setModalStates((prevStates) => ({
                          ...prevStates,
                          [item.loan_id]: false,
                        }));
                        resetLoanDetail();
                        setStatusMessage({ message: "", color: "" });
                      }}
                      footer={[
                        <React.Fragment key="footer-elements">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "25px",
                            }}
                          >
                            <Button
                              type="primary"
                              style={{
                                background: "#0DC45C",
                                borderRadius: "20px",
                                width: "15%",
                              }}
                              onClick={() => {
                                handleAcceptOffer(item.loan_id);
                              }}
                            >
                              Accept Offer
                            </Button>{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              color: statusMessage.color,
                            }}
                          >
                            <p>{statusMessage.message}</p>
                          </div>
                        </React.Fragment>,
                      ]}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="offer_amount"
                            style={{
                              fontFamily: "Poppins, sans-serif",
                              fontWeight: "600",
                              fontSize: "40px",
                              color: "#0E5AA5",
                            }}
                          >
                            Offered Loan
                          </label>
                          <TableOfferedLoan
                            loan_id={item.loan_id}
                            onRowClick={(loan_id, offer_id) =>
                              handleRowClick(loan_id, offer_id)
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      {offeredLoanDetail && offeredLoanDetail.length > 0 ? (
                        <LoanDetails offeredLoanDetail={offeredLoanDetail} />
                      ) : (
                        <h5>
                          Please click on the table row to display the Loan
                          Details
                        </h5>
                      )}
                    </Modal>
                  </div>
                ))
              ) : (
                <div style={{ width: "100%", padding: "0px" }}>
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 15px",
                      background: "rgba(255,255,255,0.7)",
                      borderRadius: "10px",
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "0.8em",
                      fontWeight: "600",
                      color: "#00000066",
                    }}
                  >
                    No Loan Application Data
                  </div>
                </div>
              )}
            </div>
          </div>

          <div style={{ marginTop: "-20px", width: "100%" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              // borderBottom={`4px solid ${colors.primary[500]}`}
              colors={colors.grey[910]}
              opacity="1.00"
              marginTop="25px"
              marginBottom="10px"
              marginLeft="15px"
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="18px"
                style={{ marginTop: "20px" }}
              >
                Upcoming Payment
              </Typography>
            </Box>

            <div
              style={{
                display: "flex",
                overflowX: "scroll",
                color: "grey",
                marginLeft: isMobile ? "0px" : "15px",
                width: isMobile ? "100%" : "97%",
                marginRight: "10px",
                marginBottom: "1%",
                paddingBottom: "1%",
              }}
            >
              {dataSummary && dataSummary.length > 0 ? (
                dataSummary.map((loanItem, index) =>
                  loanItem.loan.map((item) =>
                    isWithinSevenDays(formatDate(item.repay_due_date)) ? (
                      <div
                        style={{
                          // background: "green",
                          marginRight: "5px",
                          width: "calc(50%)",
                          boxSizing:
                            "border-box" /* Include padding and borders in width */,
                        }}
                      >
                        <Container2
                          key={index}
                          item={item}
                          icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                          title={
                            item.amount
                              ? item.amount.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "SGD",
                                })
                              : "-"
                          }
                          description={
                            item.repay ? formatDate(item.repay) : "-"
                          }
                          repay_id={item.repay_id}
                          label1="Principal:"
                          description1={
                            item.repay_total_amount
                              ? item.repay_total_amount.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "SGD",
                                  }
                                )
                              : "-"
                          }
                          label2="Interest:"
                          description2={
                            item.repay_interest
                              ? item.repay_interest.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "SGD",
                                })
                              : "-"
                          }
                          label4={`Your payment will be deducted from your main account (${
                            item.bank_name || "-"
                          } : ${item.bank_account_no || "-"}) 
                                                on or after the stated date.`}
                          label="Last Payment Date"
                          label3="Due Date"
                          description3={
                            item.repay_due_date
                              ? formatDate(item.repay_due_date)
                              : "-"
                          }
                          index={index}
                        />
                        <Modal
                          style={{
                            backgroundColor: "rgba(0, 0, 0, 0)",
                            margin: "10% auto",
                          }}
                          open={modalStates[item.repay_id]}
                          onCancel={() =>
                            setModalStates((prevStates) => ({
                              ...prevStates,
                              [item.repay_id]: false,
                            }))
                          }
                          footer={[
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 16,
                              }}
                            >
                              <Button
                                type="primary"
                                onClick={() =>
                                  handleMakePayment(item.repay_id, openLenderId)
                                }
                              >
                                Yes
                              </Button>
                              <Button
                                type="default"
                                onClick={() =>
                                  setModalStates((prevStates) => ({
                                    ...prevStates,
                                    [item.repay_id]: false,
                                  }))
                                }
                              >
                                No
                              </Button>
                            </div>,
                          ]}
                        >
                          Are you sure you want to confirm this payment to
                          lender {openLenderId ? openLenderId : ""}?
                        </Modal>
                      </div>
                    ) : null
                  )
                )
              ) : (
                <div
                  style={{ width: "100%", padding: isMobile ? "15px" : "0px" }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 10px",
                      background: "rgba(255,255,255,0.7)",
                      borderRadius: "10px",
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "0.8em",
                      fontWeight: "600",
                      color: "#00000066",
                    }}
                  >
                    No Upcoming Payments
                  </div>
                </div>
              )}
            </div>
          </div>

          <div style={{ marginTop: "30px" }}>
            <div style={{ marginLeft: "15px" }}>
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="18px"
              >
                Transaction History
              </Typography>
            </div>
            <div>
              <div
                style={{
                  marginLeft: isMobile ? "0px" : "15px",
                  width: isMobile ? "100%" : "97%",
                  padding: "0px 10px",
                  marginTop: "18px",
                }}
              >
                <div
                  style={{
                    background: "rgba(255,255,255,0.7)",
                    width: "100%",
                    borderRadius: "10px",
                    padding: "1%",
                    transition: "0.3s all ease-in-out",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center", // use "center" to vertically center the titles
                      colors: colors.grey[910],
                      backgroundColor: "#ffff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "12px",
                      marginBottom: "7px",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "left",
                        width: window.innerWidth < 1200 ? "50%" : "25%",
                      }}
                    >
                      <h3
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 700,
                          fontSize: "13px",
                          color: "#435669",
                          margin: "0px",
                        }}
                      >
                        {window.innerWidth < 1200
                          ? "Name & Amount"
                          : "Lender Name"}
                      </h3>
                    </div>
                    {window.innerWidth < 1200 ? null : (
                      <div style={{ textAlign: "center", width: "25%" }}>
                        <h3
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 700,
                            fontSize: "13px",
                            color: "#435669",
                            margin: "0px",
                          }}
                        >
                          Date & Time
                        </h3>
                      </div>
                    )}
                    {window.innerWidth < 1200 ? null : (
                      <div style={{ textAlign: "center", width: "25%" }}>
                        <h3
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 700,
                            fontSize: "13px",
                            color: "#435669",
                            margin: "0px",
                          }}
                        >
                          Amount
                        </h3>
                      </div>
                    )}
                    <div
                      style={{
                        textAlign:
                          window.innerWidth < 1200 ? "right" : "center",
                        width: window.innerWidth < 1200 ? "50%" : "25%",
                      }}
                    >
                      <h3
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 700,
                          fontSize: "13px",
                          color: "#435669",
                          margin: "0px",
                        }}
                      >
                        {window.innerWidth < 1200 ? "Date & Status" : "Status"}
                      </h3>
                    </div>
                  </div>
                  {data && data.length > 0 ? (
                    (showAllTransactions ? data : data.slice(0, 3)).map(
                      (item, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center", // use "center" to vertically center the titles
                            colors: colors.grey[910],
                            width: "100%",
                            padding: "8px 12px",
                            flexWrap: "wrap",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "left",
                              width: window.innerWidth < 1200 ? "50%" : "25%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div style={{}}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                  }}
                                >
                                  <h3
                                    style={{
                                      fontFamily: "Poppins, sans-serif",
                                      fontWeight: 700,
                                      fontSize: "13px",
                                      color: "#435669",
                                      margin: "0px",
                                    }}
                                  >
                                    {item.lender_name || "-"}
                                  </h3>
                                  {/* <span style={{ color: 'blue', fontFamily: "Manrope, sans-serif", fontSize: "13px", fontWeight: "400", marginTop: "20px", marginLeft: "5px" }}>{' #3057'}</span> */}
                                </div>
                                <p
                                  style={{
                                    fontFamily: "Poppins, sans-serif",
                                    fontWeight: "500",
                                    fontSize: "11px",
                                    textAlign: "left",
                                    margin: "0px",
                                    opacity: ".6",
                                    color: "#435669",
                                  }}
                                >
                                  {item.Date || "-"}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              textAlign:
                                window.innerWidth < 1200 ? "right" : "center",
                              width: window.innerWidth < 1200 ? "50%" : "25%",
                            }}
                          >
                            <h3
                              style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 700,
                                fontSize: "13px",
                                color: "#435669",
                                margin: "0px",
                              }}
                            >
                              {item.repay_date
                                ? reformatDateString(
                                    formatDate(item.repay_date)
                                  )
                                : "-"}
                            </h3>
                          </div>

                          <div
                            style={{
                              textAlign:
                                window.innerWidth < 1200 ? "left" : "center",
                              width: window.innerWidth < 1200 ? "50%" : "25%",
                            }}
                          >
                            <h3
                              style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 700,
                                fontSize: "13px",
                                color: "#435669",
                                margin: "0px",
                              }}
                            >
                              {item.repay_total_amount
                                ? item.repay_total_amount.toLocaleString(
                                    "en-US",
                                    { style: "currency", currency: "SGD" }
                                  )
                                : "-"}
                            </h3>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              width: window.innerWidth < 1200 ? "50%" : "25%",
                              display: "flex",
                              justifyContent:
                                window.innerWidth < 1200
                                  ? "flex-end"
                                  : "center",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor:
                                  item.status === "done"
                                    ? "#D6F2D8"
                                    : "#B13434",
                                color:
                                  item.status === "done"
                                    ? "#139F2ACC"
                                    : "#FFFFFF",
                                border: "1px solid",
                                borderColor:
                                  item.status === "done"
                                    ? "#D6F2D8"
                                    : "#B13434",
                                borderRadius: "10px",
                                width: "40%",
                                height: "30px",

                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 700,
                                fontSize: "13px",
                                margin: "0px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {item.status
                                ? item.status.charAt(0).toUpperCase() +
                                  item.status.slice(1)
                                : "-"}
                            </div>
                          </div>
                          {index !== 2 && index !== data.length - 1 ? (
                            <hr
                              style={{
                                margin: "10px 0px 0px 0px",
                                width: "100%",
                                height: "2px",
                                background: "rgba(0,0,0,0.2)",
                              }}
                            />
                          ) : null}
                        </div>
                      )
                    )
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 0px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          padding: "0px 15px",
                          // background: "rgba(255,255,255,0.7)",
                          borderRadius: "10px",
                          height: "150px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Manrope, sans-serif",
                          fontSize: screenWidth < 800 ? "12px" : "18px",
                          fontWeight: "600",
                          color: "#00000066",
                        }}
                      >
                        No transaction data
                      </div>
                    </div>
                  )}

                  {data && data.length > 3 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center", // use "center" to vertically center the titles
                        colors: colors.grey[910],
                        backgroundColor: "#ffff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setShowAllTransactions(
                          showAllTransactions ? false : true
                        )
                      }
                    >
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <h5
                          className="all_button"
                          style={{
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 700,
                            fontSize: "13px",
                            color: "#435669",
                            margin: "0px",
                          }}
                        >
                          {showAllTransactions
                            ? "View Less Transactions"
                            : "View All Transactions"}
                        </h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Box>
        {!isMobile ? (
          <Box
            gridColumn="span 3"
            gridRow="span 2"
            overflow="auto"
            height="90vh"
          >
            {/* <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"

                        marginTop="-17px"
                        p="15px"
                    >
                        <Typography color={colors.grey[920]} variant="h5" fontWeight="800" fontFamily="Manrope, sans-serif" fontSize="20px">
                            Ad Spot
                        </Typography>
                    </Box> */}
            <Box
              gridColumn="span 2"
              opacity="1.00"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="34%"
              position="relative" // ensure the Box is relatively positioned
            >
              {/* <img
                src={adImage}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  position: "absolute", // make the image positioned absolutely
                  top: "0",
                  left: "0",
                  zIndex: "1", // layer the image below the button
                  objectFit: "cover",
                }}
              /> */}
              <DashboardAds />

              {/* <Button
              style={{
                position: "absolute", // This makes the button float on top of the image
                top: "280px", // position it at the top of the box
                zIndex: "2", // layer the button above the image
                borderRadius: "20px",
                fontSize: "18px",
                fontWeight: "400",
                fontFamily: "Manrope, sans-serif",
                color: "#115CA6",
              }}
              onClick={(event) => handleApplyLoan(event)}
            >
              Apply Now
            </Button> */}
            </Box>
            <Box
              gridColumn="span 4"
              gridRow="span 2"
              backgroundColor="rgba(153, 178, 205, 0)"
              overflow="auto"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // borderBottom={`4px solid ${colors.primary[500]}`}
                colors={colors.grey[100]}
                p="10px"
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  Recent Activities
                </Typography>
              </Box>
              {dataActivityLog && dataActivityLog.length > 0 ? (
                dataActivityLog.slice(0, 6).map((item, index) => (
                  <div
                    style={{
                      opacity:
                        index === 4
                          ? 0.6
                          : index === 5
                          ? 0.2
                          : index === 3
                          ? 0.9
                          : 1,
                      transitionDelay: `${index * 0.1}s`,
                    }}
                  >
                    <ContainerActivity
                      key={index}
                      title={
                        item.user_id[0] === "L"
                          ? item.principal_name
                          : item.entity_name
                          ? item.entity_name
                          : item.principal_name || "-"
                      }
                      subtitle={item.description || "-"}
                      index={index}
                    />
                  </div>
                ))
              ) : (
                <div
                  style={{
                    width: "100%",
                    padding: "0px 0px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 15px",
                      background: "rgba(255,255,255,0.7)",
                      borderRadius: "10px",
                      height: "350px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Manrope, sans-serif",
                      fontSize: "0.8em",
                      fontWeight: "600",
                      color: "#00000066",
                    }}
                  >
                    No recent activities
                  </div>
                </div>
              )}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Dashboard;
