import React from "react";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Modal from "antd/es/modal/Modal";
import { Collapse, message } from "antd";
import TableRepaymentUserDatabase from "../components/TableRepaymentUserDatabase";
import * as XLSX from "xlsx";
import * as Icons from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual } from "lodash";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import logo from "../assets/img/SL-01.png";
import boldFont from "../assets/font/Poppins-SemiBold.ttf";
import { NumbersOutlined } from "@mui/icons-material";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import pdfToText from "react-pdftotext";
import { CircularProgress } from "@mui/material";

Font.register({
  family: "Poppins",
  fonts: [
    { src: boldFont, fontWeight: 700 }, // Bold (700)
  ],
});

const UserDatabase = ({ searchTerm }) => {
  const iconLibrary = Icons;
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const iconNames = Object.keys(iconLibrary);
  const [dataAll, setDataAll] = useState([]);
  const [dataAll2, setDataAll2] = useState([]);

  const [dataLender, setDataLender] = useState([]);
  const [dataBorrower, setDataBorrower] = useState([]);
  const [subscribedEmail, setDataSubscribedEmail] = useState([]);

  const [activeTab, setActiveTab] = useState("Borrower");
  const [activeTab2, setActiveTab2] = useState("Admin");
  const [activeUser, setActiveUser] = useState(null);
  const [activeUser2, setActiveUser2] = useState(null);
  const [modal1, setModal1] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [selectedLoan2, setSelectedLoan2] = useState(null);
  const { Panel } = Collapse;
  const [dataRepaymentSchedule, setDataRepaymentSchedule] = useState([]);

  const [dataSingpassModal, setDataSingpassModal] = useState(false);
  const [needOtherAuthoriser, setNeedOtherAuthoriser] = useState(false);
  const [otherAuthoriserDetails, setOtherAuthoriserDetails] = useState(null);
  const [openDataIndex, setOpenDataIndex] = useState(null);
  const [keyman, setKeyman] = useState("");
  const [viewingUser, setViewingUser] = useState(0);
  const [viewingTab, setViewingTab] = useState(0);
  const [liveUserData, setLiveUserData] = useState(null);
  const [singpassData, setSingpassData] = useState(null);
  const [combinedSingpassData, setCombinedSingpassData] = useState([]);
  const [allCBSData, setAllCBSData] = useState([]);
  const [badgeVerified, setBadgeVerified] = useState(false);
  const [updatingUser, setUpdatingUser] = useState(null);
  const [keymanDetails, setKeymanDetails] = useState({
    contact: "",
    email: "",
  });
  const [accountManagerModalIsOpen, setAccountManagerModalIsOpen] =
    useState(false);
  const [accountManagerDetails, setAccountManagerDetails] = useState({
    profile: "",
    name: "",
    ph: "",
    email: "",
  });

  const [employeeList, setEmployeeList] = useState([]);
  const [creditReportFile, setCreditReportFile] = useState([]);
  const [creditFile, setCreditFile] = useState(null);
  const [CBSImpLoan, setCBSImpLoan] = useState(null);
  const [defaults, setDefaults] = useState(null);
  const [bankcruptcyProceedings, setBankcruptcyProceedings] = useState(null);
  const [overdueBalance, setOverdueBalance] = useState(null);
  const [monthlyCommitment, setMonthlyCommitment] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [enquiryDate, setEnquiryDate] = useState(null);
  const [chartMaxValue, setChartMaxValue] = useState(null);
  const [
    cBSImpLoanSupplementaryMonthlyInstallment,
    setCBSImpLoanSupplementaryMonthlyInstallment,
  ] = useState(null);

  function extractText(file) {
    pdfToText(file)
      .then((text) => {
        console.log(text);
        setCreditFile(text);
      })
      .catch((error) => {
        console.error("Failed to extract text from pdf");
      });
  }

  function splitStringToRows(inputString) {
    // Use a regular expression to match exactly two spaces
    const rows = inputString.split(/(?<! ) {2}(?! )/);

    return rows;
  }

  function extractTextBetween(beforeText, afterText) {
    if (creditFile !== null) {
      // Replace multiple spaces in beforeText and afterText with \s+ to match any number of spaces
      const beforeTextPattern = beforeText.replace(/\s+/g, "\\s+");
      const afterTextPattern = afterText.replace(/\s+/g, "\\s+");

      // Create a regex to find text between the beforeTextPattern and afterTextPattern
      const regex = new RegExp(`${beforeTextPattern}(.*?)${afterTextPattern}`);

      const match = creditFile.match(regex);
      if (match && match[1]) {
        return match[1].trim(); // Extract and trim any leading/trailing spaces
      } else {
        return "Text not found between specified markers";
      }
    }
  }

  function removeCharactersAroundPage(inputString) {
    // Regular expression to find the word "page" and capture the preceding and following characters
    const regex = /(.{0,19})page(.{0,7})/g; // Adjusted to capture more characters before "page"

    // Replace the matched segments with the word "page" alone
    const result = inputString.replace(regex, (match, p1, p2) => {
      // Determine the number of characters to remove from the beginning and the end
      const charsToRemoveBefore = 19 - 11; // 4 characters before the original 11
      const charsToRemoveAfter = 11 - 4;

      const removedStart =
        p1.length > charsToRemoveBefore
          ? p1.slice(0, -charsToRemoveBefore)
          : "";
      const removedEnd =
        p2.length > charsToRemoveAfter ? p2.slice(charsToRemoveAfter) : "";
      return `${removedStart}page${removedEnd}`;
    });

    return result;
  }

  function extractTextBetweeforloandetails(creditText, beforeText, afterText) {
    // Find the starting index of beforeText in the original text
    if (creditText !== null) {
      const startIndex = creditText.indexOf(beforeText);
      if (startIndex === -1) {
        return "Before text not found";
      }

      // Adjust the starting index to exclude the beforeText
      const adjustedStartIndex = startIndex + beforeText.length;

      // Find the ending index of afterText in the original text
      const endIndex = creditText.indexOf(afterText, adjustedStartIndex);
      if (endIndex === -1) {
        return "After text not found";
      }

      // Extract the text between beforeText and afterText
      const extractedText = creditText.substring(adjustedStartIndex, endIndex);

      return extractedText;
    }
  }

  const uploadCBSReport = async (file) => {
    try {
      const formData1 = new FormData();
      formData1.append("credit_report", creditReportFile);

      const targetLine =
        "  account status history product type   grantor bank   account type date opened / date closed   overdue balance last 12 cycles / cash advance / balance transfer full payment  ";

      function getMaxValue(data) {
        return data.reduce((max, current) => {
          // Parse the current value to a float for accurate comparison
          const currentValue = parseFloat(current.value.replace(/,/g, ""));
          return currentValue > max ? currentValue : max;
        }, 0);
      }
      function parseData(inputString) {
        // Adjusted regex to account for extra numbers before the relevant value
        const regex =
          /([a-zA-Z]+\s\d{4})\s+All\s+All\s+[\d,.]+\s+([\d,.]+\.\d{2})/g;

        const result = [];
        let match;

        // Loop through the matches and create the array of objects
        while ((match = regex.exec(inputString)) !== null) {
          const date = match[1];
          const value = match[2];
          result.push({ date, value });
        }

        return result;
      }

      function formatAndMergeString(input) {
        // Define patterns to split by
        const patterns = [
          "unsecured overdraft",
          "unsecured credit card",
          "other",
          "motor vehicle loan",
          "hdb loan",
          "private residential purchase",
          "unsecured personal loan",
        ];

        // Create a regular expression to split the string at the start of each record
        const regex = new RegExp(`(${patterns.join("|")})`, "g");

        // Split and rejoin the string with line breaks
        const formattedString = input
          .split(regex) // Split using the pattern
          .filter(Boolean) // Remove empty strings
          .map((str) => str.trim()) // Trim unnecessary spaces
          .join("\n"); // Rejoin with line breaks

        // Now, split each line by three or more spaces to create arrays
        const lines = formattedString.split("\n");

        // Merge consecutive elements, but check if the next element exists
        const result = [];
        for (let i = 0; i < lines.length; i += 2) {
          // Check if the next element exists before attempting to split
          if (lines[i + 1]) {
            const record = [lines[i]].concat(
              lines[i + 1].split(/\s{3,}/).map((part) => part.trim())
            );
            result.push(record);
          } else {
            // If there is no next element, just push the current one as is
            result.push([lines[i]]);
          }
        }

        return result;
      }
      function hasOverdueBalance(data) {
        // Iterate over the data (array of arrays)
        return data.some((item) => {
          // Check if the fifth index exists and is a valid number (i.e., a string that can be converted to a number)
          const fifthIndex = item[4];
          return !isNaN(fifthIndex) && parseFloat(fifthIndex) > 0; // If it's a number and greater than 0, consider it as overdue balance
        });
      }

      const combinePrivateResidentialPurchase = (splitArray) => {
        const combinedArray = [];

        for (let i = 0; i < splitArray.length; i++) {
          if (splitArray[i].includes("private residential purchase")) {
            combinedArray.push(
              splitArray[i] + "  " + (splitArray[i + 1] || "")
            );
            i++; // Skip the next element as it has been combined
          } else {
            combinedArray.push(splitArray[i]);
          }
        }

        return combinedArray;
      };
      const splitExactlyTwoSpaces = (inputString) => {
        // Split the string using the regular expression for exactly two spaces
        const splitArray = inputString.split(/(?<! )  (?! )/);
        return splitArray;
      };
      const combineSingleOrJoint = (splitArray) => {
        const combinedArray = [];

        for (let i = 0; i < splitArray.length; i++) {
          if (
            splitArray[i].startsWith("single") ||
            splitArray[i].startsWith("joint")
          ) {
            if (combinedArray.length > 0) {
              combinedArray[combinedArray.length - 1] += "  " + splitArray[i];
            } else {
              combinedArray.push(splitArray[i]);
            }
          } else {
            combinedArray.push(splitArray[i]);
          }
        }

        return combinedArray;
      };

      console.log(file);
      console.log(extractText(file));

      const textWithoutPage = removeCharactersAroundPage(
        creditFile.toLowerCase()
      ).replace(/page/g, "");

      const extract = extractTextBetweeforloandetails(
        textWithoutPage,
        "aggregated outstanding balances  month   product type   grantor bank   secured balances  unsecured balances interest bearing  unsecured balances non-interest bearing  exempted unsecured balances  ",
        "total"
      );

      const extract2ForMonthlyCommitment = extractTextBetweeforloandetails(
        textWithoutPage,
        "aggregated monthly instalment",
        "aggregated monthly instalments for preceding 5 months"
      );

      console.log(extract2ForMonthlyCommitment);

      const extract3ForChartingOutstandingBalance =
        extractTextBetweeforloandetails(
          creditFile,
          "Aggregated Outstanding Balances for Preceding 5 Months",
          "Aggregated Monthly Instalment"
        );
      let chartDate = parseData(extract3ForChartingOutstandingBalance);

      const extract4ForChartingOutstandingBalance =
        extractTextBetweeforloandetails(
          textWithoutPage,
          "aggregated monthly instalment  month   product type   grantor bank   account type**   property***   non-property   unsecured  exempted unsecured  ",
          "aggregated monthly instalments for preceding 5 months"
        );

      const extractEnquiryDate = extractTextBetweeforloandetails(
        textWithoutPage,
        "enquiry date",
        "reference"
      );

      const extract8ForOverdueBalance = extractTextBetweeforloandetails(
        textWithoutPage,
        "account status history",
        "previous enquiries  date "
      )
        .replace(new RegExp(targetLine, "g"), " ") // Replace all occurrences of targetLine
        .replace(/account status history/g, "") // Replace all occurrences of the specific phrases
        .replace(/product type/g, "")
        .replace(/grantor bank/g, "")
        .replace(/account type/g, "")
        .replace(/date opened \/ date closed/g, "")
        .replace(/overdue balance/g, "")
        .replace(
          /last 12 cycles \/ cash advance \/ balance transfer full payment/g,
          ""
        )
        .trim(); // Trim any leading/trailing spaces

      const creditReportData = {
        EnquiryDate: extractEnquiryDate,
        SecuredCreditLimit:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "Secured Credit Limit",
                  "Unsecured Credit Limit"
                ).replace(/,/g, "")
              )
            : null,
        UnsecuredCreditLimit:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "Unsecured Credit Limit",
                  "Exempted Credit Limit"
                ).replace(/,/g, "")
              )
            : null,
        CreditScore:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "financial institution.  Score",
                  "Risk Grade"
                )
              )
            : null,
        RiskGrade:
          creditFile && creditFile !== ""
            ? extractTextBetween("Risk Grade ", "Risk Grade Description", file)
            : null,
        ProbabilityofDefault:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "Probability of Default ",
                  " For more details on the description"
                ).replace(/,/g, "")
              )
            : null,
        SecuredCreditUtilized:
          creditFile && creditFile !== ""
            ? extractTextBetween(
                "Total  ",
                " Aggregated Outstanding Balances for Preceding 5 Months",
                file
              )
                ?.split("  ")
                ?.map((num) => parseFloat(num.replace(/,/g, "")))[0]
            : null,
        UnsecuredCreditUtilized:
          creditFile && creditFile !== ""
            ? extractTextBetween(
                "Total  ",
                " Aggregated Outstanding Balances for Preceding 5 Months",
                file
              )
                ?.split("  ")
                ?.map((num) => parseFloat(num.replace(/,/g, "")))[1]
            : null,
        SecuredCreditUtilizationinpercentage:
          creditFile && creditFile !== ""
            ? (
                (extractTextBetween(
                  "Total  ",
                  " Aggregated Outstanding Balances for Preceding 5 Months"
                )
                  ?.split("  ")
                  ?.map((num) => parseFloat(num.replace(/,/g, "")))[0] /
                  parseFloat(
                    extractTextBetween(
                      "Secured Credit Limit",
                      "Unsecured Credit Limit",
                      file
                    )?.replace(/,/g, "")
                  )) *
                100
              )?.toFixed(2)
            : null,
        UnsecuredCreditUtilizationinpercentage:
          creditFile && creditFile !== ""
            ? (
                (extractTextBetween(
                  "Total  ",
                  " Aggregated Outstanding Balances for Preceding 5 Months"
                )
                  ?.split("  ")
                  ?.map((num) => parseFloat(num.replace(/,/g, "")))[1] /
                  parseFloat(
                    extractTextBetween(
                      "Unsecured Credit Limit",
                      "Exempted Credit Limit",
                      file
                    )?.replace(/,/g, "")
                  )) *
                100
              )?.toFixed(2)
            : null,
        MonthlyCommitment: (
          parseFloat(
            extract2ForMonthlyCommitment
              .trim()
              .split("   ")
              [
                extract2ForMonthlyCommitment.trim().split("   ").length - 4
              ]?.replace(/,/g, "")
          ) +
          parseFloat(
            extract2ForMonthlyCommitment
              .trim()
              .split("   ")
              [
                extract2ForMonthlyCommitment.trim().split("   ").length - 3
              ]?.replace(/,/g, "")
          )
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "SGD",
        }),
        NoteworthyOngoingLoans:
          splitStringToRows(extract).filter(
            (row) =>
              row.includes("private residential purchase") ||
              row.includes("motor vehicle loan") ||
              row.includes("hdb loan")
          ) &&
          splitStringToRows(extract).filter(
            (row) =>
              row.includes("private residential purchase") ||
              row.includes("motor vehicle loan") ||
              row.includes("hdb loan")
          ).length !== 0
            ? splitStringToRows(extract).filter(
                (row) =>
                  row.includes("private residential purchase") ||
                  row.includes("motor vehicle loan") ||
                  row.includes("hdb loan")
              )
            : null,
        Last6MonthsGraphChartMaxData:
          getMaxValue(chartDate) && chartDate.reverse()
            ? getMaxValue(chartDate)
            : null,
        Last6MonthsGraphChartData:
          getMaxValue(chartDate) && chartDate.reverse()
            ? chartDate.reverse()
            : null,
        CBSImpLoanSupplementaryInstallment: combineSingleOrJoint(
          combinePrivateResidentialPurchase(
            splitExactlyTwoSpaces(extract4ForChartingOutstandingBalance)
          )
        ).filter(
          (row) =>
            row.includes("private residential purchase") ||
            row.includes("motor vehicle loan") ||
            row.includes("hdb loan")
        )
          ? combineSingleOrJoint(
              combinePrivateResidentialPurchase(
                splitExactlyTwoSpaces(extract4ForChartingOutstandingBalance)
              )
            ).filter(
              (row) =>
                row.includes("private residential purchase") ||
                row.includes("motor vehicle loan") ||
                row.includes("hdb loan")
            )
          : null,
        defaults: parseFloat(
          extractTextBetween("Defaults   ", "  Bankruptcy Proceedings   ")
        ),
        bankcruptcyProceedings: parseFloat(
          extractTextBetween(
            "  Bankruptcy Proceedings   ",
            "  Secured Credit Limit "
          )
        ),
        overdueBalance: hasOverdueBalance(
          formatAndMergeString(extract8ForOverdueBalance)
        ),
      };
      formData1.append("credit_report_data", JSON.stringify(creditReportData));

      formData1.append("user_id", `${activeUser.user_id}`);

      const updateBorrowerCreditReport = await fetch(
        "https://www.smart-lend.com/api/user/adminborrowerUpdateCreditReport",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: formData1,
        }
      );
      const updateBorrowerCreditReportasd =
        await updateBorrowerCreditReport.json();

      if (updateBorrowerCreditReport.status === 200) {
        fetchData2();
        message.success("CBS Report updated");
        setModal5(false);
        setIsLoadingScreen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const UniversaleUploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    // console.log(file);
    try {
      const response1 = await fetch(
        "https://www.smart-lend.com/api/file/uploadUniversalFile",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: formData,
        }
      );
      if (response1.status === 200) {
        const data = await response1.json();
        const blobUrl = data.url; // Assuming this is the URL you want to store
        // console.log(blobUrl);
        setAccountManagerDetails({
          ...accountManagerDetails,
          profile: blobUrl,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getEmployee = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/employee/getEmployee`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      setEmployeeList(json.employees);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/getAllUser",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            Accept: "application/json",
          },
        }
      );
      const json = await response.json();
      console.log("json:", json);
      setDataAll2(json);
      setDataAll(json);
      setDataLender(json["Lender"]);
      setDataBorrower(json["Borrower"]);
      setActiveUser(json["Borrower"][0]);
      setActiveUser2(json["Borrower"][0]);

      console.log("credit_report:", json["Borrower"]);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/getAllUser",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            Accept: "application/json",
          },
        }
      );
      const json = await response.json();
      console.log("json:", json);
      setDataAll2(json);
      setDataAll(json);
      setDataLender(json["Lender"]);
      setDataBorrower(json["Borrower"]);
      if (activeUser) {
        const activeUserIndex = json["Borrower"].findIndex(
          (us) => us.id === activeUser.id
        );
        if (activeUserIndex > -1) {
          setActiveUser(json["Borrower"][activeUserIndex]);
        }
      }

      console.log("credit_report:", json["Borrower"]);
    } catch (error) {
      console.error(error);
    }
  };

  const setAccountManager = async (dataTosend) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/setAccountManager`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(dataTosend),
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        message.success("Account Manager updated");
        fetchData2();
        setAccountManagerModalIsOpen(false);

        setAccountManagerDetails({
          profile: "",
          name: "",
          ph: "",
          email: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getEmployee();
  }, []);

  useEffect(() => {
    console.log(searchTerm);
  }, [searchTerm]);

  const getEmployeeName = (eid) => {
    if (eid === "cbs") {
      return "CBS Campaign";
    } else {
      if (eid && employeeList.length > 0) {
        const employeeIndex = employeeList.findIndex(
          (employee) => employee.id === parseFloat(eid)
        );
        if (employeeIndex !== -1) {
          return employeeList[employeeIndex]?.name
            ? employeeList[employeeIndex]?.name.length > 10
              ? employeeList[employeeIndex]?.name.slice(0, 10) + "..."
              : employeeList[employeeIndex]?.name
            : "Web";
        } else {
          return "Web";
        }
      } else {
        return "Web";
      }
    }
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [paramValue, setParamValue] = useState(""); // Extract the "param" value

  useEffect(() => {
    if (searchParams.get("search")) {
      setParamValue(searchParams.get("search"));
    } else {
      setParamValue("");
    }
  }, [searchParams.get("search")]);

  const availableTab = [
    { name: "Personal Information" },
    { name: "Corporate Information" },
    { name: "NOA History" },
    { name: "Capitals" },
    { name: "Financials" },
    { name: "Shareholders" },
    { name: "Borrower Report" },
  ];

  const singpassDetails = async (
    user,
    other_authorization,
    loan_id,
    keyman
  ) => {
    try {
      setKeyman(keyman);
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getBorrowerSingppass?user_id=${user}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      let allSingpassData = [json.singpass_data];
      setDataSingpassModal(true);
      if (other_authorization) {
        console.log("need other authorizer");
        setNeedOtherAuthoriser(true);
        try {
          const response2 = await fetch(
            `https://www.smart-lend.com/api/loanDetails/getLoanAuthorisers`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
              body: JSON.stringify({ loan_id: loan_id }),
            }
          );

          const json2 = await response2.json();
          setOtherAuthoriserDetails(json2.authorisers);
          console.log(JSON.parse(json2.authorisers[0].singpass_data));
          console.log(json.singpass_data);
          setLiveUserData(json.singpass_data);
          console.log(json2.authorisers);

          for (const single of json2.authorisers) {
            allSingpassData.push(JSON.parse(single.singpass_data));
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.log("Doesnt need other authoriser");
        setLiveUserData(json.singpass_data);
        setNeedOtherAuthoriser(false);
        setOtherAuthoriserDetails(null);
      }
      if (json.singpass_data) {
        setSingpassData(json.singpass_data);
        console.log(allSingpassData);
        setCombinedSingpassData(allSingpassData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getShareholderAddress = (data) => {
    const allSingpassData = [singpassData];
    if (otherAuthoriserDetails !== null && otherAuthoriserDetails.length > 0) {
      for (const single of otherAuthoriserDetails) {
        allSingpassData.push(JSON.parse(single.singpass_data));
      }
      const toPush = allSingpassData.filter(
        (sd) => sd?.person?.name?.value === data
      );
      // console.log(toPush);
      return toPush;
    } else {
      return allSingpassData;
    }
  };
  const [lenderProfileData, setLenderProfileData] = useState(null);

  const handleGetLenderProfile = async (lender_id) => {
    try {
      const response1 = await fetch(
        "https://www.smart-lend.com/api/user/getLenderProfileDesign",
        {
          method: "POST",
          body: JSON.stringify({ user_id: lender_id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response1.json();
      if (json.design !== null) {
        if (isEqual(JSON.parse(json.design), lenderProfileData)) {
          setLenderProfileData(null);
        } else {
          setLenderProfileData(JSON.parse(json.design));
        }
      } else {
        setLenderProfileData(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function hasLateDays(loan, threshold) {
    console.log("loan value:", loan);
    console.log("threshold value:", threshold);
    if (!loan.repaymentSchedule) return false;
    for (let schedule of loan.repaymentSchedule) {
      console.log("schedule status:", schedule.status);
      if (schedule.status === "pending" && schedule.late_day > threshold) {
        return true;
      }
    }
    return false; // Return false if no matching schedule is found
  }

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1 and pad with a 0 if needed
    const day = String(today.getDate()).padStart(2, "0"); // Pad with a 0 if needed

    return `${year}-${month}-${day}`;
  }

  function hasAlmostThreeDay(loan, todayDate) {
    console.log("loan value:", loan);
    console.log("todayDate value:", todayDate);
    if (!loan.repaymentSchedule) return false;

    // Convert todayDate string into a Date object
    const today = new Date(todayDate);

    for (let schedule of loan.repaymentSchedule) {
      console.log("schedule status:", schedule.status);

      // Convert schedule.repay_due_date string into a Date object
      const dueDate = new Date(schedule.repay_due_date);
      console.log("dueDate:", dueDate);
      // Calculate the difference in days
      const diffInDays = Math.ceil((dueDate - today) / (1000 * 60 * 60 * 24));
      console.log("diffInDays:", diffInDays);
      if (schedule.status === "pending" && diffInDays <= 3) {
        return true;
      }
    }
    return false; // Return false if no matching schedule is found
  }

  const handleDownloadCreditReport = async (userId, event) => {
    event.preventDefault();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/viewCreditReport?user_id=${userId}`,
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;

      // the filename you want
      a.download = "CreditReport.pdf"; // You can dynamically set the filename based on content-disposition header or any other logic

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [vpntoaccountuserId, setVpntoaccountuserId] = useState(null);
  const [otpPin, setOtpPin] = useState("");
  const [otpModalIsOpen, setOtpModalIsOpen] = useState(false);

  const createVPNPin = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/vpn/createpin",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        message.success("OTP have been sent to Roy.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validatePassword = async () => {
    try {
      const formData = new FormData();
      formData.append("otp", otpPin);
      const response = await fetch(
        "https://www.smart-lend.com/api/vpn/validatepin",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            Accept: "application/json",
          },
          body: formData,
        }
      );

      if (response.status === 200) {
        message.success("Otp is valid.");
        vpnToAccount(vpntoaccountuserId);
      } else {
        message.error("Otp is invalid.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const vpnToAccount = async (user_id) => {
    try {
      const formData = new FormData();
      formData.append("userId", user_id);

      const response = await fetch(
        "https://www.smart-lend.com/api/admin/vpnToUserAccount",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            Accept: "application/json",
          },
          body: formData,
        }
      );

      const json = await response.json();
      if (response.status === 200) {
        if (json.user.user_account_type === "lender") {
          Cookies.set("token", json.token);
          Cookies.set("user_account_type", "lender");
          window.location.href = "https://smart-lend.com/dashboard";
        } else {
          Cookies.set("token", json.token);
          Cookies.set("user_account_type", "borrower");
          window.location.href = "https://smart-lend.com/b-dashboard";
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/guest/getEmailSubcription",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + Cookies.get("token"),
              Accept: "application/json",
            },
          }
        );
        const json = await response.json();
        console.log("json:", json);
        setDataAll2(json);
        // setDataLender(json["Lender"]);
        // setDataBorrower(json["Borrower"]);
        // setActiveUser(json["Borrower"][0]);
        // setActiveUser2(json["Borrower"][0]);
        setDataSubscribedEmail(json);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const addressFormatter = (value) => {
    if (value) {
      const dateParts = value.split("-");
      if (dateParts.length === 3) {
        const day = dateParts[2].split(" ")[0];
        const month = dateParts[1];
        const year = dateParts[0];

        // Map month numbers to month names (e.g., '09' => 'Sep')
        const monthNames = {
          "01": "Jan",
          "02": "Feb",
          "03": "Mar",
          "04": "Apr",
          "05": "May",
          "06": "Jun",
          "07": "Jul",
          "08": "Aug",
          "09": "Sep",
          10: "Oct",
          11: "Nov",
          12: "Dec",
        };

        // Format the date (e.g., '19 Sep' or '30 Dec')
        const formattedDate = `${monthNames[month]} ${day}, ${year}`;

        return formattedDate;
      }

      // Return the original value if it doesn't match the expected format
      return value;
    } else {
      return null;
    }
  };

  const options = { day: "numeric", month: "short", year: "numeric" };
  const currentDate = new Date();

  function getSanitizedUserInfo(activeUser) {
    const excludedProperties = ["loanOffers", "subscription", "password"];

    if (activeUser) {
      let sanitizedData = {};
      Object.entries(activeUser).forEach(([key, value]) => {
        if (!excludedProperties.includes(key)) {
          sanitizedData[key] = value;
        }
      });
      return sanitizedData;
    }
    return null;
  }

  function prepareDataForExcel(userType, data) {
    let result = [];

    if (data[userType]) {
      // Add user basic info
      result = [...data[userType]];

      // Add loanAccepted
      if (data.loanAccepted) {
        result = [...result, ...data.loanAccepted];
      }

      // Add loanRequest for Borrower
      if (userType === "Borrower" && data.loanRequest) {
        result = [...result, ...data.loanRequest];
      }

      // Add singpass_data if it exists and for Borrower
      if (userType === "Borrower" && data.singpass_data) {
        if (data.singpass_data.entity) {
          result = [...result, ...data.singpass_data.entity];
        }
        if (data.singpass_data.person) {
          result = [...result, ...data.singpass_data.person];
        }
      }
    }
    return result;
  }

  function renderUserInfo(activeUser) {
    // Define an array of properties to exclude from display
    const excludedProperties = [
      "loanOffers",
      "subscription",
      "id",
      "password",
      "singpass_data",
      "loanRequest",
      "loanAccepted",
      "singpass_data_backup",
    ];
    if (activeUser) {
      return Object.entries(activeUser).map(([key, value]) => {
        // Check if the property is excluded
        if (excludedProperties.includes(key)) {
          return null; // Skip this property
        }

        // Check if the value is a JSON string (e.g., subscription)
        if (
          typeof value === "string" &&
          value.startsWith("{") &&
          value.endsWith("}")
        ) {
          try {
            value = JSON.parse(value);
          } catch (error) {
            // Handle JSON parse error (optional)
          }
        }

        // Render the label and value
        return (
          <div key={key} style={{ width: "23%" }}>
            <div>{key}:</div>
            <div
              style={{
                color: "#142C44CC",
                fontSize: "15px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                margin: "0px 0px 10px 0px",
              }}
            >
              {typeof value === "object" ? JSON.stringify(value) : value}
            </div>
          </div>
        );
      });
    }
  }

  // Usage in your component
  <div>{activeUser ? renderUserInfo(activeUser) : null}</div>;

  function exportToExcel(data) {
    if (data) {
      const wb = XLSX.utils.book_new(); // Create a new workbook

      // Sheet for main user info
      const mainUserInfo = { ...data };
      delete mainUserInfo.singpass_data;
      delete mainUserInfo.loanRequest;
      delete mainUserInfo.loanAccepted;
      const mainWs = XLSX.utils.json_to_sheet([mainUserInfo]);
      XLSX.utils.book_append_sheet(wb, mainWs, "UserInfo");
      // Sheet for singpass_data
      if (data.singpass_data) {
        const singpassWs = XLSX.utils.json_to_sheet([
          flattenObject(data.singpass_data),
        ]);
        console.log(flattenObject(singpassWs), singpassWs);
        XLSX.utils.book_append_sheet(wb, singpassWs, "SingPass Data");
      }

      // Sheet for loanRequest
      if (data.loanRequest && data.loanRequest.length) {
        const loanRequestWs = XLSX.utils.json_to_sheet(data.loanRequest);
        console.log(loanRequestWs);
        XLSX.utils.book_append_sheet(wb, loanRequestWs, "Loan Requests");
      }

      // Sheet for loanAccepted
      if (data.loanAccepted && data.loanAccepted.length) {
        const loanAcceptedWs = XLSX.utils.json_to_sheet(data.loanAccepted);
        XLSX.utils.book_append_sheet(wb, loanAcceptedWs, "Loans Accepted");
      }

      XLSX.writeFile(wb, "user_info.xlsx"); // Save the workbook as an Excel file
    }
  }

  function exportToEmailSubscribed(data) {
    // Create a new array without the 'created_at' and 'updated_at' properties
    const filteredData = data.map(
      ({ created_at, updated_at, ...item }) => item
    );

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the filtered data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscribed Emails");

    // Generate an Excel file
    XLSX.writeFile(workbook, "SubscribedEmails.xlsx");
  }

  function getBorrowerInfo(data) {
    console.log("dataGetBorrower:", data.Borrower);

    // Initialize an array to hold the processed borrower information
    let processedBorrowers = [];

    // Iterating over each borrower in the Borrower array
    data.Borrower.forEach((borrower, index) => {
      console.log(`Borrower ${index}:`, borrower);

      // Check if loanAccepted and loanRequest exist and are arrays for each borrower
      const loanAccepted = Array.isArray(borrower.loanAccepted)
        ? borrower.loanAccepted
        : [];
      const loanRequest = Array.isArray(borrower.loanRequest)
        ? borrower.loanRequest
        : [];

      // Logging the contents of loanAccepted and loanRequest arrays for each borrower
      console.log(
        `Borrower ${index} Loan Accepted:`,
        loanAccepted.length > 0 ? loanAccepted : "No data"
      );
      console.log(
        `Borrower ${index} Loan Request:`,
        loanRequest.length > 0 ? loanRequest : "No data"
      );

      // Collecting processed borrower data
      processedBorrowers.push({
        Borrower: borrower,
        loanAccepted: loanAccepted,
        loanRequest: loanRequest,
        singpass_data_entity: borrower.singpass_data
          ? borrower.singpass_data.entity
          : null,
        singpass_data_person: borrower.singpass_data
          ? borrower.singpass_data.person
          : null,
      });
    });

    // Return the processed borrower information
    return processedBorrowers;
  }

  function getLenderInfo(data) {
    console.log("dataGetLender:", data.Lender);

    // Initialize an array to hold the processed lender information
    let processedLender = [];

    // Iterating over each lender in the Lender array
    data.Lender.forEach((lender, index) => {
      console.log(`Lender ${index}:`, lender);

      // Check if loanAccepted and loanOffers exist and are arrays for each lender
      const loanAccepted = Array.isArray(lender.loanAccepted)
        ? lender.loanAccepted
        : [];
      const loanOffers = Array.isArray(lender.loanOffers)
        ? lender.loanOffers
        : [];

      // Logging the contents of loanAccepted and loanOffers arrays for each lender
      console.log(
        `Lender ${index} Loan Accepted:`,
        loanAccepted.length > 0 ? loanAccepted : "No data"
      );
      console.log(
        `Lender ${index} Loan Offered:`,
        loanOffers.length > 0 ? loanOffers : "No data"
      );

      // Collecting processed lender data
      processedLender.push({
        Lender: lender,
        loanAccepted: loanAccepted,
        loanOffers: loanOffers,
        singpass_data_entity: lender.singpass_data
          ? lender.singpass_data.entity
          : null,
        singpass_data_person: lender.singpass_data
          ? lender.singpass_data.person
          : null,
      });
    });

    // Return the processed lender information
    return processedLender;
  }
  function flattenObject(obj, parentKey = "", result = {}) {
    if (Array.isArray(obj)) {
      obj.forEach((item, index) => {
        flattenObject(item, `${parentKey}[${index}]`, result);
      });
    } else {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          let propName = parentKey ? `${parentKey}_${key}` : key;

          if (typeof obj[key] === "object" && obj[key] !== null) {
            flattenObject(obj[key], propName, result);
          } else {
            result[propName] = obj[key];
          }
        }
      }
    }
    return result;
  }

  function exportToExcelAllInfo(processedData, userType) {
    console.log("userType: ", userType);
    console.log(processedData);
    if (processedData && processedData.length > 0) {
      const wb = XLSX.utils.book_new(); // Create a new workbook

      // Sheet for basic borrower/lender info
      const baseData = processedData.map(
        (item) => item.Borrower || item.Lender
      );

      let updatedFlattenedBaseData = [];

      for (const base of baseData) {
        const flattenedObject = flattenObject(base);
        updatedFlattenedBaseData.push(flattenedObject);
      }
      const baseWs = XLSX.utils.json_to_sheet(updatedFlattenedBaseData);
      XLSX.utils.book_append_sheet(wb, baseWs, `${userType} Info`);
      console.log(baseData, updatedFlattenedBaseData, baseWs);

      // Sheets for loanAccepted, loanRequest, singpass_data (entity and person)
      processedData.forEach((item, index) => {
        if (item.loanAccepted && item.loanAccepted.length > 0) {
          const loanAcceptedWs = XLSX.utils.json_to_sheet(item.loanAccepted);
          XLSX.utils.book_append_sheet(
            wb,
            loanAcceptedWs,
            `Loan Accepted ${index}`
          );
        }

        if (
          userType === "Borrower" &&
          item.loanRequest &&
          item.loanRequest.length > 0
        ) {
          const loanRequestWs = XLSX.utils.json_to_sheet(item.loanRequest);
          XLSX.utils.book_append_sheet(
            wb,
            loanRequestWs,
            `Loan Request ${index}`
          );
        }

        // New code for loanOffers (specific to Lender)
        if (
          userType === "Lender" &&
          item.loanOffers &&
          item.loanOffers.length > 0
        ) {
          const loanOffersWs = XLSX.utils.json_to_sheet(item.loanOffers);
          XLSX.utils.book_append_sheet(
            wb,
            loanOffersWs,
            `Loan Offers ${index}`
          );
        }

        if (userType === "Borrower" && item.singpass_data) {
          if (item.singpass_data.entity) {
            const entityWs = XLSX.utils.json_to_sheet([
              item.singpass_data.entity,
            ]);
            console.log(entityWs);
            XLSX.utils.book_append_sheet(
              wb,
              entityWs,
              `SingPass Entity Data ${index}`
            );
          }
          if (item.singpass_data.person) {
            const personWs = XLSX.utils.json_to_sheet([
              item.singpass_data.person,
            ]);
            console.log(personWs);
            XLSX.utils.book_append_sheet(
              wb,
              personWs,
              `SingPass Person Data ${index}`
            );
          }
        }
      });

      XLSX.writeFile(wb, `${userType}_info.xlsx`); // Save the workbook as an Excel file
    } else {
      console.error("No data available to export.");
    }
  }

  function renderLoanAcceptedInfo(loan) {
    // Define an array of properties to exclude from display
    const excludedProperties = ["repaymentSchedule"];
    if (loan) {
      return Object.entries(loan).map(([key, value]) => {
        // Check if the property is excluded
        if (excludedProperties.includes(key)) {
          return null; // Skip this property
        }
        return (
          <div key={key} style={{ width: "45%" }}>
            <div>{key}:</div>
            <div
              style={{
                color: "#142C44CC",
                fontSize: "15px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                margin: "0px 0px 10px 0px",
              }}
            >
              {typeof value === "object" ? JSON.stringify(value) : value}
            </div>
          </div>
        );
      });
    }
    return null;
  }

  function renderLoanInfo(loan) {
    if (loan) {
      return Object.entries(loan).map(([key, value]) => {
        return (
          <div key={key} style={{ width: "45%" }}>
            <div>{key}:</div>
            <div
              style={{
                color: "#142C44CC",
                fontSize: "15px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
                margin: "0px 0px 10px 0px",
              }}
            >
              {typeof value === "object" ? JSON.stringify(value) : value}
            </div>
          </div>
        );
      });
    }
    return null;
  }

  // Usage in your component
  // <div>{activeUser ? renderLoanAcceptedInfo(activeUser) : null}</div>;

  const [resetPassword, setResetPassword] = useState("");

  const adminResetPassword = async (email) => {
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", resetPassword);
      const response = await fetch(
        "https://www.smart-lend.com/api/admin/passwordreset",
        {
          method: "POST",
          body: formData,
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        console.log(json);
        setModal4(false);
        setResetPassword("");
        message.success("Password has been changed");
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div
      style={{
        width: "100%",
        height: "91vh",
        display: "flex",
        justifyContent: "space-betweeen",
        gap: "20px",
      }}
    >
      {isLoadingScreen ? (
        <div
          style={{
            background: "rgba(256,256,256,0.4)",
            height: window.innerHeight,
            width: window.innerWidth,
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2000,
          }}
        >
          <CircularProgress size={"5rem"} />
        </div>
      ) : null}
      <Modal
        width={"60%"}
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={dataSingpassModal}
        onCancel={() => {
          setDataSingpassModal(false);
          setSingpassData(null);
          setOpenDataIndex(null);
          setKeyman("");
        }}
        footer={null}
      >
        {singpassData ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "20px",
                color: "rgba(20, 44, 68, 0.8)",
                fontWeight: 900,
                fontFamily: "Manrope, sans-serif",
                fontSize: 20,
              }}
            >
              <h2> Borrower information</h2>
            </div>
            <div
              style={{
                display: "flex",
                borderTop: "1px solid lightgrey",
                borderBottom: "1px solid lightgrey",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "20px",
                padding: "10px 0px",
                width: "100%",
                overflowX: "hidden",
              }}
            >
              {availableTab.map((view, index) => (
                <div
                  onClick={() => {
                    setViewingTab(index);
                  }}
                  className="all_button"
                  style={{
                    cursor: "pointer",
                    color:
                      viewingTab === index
                        ? "rgba(20, 44, 68, 0.8)"
                        : "rgba(0,0,0,0.4)",
                    fontSize: viewingTab === index ? "13px" : "12px",
                    fontWeight: viewingTab === index ? "700" : "400",
                    transition: "0.3s all ease-in-out",
                  }}
                  key={index}
                >
                  {view.name}
                </div>
              ))}
            </div>
            <div
              style={{
                padding: "15px 0px",
                overflowX: "hidden",
              }}
            >
              {liveUserData ? (
                viewingTab === 0 ? (
                  <div
                    style={{
                      padding: "15px 15px",
                      borderRadius: "8px",
                      width: "100%",
                      background: "rgba(14, 90, 165, 0.1)",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "2px 20px",
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        NRIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          min={0}
                          // className={nricError ? "error-border" : ""}
                          value={liveUserData?.person?.uinfin?.value}
                        />
                        {/* {nricError && <div style={{ color: 'red' }}>Please fill in the NRIC</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Full Name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          type="text"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          value={liveUserData?.person?.name?.value}
                          // required
                        />
                        {/* {fullNameError && <div style={{ color: 'red' }}>Please fill in the Full Name</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Sex
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          value={liveUserData?.person?.sex?.desc}
                          // required
                        />
                        {/* {sexError && <div style={{ color: 'red' }}>Please fill in the Sex</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Race
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.race?.desc}
                        />
                        {/* {raceError && <div style={{ color: 'red' }}>Please fill in the Race</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Nationality
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.nationality?.desc}
                        />
                        {/* {nationalityError && <div style={{ color: 'red' }}>Please fill in the Nationality</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Date of Birth
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={liveUserData?.person?.dob?.value}
                        />
                        {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Country of Birth
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.birthcountry?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Residential Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.residentialstatus?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.passtype?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.passstatus?.value}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass Expiry Date
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={liveUserData?.person?.passexpirydate?.value}
                        />
                        {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Ownership of Private Property Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.ownerprivate?.value}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Email
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          type="email"
                          disabled
                          // required
                          value={liveUserData?.person?.email?.value}
                        />
                        {/* {emailError && <div style={{ color: 'red' }}>Please fill in the Email</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Mobile Number
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="number"
                          // required
                          value={liveUserData?.person?.mobileno?.nbr?.value}
                        />
                        {/* {mobileNoError && <div style={{ color: 'red' }}>Please fill in the Mobile Number</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registered Address
                      </div>
                      <div style={{ width: "60%" }}>
                        <textarea
                          rows="2"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          // required
                          value={
                            liveUserData?.person?.regadd
                              ? (liveUserData?.person?.regadd?.unit?.value !==
                                null
                                  ? `Unit ${liveUserData?.person?.regadd?.unit?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.floor?.value !==
                                null
                                  ? `Floor ${liveUserData?.person?.regadd?.floor?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.block?.value !==
                                null
                                  ? `Block ${liveUserData?.person?.regadd?.block?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.street?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.street?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.postal?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.postal?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.country?.desc !==
                                null
                                  ? `${liveUserData?.person?.regadd?.country?.desc}.`
                                  : "")
                              : ""
                          }
                        ></textarea>
                        {/* {registeredAddressError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Housing Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.housingtype?.desc}
                        />
                        {/* {housingTypeError && <div style={{ color: 'red' }}>Please fill in the Housing Type</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Marital Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          value={liveUserData?.person?.marital?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Occupation
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.occupation?.value}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Employer's name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={""}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Employment Sector
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={""}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.hdbtype?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Housing Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.housingtype?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    {liveUserData?.person?.hdbownership?.map((hdb, index) => (
                      <div
                        key={index}
                        style={{
                          width: "83.5%",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            HDB Ownership {index + 1} - Address
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={
                                hdb?.address
                                  ? (hdb?.address?.unit?.value !== null
                                      ? `Unit ${hdb?.address?.unit?.value}, `
                                      : "") +
                                    (hdb?.address?.floor?.value !== null
                                      ? `Floor ${hdb?.address?.floor?.value}, `
                                      : "") +
                                    (hdb?.address?.block?.value !== null
                                      ? `Block ${hdb?.address?.block?.value}, `
                                      : "") +
                                    (hdb?.address?.street?.value !== null
                                      ? `${hdb?.address?.street?.value}, `
                                      : "") +
                                    (hdb?.address?.postal?.value !== null
                                      ? `${hdb?.address?.postal?.value}, `
                                      : "") +
                                    (hdb?.address?.country?.desc !== null
                                      ? `${hdb?.address?.country?.desc}.`
                                      : "")
                                  : ""
                              }
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            HDB Ownership {index + 1} - Outstanding HDB Loan
                            Balance
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={hdb?.outstandingloanbalance?.value?.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "SGD",
                                }
                              )}
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            HDB Ownership {index + 1} - Type of HDB Dwelling
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={hdb?.hdbtype?.desc}
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            HDB Ownership {index + 1} - Monthly Loan Installment
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={hdb?.monthlyloaninstalment?.value?.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "SGD",
                                }
                              )}
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                      </div>
                    ))}
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Vehicles - Vehicles Model
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.person?.vehicles[0]?.model?.value
                          }
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Vehicles - Vehicles Make
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.vehicles[0]?.make?.value}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    {liveUserData?.person?.cpfhousingwithdrawal?.withdrawaldetails?.map(
                      (cpf, index) => (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            CPF Usage{" "}
                            {(cpf.address.unit?.value !== undefined
                              ? `Unit ${cpf.address.unit?.value}, `
                              : "") +
                              (cpf.address.floor?.value !== undefined
                                ? `Floor ${cpf.address.floor?.value}, `
                                : "") +
                              (cpf.address.block?.value !== undefined
                                ? `Block ${cpf.address.block?.value}, `
                                : "") +
                              (cpf.address.street?.value !== undefined
                                ? `${cpf.address.street?.value}, `
                                : "") +
                              (cpf.address.postal?.value !== undefined
                                ? `${cpf.address.postal?.value}, `
                                : "") +
                              (cpf.address.country?.desc !== undefined
                                ? `${cpf.address.country?.desc}.`
                                : "")}
                            :
                          </div>
                          <div
                            style={{
                              width: "42%",
                              display: "flex",
                              marginBottom: 10,
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                color: "rgba(20, 44, 68, 0.8)",
                                fontWeight: 800,
                                fontFamily: "Manrope, sans-serif",
                                fontSize: 14,
                              }}
                            >
                              Principal Withdrawal Amount:
                            </div>
                            <div style={{ width: "40%" }}>
                              <input
                                style={{
                                  width: "100%",
                                  borderRadius: "10px",
                                  border: "none",
                                  outline: "none",
                                  padding: 7,
                                }}
                                disabled
                                type="text"
                                // required
                                value={cpf?.principalwithdrawalamt?.value?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "SGD",
                                  }
                                )}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              width: "42%",
                              display: "flex",
                              marginBottom: 10,
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                color: "rgba(20, 44, 68, 0.8)",
                                fontWeight: 800,
                                fontFamily: "Manrope, sans-serif",
                                fontSize: 14,
                              }}
                            >
                              Accrued Interest Amount:
                            </div>
                            <div style={{ width: "40%" }}>
                              <input
                                style={{
                                  width: "100%",
                                  borderRadius: "10px",
                                  border: "none",
                                  outline: "none",
                                  padding: 7,
                                }}
                                disabled
                                type="text"
                                // required
                                value={cpf?.accruedinterestamt?.value?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "SGD",
                                  }
                                )}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              width: "42%",
                              display: "flex",
                              marginBottom: 10,
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                color: "rgba(20, 44, 68, 0.8)",
                                fontWeight: 800,
                                fontFamily: "Manrope, sans-serif",
                                fontSize: 14,
                              }}
                            >
                              Monthly Installment (CPF):
                            </div>
                            <div style={{ width: "40%" }}>
                              <input
                                style={{
                                  width: "100%",
                                  borderRadius: "10px",
                                  border: "none",
                                  outline: "none",
                                  padding: 7,
                                }}
                                disabled
                                type="text"
                                // required
                                value={cpf?.monthlyinstalmentamt?.value?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "SGD",
                                  }
                                )}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              width: "42%",
                              display: "flex",
                              marginBottom: 10,
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                color: "rgba(20, 44, 68, 0.8)",
                                fontWeight: 800,
                                fontFamily: "Manrope, sans-serif",
                                fontSize: 14,
                              }}
                            >
                              Total Amount of CPF Allowed For Property:
                            </div>
                            <div style={{ width: "40%" }}>
                              <input
                                style={{
                                  width: "100%",
                                  borderRadius: "10px",
                                  border: "none",
                                  outline: "none",
                                  padding: 7,
                                }}
                                disabled
                                type="text"
                                // required
                                value={cpf?.totalamountofcpfallowedforproperty?.value?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "SGD",
                                  }
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : viewingTab === 1 ? (
                  <div
                    style={{
                      padding: "15px 15px",
                      borderRadius: "8px",
                      width: "100%",
                      background: "rgba(14, 90, 165, 0.1)",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "2px 20px",
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        UEN
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]?.uen?.value
                          }
                        />
                        {/* {uenError && <div style={{ color: 'red' }}>Please fill in the UEN</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]["entity-name"]
                              ?.value
                          }
                        />
                        {/* {entityNameError && <div style={{ color: 'red' }}>Please fill in the Entity Name</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]["entity-type"]
                              ?.value
                          }
                        />
                        {/* {entityTypeError && <div style={{ color: 'red' }}>Please fill in the Entity Type</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "entity-status"
                            ]?.value
                          }
                        />
                        {/* {entityStatusError && <div style={{ color: 'red' }}>Please fill in the Entity Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registered Address
                      </div>
                      <div style={{ width: "60%" }}>
                        <textarea
                          rows="2"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                            resize: "none",
                          }}
                          disabled
                          // required
                          value={
                            liveUserData?.entity?.addresses["addresses-list"][0]
                              ? (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.unit?.value !== undefined
                                  ? `Unit ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.floor?.value !== undefined
                                  ? `Floor ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.block?.value !== undefined
                                  ? `Block ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.street?.value !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.postal?.value !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.country?.desc !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                  : "")
                              : ""
                          }
                        ></textarea>
                        {/* {registeredAddressEntityError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registration Date
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "registration-date"
                            ]?.value
                          }
                        />
                        {/* {registrationDateError && <div style={{ color: 'red' }}>Please fill in the Registered Date</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Primary SSIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "primary-activity"
                            ]?.desc
                          }
                        />
                        {/* {primarySSICError && <div style={{ color: 'red' }}>Please fill in the Primary SSIC</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Secondary SSIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "secondary-activity"
                            ]?.desc
                          }
                        />
                        {/* {secondarySSICError && <div style={{ color: 'red' }}>Please fill in the Secondary SSIC</div>} */}
                      </div>
                    </div>
                    {liveUserData?.entity?.appointments[
                      "appointments-list"
                    ]?.map((appointment, index) => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "2px 20px",
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Position
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={appointment.position.desc}
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        {/* Similar structure for NRIC, Full Name, and Appointment Date */}
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - NRIC
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"].idno.value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"].uen.value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Full Name
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"][
                                      "person-name"
                                    ].value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"][
                                      "entity-name"
                                    ].value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Appointment Date
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="date"
                              value={appointment["appointment-date"].value}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : viewingTab === 2 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Category
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Employment
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Interest
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Rent
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Tax Clearance
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Trade
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Years of Assessment
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.person?.noahistory?.noas?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.amount?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.category?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.employment?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.interest?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.rent?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.taxclearance?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.trade?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.yearofassessment?.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 3 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Capital Type
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Issued Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Paid Up Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Share Alloted Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.capitals["capitals-list"]?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["capital-type"]?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "issued-capital-amount"
                              ]?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "paid-up-capital-amount"
                              ]?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "share-allotted-amount"
                              ]?.value?.toLocaleString("en-US")}
                            </td>
                            {/* Add other data cells */}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 4 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Profit Loss After Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Profit Loss Before Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Revenue
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Current Period End Date
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Current Period Start Date
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Capital Paid Up Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Profit Loss After Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Profit Loss Before Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Revenue
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Is Audited
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.financials?.highlights?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-profit-loss-after-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-profit-loss-before-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-revenue"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["current-period-end-date"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["current-period-start-date"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {
                                item?.["group-capital-paid-up-capital-amount"]
                                  ?.value
                              }
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-profit-loss-after-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-profit-loss-before-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-revenue"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["is-audited"]?.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 5 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Allocation
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Category
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Name
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          NRIC
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        {/* <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference NRIC</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Nationality</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Name</th> */}
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Share Type
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.shareholders[
                        "shareholders-list"
                      ] ? (
                        liveUserData?.entity?.shareholders[
                          "shareholders-list"
                        ]?.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.allocation?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.category?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {
                                item?.["person-reference"]?.["person-name"]
                                  ?.value
                              }
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["person-reference"]?.idno?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            {/* <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].idno.value}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].nationality.desc}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"]["person-name"].value}</td> */}
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["share-type"]?.desc}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              textAlign: "center",
                              padding: "5px 10px",
                            }}
                            colSpan="11"
                          >
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                    <PDFViewer style={{ width: "100%", height: "80vh" }}>
                      <Document>
                        <Page size="A4">
                          {/**Letterhead section */}
                          <View
                            style={{
                              paddingVertical: 15,
                            }}
                          >
                            <View
                              style={{
                                paddingHorizontal: 40,
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: 10,
                                // borderBottom: 1,
                                // borderBottomColor: "grey",
                                paddingBottom: 10,
                              }}
                            >
                              <Image
                                source={logo}
                                style={{ width: 80, objectFit: "contain" }}
                              />
                              <View>
                                <Text
                                  style={{
                                    fontSize: 18,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  SmartLend Financial Pte. Ltd.
                                </Text>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 4,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: -3,
                                  }}
                                >
                                  <Text style={{ fontSize: 7 }}>
                                    UEN: 202327568G
                                  </Text>
                                  <Text style={{ fontSize: 7 }}>
                                    Tel: +65 88288147
                                  </Text>
                                  <Text style={{ fontSize: 7 }}>
                                    Email: support@smart-lend.com
                                  </Text>
                                </View>
                              </View>
                            </View>
                            <View
                              style={{
                                backgroundColor: "lightgrey",
                                height: 2,
                                width: "100%",
                                marginTop: 20,
                              }}
                            />
                            <View
                              style={{
                                backgroundColor: "#2881c4",
                                height: 4,
                                width: "76%",
                                marginTop: -3,
                                marginLeft: "12%",
                                marginBottom: 15,
                              }}
                            />
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Company Profile
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  padding: 8,
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                }}
                              >
                                <View style={{ width: "50%" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Entity Name:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"][
                                          "entity-name"
                                        ]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      UEN:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"]
                                          ?.uen?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Registration Date:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["registration-date"]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Primary Activity:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"][
                                          "primary-activity"
                                        ]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Ownership:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["ownership"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Entity Status:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["entity-status"]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Business Constitution:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["company-type"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{ flexDirection: "row", gap: 10 }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Business Verification By Smartlend:
                                    </Text>
                                    <Text style={{ fontSize: 7 }}>
                                      {badgeVerified
                                        ? "Verfied"
                                        : "Un-verified"}
                                    </Text>
                                  </View>
                                </View>
                                <View style={{ width: "50%" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      ACRA Address:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.addresses[
                                        "addresses-list"
                                      ][0]
                                        ? (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.unit?.value !== undefined
                                            ? `Unit ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.floor?.value !== undefined
                                            ? `Floor ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.block?.value !== undefined
                                            ? `Block ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.street?.value !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.postal?.value !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.country?.desc !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                            : "")
                                        : ""}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Capital Type:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {
                                        singpassData?.entity?.["capitals"]?.[
                                          "capitals-list"
                                        ]?.[0]?.["capital-type"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Paid Up Capital:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "paid-up-capital-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Share Allocated Amount:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "share-allotted-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Issued Capital Amount:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "issued-capital-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Keyman Mobile:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {getShareholderAddress(keyman)?.[0]
                                        ?.person?.mobileno?.nbr?.value !==
                                      undefined
                                        ? getShareholderAddress(keyman)?.[0]
                                            ?.person?.mobileno?.prefix?.value +
                                          getShareholderAddress(keyman)?.[0]
                                            ?.person?.mobileno?.areacode
                                            ?.value +
                                          "-" +
                                          getShareholderAddress(keyman)?.[0]
                                            ?.person?.mobileno?.nbr?.value
                                        : ""}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Keyman Email:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {getShareholderAddress(keyman)?.[0]
                                        ?.person?.email?.value !== undefined
                                        ? getShareholderAddress(keyman)?.[0]
                                            ?.person?.email?.value
                                        : ""}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Financial Highlight
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Revenue</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Profit pre-tax</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Profit post-tax</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Start date</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>End date</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.financials?.highlights?.map(
                                  (data, index) => (
                                    <View
                                      key={index}
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        backgroundColor:
                                          index % 2
                                            ? "rgba(199, 220, 236, 0.2)"
                                            : "transparent",
                                        padding: 4,
                                      }}
                                    >
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-revenue"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-profit-loss-before-tax"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-profit-loss-after-tax"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {
                                            data?.["current-period-start-date"]
                                              ?.value
                                          }
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {
                                            data?.["current-period-end-date"]
                                              ?.value
                                          }
                                        </Text>
                                      </View>
                                    </View>
                                  )
                                )}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Grants
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Category</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Functional Area</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Grant Type</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Grant Status</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Approved Amount</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.grants?.[
                                  "grants-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["development-category"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["functional-area"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>{data?.["grant-type"]?.desc}</Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["grant-status"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.[
                                          "approved-amount"
                                        ]?.value?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "SGD",
                                        })}
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Officer(S) / Owners
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "25%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Officer Name</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Identity No.</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Position</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Appointment Date</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Nationality</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.appointments?.[
                                  "appointments-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View style={{ width: "25%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value
                                        }
                                      </Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.["idno"]
                                            ?.value
                                        }
                                      </Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>{data?.["position"]?.desc}</Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["appointment-date"]?.value}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "nationality"
                                          ]?.desc
                                        }
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Shareholders
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "25%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Shareholder Name / Address</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Identity No.</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "18%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Share Type</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>No of Share</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Nationality</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "15%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Date of birth</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Corppass Contact</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.shareholders?.[
                                  "shareholders-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "25%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data?.["person-reference"]?.[
                                          "person-name"
                                        ]?.value
                                          ? data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value +
                                            " (" +
                                            getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.sex?.code +
                                            ")"
                                          : data?.["entity-reference"]?.[
                                              "entity-name"
                                            ]?.value + " (Corporate)"}
                                      </Text>
                                      <Text>
                                        {getShareholderAddress(
                                          data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value
                                        )?.[0]?.person?.regadd
                                          ? (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.unit
                                              ?.value !== null
                                              ? `Unit ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.unit
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.floor
                                              ?.value !== null
                                              ? `Floor ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.floor
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.block
                                              ?.value !== null
                                              ? `Block ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.block
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.street
                                              ?.value !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.street
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.postal
                                              ?.value !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.postal
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.country
                                              ?.desc !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd
                                                    ?.country?.desc
                                                }.`
                                              : "")
                                          : ""}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "13%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data?.["person-reference"]?.["idno"]
                                          ?.value
                                          ? data?.["person-reference"]?.["idno"]
                                              ?.value
                                          : data?.["entity-reference"]?.["uen"]
                                              ?.value + " (UEN)"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "18%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>{data?.["share-type"]?.desc}</Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "13%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>{data?.["allocation"]?.value}</Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "19%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "nationality"
                                          ]?.desc
                                        }
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "15%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.dob?.value
                                        }
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "19%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.email?.value
                                        }
                                      </Text>
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.mobileno?.nbr?.value
                                        }
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                          </View>
                          <View></View>
                        </Page>
                        {combinedSingpassData
                          ? combinedSingpassData.map((data, index) => (
                              <Page key={index} size="A4">
                                {/**Letterhead section */}
                                <View
                                  style={{
                                    paddingVertical: 15,
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 15,
                                      paddingHorizontal: 40,
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: "#c7dcec",
                                        alignItems: "center",
                                        padding: 6,
                                        borderTopLeftRadius: 7,
                                        borderTopRightRadius: 7,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          fontFamily: "Poppins",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {data?.person?.name?.value +
                                          " | Personal Information"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        padding: 8,
                                        borderColor: "#c7dcec",
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                        borderBottomWidth: 2,
                                      }}
                                    >
                                      <View style={{ width: "50%" }}>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Ownership of Private Residential
                                            Property:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {data?.person?.ownerprivate?.value
                                              ? "Yes"
                                              : "No"}
                                          </Text>
                                        </View>
                                        {data?.person?.hdbownership
                                          ? data?.person?.hdbownership?.length >
                                            0
                                            ? data?.person?.hdbownership?.map(
                                                (hdb, index3) => (
                                                  <View key={index3}>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                        marginTop: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        HDB {index3 + 1}
                                                      </Text>
                                                    </View>

                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        HDB Ownership:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.address
                                                          ? (hdb?.address?.unit
                                                              ?.value !== null
                                                              ? `Unit ${hdb?.address?.unit?.value}, `
                                                              : "") +
                                                            (hdb?.address?.floor
                                                              ?.value !== null
                                                              ? `Floor ${hdb?.address?.floor?.value}, `
                                                              : "") +
                                                            (hdb?.address?.block
                                                              ?.value !== null
                                                              ? `Block ${hdb?.address?.block?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.street
                                                              ?.value !== null
                                                              ? `${hdb?.address?.street?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.postal
                                                              ?.value !== null
                                                              ? `${hdb?.address?.postal?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.country
                                                              ?.desc !== null
                                                              ? `${hdb?.address?.country?.desc}.`
                                                              : "")
                                                          : ""}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Outstanding HDB Loan
                                                        Balance:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {hdb?.outstandingloanbalance?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Type of HDB:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.hdbtype?.desc}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Monthly Loan
                                                        Installment:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.monthlyloaninstalment?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )
                                              )
                                            : "No"
                                          : "No"}

                                        <View>
                                          {data?.person?.cpfhousingwithdrawal
                                            ?.withdrawaldetails?.length > 0
                                            ? data?.person?.cpfhousingwithdrawal?.withdrawaldetails.map(
                                                (cpf, index) => (
                                                  <View
                                                    style={{ marginTop: 10 }}
                                                    key={index}
                                                  >
                                                    <Text
                                                      style={{
                                                        fontSize: 7,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 700,
                                                      }}
                                                    >
                                                      CPF Usage{" "}
                                                      {(cpf.address.unit
                                                        ?.value !== undefined
                                                        ? `Unit ${cpf.address.unit?.value}, `
                                                        : "") +
                                                        (cpf.address.floor
                                                          ?.value !== undefined
                                                          ? `Floor ${cpf.address.floor?.value}, `
                                                          : "") +
                                                        (cpf.address.block
                                                          ?.value !== undefined
                                                          ? `Block ${cpf.address.block?.value}, `
                                                          : "") +
                                                        (cpf.address.street
                                                          ?.value !== undefined
                                                          ? `${cpf.address.street?.value}, `
                                                          : "") +
                                                        (cpf.address.postal
                                                          ?.value !== undefined
                                                          ? `${cpf.address.postal?.value}, `
                                                          : "") +
                                                        (cpf.address.country
                                                          ?.desc !== undefined
                                                          ? `${cpf.address.country?.desc}.`
                                                          : "")}
                                                      :
                                                    </Text>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Principal Withdrawal
                                                        Amount:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {cpf?.principalwithdrawalamt?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Accrued Interest Amount:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {cpf?.accruedinterestamt?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Monthly Installment
                                                        (CPF):
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {cpf?.monthlyinstalmentamt?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Total Amount of CPF
                                                        Allowed For Property:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {cpf?.totalamountofcpfallowedforproperty?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )
                                              )
                                            : "None"}
                                        </View>
                                      </View>
                                      <View style={{ width: "50%" }}>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Contact:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.mobileno?.nbr?.value}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Email:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.email?.value}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Marital Status:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.marital?.desc}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Vehicles:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.vehicles?.length > 0
                                              ? data?.person?.vehicles?.map(
                                                  (veh, index4) => (
                                                    <Text
                                                      key={index4}
                                                      style={{
                                                        fontSize: 7,
                                                        width: "65%",
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {veh?.make?.value +
                                                          ": " +
                                                          veh?.model?.value}
                                                      </Text>
                                                    </Text>
                                                  )
                                                )
                                              : "None"}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                  <View
                                    style={{
                                      marginTop: 15,
                                      paddingHorizontal: 40,
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: "#c7dcec",
                                        alignItems: "center",
                                        padding: 6,
                                        borderTopLeftRadius: 7,
                                        borderTopRightRadius: 7,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          fontFamily: "Poppins",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {data?.person?.name?.value +
                                          " | Notice of Assessments"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        borderColor: "#c7dcec",
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                        borderBottomWidth: 2,
                                        width: "100%",
                                      }}
                                    >
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          width: "100%",
                                          backgroundColor: "#c7dcec",
                                          opacity: 0.7,
                                          padding: 4,
                                          gap: 10,
                                        }}
                                      >
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Year of Assesment</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Amount</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Employment</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Interest</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Rent</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Trade</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Tax Clearance</Text>
                                        </View>
                                      </View>
                                      {data?.person?.noahistory?.noas?.map(
                                        (data2, index2) => (
                                          <View
                                            key={index2}
                                            style={{
                                              flexDirection: "row",
                                              width: "100%",
                                              backgroundColor:
                                                index2 % 2
                                                  ? "rgba(199, 220, 236, 0.2)"
                                                  : "transparent",
                                              padding: 4,
                                              gap: 10,
                                            }}
                                          >
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {
                                                  data2?.["yearofassessment"]
                                                    ?.value
                                                }
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "amount"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "employment"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "interest"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "rent"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "trade"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.["taxclearance"]?.value}
                                              </Text>
                                            </View>
                                          </View>
                                        )
                                      )}
                                    </View>
                                  </View>
                                  {allCBSData?.filter(
                                    (dat2) =>
                                      dat2?.name === data?.person?.name?.value
                                  )?.[0] ? (
                                    <View
                                      style={{
                                        marginTop: 15,
                                        paddingHorizontal: 40,
                                      }}
                                    >
                                      <View
                                        style={{
                                          backgroundColor: "#c7dcec",
                                          alignItems: "center",
                                          padding: 6,
                                          borderTopLeftRadius: 7,
                                          borderTopRightRadius: 7,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 10,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          {data?.person?.name?.value +
                                            " | Credit Bureau | Personal Commitement"}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                        }}
                                      >
                                        <View style={{ width: "50%" }}>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Limit:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.SecuredCreditLimit?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Credit Score:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data?.CreditScore
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Probability of Default:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data
                                                  ?.ProbabilityofDefault
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Utilized:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.UnsecuredCreditUtilized?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Utilization in
                                              percentage:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {(Number(
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data
                                                  ?.UnsecuredCreditUtilizationinpercentage
                                              ) || 0) + "%"}
                                            </Text>
                                          </View>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Limit:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.UnsecuredCreditLimit?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Risk Grade:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data?.RiskGrade
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Utilized:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.SecuredCreditUtilized?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Utilization in
                                              percentage:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {(Number(
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data
                                                  ?.SecuredCreditUtilizationinpercentage
                                              ) || 0) + "%"}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Monthly Commitment/Installment
                                              Secured:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {(
                                                Number(
                                                  allCBSData?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]?.cbs_data
                                                    ?.MonthlyCommitment
                                                ) || 0
                                              )?.toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "SGD",
                                              })}
                                            </Text>
                                          </View>
                                        </View>
                                      </View>
                                      <View
                                        style={{
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                        }}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            width: "100%",
                                            backgroundColor: "#c7dcec",
                                            opacity: 0.7,
                                            padding: 4,
                                            gap: 10,
                                          }}
                                        >
                                          <View
                                            style={{
                                              width: "30%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Loan / Product Type</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "30%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Grantor / Bank</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "20%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Outstanding Balance</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "20%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Monthly Installment</Text>
                                          </View>
                                        </View>

                                        {allCBSData
                                          ?.filter(
                                            (dat2) =>
                                              dat2?.name ===
                                              data?.person?.name?.value
                                          )?.[0]
                                          ?.cbs_data?.NoteworthyOngoingLoans?.map(
                                            (imp, index10) => (
                                              <View
                                                key={index10}
                                                style={{
                                                  flexDirection: "row",
                                                  width: "100%",
                                                  backgroundColor:
                                                    index10 % 2
                                                      ? "rgba(199, 220, 236, 0.4)"
                                                      : "rgba(199, 220, 236, 0.15)",
                                                  padding: 4,
                                                  gap: 10,
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    width: "30%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {imp.includes(
                                                      "private residential purchase",
                                                      ""
                                                    )
                                                      ? "Private Residential Purchase"
                                                      : imp.includes(
                                                          "motor vehicle loan",
                                                          ""
                                                        )
                                                      ? "Motor Vehicle Loan"
                                                      : imp.includes(
                                                          "hdb loan",
                                                          ""
                                                        )
                                                      ? "HDB Loan"
                                                      : ""}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "30%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {imp
                                                      .replace(
                                                        "private residential purchase",
                                                        ""
                                                      )
                                                      .replace(
                                                        "motor vehicle loan",
                                                        ""
                                                      )
                                                      .replace("hdb loan", "")
                                                      .trim()
                                                      .split(" ")[0]
                                                      .toUpperCase()}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "20%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {parseFloat(
                                                      imp
                                                        .split("   ")
                                                        [
                                                          imp.split("   ")
                                                            .length - 4
                                                        ].replace(/,/g, "")
                                                    ).toLocaleString("en-US", {
                                                      style: "currency",
                                                      currency: "SGD",
                                                    })}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "20%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {allCBSData?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]?.cbs_data
                                                      ?.CBSImpLoanSupplementaryInstallment?.[
                                                      index10
                                                    ]
                                                      ? (
                                                          parseFloat(
                                                            allCBSData
                                                              ?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]
                                                              ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                index10
                                                              ].split("   ")
                                                              [
                                                                allCBSData
                                                                  ?.filter(
                                                                    (dat2) =>
                                                                      dat2?.name ===
                                                                      data
                                                                        ?.person
                                                                        ?.name
                                                                        ?.value
                                                                  )?.[0]
                                                                  ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                    index10
                                                                  ].split("   ")
                                                                  .length - 4
                                                              ]?.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                          ) +
                                                          parseFloat(
                                                            allCBSData
                                                              ?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]
                                                              ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                index10
                                                              ].split("   ")
                                                              [
                                                                allCBSData
                                                                  ?.filter(
                                                                    (dat2) =>
                                                                      dat2?.name ===
                                                                      data
                                                                        ?.person
                                                                        ?.name
                                                                        ?.value
                                                                  )?.[0]
                                                                  ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                    index10
                                                                  ].split("   ")
                                                                  .length - 3
                                                              ]?.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                          )
                                                        ).toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )
                                                      : null}
                                                  </Text>
                                                </View>
                                              </View>
                                            )
                                          )}
                                      </View>
                                      <View
                                        style={{
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                          borderBottomWidth: 2,
                                        }}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Last 6 Months (Unsecured) Credit
                                            Utilization Graph:
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "100%",
                                            fontSize: 7,
                                          }}
                                        >
                                          <View
                                            style={{ flexDirection: "row" }}
                                          >
                                            <View
                                              style={{
                                                width: "5%",
                                                position: "relative",
                                              }}
                                            >
                                              <View style={{ height: "15px" }}>
                                                <Text
                                                  style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                  }}
                                                >
                                                  {allCBSData
                                                    ?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]
                                                    ?.cbs_data?.Last6MonthsGraphChartMaxData?.toLocaleString(
                                                      "en-US",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                </Text>
                                              </View>
                                              <View
                                                style={{ height: "220px" }}
                                              ></View>
                                            </View>
                                            <View
                                              style={{
                                                width: "95%",
                                              }}
                                            >
                                              <View
                                                style={{
                                                  width: "100%",
                                                  flexDirection: "row",
                                                  marginTop: "25px",
                                                  borderBottom:
                                                    "1px solid lightgrey",
                                                  height: "200px",
                                                  alignItems: "flex-end",
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    height: "200px",
                                                    borderLeft:
                                                      "1px solid lightgrey",
                                                  }}
                                                />
                                                {allCBSData
                                                  ?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]
                                                  ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                    (dat30, index30) => (
                                                      <View
                                                        key={index30}
                                                        style={{
                                                          marginLeft: "4%",
                                                          marginRight: "4%",
                                                          width: "8%",
                                                          borderTopLeftRadius:
                                                            "4px",
                                                          borderTopRightRadius:
                                                            "4px",
                                                          height: `${
                                                            (parseFloat(
                                                              dat30.value.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                            ) /
                                                              allCBSData?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]?.cbs_data
                                                                ?.Last6MonthsGraphChartMaxData) *
                                                            100
                                                          }%`,
                                                          backgroundColor:
                                                            "rgba(217, 238, 255, 1)",
                                                        }}
                                                      ></View>
                                                    )
                                                  )}
                                              </View>
                                              <View
                                                style={{
                                                  marginTop: "8px",
                                                  flexDirection: "row",
                                                  fontSize: 7,
                                                }}
                                              >
                                                {allCBSData
                                                  ?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]
                                                  ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                    (dat, index) => (
                                                      <View
                                                        key={index}
                                                        style={{
                                                          width: "16%",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <Text
                                                          style={{
                                                            fontWeight: 600,
                                                            fontSize: 7,
                                                          }}
                                                        >
                                                          {dat.date
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            dat.date.slice(1)}
                                                        </Text>
                                                        <Text
                                                          style={{
                                                            fontWeight: 600,
                                                            fontSize: 7,
                                                          }}
                                                        >
                                                          {parseFloat(
                                                            dat.value.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ).toLocaleString(
                                                            "en-US",
                                                            {
                                                              style: "currency",
                                                              currency: "SGD",
                                                            }
                                                          )}
                                                        </Text>
                                                      </View>
                                                    )
                                                  )}
                                              </View>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  ) : null}
                                </View>
                              </Page>
                            ))
                          : null}
                      </Document>
                    </PDFViewer>
                  </div>
                )
              ) : null}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </Modal>
      <Modal
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        width={"600px"}
        open={accountManagerModalIsOpen}
        onCancel={() => {
          setAccountManagerModalIsOpen(false);
          setAccountManagerDetails({
            profile: "",
            name: "",
            ph: "",
            email: "",
          });
        }}
        footer={null}
      >
        <div
          style={{
            color: "rgba(0,0,0,0.4)",
            fontSize: "10px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "500",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            Account Manager details
          </div>
          <div style={{ marginTop: "15px" }}>
            <div>Account Manager Profile Photo</div>
            {accountManagerDetails.profile ? (
              <img
                style={{
                  height: "150px",
                  width: "150px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  objectFit: "cover",
                  marginTop: "15px",
                }}
                src={accountManagerDetails.profile}
              />
            ) : null}
            <input
              onChange={(e) => {
                UniversaleUploadFile(e.target.files[0]);
              }}
              type="file"
              style={{
                width: "400px",
                borderRadius: 6,
                padding: "2px 10px",
                fontSize: 13,
                outline: "none",
                border: "1px solid lightgrey",
              }}
            />
            <div>Account Manager Name</div>

            <input
              value={accountManagerDetails.name}
              onChange={(e) => {
                setAccountManagerDetails({
                  ...accountManagerDetails,
                  name: e.target.value,
                });
              }}
              style={{
                width: "400px",
                borderRadius: 6,
                padding: "2px 10px",
                fontSize: 13,
                outline: "none",
                border: "1px solid lightgrey",
              }}
            />
          </div>
          <div style={{ marginTop: "7px" }}>
            <div>Account Manager Phone Number</div>
            <input
              value={accountManagerDetails.ph}
              onChange={(e) => {
                setAccountManagerDetails({
                  ...accountManagerDetails,
                  ph: e.target.value,
                });
              }}
              style={{
                width: "400px",
                borderRadius: 6,
                padding: "2px 10px",
                fontSize: 13,
                outline: "none",
                border: "1px solid lightgrey",
              }}
            />
          </div>
          <div style={{ marginTop: "7px" }}>
            <div>Account Manager Email</div>
            <input
              value={accountManagerDetails.email}
              onChange={(e) => {
                setAccountManagerDetails({
                  ...accountManagerDetails,
                  email: e.target.value,
                });
              }}
              style={{
                width: "400px",
                borderRadius: 6,
                padding: "2px 10px",
                fontSize: 13,
                outline: "none",
                border: "1px solid lightgrey",
              }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <button
              onClick={(e) => {
                e.preventDefault();
                const dataTosend = {
                  user_id: activeUser.user_id,
                  account_manager: JSON.stringify(accountManagerDetails),
                };

                if (localStorage.getItem("prevAccountManager")) {
                  const prevAccountManager =
                    JSON.parse(localStorage.getItem("prevAccountManager")) ||
                    [];

                  // Check if the name already exists
                  const existingManagerIndex = prevAccountManager.findIndex(
                    (manager) => manager.name === accountManagerDetails.name
                  );

                  if (existingManagerIndex !== -1) {
                    // Update the existing manager
                    prevAccountManager[existingManagerIndex] =
                      accountManagerDetails;
                  } else {
                    // Add the new manager
                    prevAccountManager.push(accountManagerDetails);
                  }

                  localStorage.setItem(
                    "prevAccountManager",
                    JSON.stringify(prevAccountManager)
                  );
                  console.log(prevAccountManager);
                } else {
                  const dataTosave = [accountManagerDetails];
                  localStorage.setItem(
                    "prevAccountManager",
                    JSON.stringify(dataTosave)
                  );
                }

                setAccountManager(dataTosend);
              }}
              className="all_button"
              style={{
                background: "#1557a9",
                color: "white",
                border: "none",
                borderRadius: "50px",
                padding: "6px 25px",
                fontSize: "12px",
                margin: "10px 0px 0px 0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Update
            </button>
          </div>
          {localStorage.getItem("prevAccountManager") ? (
            <div>
              <hr
                style={{
                  width: "80%",
                  height: "1px",
                  background: "rgb(50,50,50)",
                }}
              />
              <div>
                <div
                  style={{
                    color: "#142C44CC",
                    fontSize: "18px",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                  }}
                >
                  Select from previous managers
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: "10px",
                    gap: "5px",
                  }}
                >
                  {JSON.parse(localStorage.getItem("prevAccountManager")).map(
                    (prevMan, index) => (
                      <div
                        className="mui_button"
                        style={{
                          padding: "5px 25px",
                          color: "grey",
                          border: "1px solid grey",
                          borderRadius: "8px",
                        }}
                        key={index}
                        onClick={() => {
                          const dataTosend = {
                            user_id: activeUser.user_id,
                            account_manager: JSON.stringify(prevMan),
                          };
                          setAccountManager(dataTosend);
                        }}
                      >
                        {prevMan.name}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
      <Modal
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        width={"600px"}
        open={otpModalIsOpen}
        onCancel={() => setOtpModalIsOpen(false)}
        footer={null}
      >
        <div>
          <div>Please type the 4 digit otp sent to Roy's email.</div>
          <div>
            <input
              value={otpPin}
              onChange={(e) => {
                setOtpPin(e.target.value);
              }}
              type="password"
              style={{
                width: "400px",
                borderRadius: 10,
                padding: "10px 20px",
                fontSize: 13,
              }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div
              onClick={() => {
                validatePassword();
              }}
              style={{
                marginTop: 20,
                background: "#196cb0",
                color: "white",
                border: "2px solid #196cb0",
                padding: "5px 35px",
                borderRadius: "20px",
                cursor: "pointer",
              }}
            >
              Confirm
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        width={"600px"}
        open={modal4}
        onCancel={() => setModal4(false)}
        footer={null}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            Password Reset
          </div>
        </div>

        <hr style={{ width: "95%" }} />
        <div>
          <div>User's new password</div>
          <div>
            <input
              value={resetPassword}
              onChange={(e) => {
                setResetPassword(e.target.value);
              }}
              type="password"
              style={{
                width: "400px",
                borderRadius: 10,
                padding: "10px 20px",
                fontSize: 13,
              }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div
              onClick={() => {
                if (resetPassword === "") {
                  message.error("Password is empty");
                } else if (resetPassword.length < 8) {
                  message.error("Minimum 8 characters required for password.");
                } else {
                  console.log(activeUser.email);
                  adminResetPassword(activeUser.email);
                }
              }}
              style={{
                marginTop: 20,
                background: "#196cb0",
                color: "white",
                border: "2px solid #196cb0",
                padding: "5px 35px",
                borderRadius: "20px",
                cursor: "pointer",
              }}
            >
              Reset Password
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        width={"600px"}
        open={modal5}
        onCancel={() => setModal5(false)}
        footer={null}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            Inject CBS Report
          </div>
        </div>

        <hr style={{ width: "95%" }} />
        <div>
          <div>Upload CBS report</div>
          <div>
            <input
              onChange={(e) => {
                extractText(e.target.files[0]);
                setCreditReportFile(e.target.files[0]);
              }}
              style={{
                outline: "none",
                border: "1px solid lightgrey",
                borderRadius: "8px",
                fontSize: "12px",
                padding: "5px 10px",
                width: "50%",
              }}
              type="file"
            />
          </div>
          <div style={{ display: "flex" }}>
            <div
              onClick={() => {
                setIsLoadingScreen(true);
                uploadCBSReport(creditReportFile);
              }}
              style={{
                marginTop: 20,
                background: "#196cb0",
                color: "white",
                border: "2px solid #196cb0",
                padding: "5px 35px",
                borderRadius: "20px",
                cursor: "pointer",
              }}
            >
              Inject
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        width={"90vw"}
        open={modal1}
        onCancel={() => setModal1(false)}
        footer={null}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            User Details
          </div>
          <div>
            <label
              onClick={() => {
                const sanitizedData = getSanitizedUserInfo(activeUser);
                exportToExcel(sanitizedData);
              }}
              className="all_button"
              style={{
                background: "#1557a9",
                color: "white",
                width: "100%",
                border: "none",
                borderRadius: "50px",
                padding: "6px 30px",
                fontSize: "12px",
                margin: "0px 0px 0px -20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              Export
            </label>
          </div>
        </div>

        <hr style={{ width: "95%" }} />
        <div
          style={{
            display: "flex",
            fontSize: "11px",
            color: "rgba(0,0,0,0.5)",
            flexWrap: "wrap",
            gap: "1%",
          }}
        >
          {renderUserInfo(flattenObject(activeUser))}
        </div>
      </Modal>
      <Modal
        width="50%"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        open={modal2}
        onCancel={() => setModal2(false)}
        footer={null}
      >
        <div
          style={{
            color: "#142C44CC",
            fontSize: "18px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
          }}
        >
          Loan Details
        </div>
        <hr style={{ width: "95%" }} />
        <div
          style={{
            display: "flex",
            fontSize: "11px",
            color: "rgba(0,0,0,0.5)",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {renderLoanAcceptedInfo(selectedLoan)}
        </div>
        <hr style={{ width: "95%" }} />
        <div style={{ width: "100%" }}>
          <Collapse defaultActiveKey={["0"]} ghost>
            <Panel
              header="View your Repayment Schedule"
              key="1"
              // onClick={(event) =>
              //   handleViewRepaymentSchedule(
              //     selectedLoan,
              //     event
              //   )
              // }
            >
              <TableRepaymentUserDatabase
                dataRepaymentSchedule={
                  selectedLoan ? selectedLoan.repaymentSchedule : []
                }
                setDataRepaymentSchedule={setDataRepaymentSchedule}
              />
            </Panel>
          </Collapse>
        </div>
      </Modal>
      <Modal
        width="50%"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        open={modal3}
        onCancel={() => setModal3(false)}
        footer={null}
      >
        <div
          style={{
            color: "#142C44CC",
            fontSize: "18px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
          }}
        >
          Loan Details
        </div>
        <hr style={{ width: "95%" }} />
        <div
          style={{
            display: "flex",
            fontSize: "11px",
            color: "rgba(0,0,0,0.5)",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {renderLoanInfo(selectedLoan2)}
        </div>
      </Modal>
      <div
        style={{
          width: "75%",
          height: "100%",
          padding: "4px 0px 20px 20px",
        }}
      >
        <div
          style={{
            height: "100%",
            borderRadius: "10px",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            User Database
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            <div style={{ display: "flex" }}>
              {" "}
              {/* New div to group Borrower and Lender */}
              <div
                onClick={() => {
                  setActiveTab("Borrower");
                  setLenderProfileData(null);
                  setActiveUser(dataBorrower[0]);
                }}
                style={{
                  background:
                    activeTab === "Borrower"
                      ? "rgba(255,255,255,0.7)"
                      : "rgba(0,0,0,0.1)",
                  borderRadius: "10px 10px 0px 0px",
                  padding: "5px 25px",
                  color: "#142c44cc",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
              >
                Borrower
              </div>
              <div
                onClick={() => {
                  setActiveTab("Lender");
                  setLenderProfileData(null);
                  setActiveUser(dataLender[0]);
                  setActiveUser2(dataBorrower[0]);
                }}
                style={{
                  background:
                    activeTab === "Lender"
                      ? "rgba(255,255,255,0.7)"
                      : "rgba(0,0,0,0.1)",
                  borderRadius: "10px 10px 0px 0px",
                  padding: "5px 25px",
                  color: "#142c44cc",
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
              >
                Lender
              </div>
            </div>

            {/* <div
              style={{
                background: "rgba(0,0,0,0.1)",
                borderRadius: "10px 10px 0px 0px",
                padding: "5px 25px",
                color: "#142c44cc",
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "13px",
                cursor: "pointer",
              }}
            >
              <label
                onClick={() => {
                  exportToEmailSubscribed(subscribedEmail);
                }}
                className="all_button"
                style={{
                  background: "#1557a9",
                  color: "white",
                  width: "100%",
                  border: "none",
                  borderRadius: "50px",
                  padding: "3px 15px",
                  fontSize: "10px",
                  margin: "0px 0px 0px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                Email Subscribed
              </label>
            </div> */}
          </div>

          <div
            style={{
              background: "rgba(255,255,255,0.7)",
              width: "100%",
              padding: "10px",
              height: "75vh",
              overflowY: "auto",
              borderRadius:
                activeTab === "Borrower" ? "0px 10px 10px 10px" : "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "grey",
                // borderTop: "1px solid lightgrey",
                // borderBottom: "1px solid lightgrey",
                width: "100%",
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "11px",
                padding: "5px 0px",
              }}
            >
              <div style={{ width: "15%", paddingLeft: "10px" }}>User ID</div>
              <div style={{ width: "20%" }}>User Name</div>
              <div style={{ width: "15%" }}>Phone Number</div>
              <div style={{ width: "30%" }}>Email</div>
              <div style={{ width: "17%" }}>Registered</div>
              {activeTab === "Borrower" ? (
                <div style={{ width: "10%" }}>BTI Rating</div>
              ) : null}
              {activeTab === "Borrower" ? (
                <div style={{ width: "10%" }}>Employee.ID</div>
              ) : null}
              {activeTab === "Borrower" ? (
                <div style={{ width: "10%" }}>R.M</div>
              ) : null}
              {activeTab === "Borrower" ? (
                <div
                  style={{
                    width: "150px",
                  }}
                >
                  <label
                    onClick={() => {
                      const allUserInfo =
                        activeTab === "Borrower"
                          ? getBorrowerInfo(dataAll)
                          : getLenderInfo(dataAll);
                      exportToExcelAllInfo(allUserInfo, activeTab);
                    }}
                    className="all_button"
                    style={{
                      padding: "7px 20px",
                      border: "1px solid #1557a9",
                      color: "#1557a9",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      cursor: "pointer",
                      fontWeight: 600,
                    }}
                  >
                    Export All
                  </label>
                </div>
              ) : (
                <div style={{ width: "9%" }}>
                  <label
                    onClick={() => {
                      const allUserInfo =
                        activeTab === "Lender"
                          ? getLenderInfo(dataAll)
                          : getBorrowerInfo(dataAll);
                      exportToExcelAllInfo(allUserInfo, activeTab);
                    }}
                    className="all_button"
                    style={{
                      padding: "7px 20px",
                      border: "1px solid #1557a9",
                      color: "#1557a9",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      cursor: "pointer",
                      fontWeight: 600,
                    }}
                  >
                    Export All
                  </label>
                </div>
              )}
            </div>
            {activeTab === "Borrower"
              ? dataBorrower
                  .filter(
                    (user) =>
                      user.entity_name
                        ?.toLowerCase()
                        .includes(paramValue?.toLowerCase()) ||
                      user.email
                        ?.toLowerCase()
                        .includes(paramValue?.toLowerCase()) ||
                      user.principal_name
                        ?.toLowerCase()
                        .includes(paramValue?.toLowerCase()) ||
                      getEmployeeName(user.employee_id)
                        ?.toLowerCase()
                        .includes(paramValue?.toLowerCase())
                  )
                  .map((user, index) => (
                    <div
                      onClick={() => setActiveUser(user)}
                      className="db_user_row"
                      key={index}
                      style={{
                        background:
                          index % 2 ? "white" : "rgba(17, 92, 166, 0.05)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        color: "#142C44CC",
                        width: "100%",
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "800",
                        fontSize: "11px",
                        padding: "5px 0px",
                        height: "40px",
                      }}
                    >
                      <div
                        style={{
                          width: "15%",
                          paddingLeft: "10px",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <div>{index + 1}.</div>
                        <div>{user.user_id}</div>
                      </div>
                      <div style={{ width: "19%" }}>{user.entity_name}</div>
                      <div style={{ width: "15%" }}>
                        {user.active_mobile_no
                          ? user.active_mobile_no
                          : user.mobile_no
                          ? user.mobile_no
                          : "None inserted"}
                      </div>
                      <div style={{ width: "29%" }}>
                        {user.active_email
                          ? user.active_email
                          : user.email
                          ? user.email
                          : "None inserted"}
                      </div>
                      <div style={{ width: "15%" }}>
                        {addressFormatter(user.created_at)}
                      </div>
                      <div style={{ width: "10%" }}>
                        {user.credit_report_data &&
                        user.singpass_data &&
                        JSON.parse(user.credit_report_data)?.RiskGrade !==
                          null &&
                        JSON.parse(user.credit_report_data)
                          ?.UnsecuredCreditLimit !== null &&
                        user.singpass_data?.person?.noahistory?.noas?.filter(
                          (dat) => dat.yearofassessment.value === "2024"
                        ).length > 0
                          ? JSON.parse(user.credit_report_data)?.RiskGrade +
                            " " +
                            (
                              parseFloat(
                                JSON.parse(user.credit_report_data)
                                  ?.UnsecuredCreditUtilized
                              ) /
                              (parseFloat(
                                user.singpass_data?.person?.noahistory?.noas?.filter(
                                  (dat) => dat.yearofassessment.value === "2024"
                                )?.[0]?.amount?.value
                              ) /
                                12)
                            )?.toFixed(2)
                          : "N/A"}
                      </div>
                      <div style={{ width: "10%" }}>
                        {user.employee_id
                          ? getEmployeeName(user.employee_id)
                          : "Web"}
                      </div>
                      <div style={{ width: "10%" }}>
                        {user.account_manager
                          ? JSON.parse(user.account_manager)?.name
                          : "None"}
                      </div>
                      {user.credit_report ? (
                        // Render the button if credit_report is not null/undefined
                        <button
                          className="all_button"
                          onClick={(event) =>
                            handleDownloadCreditReport(user.user_id, event)
                          }
                          style={{
                            padding: "7px 20px",
                            background: "white",
                            border: "1px solid #1557a9",
                            color: "#1557a9",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "10px",
                            cursor: "pointer",
                            fontWeight: 600,
                            width: "150px",
                          }}
                        >
                          Credit Report
                        </button>
                      ) : (
                        // Render an invisible placeholder if credit_report is null/undefined
                        <div
                          style={{
                            width: "150px",
                            display: "flex",
                            padding: "3px 5px",
                          }}
                        />
                      )}
                    </div>
                  ))
              : dataLender
                  .filter(
                    (user) =>
                      user.entity_name
                        ?.toLowerCase()
                        .includes(paramValue?.toLowerCase()) ||
                      user.email
                        ?.toLowerCase()
                        .includes(paramValue?.toLowerCase()) ||
                      user.principal_name
                        ?.toLowerCase()
                        .includes(paramValue?.toLowerCase()) ||
                      getEmployeeName(user.employee_id)
                        ?.toLowerCase()
                        .includes(paramValue?.toLowerCase())
                  )
                  .map((user, index) => (
                    <div
                      onClick={() => {
                        console.log(user);
                        setLenderProfileData(null);
                        setActiveUser(user);
                      }}
                      className="db_user_row"
                      key={index}
                      style={{
                        background:
                          index % 2 ? "white" : "rgba(17, 92, 166, 0.05)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        color: "#142C44CC",
                        width: "100%",
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "800",
                        fontSize: "11px",
                        padding: "5px 0px",
                        height: "40px",
                      }}
                    >
                      <div
                        style={{
                          width: "15%",
                          paddingLeft: "10px",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <div>{index + 1}.</div>
                        <div>{user.user_id}</div>
                      </div>
                      <div style={{ width: "20.5%" }}>
                        {user.principal_name}
                      </div>
                      <div style={{ width: "14.5%" }}>
                        {user.active_mobile_no
                          ? user.active_mobile_no
                          : user.mobile_no
                          ? user.mobile_no
                          : "None inserted"}
                      </div>
                      <div style={{ width: "31%" }}>
                        {user.active_email
                          ? user.active_email
                          : user.email
                          ? user.email
                          : "None inserted"}
                      </div>
                      <div style={{ width: "26.5%" }}>
                        {addressFormatter(user.created_at)}
                      </div>
                    </div>
                  ))}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "25%",
          height: "100%",
          padding: "4px 20px 20px 0px",
        }}
      >
        <div
          style={{
            height: "100%",
            borderRadius: "10px",
            background:
              "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
            }}
          >
            User Details
          </div>
          {activeUser ? (
            <div
              style={{
                background: "rgba(255,255,255,0.7)",
                height: "78vh",
                width: "100%",
                borderRadius: "10px",
                marginTop: "15px",
                color: "#142C44CC",
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "11px",
                padding: "10px",
                overflowY: "scroll",
                position: "relative",
                overflow: "visible",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  background: "white",
                  borderRadius: "10px",
                  padding: "20px",
                  top: 0,
                  right: lenderProfileData ? "calc(100% + 20px)" : "100%",
                  opacity: lenderProfileData ? 1 : 0,
                  width: "400px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
                  transition:
                    "right 0.4s ease-in-out, opacity 0.4s ease-in-out, display 0s",
                  zIndex: 200,
                  display: lenderProfileData ? "block" : "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "15px",
                  }}
                >
                  <div>
                    <img
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "50%",
                        background: "lightgrey",
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
                        objectFit: "cover",
                      }}
                      src={lenderProfileData?.profile?.profile_photo}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        color: "rgb(14, 90, 165)",
                        fontSize: "20px",
                        fontFamily: "Manrope,sans-serif",
                        fontWeight: 800,
                        marginBottom: "5px",
                      }}
                    >
                      {lenderProfileData?.profile?.company_name}
                    </div>
                    {lenderProfileData?.profile?.additionals?.map(
                      (addi, index) => (
                        <div
                          key={index}
                          style={{ display: "flex", gap: "10px" }}
                        >
                          <div
                            style={{
                              width: "100%",
                              border: "none",
                              outline: "none",
                              color: "rgba(0,0,0,0.6)",
                              marginTop: "-5px",
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            {addi?.text}
                          </div>
                        </div>
                      )
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginLeft: "-5px",
                        marginTop: "5px",
                      }}
                    >
                      {lenderProfileData?.profile?.social?.map(
                        (soci, index) => (
                          <div
                            key={index}
                            style={{
                              padding: "5px",
                              position: "relative",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={iconLibrary[soci?.icon]}
                              style={{
                                fontSize: 20,
                                color: "rgb(14, 90, 165)",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (soci.link !== "" && soci.link !== null) {
                                  window.location.href = soci.link;
                                }
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <hr
                  style={{
                    width: "90%",
                    marginLeft: "5%",
                    background: "grey",
                    border: "none",
                    outline: "none",
                  }}
                />
                {lenderProfileData?.content?.map((cont, index) =>
                  cont.type === "text" ? (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        marginTop: cont.mt,
                        marginBottom: cont.mb,
                        textDecoration: cont.underline ? "underline" : "none",
                        fontWeight: cont.fontWeight === 400 ? 400 : 600,
                        fontSize: cont.fontWeight === 400 ? "12px" : "16px",
                        border: "none",
                        outline: "none",
                        color: "rgba(0,0,0,0.6)",
                        width: "100%",
                      }}
                    >
                      {cont?.text}
                    </div>
                  ) : cont?.type === "button" ? (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        display: "flex",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (cont.url !== "" && cont.url !== null) {
                          window.location.href = cont.url;
                        }
                      }}
                    >
                      <div
                        style={{
                          marginTop: cont.mt,
                          marginBottom: cont.mb,
                          fontWeight: 500,
                          fontSize: "14px",
                          border: "1px solid rgba(0,0,0,0.05)",
                          outline: "none",
                          color: "white",
                          background: "rgb(14, 90, 165)",
                          padding: "7px 30px",
                          borderRadius: "30px",
                        }}
                      >
                        {cont?.text}
                      </div>
                    </div>
                  ) : (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        marginTop: cont.mt,
                        marginBottom: cont.mb,
                      }}
                    >
                      <img
                        style={{
                          objectFit: "cover",
                          width: "80%",
                          height: "150px",
                          border: "none",
                          outline: "none",
                        }}
                        src={cont?.url}
                      />
                    </div>
                  )
                )}
              </div>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  margin: "0px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    textAlign: "left",
                    marginLeft: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: "11px",
                        color: "rgba(0,0,0,0.4)",
                        margin: "0px",
                      }}
                    >
                      Principal Name
                    </h3>
                    <p
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "800",
                        fontSize: "18px",
                        textAlign: "left",
                        color: "rgba(20, 44, 68, 0.8)",
                        margin: "0px",
                      }}
                    >
                      {activeTab === "Borrower"
                        ? activeUser.entity_name
                          ? activeUser.entity_name.length > 15
                            ? activeUser.entity_name.slice(0, 15) + "..."
                            : activeUser.entity_name
                          : null
                        : activeUser.principal_name
                        ? activeUser.principal_name.length > 15
                          ? activeUser.principal_name.slice(0, 15) + "..."
                          : activeUser.principal_name
                        : null}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      alignItems: "center",
                      marginTop: "15px",
                    }}
                  >
                    {activeTab === "Borrower" ? (
                      <div
                        onClick={() => {
                          console.log(activeUser);
                          setBadgeVerified(activeUser.badge_verified);
                          const dataToPush = [
                            {
                              name: activeUser.singpass_data?.person?.name
                                ?.value,
                              cbs_data: JSON.parse(
                                activeUser.credit_report_data
                              ),
                            },
                          ];
                          console.log(dataToPush);
                          setAllCBSData(dataToPush);
                          if (activeUser.loanRequest.length > 0) {
                            singpassDetails(
                              activeUser.user_id,
                              activeUser.loanRequest[
                                activeUser.loanRequest.length - 1
                              ].authorization_required,
                              activeUser.loanRequest[
                                activeUser.loanRequest.length - 1
                              ].loan_id,
                              activeUser.loanRequest[
                                activeUser.loanRequest.length - 1
                              ].keyman
                            );
                          } else {
                            singpassDetails(activeUser.user_id);
                          }
                        }}
                        className="all_button"
                        style={{
                          padding: "7px 20px",
                          border: "1px solid rgba(0,0,0,0.6)",
                          color: "rgba(0,0,0,0.6)",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                      >
                        View details
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          handleGetLenderProfile(activeUser.user_id);
                        }}
                        className="all_button"
                        style={{
                          padding: "7px 20px",
                          border: "1px solid rgba(0,0,0,0.6)",
                          color: "rgba(0,0,0,0.6)",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                      >
                        View Profile
                      </div>
                    )}

                    {activeTab === "Borrower" ? (
                      <div
                        onClick={() => {
                          setModal5(true);
                        }}
                        className="all_button"
                        style={{
                          padding: "7px 20px",
                          border: "1px solid rgba(0,0,0,0.6)",
                          color: "rgba(0,0,0,0.6)",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                      >
                        Inject CBS
                      </div>
                    ) : null}

                    <div
                      onClick={() => {
                        setModal4(true);
                      }}
                      className="all_button"
                      style={{
                        padding: "7px 20px",
                        border: "1px solid rgba(0,0,0,0.6)",
                        color: "rgba(0,0,0,0.6)",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                    >
                      Reset password
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "10px" }}>
                <h3
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                    margin: "0px",
                  }}
                >
                  VPN Function (Only use if required)
                </h3>
                <div style={{ display: "flex", marginTop: "7px" }}>
                  <div
                    onClick={() => {
                      setVpntoaccountuserId(activeUser.user_id);
                      setOtpModalIsOpen(true);
                      createVPNPin();
                    }}
                    className="all_button"
                    style={{
                      padding: "7px 20px",
                      border: "1px solid rgba(0,0,0,0.6)",
                      color: "rgba(0,0,0,0.6)",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  >
                    VPN to account
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "10px" }}>
                <h3
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                    margin: "0px",
                  }}
                >
                  Account Manager
                </h3>
                {activeUser.account_manager ? (
                  <div>{JSON.parse(activeUser.account_manager)?.name}</div>
                ) : (
                  <div>No Account Manager set.</div>
                )}
                <div style={{ display: "flex", marginTop: "7px" }}>
                  <div
                    onClick={() => {
                      setAccountManagerModalIsOpen(true);
                      if (activeUser.account_manager) {
                        setAccountManagerDetails(
                          JSON.parse(activeUser.account_manager)
                        );
                      }
                    }}
                    className="all_button"
                    style={{
                      padding: "7px 20px",
                      border: "1px solid rgba(0,0,0,0.6)",
                      color: "rgba(0,0,0,0.6)",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  >
                    Set/Change Account Manager
                  </div>
                </div>
              </div>
              {activeTab === "Lender" ? (
                activeUser ? (
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      margin: "10px 0px 0px 0px",
                    }}
                  >
                    <div
                      style={{ flex: 1, textAlign: "left", marginLeft: "0px" }}
                    >
                      {" "}
                      <div>
                        {activeUser ? (
                          activeUser.subscription ? (
                            JSON.parse(activeUser.subscription).validity &&
                            new Date(
                              JSON.parse(activeUser.subscription).validity
                            ) < currentDate ? (
                              <div
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "600",
                                  fontSize: "11px",
                                  color: "red",
                                  margin: "0px",
                                }}
                              >
                                Subscription expired
                              </div>
                            ) : (
                              <div
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "600",
                                  fontSize: "11px",
                                  color: "rgba(0,0,0,0.4)",
                                  margin: "0px",
                                }}
                              >
                                Subscription
                              </div>
                            )
                          ) : null
                        ) : null}
                      </div>
                      <p
                        style={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "800",
                          fontSize: "18px",
                          textAlign: "left",
                          color: "rgba(20, 44, 68, 0.8)",
                          margin: "0px",
                        }}
                      >
                        <select
                          id="plan"
                          name="plan"
                          style={{
                            fontFamily: "Manrope, sans-serif",
                            fontWeight: "800",
                            fontSize: "18px",
                            textAlign: "left",
                            color: "rgba(20, 44, 68, 0.8)",
                            margin: "0px",
                            padding: "8px 20px",
                            outline: "none",
                            border: "1px solid lightgrey",
                            borderRadius: "10px",
                          }}
                          value={
                            activeUser.subscription
                              ? JSON.parse(activeUser.subscription).plan
                                ? JSON.parse(activeUser.subscription).plan
                                : ""
                              : ""
                          }
                          onChange={async (e) => {
                            let sampleSubscription = { plan: "", validity: "" };
                            if (activeUser.subscription) {
                              sampleSubscription = JSON.parse(
                                activeUser.subscription
                              );
                            }
                            sampleSubscription.plan = e.target.value;
                            try {
                              const formData = new FormData();
                              formData.append("user_id", activeUser.user_id);
                              formData.append(
                                "subscription",
                                JSON.stringify(sampleSubscription)
                              );

                              const response = await fetch(
                                "https://www.smart-lend.com/api/subcription/updateSubscription",
                                {
                                  method: "POST",
                                  headers: {
                                    Authorization:
                                      "Bearer " + Cookies.get("token"),
                                    Accept: "application/json",
                                  },
                                  body: formData,
                                }
                              );
                              const json = await response.json();
                              console.log(json);

                              const changingActiveUser = { ...activeUser };
                              changingActiveUser.subscription =
                                JSON.stringify(sampleSubscription);
                              setActiveUser(changingActiveUser);

                              const updatingUserArray = [...dataLender];
                              const lenderIndex = updatingUserArray.findIndex(
                                (lender) =>
                                  lender.user_id === activeUser.user_id
                              );
                              updatingUserArray[lenderIndex].subscription =
                                JSON.stringify(sampleSubscription);
                              setDataLender(updatingUserArray);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          <option value="">Select a plan</option>
                          <option value="Platinum">Platinum</option>
                          <option value="Gold">Gold</option>
                          <option value="Silver">Silver</option>
                        </select>
                      </p>
                      <p
                        style={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                          fontSize: "11px",
                          color: "rgba(0,0,0,0.4)",
                          margin: "0px",
                        }}
                      >
                        <input
                          type="date"
                          id="validity"
                          name="validity"
                          style={{
                            fontFamily: "Manrope, sans-serif",
                            fontWeight: "600",
                            fontSize: "11px",
                            color: "rgba(0,0,0,0.4)",
                            margin: "5px 0px 0px 0px",
                            padding: "6px 20px",
                            outline: "none",
                            border: "1px solid lightgrey",
                            borderRadius: "10px",
                          }}
                          value={
                            activeUser.subscription
                              ? JSON.parse(activeUser.subscription).validity
                                ? JSON.parse(
                                    activeUser.subscription
                                  ).validity.split(" ")[0]
                                : new Date().toISOString().split("T")[0]
                              : new Date().toISOString().split("T")[0]
                          }
                          onChange={async (e) => {
                            let sampleSubscription = { plan: "", validity: "" };
                            if (activeUser.subscription) {
                              sampleSubscription = JSON.parse(
                                activeUser.subscription
                              );
                            }
                            sampleSubscription.validity = e.target.value;
                            try {
                              const formData = new FormData();
                              formData.append("user_id", activeUser.user_id);
                              formData.append(
                                "subscription",
                                JSON.stringify(sampleSubscription)
                              );

                              const response = await fetch(
                                "https://www.smart-lend.com/api/subcription/updateSubscription",
                                {
                                  method: "POST",
                                  headers: {
                                    Authorization:
                                      "Bearer " + Cookies.get("token"),
                                    Accept: "application/json",
                                  },
                                  body: formData,
                                }
                              );
                              const json = await response.json();
                              console.log(json);

                              const changingActiveUser = { ...activeUser };
                              changingActiveUser.subscription =
                                JSON.stringify(sampleSubscription);
                              setActiveUser(changingActiveUser);

                              const updatingUserArray = [...dataLender];
                              const lenderIndex = updatingUserArray.findIndex(
                                (lender) =>
                                  lender.user_id === activeUser.user_id
                              );
                              updatingUserArray[lenderIndex].subscription =
                                JSON.stringify(sampleSubscription);
                              setDataLender(updatingUserArray);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        />
                      </p>
                    </div>
                  </div>
                ) : null
              ) : null}
              {activeTab == "Borrower" ? (
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    margin: "10px 0px 0px 0px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      textAlign: "left",
                      marginLeft: "0px",
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: "11px",
                        color: "rgba(0,0,0,0.4)",
                        margin: "0px",
                      }}
                    >
                      Loan Accepted
                    </h3>
                    <>
                      {activeUser.loanAccepted.map((loan, index) => {
                        const isLate = hasLateDays(loan, 0);
                        const todayDateString = getCurrentDate();
                        const isAlmostThreeDay = hasAlmostThreeDay(
                          loan,
                          todayDateString
                        );

                        return (
                          <div
                            key={index}
                            style={{
                              background: "rgba(255,255,255,0.7)",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              borderRadius: "10px",
                              width: "100%",
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedLoan(loan);
                              setModal2(true);
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "rgba(0,0,0,0.5)",
                                  margin: "0px",
                                }}
                              >
                                {loan.loan_id}
                              </p>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "18px",
                                  textAlign: "left",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  margin: "0px",
                                }}
                              >
                                {loan.amount}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              {/* <div
      style={{
        height: "8px",
        width: "8px",
        borderRadius: "8px",
        background:
          loan.status === "accepted" ? "green" : "yellow",
        boxShadow:
          loan.status === "accepted"
            ? "0px 0px 5px green"
            : "0px 0px 5px yellow",
      }}
    ></div> */}
                              <div
                                className={isLate ? "blink" : ""}
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "8px",
                                  background: isAlmostThreeDay
                                    ? "red"
                                    : loan.status === "accepted"
                                    ? "green"
                                    : "yellow",
                                  boxShadow:
                                    loan.status === "accepted"
                                      ? "0px 0px 5px green"
                                      : "0px 0px 5px yellow",
                                }}
                              ></div>

                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "rgba(0,0,0,0.5)",
                                  margin: "0px",
                                }}
                              >
                                {new Date(loan.created_at).toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "left",
                      marginLeft: "0px",
                      width: "100%",
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: "11px",
                        color: "rgba(0,0,0,0.4)",
                        margin: "0px",
                      }}
                    >
                      Loan Requested
                    </h3>
                    {activeUser.loanRequest.map((loan, index) => (
                      <div
                        key={index}
                        style={{
                          background: "rgba(255,255,255,0.7)",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          borderRadius: "10px",
                          width: "100%",
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedLoan2(loan);
                          setModal3(true);
                        }}
                      >
                        <div>
                          <p
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "12px",
                              textAlign: "left",
                              color: "rgba(0,0,0,0.5)",
                              margin: "0px",
                            }}
                          >
                            {loan.loan_id}
                          </p>
                          <p
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "18px",
                              textAlign: "left",
                              color: "rgba(20, 44, 68, 0.8)",
                              margin: "0px",
                            }}
                          >
                            {loan.request_amount}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              height: "8px",
                              width: "8px",
                              borderRadius: "8px",
                              background:
                                loan.status === "accepted" ? "green" : "yellow",
                              boxShadow:
                                loan.status === "accepted"
                                  ? "0px 0px 5px green"
                                  : "0px 0px 5px yellow",
                            }}
                          ></div>
                          <p
                            style={{
                              fontFamily: "Manrope, sans-serif",
                              fontWeight: "800",
                              fontSize: "12px",
                              textAlign: "left",
                              color: "rgba(0,0,0,0.5)",
                              margin: "0px",
                            }}
                          >
                            {new Date(loan.created_at).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : activeTab == "Lender" ? (
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    margin: "20px 0px 0px 0px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      textAlign: "left",
                      marginLeft: "0px",
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: "11px",
                        color: "rgba(0,0,0,0.4)",
                        margin: "0px",
                      }}
                    >
                      Loan Accepted
                    </h3>
                    <>
                      {activeUser2.loanAccepted.map((loan, index) => {
                        const isLate = hasLateDays(loan, 0);
                        const todayDateString = getCurrentDate();
                        const isAlmostThreeDay = hasAlmostThreeDay(
                          loan,
                          todayDateString
                        );

                        return (
                          <div
                            key={index}
                            style={{
                              background: "rgba(255,255,255,0.7)",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              borderRadius: "10px",
                              width: "100%",
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedLoan(loan);
                              setModal2(true);
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "rgba(0,0,0,0.5)",
                                  margin: "0px",
                                }}
                              >
                                {loan.loan_id}
                              </p>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "18px",
                                  textAlign: "left",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  margin: "0px",
                                }}
                              >
                                {loan.amount}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              {/* <div
      style={{
        height: "8px",
        width: "8px",
        borderRadius: "8px",
        background:
          loan.status === "accepted" ? "green" : "yellow",
        boxShadow:
          loan.status === "accepted"
            ? "0px 0px 5px green"
            : "0px 0px 5px yellow",
      }}
    ></div> */}
                              <div
                                className={isLate ? "blink" : ""}
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "8px",
                                  background: isAlmostThreeDay
                                    ? "red"
                                    : loan.status === "accepted"
                                    ? "green"
                                    : "yellow",
                                  boxShadow:
                                    loan.status === "accepted"
                                      ? "0px 0px 5px green"
                                      : "0px 0px 5px yellow",
                                }}
                              ></div>

                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "rgba(0,0,0,0.5)",
                                  margin: "0px",
                                }}
                              >
                                {new Date(loan.created_at).toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "left",
                      marginLeft: "0px",
                      width: "100%",
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "600",
                        fontSize: "11px",
                        color: "rgba(0,0,0,0.4)",
                        margin: "0px",
                      }}
                    >
                      Loan Offered
                    </h3>
                    {activeUser.loanOffers
                      ? activeUser.loanOffers.map((loan, index) => (
                          <div
                            key={index}
                            style={{
                              background: "rgba(255,255,255,0.7)",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              borderRadius: "10px",
                              width: "100%",
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedLoan2(loan);
                              setModal3(true);
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "rgba(0,0,0,0.5)",
                                  margin: "0px",
                                }}
                              >
                                {loan.loan_id}
                              </p>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "18px",
                                  textAlign: "left",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  margin: "0px",
                                }}
                              >
                                {loan.offer_amount}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              <div
                                style={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "8px",
                                  background:
                                    loan.status === "ongoing"
                                      ? "green"
                                      : "yellow",
                                  boxShadow:
                                    loan.status === "ongoing"
                                      ? "0px 0px 5px green"
                                      : "0px 0px 5px yellow",
                                }}
                              ></div>
                              <p
                                style={{
                                  fontFamily: "Manrope, sans-serif",
                                  fontWeight: "800",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  color: "rgba(0,0,0,0.5)",
                                  margin: "0px",
                                }}
                              >
                                {new Date(loan.created_at).toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </p>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    background: "rgba(255,255,255,0.7)",
                    height: "78vh",
                    width: "100%",
                    borderRadius: "10px",
                    marginTop: "15px",
                    color: "#142C44CC",
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                    fontSize: "11px",
                    padding: "10px",
                  }}
                >
                  No user selected
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                background: "rgba(255,255,255,0.7)",
                height: "78vh",
                width: "100%",
                borderRadius: "10px",
                marginTop: "15px",
                color: "#142C44CC",
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "11px",
                padding: "10px",
              }}
            >
              No user selected
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDatabase;
