import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import TableAdminOngoingLoan from "../../components/TableAdminOngoingLoan";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import warn_ico from "../../assets/img/exclamation.png";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Avatar,
  Upload,
  Card,
  Tabs,
  notification,
  DatePicker,
  Space,
  InputNumber,
  Modal,
  Button,
  message,
} from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import { flexibleCompare } from "@fullcalendar/core/internal";

const OngoingLoan = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [channel, setChannel] = useState("system");
  const [data, setData] = useState([]);
  const [data2, setData2] = useState(null);
  const [outStandingBalance, setOutStandingBalance] = useState(null);
  const [subject, setSubject] = useState("");
  const { TextArea } = Input;
  const [selectedBorrowerIds, setselectedBorrowerIds] = useState([]);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [screenWidth, setScreenWidth] = useState("1920");
  const [disbursementModalIsOpen, setDisbursementModalIsOpen] = useState(null);
  const [disbursementDate, setDisbursementDate] = useState(null);
  const [adjustedDisbursementDate, setAdjustedDisbursementDate] =
    useState(null);

  const [keyforTable, setKeyForTable] = useState(1);
  const { RangePicker } = DatePicker;
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const getDifferenceInDays = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    // Calculate the difference in milliseconds and convert to days
    const differenceInMs = d2 - d1;
    const differenceInDays = Math.round(differenceInMs / (1000 * 60 * 60 * 24));
    return differenceInDays; // Can be negative or positive
  };

  // Function to calculate the new date after a given change in days
  const getChangedDate = (date, changeInDays) => {
    const d = new Date(date);
    // Add the change in days
    d.setDate(d.getDate() + changeInDays);
    // Format the date to YYYY-MM-DD
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleRowClick = async (record) => {
    console.log(record);
    const loan_id = record.loan_id;
    const lender_id = record.lender_id;
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/ongoingLoan/viewAllOngoingLoanDetail?loan_id=${loan_id}&lender_id=${lender_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      console.log("json value:", json);
      setData(json["ongoing_loan_detail"]);
      setData2(json);
      const outStandingBalance =
        json["ongoing_loan_detail"][0].monthly_payment * json.period_left;
      setOutStandingBalance(outStandingBalance);
      console.log("outstanding balance value:", outStandingBalance);
    } catch (error) {
      console.error("Error retrieving Ongoing Loan Detail Data:", error);
    }
  };

  const updateRepaymentAndDisbursementDate = async (dataToSend) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/updateRepaymentAndDisbursementDate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(dataToSend),
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        message.success("Disbursement date change successful.");
        handleRowClick(dataToSend);
        setDisbursementModalIsOpen(null);
        setDisbursementDate(null);
        setAdjustedDisbursementDate(null);
        setKeyForTable(keyforTable + 1);
      } else {
        message.error("Disbursement date change error");
      }
    } catch (error) {
      console.error("Error retrieving Ongoing Loan Detail Data:", error);
    }
  };

  const handleSelectionChange = (selectedBorrowerIds) => {
    console.log("Selected loan IDs:", selectedBorrowerIds);
    setselectedBorrowerIds(selectedBorrowerIds);
  };

  const formatDate = (dateString) => {
    try {
      if (!dateString) throw new Error("No date string provided");

      const date = new Date(dateString);
      if (isNaN(date.getTime())) throw new Error("Invalid date string");

      const formattedDate = format(date, "dd MMMM yyyy");

      return formattedDate;
    } catch (error) {
      console.error(error);
      return "-";
    }
  };

  function handleDateRangeChange(dates) {
    setDateRange(dates);
  }

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 16,
    },
    vertical: true,
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const tailLayout = {
    wrapperCol: {
      offset: 9,
      span: 24,
    },
  };

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "transparent",
        height: "60px",
      }}
    >
      <div style={{ display: "flex", alignItems: "left", color: "grey" }}>
        {icon ? (
          <img
            src={icon}
            alt={title}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "20px",
              margin: "10px 10px 10px 10px",
            }}
          />
        ) : null}
        <div
          style={{
            height: "60px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "5px",
          }}
        >
          <h3
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
              fontSize: "18px",
              textAlign: "left",
              color: "rgba(20, 44, 68, 0.8)",
              margin: "0px 0px 0px 0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              textAlign: "left",
              margin: "0px 0px 0px 0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const ContainerTransaction = ({ title, label }) => (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "12px",
      }}
    >
      <div style={{ flex: 1, textAlign: "left", marginLeft: "0px" }}>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            margin: "0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            textAlign: "left",
            color: "rgba(20, 44, 68, 0.8)",
            margin: "0px",
          }}
        >
          {label}
        </p>
      </div>
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {screenWidth > 1200 ? (
        <Box m="4px 20px 20px 20px">
          <Modal
            width={screenWidth > 1200 ? "70vw" : "80vw"}
            style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            open={disbursementModalIsOpen !== null}
            onCancel={() => {
              setDisbursementModalIsOpen(null);
              setDisbursementDate(null);
              setAdjustedDisbursementDate(null);
            }}
            footer={null}
          >
            <Typography
              color={colors.grey[920]}
              variant="h5"
              fontWeight="800"
              fontFamily="Manrope, sans-serif"
              fontSize="20px"
            >
              Change Loan Disbursment Date
            </Typography>
            <div style={{ marginTop: "20px" }}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "13px",
                    color: colors.grey[920],
                    margin: "0px",
                    width: "30%",
                  }}
                >
                  Repayment Id
                </div>
                <div
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "13px",
                    color: colors.grey[920],
                    margin: "0px",
                    width: "30%",
                  }}
                >
                  Repayment Amount
                </div>
                <div
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "13px",
                    color: colors.grey[920],
                    margin: "0px",
                    width: "40%",
                  }}
                >
                  Due Date
                </div>
              </div>
              {disbursementModalIsOpen
                ? disbursementModalIsOpen.map((dbda, index) => (
                    <div key={index} style={{ display: "flex" }}>
                      <div
                        style={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                          fontSize: "13px",
                          color: "rgba(0,0,0,0.4)",
                          margin: "0px",
                          width: "30%",
                        }}
                      >
                        {dbda.repay_id}
                      </div>
                      <div
                        style={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                          fontSize: "13px",
                          color: "rgba(0,0,0,0.4)",
                          margin: "0px",
                          width: "30%",
                        }}
                      >
                        {dbda.repay_amount}
                      </div>
                      <div
                        style={{
                          fontFamily: "Manrope, sans-serif",
                          fontWeight: "600",
                          fontSize: "13px",
                          color: "rgba(0,0,0,0.4)",
                          margin: "0px",
                          width: "40%",
                        }}
                      >
                        {adjustedDisbursementDate
                          ? formatDate(
                              getChangedDate(
                                dbda.repay_due_date,
                                getDifferenceInDays(
                                  disbursementDate,
                                  adjustedDisbursementDate
                                )
                              )
                            )
                          : formatDate(dbda.repay_due_date)}
                      </div>
                    </div>
                  ))
                : null}
            </div>
            <div
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "15px",
                color: colors.grey[920],
                margin: "0px",
                marginTop: "30px",
              }}
            >
              Change disbursement date
            </div>
            <div
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "10px",
                color: "rgba(0,0,0,0.4)",
                margin: "0px",
                marginTop: "5px",
              }}
            >
              Take note that changing the disbursement date will alter the due
              date of each repayment, as you change the disbursement date below
              please check and confirm that the repayment due date are correctly
              set before hitting confirm.
            </div>
            <input
              type="date"
              value={adjustedDisbursementDate || disbursementDate}
              onChange={(e) => {
                setAdjustedDisbursementDate(e.target.value);
                console.log(e.target.value);
              }}
              style={{
                marginTop: "15px",
                padding: "5px 20px",
                borderRadius: "6px",
                outline: "none",
                border: "1px solid rgba(0,0,0,0.3)",
              }}
            />

            <div style={{ display: "flex", marginTop: "20px" }}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  const dataToSend = {
                    loan_id: data?.[0]?.loan_id,
                    lender_id: data?.[0]?.lender_id,
                    newDisbursementDate: adjustedDisbursementDate,
                    newRepayments: disbursementModalIsOpen.map((dbda) => ({
                      id: dbda.id,
                      repay_due_date: getChangedDate(
                        dbda.repay_due_date,
                        getDifferenceInDays(
                          disbursementDate,
                          adjustedDisbursementDate
                        )
                      ),
                    })),
                  };
                  updateRepaymentAndDisbursementDate(dataToSend);
                }}
                className="all_button"
                style={{
                  background: "#1557a9",
                  color: "white",
                  width: "100%",
                  border: "none",
                  borderRadius: "50px",
                  padding: "6px 0px",
                  fontSize: "12px",
                  margin: "10px 0px 0px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Confirm disbursement date
              </button>
            </div>
          </Modal>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
            <Box
              gridColumn="span 9"
              gridRow="span 2"
              height="100vh"
              width="100%"
              borderRadius="10px"
              border="0px solid "
              opacity="0.95"
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
                background:
                  "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                borderRadius="10px"
                margin="20px 20px 20px 20px"
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  Ongoing Loan
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",

                    justifyContent: "flex-end",
                    width: "50%",
                  }}
                >
                  <label
                    htmlFor="sort-by"
                    style={{
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontSize: "13px",
                      fontFamily: "Manrope, sans-serif",
                      marginRight: "5px",
                    }}
                  >
                    View by:
                  </label>
                  {/* <div style={{ width: "80%", marginRight: "10px" }}>
                           <select
                               id="sort-by"
                               value={sortBy}
                               onChange={(e) => setSortBy(e.target.value)}
                               style={{
                                   width: "100%",
                                   borderRadius: "30px",
                                   color: "#0E5AA5",
                                   backgroundColor: "#ECF0F5",
                                   border: "1px solid #3E536733",
                                   marginRight: "15px",
                                   fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "13px"
                               }}
                           >
                               <option style={{ fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "13px" }} value="default">Date & Time</option>
                               <option style={{ fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "13px" }} value="loan-amount">Loan Amount</option>
                               <option style={{ fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "13px" }} value="date">Date</option>
                           </select>
                       </div> */}
                  <div style={{ width: "37%", marginRight: "38px" }}>
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        onChange={handleDateRangeChange}
                        value={dateRange}
                      />
                    </Space>
                  </div>
                </div>
              </Box>

              <div key={keyforTable}>
                <TableAdminOngoingLoan
                  onRowClick={handleRowClick}
                  onSelectionChange={handleSelectionChange}
                />
              </div>
            </Box>

            <Box
              gridColumn="span 3"
              gridRow="span 2"
              height="89vh"
              borderRadius="10px"
              border="0px solid white"
              style={{
                background:
                  "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // borderBottom={`4px solid ${colors.primary[500]}`}
                colors={colors.grey[910]}
                marginTop="-15px"
                marginBottom="10px"
                p="15px"
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  Ongoing Loan Details
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                backgroundColor="#FFFFFF"
                borderRadius="10px"
                margin="-10px 10px"
                style={{
                  overflowY: "scroll",
                }}
                p="15px"
                height="83.7vh"
              >
                {data && data.length > 0 ? (
                  data.map((item) => (
                    <Box
                      key={item.id}
                      display="flex"
                      justifyContent="center"
                      alignItems="flex-start" // Updated to align to the top
                      colors={colors.grey[910]}
                      // borderBottom={`1px solid ${colors.primary[500]}`}
                      width="100%"
                      textAlign="center"
                      margin="0px"
                      flexDirection="column"
                    >
                      <ContainerActivity
                        title={item.loan_id || "Hello World"}
                        subtitle={
                          item.category
                            ? item.category.charAt(0).toUpperCase() +
                              item.category.slice(1)
                            : ""
                        }
                      />
                      <div
                        style={{
                          borderBottom: "2px solid black",
                          width: "100%",
                          opacity: "0.3",
                        }}
                      />
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    colors={colors.grey[910]}
                    width="100%"
                    textAlign="center"
                    margin="1px 1px 1px 0px"
                    flexDirection="column"
                    height="80vh"
                  >
                    <ContainerActivity
                      icon={warn_ico}
                      title="Please Click on Table Row to View Details"
                    />
                  </Box>
                )}
                {!data && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    colors={colors.grey[910]}
                    width="100%"
                    textAlign="center"
                    margin="0px"
                    flexDirection="column"
                  >
                    <ContainerActivity
                      icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg"
                      title="NO VALUE HERE"
                      subtitle="NO VALUE HERE"
                    />
                    <div
                      style={{
                        borderBottom: "2px solid black",
                        width: "100%",
                        opacity: "0.3",
                      }}
                    />
                  </Box>
                )}

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Lender"
                        label={item.lender_name || "-"}
                      />
                    ))}
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Amount"
                        label={
                          item.loan_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Interest Rate"
                        label={
                          item.interest_rate
                            ? (item.interest_rate * 100).toFixed(1) + "%"
                            : "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Monthly Payment"
                        label={
                          item.monthly_payment.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {outStandingBalance && (
                    <ContainerTransaction
                      title="Outstanding Balance"
                      label={
                        outStandingBalance
                          ? outStandingBalance.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            })
                          : "-"
                      }
                    />
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Period"
                        label={item.loan_period + " Months" || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data2 && (
                    <ContainerTransaction
                      title="Remaining Period"
                      label={data2.period_left + " Months" || "-"}
                    />
                  )}
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Principal"
                        label={
                          item.principal_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Repay Due Date"
                        label={formatDate(item.repay_due_date) || "-"}
                      />
                    ))}
                </Box>
                {data &&
                  data.length > 0 &&
                  data.map((item) => (
                    <div style={{ display: "flex", marginTop: "20px" }}>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setDisbursementModalIsOpen(item.repayments);
                          console.log(item.disbursement_date.slice(0, 10));
                          setDisbursementDate(
                            item.disbursement_date.slice(0, 10)
                          );
                        }}
                        className="all_button"
                        style={{
                          background: "#1557a9",
                          color: "white",
                          border: "none",
                          borderRadius: "50px",
                          padding: "6px 25px",
                          fontSize: "12px",
                          margin: "10px 0px 0px 0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Change Disbursement Date
                      </button>
                    </div>
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              padding: "0px 20px",
              height: "100vh",
              borderRadius: "20px",
              width: "95%",
              marginLeft: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
                width: "100%",
              }}
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="20px"
              >
                Ongoing Loan
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "33%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label
                    htmlFor="sort-by"
                    style={{
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontSize: "10px",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    Sort by:
                  </label>

                  <select
                    id="sort-by"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    <option value="default">Date & Time</option>
                    <option value="loan-amount">Loan Amount</option>
                    <option value="date">Date</option>
                  </select>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <TableAdminOngoingLoan
                onRowClick={handleRowClick}
                onSelectionChange={handleSelectionChange}
              />
            </div>
            <div
              style={{
                height: "89vh",
                borderRadius: "20px",
                border: "0px solid white",
                background:
                  "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <div>
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="20px"
                  marginTop="10px"
                >
                  Ongoing Loan Details
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "20px",
                  overflowY: "scroll",
                  height: "83.7vh",
                }}
              >
                {data && data.length > 0 ? (
                  data.map((item) => (
                    <Box
                      key={item.id}
                      display="flex"
                      justifyContent="center"
                      alignItems="flex-start" // Updated to align to the top
                      colors={colors.grey[910]}
                      // borderBottom={`1px solid ${colors.primary[500]}`}
                      width="45%"
                      textAlign="center"
                      margin="1px 1px 1px 30px"
                      flexDirection="column"
                    >
                      <ContainerActivity
                        icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg" //map to data laterwards
                        title={item.loan_id || "Hello World"}
                        subtitle={
                          item.category
                            ? item.category.charAt(0).toUpperCase() +
                              item.category.slice(1)
                            : "-"
                        }
                      />
                      <div
                        style={{
                          borderBottom: "2px solid black",
                          width: "190%",
                          opacity: "0.3",
                        }}
                      />
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    colors={colors.grey[910]}
                    width="100%"
                    textAlign="center"
                    margin="1px 1px 1px 0px"
                    flexDirection="column"
                    height="80vh"
                  >
                    <ContainerActivity
                      icon={warn_ico}
                      title="Please Click on Table Row to View Details"
                    />
                  </Box>
                )}
                {!data && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    colors={colors.grey[910]}
                    width="45%"
                    textAlign="center"
                    margin="1px 1px 1px 30px"
                    flexDirection="column"
                  >
                    <ContainerActivity
                      icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg"
                      title="NO VALUE HERE"
                      subtitle="NO VALUE HERE"
                    />
                    <div
                      style={{
                        borderBottom: "2px solid black",
                        width: "190%",
                        opacity: "0.3",
                      }}
                    />
                  </Box>
                )}

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Lender"
                        label={item.lender_name || "-"}
                      />
                    ))}
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Amount"
                        label={
                          item.loan_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Interest Rate"
                        label={
                          item.interest_rate
                            ? item.interest_rate * 100 + "%"
                            : "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Monthly Payment"
                        label={
                          item.monthly_payment.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {outStandingBalance && (
                    <ContainerTransaction
                      title="Outstanding Balance"
                      label={
                        outStandingBalance
                          ? outStandingBalance.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            })
                          : "-"
                      }
                    />
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Period"
                        label={item.loan_period + " Months" || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Period"
                        label={item.loan_period + " Months" || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data2 && (
                    <ContainerTransaction
                      title="Remaining Period"
                      label={data2.period_left + " Months" || "-"}
                    />
                  )}
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Principal"
                        label={
                          item.principal_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Repay Due Date"
                        label={formatDate(item.repay_due_date) || "-"}
                      />
                    ))}
                </Box>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OngoingLoan;
