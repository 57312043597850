import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Cookies from "js-cookie";
import sampleProp from "../../";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import { Button, Modal, message } from "antd";
import { useHistory } from "react-router-dom";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import logo from "../../assets/img/SL-01.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SendIcon from "@mui/icons-material/Send";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const LPropLoan = () => {
  const theme = useTheme();
  const history = useHistory();
  const colors = tokens(theme.palette.mode);
  const [screenWidth, setScreenWidth] = useState(1920);
  const [sortBy, setSortBy] = useState("default");
  const [allPropLoans, setAllPropLoans] = useState([]);
  const [badgeVerified, setBadgeVerified] = useState(false);
  const [allCBSData, setAllCBSData] = useState(null);
  const [dataSingpassModal, setDataSingpassModal] = useState(false);
  const [singpassData, setSingpassData] = useState(null);
  const [viewingTab, setViewingTab] = useState(0);
  const [viewingUser, setViewingUser] = useState(0);
  const [liveUserData, setLiveUserData] = useState(null);
  const [keyman, setKeyman] = useState("");
  const [otherAuthoriserDetails, setOtherAuthoriserDetails] = useState(null);
  const [combinedSingpassData, setCombinedSingpassData] = useState([]);
  const [loanOfferOpenModal, setLoanOfferOpenModal] = useState(null);
  const [viewWholeTable, setViewWholeTable] = useState(false);
  const [minLoanAmount, setMinLoanAmount] = useState("");
  const [maxLoanAmount, setMaxLoanAmount] = useState("");
  const [maxLtv, setMaxLtv] = useState("");
  const [loanCategoryFilters, setLoanCategoryFilters] = useState([0, 1]);

  const handleCheckboxChange = (isChecked, category) => {
    if (isChecked) {
      setLoanCategoryFilters([...loanCategoryFilters, category]);
    } else {
      setLoanCategoryFilters(loanCategoryFilters.filter((c) => c !== category));
    }
  };

  const getShareholderAddress = (data) => {
    const allSingpassData = [singpassData];
    if (otherAuthoriserDetails !== null && otherAuthoriserDetails.length > 0) {
      for (const single of otherAuthoriserDetails) {
        allSingpassData.push(JSON.parse(single.singpass_data));
      }
      const toPush = allSingpassData.filter(
        (sd) => sd?.person?.name?.value === data
      );
      // console.log(toPush);
      return toPush;
    } else {
      return allSingpassData;
    }
  };

  const availableTab = [
    { name: "Personal Information" },
    { name: "Corporate Information" },
    { name: "NOA History" },
    { name: "Capitals" },
    { name: "Financials" },
    { name: "Shareholders" },
    { name: "Borrower Report" },
  ];
  const getInitials = (name) => {
    const textToSplit = name.split(" ");
    const initials =
      textToSplit?.[0]?.[0].toUpperCase() +
      (textToSplit?.[1]?.[0].toUpperCase() || "");
    return initials;
  };

  const getAllPropLoans = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/propTermLoan/getAllPropLoans",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            "Content-Type": "application/json", // Add this
          },
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        setAllPropLoans(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    getAllPropLoans();
  }, []);
  return (
    <Box m="4px 20px 20px 20px">
      <Modal
        width={isMobile ? "100%" : "60%"}
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={loanOfferOpenModal !== null}
        onCancel={() => {
          setLoanOfferOpenModal(null);
        }}
        footer={null}
      >
        {loanOfferOpenModal !== null ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                color: "rgba(20, 44, 68, 0.8)",
                fontWeight: 900,
                fontFamily: "Manrope, sans-serif",
                fontSize: 20,
              }}
            >
              <h2 style={{ fontSize: "24px" }}>Make an offer</h2>
            </div>
            <div
              style={{
                width: "80%",
                height: "1px",
                background: "rgba(0,0,0,0.1)",
                borderRadius: 2,
              }}
            />
            <div></div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "20px",
                color: "rgba(20, 44, 68, 0.8)",
                fontWeight: 900,
                fontFamily: "Manrope, sans-serif",
                fontSize: 20,
              }}
            >
              <h2 style={{ fontSize: "20px" }}>Loan Request Details</h2>
            </div>
            <div
              style={{
                width: "80%",
                height: "1px",
                background: "rgba(0,0,0,0.1)",
                borderRadius: 2,
              }}
            />
            <div style={{ display: "flex" }}>
              <div style={{ width: "80%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "50%" }}>
                    <div
                      style={{
                        marginTop: "15px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Property LTV:
                    </div>
                    <div style={{ fontWeight: 600, color: "#142C449A" }}>
                      {(
                        ((allPropLoans.filter(
                          (loa) => loa.id === loanOfferOpenModal
                        )?.[0].loan_data?.oustandingLoan +
                          allPropLoans.filter(
                            (loa) => loa.id === loanOfferOpenModal
                          )?.[0].loan_data?.cpfUsage +
                          allPropLoans.filter(
                            (loa) => loa.id === loanOfferOpenModal
                          )?.[0].loan_data?.accruedInterest) /
                          allPropLoans.filter(
                            (loa) => loa.id === loanOfferOpenModal
                          )?.[0].loan_data?.valuation) *
                        100
                      ).toFixed(2)}{" "}
                      %
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Loan Amount:
                    </div>
                    <div style={{ fontWeight: 600, color: "#142C449A" }}>
                      {allPropLoans
                        .filter((loa) => loa.id === loanOfferOpenModal)?.[0]
                        .loan_data.loanAmount?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Property Valuation:
                    </div>
                    <div style={{ fontWeight: 600, color: "#142C449A" }}>
                      {allPropLoans
                        .filter((loa) => loa.id === loanOfferOpenModal)?.[0]
                        .loan_data.valuation?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "50%" }}>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Loan Tenor:
                    </div>
                    <div style={{ fontWeight: 600, color: "#142C449A" }}>
                      {allPropLoans.filter(
                        (loa) => loa.id === loanOfferOpenModal
                      )?.[0].loan_data.selectedLoanType === 0
                        ? "25 Years"
                        : "5 Years"}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Interest:
                    </div>
                    <div style={{ fontWeight: 600, color: "#142C449A" }}>
                      {allPropLoans.filter(
                        (loa) => loa.id === loanOfferOpenModal
                      )?.[0].loan_data.selectedLoanType === 0
                        ? "10% p.a"
                        : "2.5% per month"}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Loan Payment Type:
                    </div>
                    <div style={{ fontWeight: 600, color: "#142C449A" }}>
                      {allPropLoans.filter(
                        (loa) => loa.id === loanOfferOpenModal
                      )?.[0].loan_data.loanPaymentType === "normal"
                        ? "Interest and principal"
                        : "Interest Only"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "0px",
                  fontSize: "10px",
                  color: "grey",
                }}
              >
                Repayment Schedule:
              </div>
              <div
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  marginTop: "10px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: "#142C442A",
                  }}
                >
                  <div
                    style={{
                      width: "20%",
                      padding: "6px 10px",
                      fontWeight: "600",
                    }}
                  >
                    Month
                  </div>
                  <div
                    style={{
                      width: "20%",
                      padding: "6px 10px",
                      fontWeight: "600",
                    }}
                  >
                    Principal
                  </div>
                  <div
                    style={{
                      width: "20%",
                      padding: "6px 10px",
                      fontWeight: "600",
                    }}
                  >
                    Interest Revenue
                  </div>
                  <div
                    style={{
                      width: "20%",
                      padding: "6px 10px",
                      fontWeight: "600",
                    }}
                  >
                    Total
                  </div>
                  <div
                    style={{
                      width: "20%",
                      padding: "6px 10px",
                      fontWeight: "600",
                    }}
                  >
                    Loan Balance
                  </div>
                </div>
                {allPropLoans
                  .filter((loa) => loa.id === loanOfferOpenModal)?.[0]
                  .loan_data?.loanRepaymentSchedule?.slice(
                    0,
                    viewWholeTable ? undefined : 8
                  )
                  .map((repay, index) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontSize: "12px",
                        background: index % 2 ? "#142C441A" : "transparent",
                      }}
                      key={index}
                    >
                      <div style={{ width: "20%", padding: "4px 10px" }}>
                        {repay.month}
                      </div>
                      <div style={{ width: "20%", padding: "4px 10px" }}>
                        {repay.principal?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </div>
                      <div style={{ width: "20%", padding: "4px 10px" }}>
                        {repay.interest?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </div>
                      <div style={{ width: "20%", padding: "4px 10px" }}>
                        {repay.total?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </div>
                      <div style={{ width: "20%", padding: "4px 10px" }}>
                        {repay.loanBalance?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "SGD",
                        })}
                      </div>
                    </div>
                  ))}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#142C442A",
                    textAlign: "center",
                    padding: "7px",
                  }}
                  className="mui_button"
                  onClick={() => {
                    setViewWholeTable(!viewWholeTable);
                  }}
                >
                  {viewWholeTable ? "Minimize View" : "View All"}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
      <Modal
        width={isMobile ? "100%" : "60%"}
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={dataSingpassModal}
        onCancel={() => {
          setDataSingpassModal(false);
          setSingpassData(null);
        }}
        footer={null}
      >
        {singpassData ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "20px",
                color: "rgba(20, 44, 68, 0.8)",
                fontWeight: 900,
                fontFamily: "Manrope, sans-serif",
                fontSize: 20,
              }}
            >
              <h2> Borrower information</h2>
            </div>
            <div
              style={{
                display: "flex",
                borderBottom: "1px solid lightgrey",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "20px",
                padding: "10px 0px",
                width: "100%",
                overflowX: isMobile ? "auto" : "hidden",
              }}
            >
              {availableTab.map((view, index) => (
                <div
                  onClick={() => {
                    setViewingTab(index);
                  }}
                  className="all_button"
                  style={{
                    cursor: "pointer",
                    color:
                      viewingTab === index
                        ? "rgba(20, 44, 68, 0.8)"
                        : "rgba(0,0,0,0.4)",
                    fontSize: viewingTab === index ? "13px" : "12px",
                    fontWeight: viewingTab === index ? "700" : "400",
                    transition: "0.3s all ease-in-out",
                  }}
                  key={index}
                >
                  {view.name}
                </div>
              ))}
            </div>
            <div
              style={{
                padding: "15px 0px",
                overflowX: isMobile ? "auto" : "hidden",
              }}
            >
              {liveUserData ? (
                viewingTab === 0 ? (
                  <div
                    style={{
                      padding: "15px 15px",
                      borderRadius: "8px",
                      width: "100%",
                      background: "rgba(14, 90, 165, 0.1)",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "2px 20px",
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        NRIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          min={0}
                          // className={nricError ? "error-border" : ""}
                          value={liveUserData?.person?.uinfin?.value}
                        />
                        {/* {nricError && <div style={{ color: 'red' }}>Please fill in the NRIC</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Full Name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          type="text"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          value={liveUserData?.person?.name?.value}
                          // required
                        />
                        {/* {fullNameError && <div style={{ color: 'red' }}>Please fill in the Full Name</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Sex
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          value={liveUserData?.person?.sex?.desc}
                          // required
                        />
                        {/* {sexError && <div style={{ color: 'red' }}>Please fill in the Sex</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Race
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.race?.desc}
                        />
                        {/* {raceError && <div style={{ color: 'red' }}>Please fill in the Race</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Nationality
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.nationality?.desc}
                        />
                        {/* {nationalityError && <div style={{ color: 'red' }}>Please fill in the Nationality</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Date of Birth
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={liveUserData?.person?.dob?.value}
                        />
                        {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Country of Birth
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.birthcountry?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Residential Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.residentialstatus?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.passtype?.desc}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.passstatus?.value}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Pass Expiry Date
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={liveUserData?.person?.passexpirydate?.value}
                        />
                        {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Ownership of Private Property Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.ownerprivate?.value}
                        />
                        {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Email
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          type="email"
                          disabled
                          // required
                          value={liveUserData?.person?.email?.value}
                        />
                        {/* {emailError && <div style={{ color: 'red' }}>Please fill in the Email</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Mobile Number
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="number"
                          // required
                          value={liveUserData?.person?.mobileno?.nbr?.value}
                        />
                        {/* {mobileNoError && <div style={{ color: 'red' }}>Please fill in the Mobile Number</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registered Address
                      </div>
                      <div style={{ width: "60%" }}>
                        <textarea
                          rows="2"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          // required
                          value={
                            liveUserData?.person?.regadd
                              ? (liveUserData?.person?.regadd?.unit?.value !==
                                null
                                  ? `Unit ${liveUserData?.person?.regadd?.unit?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.floor?.value !==
                                null
                                  ? `Floor ${liveUserData?.person?.regadd?.floor?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.block?.value !==
                                null
                                  ? `Block ${liveUserData?.person?.regadd?.block?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.street?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.street?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.postal?.value !==
                                null
                                  ? `${liveUserData?.person?.regadd?.postal?.value}, `
                                  : "") +
                                (liveUserData?.person?.regadd?.country?.desc !==
                                null
                                  ? `${liveUserData?.person?.regadd?.country?.desc}.`
                                  : "")
                              : ""
                          }
                        ></textarea>
                        {/* {registeredAddressError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Housing Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.housingtype?.desc}
                        />
                        {/* {housingTypeError && <div style={{ color: 'red' }}>Please fill in the Housing Type</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Marital Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          value={liveUserData?.person?.marital?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Occupation
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.occupation?.value}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Employer's name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={""}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Employment Sector
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={""}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        HDB Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.hdbtype?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Housing Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.housingtype?.desc}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    {liveUserData?.person?.hdbownership?.map((hdb, index) => (
                      <div
                        key={index}
                        style={{
                          width: "83.5%",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            HDB Ownership {index + 1} - Address
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={
                                hdb?.address
                                  ? (hdb?.address?.unit?.value !== null
                                      ? `Unit ${hdb?.address?.unit?.value}, `
                                      : "") +
                                    (hdb?.address?.floor?.value !== null
                                      ? `Floor ${hdb?.address?.floor?.value}, `
                                      : "") +
                                    (hdb?.address?.block?.value !== null
                                      ? `Block ${hdb?.address?.block?.value}, `
                                      : "") +
                                    (hdb?.address?.street?.value !== null
                                      ? `${hdb?.address?.street?.value}, `
                                      : "") +
                                    (hdb?.address?.postal?.value !== null
                                      ? `${hdb?.address?.postal?.value}, `
                                      : "") +
                                    (hdb?.address?.country?.desc !== null
                                      ? `${hdb?.address?.country?.desc}.`
                                      : "")
                                  : ""
                              }
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            HDB Ownership {index + 1} - Outstanding HDB Loan
                            Balance
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={hdb?.outstandingloanbalance?.value?.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "SGD",
                                }
                              )}
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            HDB Ownership {index + 1} - Type of HDB Dwelling
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={hdb?.hdbtype?.desc}
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            HDB Ownership {index + 1} - Monthly Loan Installment
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={hdb?.monthlyloaninstalment?.value?.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "SGD",
                                }
                              )}
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                      </div>
                    ))}
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Vehicles - Vehicles Model
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.person?.vehicles[0]?.model?.value
                          }
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Vehicles - Vehicles Make
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={liveUserData?.person?.vehicles[0]?.make?.value}
                        />
                        {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                      </div>
                    </div>
                    {liveUserData?.person?.cpfhousingwithdrawal?.withdrawaldetails?.map(
                      (cpf, index) => (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            CPF Usage{" "}
                            {(cpf.address.unit?.value !== undefined &&
                            cpf.address.unit?.value !== null
                              ? `Unit ${cpf.address.unit?.value}, `
                              : "") +
                              (cpf.address.floor?.value !== undefined &&
                              cpf.address.floor?.value !== null
                                ? `Floor ${cpf.address.floor?.value}, `
                                : "") +
                              (cpf.address.block?.value !== undefined &&
                              cpf.address.block?.value !== null
                                ? `Block ${cpf.address.block?.value}, `
                                : "") +
                              (cpf.address.street?.value !== undefined
                                ? `${cpf.address.street?.value}, `
                                : "") +
                              (cpf.address.postal?.value !== undefined
                                ? `${cpf.address.postal?.value}, `
                                : "") +
                              (cpf.address.country?.desc !== undefined
                                ? `${cpf.address.country?.desc}.`
                                : "")}
                            :
                          </div>
                          <div
                            style={{
                              width: "42%",
                              display: "flex",
                              marginBottom: 10,
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                color: "rgba(20, 44, 68, 0.8)",
                                fontWeight: 800,
                                fontFamily: "Manrope, sans-serif",
                                fontSize: 14,
                              }}
                            >
                              Principal Withdrawal Amount:
                            </div>
                            <div style={{ width: "40%" }}>
                              <input
                                style={{
                                  width: "100%",
                                  borderRadius: "10px",
                                  border: "none",
                                  outline: "none",
                                  padding: 7,
                                }}
                                disabled
                                type="text"
                                // required
                                value={cpf?.principalwithdrawalamt?.value?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "SGD",
                                  }
                                )}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              width: "42%",
                              display: "flex",
                              marginBottom: 10,
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                color: "rgba(20, 44, 68, 0.8)",
                                fontWeight: 800,
                                fontFamily: "Manrope, sans-serif",
                                fontSize: 14,
                              }}
                            >
                              Accrued Interest Amount:
                            </div>
                            <div style={{ width: "40%" }}>
                              <input
                                style={{
                                  width: "100%",
                                  borderRadius: "10px",
                                  border: "none",
                                  outline: "none",
                                  padding: 7,
                                }}
                                disabled
                                type="text"
                                // required
                                value={cpf?.accruedinterestamt?.value?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "SGD",
                                  }
                                )}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              width: "42%",
                              display: "flex",
                              marginBottom: 10,
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                color: "rgba(20, 44, 68, 0.8)",
                                fontWeight: 800,
                                fontFamily: "Manrope, sans-serif",
                                fontSize: 14,
                              }}
                            >
                              Monthly Installment (CPF):
                            </div>
                            <div style={{ width: "40%" }}>
                              <input
                                style={{
                                  width: "100%",
                                  borderRadius: "10px",
                                  border: "none",
                                  outline: "none",
                                  padding: 7,
                                }}
                                disabled
                                type="text"
                                // required
                                value={cpf?.monthlyinstalmentamt?.value?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "SGD",
                                  }
                                )}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              width: "42%",
                              display: "flex",
                              marginBottom: 10,
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                color: "rgba(20, 44, 68, 0.8)",
                                fontWeight: 800,
                                fontFamily: "Manrope, sans-serif",
                                fontSize: 14,
                              }}
                            >
                              Total Amount of CPF Allowed For Property:
                            </div>
                            <div style={{ width: "40%" }}>
                              <input
                                style={{
                                  width: "100%",
                                  borderRadius: "10px",
                                  border: "none",
                                  outline: "none",
                                  padding: 7,
                                }}
                                disabled
                                type="text"
                                // required
                                value={cpf?.totalamountofcpfallowedforproperty?.value?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "SGD",
                                  }
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : viewingTab === 1 ? (
                  <div
                    style={{
                      padding: "15px 15px",
                      borderRadius: "8px",
                      width: "100%",
                      background: "rgba(14, 90, 165, 0.1)",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "2px 20px",
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        UEN
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]?.uen?.value
                          }
                        />
                        {/* {uenError && <div style={{ color: 'red' }}>Please fill in the UEN</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Name
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]["entity-name"]
                              ?.value
                          }
                        />
                        {/* {entityNameError && <div style={{ color: 'red' }}>Please fill in the Entity Name</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Type
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"]["entity-type"]
                              ?.value
                          }
                        />
                        {/* {entityTypeError && <div style={{ color: 'red' }}>Please fill in the Entity Type</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Entity Status
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "entity-status"
                            ]?.value
                          }
                        />
                        {/* {entityStatusError && <div style={{ color: 'red' }}>Please fill in the Entity Status</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registered Address
                      </div>
                      <div style={{ width: "60%" }}>
                        <textarea
                          rows="2"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                            resize: "none",
                          }}
                          disabled
                          // required
                          value={
                            liveUserData?.entity?.addresses["addresses-list"][0]
                              ? (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.unit?.value !== undefined
                                  ? `Unit ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.floor?.value !== undefined
                                  ? `Floor ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.block?.value !== undefined
                                  ? `Block ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.street?.value !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.postal?.value !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                  : "") +
                                (liveUserData?.entity?.addresses?.[
                                  "addresses-list"
                                ]?.[0]?.country?.desc !== undefined
                                  ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                  : "")
                              : ""
                          }
                        ></textarea>
                        {/* {registeredAddressEntityError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Registration Date
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="date"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "registration-date"
                            ]?.value
                          }
                        />
                        {/* {registrationDateError && <div style={{ color: 'red' }}>Please fill in the Registered Date</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Primary SSIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "primary-activity"
                            ]?.desc
                          }
                        />
                        {/* {primarySSICError && <div style={{ color: 'red' }}>Please fill in the Primary SSIC</div>} */}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        marginBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "40%",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 14,
                        }}
                      >
                        Secondary SSIC
                      </div>
                      <div style={{ width: "60%" }}>
                        <input
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            border: "none",
                            outline: "none",
                            padding: 7,
                          }}
                          disabled
                          type="text"
                          // required
                          value={
                            liveUserData?.entity["basic-profile"][
                              "secondary-activity"
                            ]?.desc
                          }
                        />
                        {/* {secondarySSICError && <div style={{ color: 'red' }}>Please fill in the Secondary SSIC</div>} */}
                      </div>
                    </div>
                    {liveUserData?.entity?.appointments[
                      "appointments-list"
                    ]?.map((appointment, index) => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "2px 20px",
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Position
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={appointment.position.desc}
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        {/* Similar structure for NRIC, Full Name, and Appointment Date */}
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - NRIC
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"].idno.value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"].uen.value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Full Name
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"][
                                      "person-name"
                                    ].value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"][
                                      "entity-name"
                                    ].value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Appointment Date
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="date"
                              value={appointment["appointment-date"].value}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : viewingTab === 2 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Category
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Employment
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Interest
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Rent
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Tax Clearance
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Trade
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Years of Assessment
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.person?.noahistory?.noas?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.amount?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.category?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.employment?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.interest?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.rent?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.taxclearance?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.trade?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.yearofassessment?.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 3 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Capital Type
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Issued Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Paid Up Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Share Alloted Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.capitals["capitals-list"]?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["capital-type"]?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "issued-capital-amount"
                              ]?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "paid-up-capital-amount"
                              ]?.value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.[
                                "share-allotted-amount"
                              ]?.value?.toLocaleString("en-US")}
                            </td>
                            {/* Add other data cells */}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 4 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Profit Loss After Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Profit Loss Before Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Company Revenue
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Current Period End Date
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Current Period Start Date
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Capital Paid Up Capital Amount
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Profit Loss After Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Profit Loss Before Tax
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Group Revenue
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Is Audited
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.financials?.highlights?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-profit-loss-after-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-profit-loss-before-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["company-revenue"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["current-period-end-date"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["current-period-start-date"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {
                                item?.["group-capital-paid-up-capital-amount"]
                                  ?.value
                              }
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-profit-loss-after-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-profit-loss-before-tax"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["group-revenue"]?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["is-audited"]?.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : viewingTab === 5 ? (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "separate",
                      borderRadius: "10px",
                      overflow: "hidden",
                      padding: "10px",
                      borderSpacing: "0px",
                      background: "#0e5aa51A",
                    }}
                  >
                    <thead
                      style={{
                        borderRadius: "15px 15px 0px 0px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      <tr
                        style={{
                          border: "none",
                          borderRadius: "8px",
                          color: "rgba(20, 44, 68, 0.8)",
                          fontWeight: 800,
                          fontFamily: "Manrope, sans-serif",
                          fontSize: 12,
                          borderRadius: 8,
                        }}
                      >
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Allocation
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Category
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Name
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          NRIC
                        </th>
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Currency
                        </th>
                        {/* <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference NRIC</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Nationality</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Name</th> */}
                        <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Share Type
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveUserData?.entity?.shareholders[
                        "shareholders-list"
                      ] ? (
                        liveUserData?.entity?.shareholders[
                          "shareholders-list"
                        ]?.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.allocation?.value?.toLocaleString("en-US")}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.category?.desc}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {
                                item?.["person-reference"]?.["person-name"]
                                  ?.value
                              }
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["person-reference"]?.idno?.value}
                            </td>
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.currency?.desc}
                            </td>
                            {/* <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].idno.value}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].nationality.desc}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"]["person-name"].value}</td> */}
                            <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item?.["share-type"]?.desc}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              textAlign: "center",
                              padding: "5px 10px",
                            }}
                            colSpan="11"
                          >
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                    <PDFViewer style={{ width: "100%", height: "80vh" }}>
                      <Document>
                        <Page size="A4">
                          {/**Letterhead section */}
                          <View
                            style={{
                              paddingVertical: 15,
                            }}
                          >
                            <View
                              style={{
                                paddingHorizontal: 40,
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: 10,
                                // borderBottom: 1,
                                // borderBottomColor: "grey",
                                paddingBottom: 10,
                              }}
                            >
                              <Image
                                source={logo}
                                style={{ width: 80, objectFit: "contain" }}
                              />
                              <View>
                                <Text
                                  style={{
                                    fontSize: 18,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  SmartLend Financial Pte. Ltd.
                                </Text>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 4,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: -3,
                                  }}
                                >
                                  <Text style={{ fontSize: 7 }}>
                                    UEN: 202327568G
                                  </Text>
                                  <Text style={{ fontSize: 7 }}>
                                    Tel: +65 88288147
                                  </Text>
                                  <Text style={{ fontSize: 7 }}>
                                    Email: support@smart-lend.com
                                  </Text>
                                </View>
                              </View>
                            </View>
                            <View
                              style={{
                                backgroundColor: "lightgrey",
                                height: 2,
                                width: "100%",
                                marginTop: 20,
                              }}
                            />
                            <View
                              style={{
                                backgroundColor: "#2881c4",
                                height: 4,
                                width: "76%",
                                marginTop: -3,
                                marginLeft: "12%",
                                marginBottom: 15,
                              }}
                            />
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Company Profile
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  padding: 8,
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                }}
                              >
                                <View style={{ width: "50%" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Entity Name:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"][
                                          "entity-name"
                                        ]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      UEN:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"]
                                          ?.uen?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Registration Date:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["registration-date"]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Primary Activity:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity["basic-profile"][
                                          "primary-activity"
                                        ]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Ownership:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["ownership"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Entity Status:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["entity-status"]?.value
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Business Constitution:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "65%" }}>
                                      {
                                        singpassData?.entity?.[
                                          "basic-profile"
                                        ]?.["company-type"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{ flexDirection: "row", gap: 10 }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Business Verification By Smartlend:
                                    </Text>
                                    <Text style={{ fontSize: 7 }}>
                                      {badgeVerified
                                        ? "Verified"
                                        : "Un-verified"}
                                    </Text>
                                  </View>
                                </View>
                                <View style={{ width: "50%" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      ACRA Address:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.addresses[
                                        "addresses-list"
                                      ][0]
                                        ? (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.unit?.value !== undefined
                                            ? `Unit ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.floor?.value !== undefined
                                            ? `Floor ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.block?.value !== undefined
                                            ? `Block ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.street?.value !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.postal?.value !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                            : "") +
                                          (singpassData?.entity?.addresses?.[
                                            "addresses-list"
                                          ]?.[0]?.country?.desc !== undefined
                                            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                            : "")
                                        : ""}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Capital Type:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {
                                        singpassData?.entity?.["capitals"]?.[
                                          "capitals-list"
                                        ]?.[0]?.["capital-type"]?.desc
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Paid Up Capital:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "paid-up-capital-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Share Allocated Amount:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "share-allotted-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Issued Capital Amount:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {singpassData?.entity?.["capitals"]?.[
                                        "capitals-list"
                                      ]?.[0]?.[
                                        "issued-capital-amount"
                                      ]?.value?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "SGD",
                                      })}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Keyman Email:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {getShareholderAddress(keyman)?.[0]
                                        ?.person?.email?.value !== undefined
                                        ? getShareholderAddress(keyman)?.[0]
                                            ?.person?.email?.value
                                        : ""}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      gap: 10,
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 7,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Keyman Mobile:
                                    </Text>
                                    <Text style={{ fontSize: 7, width: "60%" }}>
                                      {getShareholderAddress(keyman)?.[0]
                                        ?.person?.mobileno?.nbr?.value !==
                                      undefined
                                        ? getShareholderAddress(keyman)?.[0]
                                            ?.person?.mobileno?.prefix?.value +
                                          getShareholderAddress(keyman)?.[0]
                                            ?.person?.mobileno?.areacode
                                            ?.value +
                                          "-" +
                                          getShareholderAddress(keyman)?.[0]
                                            ?.person?.mobileno?.nbr?.value
                                        : ""}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Financial Highlight
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Revenue</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Profit pre-tax</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Profit post-tax</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Start date</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>End date</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.financials?.highlights?.map(
                                  (data, index) => (
                                    <View
                                      key={index}
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        backgroundColor:
                                          index % 2
                                            ? "rgba(199, 220, 236, 0.2)"
                                            : "transparent",
                                        padding: 4,
                                      }}
                                    >
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-revenue"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-profit-loss-before-tax"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {data?.[
                                            "company-profit-loss-after-tax"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {
                                            data?.["current-period-start-date"]
                                              ?.value
                                          }
                                        </Text>
                                      </View>
                                      <View
                                        style={{ width: "19%", fontSize: 7 }}
                                      >
                                        <Text>
                                          {
                                            data?.["current-period-end-date"]
                                              ?.value
                                          }
                                        </Text>
                                      </View>
                                    </View>
                                  )
                                )}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Grants
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Category</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Functional Area</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Grant Type</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Grant Status</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Approved Amount</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.grants?.[
                                  "grants-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["development-category"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["functional-area"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>{data?.["grant-type"]?.desc}</Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["grant-status"]?.desc}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {data?.[
                                          "approved-amount"
                                        ]?.value?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "SGD",
                                        })}
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Officer(S) / Owners
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "25%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Officer Name</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Identity No.</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Position</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Appointment Date</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Nationality</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.appointments?.[
                                  "appointments-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View style={{ width: "25%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value
                                        }
                                      </Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.["idno"]
                                            ?.value
                                        }
                                      </Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>{data?.["position"]?.desc}</Text>
                                    </View>
                                    <View style={{ width: "13%", fontSize: 7 }}>
                                      <Text>
                                        {data?.["appointment-date"]?.value}
                                      </Text>
                                    </View>
                                    <View style={{ width: "19%", fontSize: 7 }}>
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "nationality"
                                          ]?.desc
                                        }
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                            <View
                              style={{ marginTop: 15, paddingHorizontal: 40 }}
                            >
                              <View
                                style={{
                                  backgroundColor: "#c7dcec",
                                  alignItems: "center",
                                  padding: 6,
                                  borderTopLeftRadius: 7,
                                  borderTopRightRadius: 7,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                  }}
                                >
                                  Shareholders
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderColor: "#c7dcec",
                                  borderLeftWidth: 2,
                                  borderRightWidth: 2,
                                  borderBottomWidth: 2,
                                  width: "100%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "#c7dcec",
                                    opacity: 0.7,
                                    padding: 4,
                                    gap: 10,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "25%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Shareholder Name / Address</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Identity No.</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "18%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Share Type</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "13%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>No of Share</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Nationality</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "15%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Date of birth</Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "19%",
                                      fontSize: 7,
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    <Text>Corppass Contact</Text>
                                  </View>
                                </View>
                                {singpassData?.entity?.shareholders?.[
                                  "shareholders-list"
                                ]?.map((data, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      width: "100%",
                                      backgroundColor:
                                        index % 2
                                          ? "rgba(199, 220, 236, 0.2)"
                                          : "transparent",
                                      padding: 4,
                                      gap: 10,
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "25%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data?.["person-reference"]?.[
                                          "person-name"
                                        ]?.value
                                          ? data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value +
                                            " (" +
                                            getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.sex?.code +
                                            ")"
                                          : data?.["entity-reference"]?.[
                                              "entity-name"
                                            ]?.value + " (Corporate)"}
                                      </Text>
                                      <Text>
                                        {getShareholderAddress(
                                          data?.["person-reference"]?.[
                                            "person-name"
                                          ]?.value
                                        )?.[0]?.person?.regadd
                                          ? (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.unit
                                              ?.value !== null
                                              ? `Unit ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.unit
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.floor
                                              ?.value !== null
                                              ? `Floor ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.floor
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.block
                                              ?.value !== null
                                              ? `Block ${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.block
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.street
                                              ?.value !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.street
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.postal
                                              ?.value !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd?.postal
                                                    ?.value
                                                }, `
                                              : "") +
                                            (getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd?.country
                                              ?.desc !== null
                                              ? `${
                                                  getShareholderAddress(
                                                    data?.[
                                                      "person-reference"
                                                    ]?.["person-name"]?.value
                                                  )?.[0]?.person?.regadd
                                                    ?.country?.desc
                                                }.`
                                              : "")
                                          : ""}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "13%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {data?.["person-reference"]?.["idno"]
                                          ?.value
                                          ? data?.["person-reference"]?.["idno"]
                                              ?.value
                                          : data?.["entity-reference"]?.["uen"]
                                              ?.value + " (UEN)"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "18%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>{data?.["share-type"]?.desc}</Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "13%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>{data?.["allocation"]?.value}</Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "19%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          data?.["person-reference"]?.[
                                            "nationality"
                                          ]?.desc
                                        }
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "15%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.dob?.value
                                        }
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        width: "19%",
                                        fontSize: 7,
                                      }}
                                    >
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.email?.value
                                        }
                                      </Text>
                                      <Text>
                                        {
                                          getShareholderAddress(
                                            data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                          )?.[0]?.person?.mobileno?.nbr?.value
                                        }
                                      </Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                          </View>
                          <View></View>
                        </Page>
                        {combinedSingpassData
                          ? combinedSingpassData.map((data, index) => (
                              <Page key={index} size="A4">
                                {/**Letterhead section */}
                                <View
                                  style={{
                                    paddingVertical: 15,
                                  }}
                                >
                                  <View
                                    style={{
                                      marginTop: 15,
                                      paddingHorizontal: 40,
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: "#c7dcec",
                                        alignItems: "center",
                                        padding: 6,
                                        borderTopLeftRadius: 7,
                                        borderTopRightRadius: 7,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          fontFamily: "Poppins",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {data?.person?.name?.value +
                                          " | Personal Information"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        padding: 8,
                                        borderColor: "#c7dcec",
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                        borderBottomWidth: 2,
                                      }}
                                    >
                                      <View style={{ width: "50%" }}>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Ownership of Private Residential
                                            Property:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                            }}
                                          >
                                            {data?.person?.ownerprivate?.value
                                              ? "Yes"
                                              : "No"}
                                          </Text>
                                        </View>
                                        {data?.person?.hdbownership
                                          ? data?.person?.hdbownership?.length >
                                            0
                                            ? data?.person?.hdbownership?.map(
                                                (hdb, index3) => (
                                                  <View key={index3}>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                        marginTop: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        HDB {index3 + 1}
                                                      </Text>
                                                    </View>

                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        HDB Ownership:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.address
                                                          ? (hdb?.address?.unit
                                                              ?.value !== null
                                                              ? `Unit ${hdb?.address?.unit?.value}, `
                                                              : "") +
                                                            (hdb?.address?.floor
                                                              ?.value !== null
                                                              ? `Floor ${hdb?.address?.floor?.value}, `
                                                              : "") +
                                                            (hdb?.address?.block
                                                              ?.value !== null
                                                              ? `Block ${hdb?.address?.block?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.street
                                                              ?.value !== null
                                                              ? `${hdb?.address?.street?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.postal
                                                              ?.value !== null
                                                              ? `${hdb?.address?.postal?.value}, `
                                                              : "") +
                                                            (hdb?.address
                                                              ?.country
                                                              ?.desc !== null
                                                              ? `${hdb?.address?.country?.desc}.`
                                                              : "")
                                                          : ""}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Outstanding HDB Loan
                                                        Balance:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {hdb?.outstandingloanbalance?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Type of HDB:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.hdbtype?.desc}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Monthly Loan
                                                        Installment:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {hdb?.monthlyloaninstalment?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )
                                              )
                                            : "No"
                                          : "No"}
                                        <View>
                                          {data?.person?.cpfhousingwithdrawal
                                            ?.withdrawaldetails?.length > 0
                                            ? data?.person?.cpfhousingwithdrawal?.withdrawaldetails.map(
                                                (cpf, index) => (
                                                  <View
                                                    style={{ marginTop: 10 }}
                                                    key={index}
                                                  >
                                                    <Text
                                                      style={{
                                                        fontSize: 7,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 700,
                                                      }}
                                                    >
                                                      CPF Usage{" "}
                                                      {(cpf.address.unit
                                                        ?.value !== undefined &&
                                                      cpf.address.unit
                                                        ?.value !== null
                                                        ? `Unit ${cpf.address.unit?.value}, `
                                                        : "") +
                                                        (cpf.address.floor
                                                          ?.value !==
                                                          undefined &&
                                                        cpf.address.floor
                                                          ?.value !== null
                                                          ? `Floor ${cpf.address.floor?.value}, `
                                                          : "") +
                                                        (cpf.address.block
                                                          ?.value !==
                                                          undefined &&
                                                        cpf.address.block
                                                          ?.value !== null
                                                          ? `Block ${cpf.address.block?.value}, `
                                                          : "") +
                                                        (cpf.address.street
                                                          ?.value !== undefined
                                                          ? `${cpf.address.street?.value}, `
                                                          : "") +
                                                        (cpf.address.postal
                                                          ?.value !== undefined
                                                          ? `${cpf.address.postal?.value}, `
                                                          : "") +
                                                        (cpf.address.country
                                                          ?.desc !== undefined
                                                          ? `${cpf.address.country?.desc}.`
                                                          : "")}
                                                      :
                                                    </Text>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Principal Withdrawal
                                                        Amount:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {cpf?.principalwithdrawalamt?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Accrued Interest Amount:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {cpf?.accruedinterestamt?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Monthly Installment
                                                        (CPF):
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {cpf?.monthlyinstalmentamt?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        flexDirection: "row",
                                                        gap: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          fontFamily: "Poppins",
                                                          fontWeight: 700,
                                                        }}
                                                      >
                                                        Total Amount of CPF
                                                        Allowed For Property:
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "30%",
                                                        }}
                                                      >
                                                        {cpf?.totalamountofcpfallowedforproperty?.value?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )
                                              )
                                            : "None"}
                                        </View>
                                      </View>
                                      <View style={{ width: "50%" }}>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Contact:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.mobileno?.nbr?.value}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Email:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.email?.value}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Marital Status:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.marital?.desc}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Vehicles:
                                          </Text>
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              width: "65%",
                                            }}
                                          >
                                            {data?.person?.vehicles?.length > 0
                                              ? data?.person?.vehicles?.map(
                                                  (veh, index4) => (
                                                    <Text
                                                      key={index4}
                                                      style={{
                                                        fontSize: 7,
                                                        width: "65%",
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: 7,
                                                          width: "65%",
                                                        }}
                                                      >
                                                        {veh?.make?.value +
                                                          ": " +
                                                          veh?.model?.value}
                                                      </Text>
                                                    </Text>
                                                  )
                                                )
                                              : "None"}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                  <View
                                    style={{
                                      marginTop: 15,
                                      paddingHorizontal: 40,
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: "#c7dcec",
                                        alignItems: "center",
                                        padding: 6,
                                        borderTopLeftRadius: 7,
                                        borderTopRightRadius: 7,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          fontSize: 10,
                                          fontFamily: "Poppins",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {data?.person?.name?.value +
                                          " | Notice of Assessments"}
                                      </Text>
                                    </View>
                                    <View
                                      style={{
                                        borderColor: "#c7dcec",
                                        borderLeftWidth: 2,
                                        borderRightWidth: 2,
                                        borderBottomWidth: 2,
                                        width: "100%",
                                      }}
                                    >
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          width: "100%",
                                          backgroundColor: "#c7dcec",
                                          opacity: 0.7,
                                          padding: 4,
                                          gap: 10,
                                        }}
                                      >
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Year of Assesment</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Amount</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Employment</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Interest</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Rent</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Trade</Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "14%",
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                          }}
                                        >
                                          <Text>Tax Clearance</Text>
                                        </View>
                                      </View>
                                      {data?.person?.noahistory?.noas?.map(
                                        (data2, index2) => (
                                          <View
                                            key={index2}
                                            style={{
                                              flexDirection: "row",
                                              width: "100%",
                                              backgroundColor:
                                                index2 % 2
                                                  ? "rgba(199, 220, 236, 0.2)"
                                                  : "transparent",
                                              padding: 4,
                                              gap: 10,
                                            }}
                                          >
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {
                                                  data2?.["yearofassessment"]
                                                    ?.value
                                                }
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "amount"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "employment"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "interest"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "rent"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.[
                                                  "trade"
                                                ]?.value?.toLocaleString(
                                                  "en-us",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <Text>
                                                {data2?.["taxclearance"]?.value}
                                              </Text>
                                            </View>
                                          </View>
                                        )
                                      )}
                                    </View>
                                  </View>
                                  {allCBSData?.filter(
                                    (dat2) =>
                                      dat2?.name === data?.person?.name?.value
                                  )?.[0] ? (
                                    <View
                                      style={{
                                        marginTop: 15,
                                        paddingHorizontal: 40,
                                      }}
                                    >
                                      <View
                                        style={{
                                          backgroundColor: "#c7dcec",
                                          alignItems: "center",
                                          padding: 6,
                                          borderTopLeftRadius: 7,
                                          borderTopRightRadius: 7,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 10,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          {data?.person?.name?.value +
                                            " | Credit Bureau | Personal Commitement"}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                        }}
                                      >
                                        <View style={{ width: "50%" }}>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Limit:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.SecuredCreditLimit?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Credit Score:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data?.CreditScore
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Probability of Default:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data
                                                  ?.ProbabilityofDefault
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Utilized:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.UnsecuredCreditUtilized?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Utilization in
                                              percentage:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {(Number(
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data
                                                  ?.UnsecuredCreditUtilizationinpercentage
                                              ) || 0) + "%"}
                                            </Text>
                                          </View>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Unsecured Credit Limit:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.UnsecuredCreditLimit?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Risk Grade:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data?.RiskGrade
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Utilized:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {allCBSData
                                                ?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]
                                                ?.cbs_data?.SecuredCreditUtilized?.toLocaleString(
                                                  "en-US",
                                                  {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  }
                                                )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Secured Credit Utilization in
                                              percentage:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {(Number(
                                                allCBSData?.filter(
                                                  (dat2) =>
                                                    dat2?.name ===
                                                    data?.person?.name?.value
                                                )?.[0]?.cbs_data
                                                  ?.SecuredCreditUtilizationinpercentage
                                              ) || 0) + "%"}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              gap: 10,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              Monthly Commitment/Installment
                                              Secured:
                                            </Text>
                                            <Text
                                              style={{
                                                fontSize: 7,
                                              }}
                                            >
                                              {(
                                                Number(
                                                  allCBSData?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]?.cbs_data
                                                    ?.MonthlyCommitment
                                                ) || 0
                                              )?.toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "SGD",
                                              })}
                                            </Text>
                                          </View>
                                        </View>
                                      </View>
                                      <View
                                        style={{
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                        }}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            width: "100%",
                                            backgroundColor: "#c7dcec",
                                            opacity: 0.7,
                                            padding: 4,
                                            gap: 10,
                                          }}
                                        >
                                          <View
                                            style={{
                                              width: "30%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Loan / Product Type</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "30%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Grantor / Bank</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "20%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Outstanding Balance</Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "20%",
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            <Text>Monthly Installment</Text>
                                          </View>
                                        </View>

                                        {allCBSData
                                          ?.filter(
                                            (dat2) =>
                                              dat2?.name ===
                                              data?.person?.name?.value
                                          )?.[0]
                                          ?.cbs_data?.NoteworthyOngoingLoans?.map(
                                            (imp, index10) => (
                                              <View
                                                key={index10}
                                                style={{
                                                  flexDirection: "row",
                                                  width: "100%",
                                                  backgroundColor:
                                                    index10 % 2
                                                      ? "rgba(199, 220, 236, 0.4)"
                                                      : "rgba(199, 220, 236, 0.15)",
                                                  padding: 4,
                                                  gap: 10,
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    width: "30%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {imp.includes(
                                                      "private residential purchase",
                                                      ""
                                                    )
                                                      ? "Private Residential Purchase"
                                                      : imp.includes(
                                                          "motor vehicle loan",
                                                          ""
                                                        )
                                                      ? "Motor Vehicle Loan"
                                                      : imp.includes(
                                                          "hdb loan",
                                                          ""
                                                        )
                                                      ? "HDB Loan"
                                                      : ""}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "30%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {imp
                                                      .replace(
                                                        "private residential purchase",
                                                        ""
                                                      )
                                                      .replace(
                                                        "motor vehicle loan",
                                                        ""
                                                      )
                                                      .replace("hdb loan", "")
                                                      .trim()
                                                      .split(" ")[0]
                                                      .toUpperCase()}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "20%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {parseFloat(
                                                      imp
                                                        .split("   ")
                                                        [
                                                          imp.split("   ")
                                                            .length - 4
                                                        ].replace(/,/g, "")
                                                    ).toLocaleString("en-US", {
                                                      style: "currency",
                                                      currency: "SGD",
                                                    })}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "20%",
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                  }}
                                                >
                                                  <Text>
                                                    {allCBSData?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]?.cbs_data
                                                      ?.CBSImpLoanSupplementaryInstallment?.[
                                                      index10
                                                    ]
                                                      ? (
                                                          parseFloat(
                                                            allCBSData
                                                              ?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]
                                                              ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                index10
                                                              ].split("   ")
                                                              [
                                                                allCBSData
                                                                  ?.filter(
                                                                    (dat2) =>
                                                                      dat2?.name ===
                                                                      data
                                                                        ?.person
                                                                        ?.name
                                                                        ?.value
                                                                  )?.[0]
                                                                  ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                    index10
                                                                  ].split("   ")
                                                                  .length - 4
                                                              ]?.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                          ) +
                                                          parseFloat(
                                                            allCBSData
                                                              ?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]
                                                              ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                index10
                                                              ].split("   ")
                                                              [
                                                                allCBSData
                                                                  ?.filter(
                                                                    (dat2) =>
                                                                      dat2?.name ===
                                                                      data
                                                                        ?.person
                                                                        ?.name
                                                                        ?.value
                                                                  )?.[0]
                                                                  ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                    index10
                                                                  ].split("   ")
                                                                  .length - 3
                                                              ]?.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                          )
                                                        ).toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )
                                                      : null}
                                                  </Text>
                                                </View>
                                              </View>
                                            )
                                          )}
                                      </View>
                                      <View
                                        style={{
                                          padding: 8,
                                          borderColor: "#c7dcec",
                                          borderLeftWidth: 2,
                                          borderRightWidth: 2,
                                          borderBottomWidth: 2,
                                        }}
                                      >
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            gap: 10,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 7,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            Last 6 Months (Unsecured) Credit
                                            Utilization Graph:
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "100%",
                                            fontSize: 7,
                                          }}
                                        >
                                          <View
                                            style={{ flexDirection: "row" }}
                                          >
                                            <View
                                              style={{
                                                width: "5%",
                                                position: "relative",
                                              }}
                                            >
                                              <View style={{ height: "15px" }}>
                                                <Text
                                                  style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                  }}
                                                >
                                                  {allCBSData
                                                    ?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]
                                                    ?.cbs_data?.Last6MonthsGraphChartMaxData?.toLocaleString(
                                                      "en-US",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                </Text>
                                              </View>
                                              <View
                                                style={{ height: "220px" }}
                                              ></View>
                                            </View>
                                            <View
                                              style={{
                                                width: "95%",
                                              }}
                                            >
                                              <View
                                                style={{
                                                  width: "100%",
                                                  flexDirection: "row",
                                                  marginTop: "25px",
                                                  borderBottom:
                                                    "1px solid lightgrey",
                                                  height: "200px",
                                                  alignItems: "flex-end",
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    height: "200px",
                                                    borderLeft:
                                                      "1px solid lightgrey",
                                                  }}
                                                />
                                                {allCBSData
                                                  ?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]
                                                  ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                    (dat30, index30) => (
                                                      <View
                                                        key={index30}
                                                        style={{
                                                          marginLeft: "4%",
                                                          marginRight: "4%",
                                                          width: "8%",
                                                          borderTopLeftRadius:
                                                            "4px",
                                                          borderTopRightRadius:
                                                            "4px",
                                                          height: `${
                                                            (parseFloat(
                                                              dat30.value.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                            ) /
                                                              allCBSData?.filter(
                                                                (dat2) =>
                                                                  dat2?.name ===
                                                                  data?.person
                                                                    ?.name
                                                                    ?.value
                                                              )?.[0]?.cbs_data
                                                                ?.Last6MonthsGraphChartMaxData) *
                                                            100
                                                          }%`,
                                                          backgroundColor:
                                                            "rgba(217, 238, 255, 1)",
                                                        }}
                                                      ></View>
                                                    )
                                                  )}
                                              </View>
                                              <View
                                                style={{
                                                  marginTop: "8px",
                                                  flexDirection: "row",
                                                  fontSize: 7,
                                                }}
                                              >
                                                {allCBSData
                                                  ?.filter(
                                                    (dat2) =>
                                                      dat2?.name ===
                                                      data?.person?.name?.value
                                                  )?.[0]
                                                  ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                    (dat, index) => (
                                                      <View
                                                        key={index}
                                                        style={{
                                                          width: "16%",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <Text
                                                          style={{
                                                            fontWeight: 600,
                                                            fontSize: 7,
                                                          }}
                                                        >
                                                          {dat.date
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            dat.date.slice(1)}
                                                        </Text>
                                                        <Text
                                                          style={{
                                                            fontWeight: 600,
                                                            fontSize: 7,
                                                          }}
                                                        >
                                                          {parseFloat(
                                                            dat.value.replace(
                                                              /,/g,
                                                              ""
                                                            )
                                                          ).toLocaleString(
                                                            "en-US",
                                                            {
                                                              style: "currency",
                                                              currency: "SGD",
                                                            }
                                                          )}
                                                        </Text>
                                                      </View>
                                                    )
                                                  )}
                                              </View>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  ) : null}
                                </View>
                              </Page>
                            ))
                          : null}
                      </Document>
                    </PDFViewer>
                  </div>
                )
              ) : null}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </Modal>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
        borderRadius="10px"
      >
        <Box
          gridColumn={screenWidth > 1200 ? "span 9" : "span 12"}
          gridRow="span 2"
          borderRadius="10px"
          opacity="0.95"
          paddingBottom="100px"
          style={{
            overflowY: "scroll",
            height: isMobile ? "80vh" : "90vh",
            overflowX: "hidden",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            padding: "15px",
          }}
          // margin="20px 20px 20px 20px"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            borderRadius="10px"
            style={{ marginBottom: "10px" }}
          >
            <Typography
              color={colors.grey[920]}
              variant="h5"
              fontWeight="800"
              fontFamily="Manrope, sans-serif"
              fontSize="18px"
            >
              Requested Loans
            </Typography>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="sort-by"
                  style={{
                    marginRight: "7px",
                    marginLeft: "10px",
                    color: "#142C44CC",
                    fontWeight: "800",
                    fontSize: "13px",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  Sort by:
                </label>
                <div style={{ width: "63%" }}>
                  <select
                    id="sort-by"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    <option value="default">Recommend</option>
                    <option value="latest-request">Latest Request</option>
                  </select>
                </div>
              </div>
            </div>
          </Box>
          <div style={{ display: "flex", flex: "wrap", gap: "10px" }}>
            {allPropLoans
              .filter((loan) => {
                const loanAmount = loan.loan_data?.loanAmount || 0;

                // Filter by loan amount range
                const meetsMinAmount =
                  minLoanAmount === "" || loanAmount >= Number(minLoanAmount);
                const meetsMaxAmount =
                  maxLoanAmount === "" || loanAmount <= Number(maxLoanAmount);
                const meetMaxLtv =
                  maxLtv === "" ||
                  ((loan.loan_data?.oustandingLoan +
                    loan.loan_data?.cpfUsage +
                    loan.loan_data?.accruedInterest) /
                    loan.loan_data?.valuation) *
                    100 <=
                    Number(maxLtv);

                // Filter by loan category
                const meetsCategory =
                  loanCategoryFilters.length === 0 ||
                  loanCategoryFilters.includes(
                    loan.loan_data?.selectedLoanType
                  );

                return (
                  meetsMinAmount &&
                  meetsMaxAmount &&
                  meetsCategory &&
                  meetMaxLtv
                );
              })
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by latest created_at
              .map((item) => (
                <div
                  style={{
                    width: "24%",
                    padding: "10px",
                    borderRadius: "10px",
                    background: "rgba(256,256,256,0.3)",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                    color: "rgb(70,70,70)",
                  }}
                  key={item.id}
                  className="loan-item"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      className="step1s"
                      style={{
                        width: "55px",
                        height: "55px",
                        borderRadius: "50%",
                        background: "rgb(140,140,170)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "white",
                        fontSize: 20,
                        fontWeight: 600,
                        position: "relative",
                      }}
                      onClick={() => {
                        console.log(
                          JSON.parse(item.borrower_data.singpass_data)?.person
                            ?.name?.value
                        );
                        setBadgeVerified(item.borrower_data.badge_verified);
                        const dataToPush = [
                          {
                            name: JSON.parse(item.borrower_data.singpass_data)
                              ?.person?.name?.value,
                            cbs_data: JSON.parse(
                              item.borrower_data.credit_report_data
                            ),
                          },
                        ];
                        setAllCBSData(dataToPush);
                        setDataSingpassModal(true);
                        setLiveUserData(
                          JSON.parse(item.borrower_data.singpass_data)
                        );
                        setCombinedSingpassData([
                          JSON.parse(item.borrower_data.singpass_data),
                        ]);
                        setSingpassData(
                          JSON.parse(item.borrower_data.singpass_data)
                        );
                      }}
                    >
                      {item.borrower_data.principal_name
                        ? getInitials(item.borrower_data.principal_name)
                        : "SL"}
                      {item.borrower_data.badge_verified ? (
                        <div
                          className="tooltip-container"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: "18px",
                            height: "18px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background:
                              item.loan_data.selectedLoanType === 0
                                ? "rgb(14, 90, 165)"
                                : "#ebc313",
                          }}
                        >
                          <LocalPoliceIcon
                            className="tooltip-target"
                            style={{ color: "white", fontSize: "14px" }}
                          />
                          <span className="tooltip-text">
                            Verified borrowers have undergone SmartLend's
                            authentication process, demonstrating legitimate
                            business operations. This includes providing
                            evidence such as current tenancy agreements,
                            business premises signage, or recent invoices
                            matching their bank statements
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <div
                      style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}
                    >
                      <h3
                        style={{
                          fontWeight: "700",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "16px",
                          color: "#142C44CC",
                          textAlign: "left",
                          margin: "0px",
                        }}
                      >
                        {item.borrower_data.principal_name.length > 20
                          ? item.borrower_data.principal_name.slice(0, 20) +
                            "..."
                          : item.borrower_data.principal_name}
                      </h3>
                      <tooltip
                        placement="topRight"
                        title="This application qualifies for our 24-hour service 
      guarantee based on our credit scoring. Be the first to fund them within 24 hours!"
                      >
                        <p
                          style={{
                            fontFamily: "Manrope, sans-serif",
                            fontWeight: "600",
                            fontSize: "11px",
                            color: "rgba(0,0,0,0.4)",
                            textAlign: "left",
                            margin: "0px",
                          }}
                        >
                          {item.borrower_data.badge_verified
                            ? "Verified"
                            : null}
                        </p>
                      </tooltip>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        marginTop: "15px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Property LTV:
                    </div>
                    <div style={{ fontWeight: 600, color: "#142C449A" }}>
                      {(
                        ((item.loan_data?.oustandingLoan +
                          item.loan_data?.cpfUsage +
                          item.loan_data?.accruedInterest) /
                          item.loan_data?.valuation) *
                        100
                      ).toFixed(2)}{" "}
                      %
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Loan Amount:
                    </div>
                    <div style={{ fontWeight: 600, color: "#142C449A" }}>
                      {item.loan_data.loanAmount?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "SGD",
                      })}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Property Valuation:
                    </div>
                    <div style={{ fontWeight: 600, color: "#142C449A" }}>
                      {item.loan_data.valuation?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "SGD",
                      })}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Loan Tenor:
                    </div>
                    <div style={{ fontWeight: 600, color: "#142C449A" }}>
                      {item.loan_data.selectedLoanType === 0
                        ? "25 Years"
                        : "5 Years"}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Interest:
                    </div>
                    <div style={{ fontWeight: 600, color: "#142C449A" }}>
                      {item.loan_data.selectedLoanType === 0
                        ? "10% p.a"
                        : "2.5% per month"}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Loan Payment Type:
                    </div>
                    <div style={{ fontWeight: 600, color: "#142C449A" }}>
                      {item.loan_data.loanPaymentType === "normal"
                        ? "Interest and principal"
                        : "Interest Only"}
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Loan Offers:
                    </div>
                    <div style={{ fontWeight: 600, color: "#142C449A" }}>
                      {item.offers.length + " Offers"}
                    </div>
                    <div style={{ display: "flex", marginTop: "20px" }}>
                      <div
                        className="mui_button"
                        style={{
                          borderRadius: "20px",
                          width: "100%",
                          fontSize: "14px",
                          fontWeight: "700",
                          fontFamily: "Poppins, sans-serif",
                          padding: "4px",
                          background:
                            item.loan_data.selectedLoanType === 0
                              ? "rgb(14, 90, 165)"
                              : "#ebc313",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "10px",
                        }}
                        onClick={() => {
                          setLoanOfferOpenModal(item.id);
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "20px",
                            marginRight: "10px",
                          }}
                        >
                          Make an Offer
                        </div>
                        <div
                          style={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            background: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <NavigateNextIcon
                            style={{
                              color:
                                item.loan_data.selectedLoanType === 0
                                  ? "rgb(14, 90, 165)"
                                  : "#ebc313",
                              fontSize: "24px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "8px" }}>
                      <div
                        className="mui_button"
                        style={{
                          borderRadius: "20px",
                          width: "100%",
                          fontSize: "14px",
                          fontWeight: "700",
                          fontFamily: "Poppins, sans-serif",
                          padding: "4px",
                          background:
                            item.loan_data.selectedLoanType === 0
                              ? "rgb(14, 90, 165)"
                              : "#ebc313",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "10px",
                        }}
                        onClick={() => {}}
                      >
                        <div
                          style={{
                            marginLeft: "20px",
                            marginRight: "10px",
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            const url = `/l-messages?principelname=${item.borrower_data.principal_name}&userid=${item.borrower_data.user_id}&requestamount=${item.loan_data.loanAmount}`;
                            history.push(url);
                          }}
                        >
                          Message Borrower
                        </div>
                        <div
                          style={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            background: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <SendIcon
                            style={{
                              color:
                                item.loan_data.selectedLoanType === 0
                                  ? "rgb(14, 90, 165)"
                                  : "#ebc313",
                              fontSize: "18px",
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Box>
        {screenWidth > 1200 ? (
          <Box
            gridColumn="span 3"
            opacity="1.00"
            display="flex"
            style={{
              height: "90vh",
              padding: "15px",
              background:
                "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <div
              className="step2s"
              style={{
                border: "none",
                margin: "0px",
                padding: "0px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FilterAltIcon
                  style={{ color: "#142C44CC", marginRight: "5px" }}
                />
                <h4
                  style={{
                    color: "#142C44CC",
                    fontWeight: "800",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "18px",
                    margin: "0px",
                  }}
                >
                  Search Filter
                </h4>
              </div>
              <div className="title-loan-request">
                <h5
                  style={{
                    color: "#142C44CC",
                    fontWeight: "700",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "16px",
                    margin: "15px 0px 0px 0px",
                  }}
                >
                  Loan Amount
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(0,0,0,0.4)",
                  margin: "5px 0px 0px 0px",
                }}
              >
                <input
                  type="number"
                  placeholder="MIN"
                  value={minLoanAmount}
                  onChange={(e) => setMinLoanAmount(e.target.value)}
                  style={{
                    border: "none",
                    outline: "1px solid lightgrey",
                    borderRadius: "5px",
                    textAlign: "center",
                    margin: "0px 10px 0px 0px",
                    width: "40%",
                  }}
                />
                -
                <input
                  type="number"
                  placeholder="MAX"
                  value={maxLoanAmount}
                  onChange={(e) => setMaxLoanAmount(e.target.value)}
                  style={{
                    border: "none",
                    outline: "1px solid lightgrey",
                    borderRadius: "5px",
                    textAlign: "center",
                    margin: "0px 0px 0px 10px",
                    width: "40%",
                  }}
                />
              </div>
              <div className="title-loan-request">
                <h5
                  style={{
                    color: "#142C44CC",
                    fontWeight: "700",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "16px",
                    margin: "15px 0px 0px 0px",
                  }}
                >
                  Maximum LTV
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(0,0,0,0.4)",
                  margin: "5px 0px 0px 0px",
                }}
              >
                <input
                  type="number"
                  placeholder="MAX"
                  value={maxLtv}
                  onChange={(e) => setMaxLtv(e.target.value)}
                  style={{
                    border: "none",
                    outline: "1px solid lightgrey",
                    borderRadius: "5px",
                    textAlign: "center",
                    margin: "0px 0px 0px 0px",
                    width: "40%",
                    marginRight: "10px",
                  }}
                />{" "}
                %
              </div>
              <div className="title-loan-request">
                <h5
                  style={{
                    color: "#142C44CC",
                    fontWeight: "700",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "16px",
                    margin: "15px 0px 0px 0px",
                  }}
                >
                  Loan Category
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "0px",
                  alignItems: "center",
                  margin: "10px 0px 0px 0px",
                }}
              >
                <input
                  type="checkbox"
                  id="checkbox9"
                  onChange={(e) => handleCheckboxChange(e.target.checked, 0)}
                  checked={loanCategoryFilters.includes(0)}
                  style={{ margin: "0px 10px 0px 0px" }}
                />
                <label
                  htmlFor="checkbox9"
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(0,0,0,0.4)",
                    textAlign: "left",
                  }}
                >
                  Equity Term Loan
                </label>{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "0px",
                  alignItems: "center",
                  margin: "10px 0px 0px 0px",
                }}
              >
                <input
                  type="checkbox"
                  id="checkbox9"
                  onChange={(e) => handleCheckboxChange(e.target.checked, 1)}
                  checked={loanCategoryFilters.includes(1)}
                  style={{ margin: "0px 10px 0px 0px" }}
                />
                <label
                  htmlFor="checkbox9"
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(0,0,0,0.4)",
                    textAlign: "left",
                  }}
                >
                  Bridging Loan
                </label>{" "}
              </div>
              <div style={{ marginTop: "15px" }}>
                <button
                  className="step3s"
                  style={{
                    background: "rgb(14, 90, 165)",
                    borderRadius: "30px",
                    padding: "7px 35px",
                    border: "none",
                    color: "white",
                    fontWeight: 600,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setMinLoanAmount("");
                    setMaxLoanAmount("");
                    setMaxLtv("");
                    setLoanCategoryFilters([0, 1]);
                  }}
                >
                  Reset filter
                </button>
              </div>
            </div>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default LPropLoan;
