import { Checkbox } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { MobileStepper } from "@mui/material";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

function ConfirmPassword() {
  const [password, setPassword] = useState("");
  const [password_confirmation, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [screenWidth, setScreenWidth] = useState("1920");
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (token) {
      // Set token in cookies
      Cookies.set("token", token, { expires: 365 }); // Expires in 7 days, adjust as needed

      // Remove token from URL
      const { pathname } = location;
      const newUrl = pathname + location.hash;
      history.replace(newUrl);
    }
  }, [location, history]);

  // const token = sessionStorage.getItem("token");
  const handleConfirmPassword = async (event) => {
    event.preventDefault();

    if (password !== password_confirmation) {
      setError("Passwords do not match");
    } else {
      setIsLoadingScreen(true);
      setError("");
      console.log(`Bearer ${Cookies.get("token")}`);

      try {
        const formData = new FormData();
        formData.append("password", password);
        formData.append("password_confirmation", password_confirmation);

        const dataTopush = {
          password: password,
          password_confirmation: password_confirmation,
        };
        const response = await fetch(
          "https://www.smart-lend.com/api/register/password",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
            body: JSON.stringify(dataTopush),
          }
        );

        // Handle the response as needed
        const json = await response.json();
        console.log(json);

        if (response.status === 200) {
          history.push("/auth/confirm-email");
        } else {
          setError("An error occurred. Please try again later.");
          setIsLoadingScreen(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoadingScreen(false);
      }
    }
  };

  useEffect(() => {
    if (isConfirmed) {
      // Show success message
    } else if (error) {
      // Show error message
    }
  }, [isConfirmed, error]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isLoadingScreen ? (
        <div
          style={{
            background: "rgba(256,256,256,0.4)",
            height: window.innerHeight,
            width: window.innerWidth,
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2000,
          }}
        >
          <CircularProgress size={"5rem"} />
        </div>
      ) : null}
      {screenWidth > 1200 ? (
        <React.Fragment>
          <div className="auth-header">
            <h3 className="auth-header-title-password">Welcome on board!</h3>
            <p className="auth-header-subtitle-password">
              Please insert your password
            </p>
          </div>
          <div className="auth-body">
            <form className="auth-form-validation">
              <div className="input-field">
                <label htmlFor="password" className="input-label">
                  Password
                </label>
                <input
                  type={visible ? "text" : "password"}
                  // name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  // id="password"
                  className="input-control"
                  placeholder=" Password"
                  autoComplete="off"
                  required
                  suffix={
                    <span style={{ position: "absolute", right: 5, bottom: 8 }}>
                      {visible ? (
                        <EyeTwoTone onClick={() => setVisible(false)} />
                      ) : (
                        <EyeInvisibleOutlined
                          onClick={() => setVisible(true)}
                        />
                      )}
                    </span>
                  }
                />
              </div>
              <div className="input-field">
                <label htmlFor="password" className="input-label">
                  Re-enter Password
                </label>
                <input
                  type={visible ? "text" : "password"}
                  // name="password"
                  // id="confirmPassword"
                  value={password_confirmation}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="input-control"
                  placeholder=" Re-enter Password"
                  autoComplete="off"
                  required
                  suffix={
                    <span style={{ position: "absolute", right: 5, bottom: 8 }}>
                      {visible2 ? (
                        <EyeTwoTone onClick={() => setVisible2(false)} />
                      ) : (
                        <EyeInvisibleOutlined
                          onClick={() => setVisible2(true)}
                        />
                      )}
                    </span>
                  }
                />
              </div>
              <div className="btn-position">
                <button
                  type="submit"
                  className={checkbox ? "btn-submit" : "disabled-button"}
                  onClick={handleConfirmPassword}
                  style={{ width: "100%" }}
                  disabled={!checkbox}
                >
                  Confirm Password
                </button>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </div>
            </form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="flex-end-password">
                <Checkbox
                  checked={checkbox}
                  onChange={(e) => setCheckbox(e.target.checked)}
                >
                  I agree to all the{" "}
                </Checkbox>
                <Link
                  to={"/auth/contact-us"}
                  className="link-text-center-password"
                >
                  Terms Condition & Policy
                </Link>
              </div>
              <div style={{ width: 10 }}>
                <MobileStepper
                  variant="dots"
                  steps={6}
                  position="static"
                  activeStep={3}
                  sx={{ maxWidth: 400, flexGrow: 1, marginLeft: -9.5 }}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              width: "95%",
              marginLeft: "10px",
            }}
          >
            <div className="auth-header">
              <h3 className="auth-header-title-password">Welcome on board!</h3>
              <p className="auth-header-subtitle-password">
                Please insert your password
              </p>
            </div>
            <div className="auth-body">
              <form className="auth-form-validation">
                <div className="input-field">
                  <label htmlFor="password" className="input-label">
                    Password
                  </label>
                  <input
                    type={visible ? "text" : "password"}
                    // name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    // id="password"
                    className="input-control"
                    placeholder=" Password"
                    autoComplete="off"
                    required
                    suffix={
                      <span
                        style={{ position: "absolute", right: 5, bottom: 8 }}
                      >
                        {visible ? (
                          <EyeTwoTone onClick={() => setVisible(false)} />
                        ) : (
                          <EyeInvisibleOutlined
                            onClick={() => setVisible(true)}
                          />
                        )}
                      </span>
                    }
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="password" className="input-label">
                    Re-enter Password
                  </label>
                  <input
                    type={visible ? "text" : "password"}
                    // name="password"
                    // id="confirmPassword"
                    value={password_confirmation}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="input-control"
                    placeholder=" Re-enter Password"
                    autoComplete="off"
                    required
                    suffix={
                      <span
                        style={{ position: "absolute", right: 5, bottom: 8 }}
                      >
                        {visible2 ? (
                          <EyeTwoTone onClick={() => setVisible2(false)} />
                        ) : (
                          <EyeInvisibleOutlined
                            onClick={() => setVisible2(true)}
                          />
                        )}
                      </span>
                    }
                  />
                </div>
                <div className="btn-position">
                  <button
                    type="submit"
                    className={checkbox ? "btn-submit" : "disabled-button"}
                    onClick={handleConfirmPassword}
                    disabled={!checkbox}
                  >
                    Confirm Password
                  </button>
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
              </form>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Checkbox
                    checked={checkbox}
                    onChange={(e) => setCheckbox(e.target.checked)}
                  >
                    I agree to all the{" "}
                  </Checkbox>
                  <Link
                    to={"/auth/contact-us"}
                    className="link-text-center-password"
                  >
                    Terms Condition & Policy
                  </Link>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                }}
              >
                <MobileStepper
                  variant="dots"
                  steps={6}
                  position="static"
                  activeStep={3}
                  sx={{ maxWidth: 400 }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ConfirmPassword;
