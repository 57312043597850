import React from "react";
import bgImg from "../assets/img/login_background_latest_2.png";

const Verifylayout = ({ children }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: window.innerHeight,
        position: "relative",
      }}
    >
      <img
        src={bgImg}
        style={{
          width: "52%",
          height: "100%",
          zIndex: 0,
          objectFit: "cover",
          position: "absolute",
          right: 0,
        }}
      />
      <div
        className="verify-box"
        style={{ zIndex: 5, position: "absolute", left: 0 }}
      >
        {children}
      </div>
    </div>
  );
};

export default Verifylayout;
