import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import TableAdminLoanRequest from "../../components/TableAdminLoanRequest";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import warn_ico from "../../assets/img/exclamation.png";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Avatar,
  Upload,
  Card,
  Tabs,
  notification,
  DatePicker,
  Space,
  InputNumber,
  Modal,
  Button,
} from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { Collapse, message } from "antd";
import { format } from "date-fns";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import logo from "../../assets/img/SL-01.png";
import boldFont from "../../assets/font/Poppins-SemiBold.ttf";

Font.register({
  family: "Poppins",
  fonts: [
    { src: boldFont, fontWeight: 700 }, // Bold (700)
  ],
});

const RequestedLoan = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [channel, setChannel] = useState("system");
  const [data, setData] = useState([]);
  const [subject, setSubject] = useState("");
  const { TextArea } = Input;
  const [selectedBorrowerIds, setselectedBorrowerIds] = useState([]);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setConfirmPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [error, setError] = useState("");
  const [screenWidth, setScreenWidth] = useState("1920");
  const [removeLoan, setRemoveLoan] = useState(1);

  const handleRowClick = async (record) => {
    const loan_id = record.loan_id;
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/loanRequest/viewLoanRequestDetail?loan_id=${loan_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const data = await response.json();

      const parsedData = data["Loan Detail"].map((item) => {
        const updatedItem = {
          ...item,
          credit_report_data: JSON.parse(item.credit_report_data),
        };

        // Parse credit_report_data in loan_authorizations array
        const updatedLoanAuthorizations = item.loan_authorizations.map(
          (authorization) => ({
            ...authorization,
            credit_report_data: JSON.parse(authorization.credit_report_data),
          })
        );

        // Update the item with the updated loan_authorizations array
        updatedItem.loan_authorizations = updatedLoanAuthorizations;

        return updatedItem;
      });

      console.log(parsedData);
      setData(parsedData);
    } catch (error) {
      console.error("Error retrieving Loan Detail Data:", error);
    }
  };

  const handleAdminRemoval = async ({ loan_id, boolean }) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/adminRemoveLoanRequest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({ loan_id: loan_id, admin_removal: boolean }),
        }
      );
      const jsonData = await response.json();

      if (response.status === 200) {
        if (boolean) {
          message.success("Admin removal Successful!");
          const updatingLoan = [...data];
          updatingLoan[0].admin_removal = boolean;
          setData(updatingLoan);
        } else {
          message.success("Loan back in market!");
          const updatingLoan = [...data];
          updatingLoan[0].admin_removal = boolean;
          setData(updatingLoan);
        }
      }
    } catch (error) {
      console.error("Error retrieving Loan Detail Data:", error);
    }
  };

  const handleLoanDelete = async (loan_id) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/adminDeleteLoanRequest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({ loan_id: loan_id }),
        }
      );
      const jsonData = await response.json();

      if (response.status === 200) {
        message.success("Loan deleted Successful!");
        let updatingLoanRemoved = removeLoan;
        updatingLoanRemoved += 1;
        setRemoveLoan(updatingLoanRemoved);
        setData([]);
      }
    } catch (error) {
      console.error("Error retrieving Loan Detail Data:", error);
    }
  };

  const handleSelectionChange = (selectedBorrowerIds) => {
    console.log("Selected loan IDs:", selectedBorrowerIds);
    setselectedBorrowerIds(selectedBorrowerIds);
  };

  const formatDate = (dateString) => {
    try {
      if (!dateString) throw new Error("No date string provided");

      const date = new Date(dateString);
      if (isNaN(date.getTime())) throw new Error("Invalid date string");

      const formattedDate = format(date, "dd MMMM yyyy");

      return formattedDate;
    } catch (error) {
      console.error(error);
      return "-";
    }
  };

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    } else if (user_account_type === "borrower") {
      history.push("/b-dashboard");
    }
  }, [history]);

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 16,
    },
    vertical: true,
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const tailLayout = {
    wrapperCol: {
      offset: 9,
      span: 24,
    },
  };

  const [dataSingpassModal, setDataSingpassModal] = useState(false);
  const [needOtherAuthoriser, setNeedOtherAuthoriser] = useState(false);
  const [otherAuthoriserDetails, setOtherAuthoriserDetails] = useState(null);
  const [openDataIndex, setOpenDataIndex] = useState(null);
  const [keyman, setKeyman] = useState("");
  const [viewingUser, setViewingUser] = useState(0);
  const [viewingTab, setViewingTab] = useState(0);
  const [liveUserData, setLiveUserData] = useState(null);
  const [singpassData, setSingpassData] = useState(null);
  const [combinedSingpassData, setCombinedSingpassData] = useState([]);
  const [allCBSData, setAllCBSData] = useState([]);
  const [badgeVerified, setBadgeVerified] = useState(false);
  const [keymanDetails, setKeymanDetails] = useState({
    contact: "",
    email: "",
  });

  const availableTab = [
    { name: "Personal Information" },
    { name: "Corporate Information" },
    { name: "NOA History" },
    { name: "Capitals" },
    { name: "Financials" },
    { name: "Shareholders" },
    { name: "Borrower Report" },
  ];
  const singpassDetails = async (
    user,
    other_authorization,
    loan_id,
    keyman
  ) => {
    try {
      setKeyman(keyman);
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getBorrowerSingppass?user_id=${user}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      console.log(json);
      let allSingpassData = [json.singpass_data];
      setDataSingpassModal(true);
      if (other_authorization) {
        console.log("need other authorizer");
        setNeedOtherAuthoriser(true);
        try {
          const response2 = await fetch(
            `https://www.smart-lend.com/api/loanDetails/getLoanAuthorisers`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
              body: JSON.stringify({ loan_id: loan_id }),
            }
          );

          const json2 = await response2.json();
          setOtherAuthoriserDetails(json2.authorisers);
          console.log(JSON.parse(json2.authorisers[0].singpass_data));
          console.log(json.singpass_data);
          setLiveUserData(json.singpass_data);
          console.log(json2.authorisers);

          for (const single of json2.authorisers) {
            allSingpassData.push(JSON.parse(single.singpass_data));
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.log("Doesnt need other authoriser");
        setLiveUserData(json.singpass_data);
        setNeedOtherAuthoriser(false);
        setOtherAuthoriserDetails(null);
      }
      if (json.singpass_data) {
        setSingpassData(json.singpass_data);
        console.log(allSingpassData);
        setCombinedSingpassData(allSingpassData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getShareholderAddress = (data) => {
    const allSingpassData = [singpassData];
    if (otherAuthoriserDetails !== null && otherAuthoriserDetails.length > 0) {
      for (const single of otherAuthoriserDetails) {
        allSingpassData.push(JSON.parse(single.singpass_data));
      }
      const toPush = allSingpassData.filter(
        (sd) => sd?.person?.name?.value === data
      );
      // console.log(toPush);
      return toPush;
    } else {
      return allSingpassData;
    }
  };

  const getInitials = (name) => {
    const textToSplit = name.split(" ");
    const initials =
      textToSplit?.[0]?.[0].toUpperCase() +
      (textToSplit?.[1]?.[0].toUpperCase() || "");
    return initials;
  };

  const ContainerActivity = ({ item, icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "transparent",
        height: "60px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", color: "grey" }}>
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            background: "rgb(140,140,170)",
            marginLeft: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            color: "white",
            fontSize: 20,
            fontWeight: 600,
            cursor: "pointer",
          }}
          onClick={() => {
            console.log(item);
            setBadgeVerified(item.badge_verified);
            const dataToPush = [
              {
                name: JSON.parse(item.singpass_data)?.person?.name?.value,
                cbs_data: item.credit_report_data,
              },
            ];
            for (const dat of item.loan_authorizations) {
              dataToPush.push({
                name: dat.director_name,
                cbs_data: dat.credit_report_data,
              });
            }
            console.log(item);
            setAllCBSData(dataToPush);
            singpassDetails(
              item.user_id,
              item.authorization_required,
              item.loan_id,
              item.keyman
            );
          }}
        >
          {title ? getInitials(title) : "SL"}
        </div>
        <div
          style={{
            height: "60px",
            width: "calc(100% - 60px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "5px",
          }}
        >
          <h3
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
              fontSize: "18px",
              textAlign: "left",
              color: "rgba(20, 44, 68, 0.8)",
              margin: "0px 0px 0px 0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              textAlign: "left",
              margin: "0px 0px 0px 0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const ContainerTransaction = ({ title, label }) => (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "12px",
      }}
    >
      <div style={{ flex: 1, textAlign: "left", marginLeft: "0px" }}>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            margin: "0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            textAlign: "left",
            color: "rgba(20, 44, 68, 0.8)",
            margin: "0px",
          }}
        >
          {label}
        </p>
      </div>
    </div>
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {screenWidth > 1200 ? (
        <Box m="4px 20px 20px 20px">
          <Modal
            width={"80%"}
            style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
            open={dataSingpassModal}
            onCancel={() => {
              setDataSingpassModal(false);
              setSingpassData(null);
              setOpenDataIndex(null);
              setKeyman("");
            }}
            footer={null}
          >
            {singpassData ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "20px",
                    color: "rgba(20, 44, 68, 0.8)",
                    fontWeight: 900,
                    fontFamily: "Manrope, sans-serif",
                    fontSize: 20,
                  }}
                >
                  <h2> Borrower information</h2>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      background: "rgba(14, 90, 165, 0.1)",
                      color: "rgba(20, 44, 68, 0.8)",
                      fontWeight: 800,
                      fontFamily: "Manrope, sans-serif",
                      fontSize: 16,
                      padding: "3px 15px",
                      borderRadius: 7,
                    }}
                  >
                    Loan keyman: {keyman ? keyman : ""}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    borderTop: "1px solid lightgrey",
                    borderBottom: "1px solid lightgrey",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: 25,
                    gap: "20px",
                    padding: "10px 0px",
                    overflowX: "hidden",
                  }}
                >
                  <div
                    className="all_button"
                    style={{
                      cursor: "pointer",
                      color:
                        viewingUser === 0
                          ? "rgba(20, 44, 68, 0.8)"
                          : "rgba(0,0,0,0.4)",
                      fontSize: viewingUser === 0 ? "14px" : "12px",
                      fontWeight: viewingUser === 0 ? "800" : "400",
                      transition: "0.3s all ease-in-out",
                    }}
                    onClick={() => {
                      setViewingUser(0);
                      console.log(singpassData);
                      setLiveUserData(singpassData);
                    }}
                  >
                    {singpassData?.person?.name?.value !== undefined
                      ? singpassData?.person?.name?.value
                      : null}
                  </div>

                  {otherAuthoriserDetails
                    ? otherAuthoriserDetails.map((aut, index) => (
                        <div
                          className="all_button"
                          style={{
                            cursor: "pointer",
                            color:
                              viewingUser === index + 1
                                ? "rgba(20, 44, 68, 0.8)"
                                : "rgba(0,0,0,0.4)",
                            fontSize:
                              viewingUser === index + 1 ? "14px" : "12px",
                            fontWeight:
                              viewingUser === index + 1 ? "800" : "400",
                            transition: "0.3s all ease-in-out",
                          }}
                          onClick={() => {
                            setViewingUser(index + 1);
                            console.log(JSON.parse(aut.singpass_data));
                            setLiveUserData(JSON.parse(aut.singpass_data));
                          }}
                          key={index}
                        >
                          {aut.director_name}
                        </div>
                      ))
                    : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    borderBottom: "1px solid lightgrey",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "20px",
                    padding: "10px 0px",
                    width: "100%",
                    overflowX: "hidden",
                  }}
                >
                  {availableTab.map((view, index) => (
                    <div
                      onClick={() => {
                        setViewingTab(index);
                      }}
                      className="all_button"
                      style={{
                        cursor: "pointer",
                        color:
                          viewingTab === index
                            ? "rgba(20, 44, 68, 0.8)"
                            : "rgba(0,0,0,0.4)",
                        fontSize: viewingTab === index ? "13px" : "12px",
                        fontWeight: viewingTab === index ? "700" : "400",
                        transition: "0.3s all ease-in-out",
                      }}
                      key={index}
                    >
                      {view.name}
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    padding: "15px 0px",
                    overflowX: "hidden",
                  }}
                >
                  {liveUserData ? (
                    viewingTab === 0 ? (
                      <div
                        style={{
                          padding: "15px 15px",
                          borderRadius: "8px",
                          width: "100%",
                          background: "rgba(14, 90, 165, 0.1)",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "2px 20px",
                        }}
                      >
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            NRIC
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              min={0}
                              // className={nricError ? "error-border" : ""}
                              value={liveUserData?.person?.uinfin?.value}
                            />
                            {/* {nricError && <div style={{ color: 'red' }}>Please fill in the NRIC</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Full Name
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              value={liveUserData?.person?.name?.value}
                              // required
                            />
                            {/* {fullNameError && <div style={{ color: 'red' }}>Please fill in the Full Name</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Sex
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={liveUserData?.person?.sex?.desc}
                              // required
                            />
                            {/* {sexError && <div style={{ color: 'red' }}>Please fill in the Sex</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Race
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={liveUserData?.person?.race?.desc}
                            />
                            {/* {raceError && <div style={{ color: 'red' }}>Please fill in the Race</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Nationality
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={liveUserData?.person?.nationality?.desc}
                            />
                            {/* {nationalityError && <div style={{ color: 'red' }}>Please fill in the Nationality</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Date of Birth
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="date"
                              // required
                              value={liveUserData?.person?.dob?.value}
                            />
                            {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Country of Birth
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={liveUserData?.person?.birthcountry?.desc}
                            />
                            {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Residential Status
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={
                                liveUserData?.person?.residentialstatus?.desc
                              }
                            />
                            {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Pass type
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={liveUserData?.person?.passtype?.desc}
                            />
                            {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Pass Status
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={liveUserData?.person?.passstatus?.value}
                            />
                            {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Pass Expiry Date
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="date"
                              // required
                              value={
                                liveUserData?.person?.passexpirydate?.value
                              }
                            />
                            {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Ownership of Private Property Status
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={liveUserData?.person?.ownerprivate?.value}
                            />
                            {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Email
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              type="email"
                              disabled
                              // required
                              value={liveUserData?.person?.email?.value}
                            />
                            {/* {emailError && <div style={{ color: 'red' }}>Please fill in the Email</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Mobile Number
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="number"
                              // required
                              value={liveUserData?.person?.mobileno?.nbr?.value}
                            />
                            {/* {mobileNoError && <div style={{ color: 'red' }}>Please fill in the Mobile Number</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Registered Address
                          </div>
                          <div style={{ width: "60%" }}>
                            <textarea
                              rows="2"
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              // required
                              value={
                                liveUserData?.person?.regadd
                                  ? (liveUserData?.person?.regadd?.unit
                                      ?.value !== null
                                      ? `Unit ${liveUserData?.person?.regadd?.unit?.value}, `
                                      : "") +
                                    (liveUserData?.person?.regadd?.floor
                                      ?.value !== null
                                      ? `Floor ${liveUserData?.person?.regadd?.floor?.value}, `
                                      : "") +
                                    (liveUserData?.person?.regadd?.block
                                      ?.value !== null
                                      ? `Block ${liveUserData?.person?.regadd?.block?.value}, `
                                      : "") +
                                    (liveUserData?.person?.regadd?.street
                                      ?.value !== null
                                      ? `${liveUserData?.person?.regadd?.street?.value}, `
                                      : "") +
                                    (liveUserData?.person?.regadd?.postal
                                      ?.value !== null
                                      ? `${liveUserData?.person?.regadd?.postal?.value}, `
                                      : "") +
                                    (liveUserData?.person?.regadd?.country
                                      ?.desc !== null
                                      ? `${liveUserData?.person?.regadd?.country?.desc}.`
                                      : "")
                                  : ""
                              }
                            ></textarea>
                            {/* {registeredAddressError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Housing Type
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={liveUserData?.person?.housingtype?.desc}
                            />
                            {/* {housingTypeError && <div style={{ color: 'red' }}>Please fill in the Housing Type</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Marital Status
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={liveUserData?.person?.marital?.desc}
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Occupation
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={liveUserData?.person?.occupation?.value}
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Employer's name
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={""}
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Employment Sector
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={""}
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            HDB Type
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={liveUserData?.person?.hdbtype?.desc}
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Housing Type
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={liveUserData?.person?.housingtype?.desc}
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        {liveUserData?.person?.hdbownership?.map(
                          (hdb, index) => (
                            <div
                              key={index}
                              style={{
                                width: "83.5%",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                style={{
                                  width: "50%",
                                  display: "flex",
                                  marginBottom: 10,
                                }}
                              >
                                <div
                                  style={{
                                    width: "40%",
                                    color: "rgba(20, 44, 68, 0.8)",
                                    fontWeight: 800,
                                    fontFamily: "Manrope, sans-serif",
                                    fontSize: 14,
                                  }}
                                >
                                  HDB Ownership {index + 1} - Address
                                </div>
                                <div style={{ width: "60%" }}>
                                  <input
                                    style={{
                                      width: "100%",
                                      borderRadius: "10px",
                                      border: "none",
                                      outline: "none",
                                      padding: 7,
                                    }}
                                    disabled
                                    type="text"
                                    // required
                                    value={
                                      hdb?.address
                                        ? (hdb?.address?.unit?.value !== null
                                            ? `Unit ${hdb?.address?.unit?.value}, `
                                            : "") +
                                          (hdb?.address?.floor?.value !== null
                                            ? `Floor ${hdb?.address?.floor?.value}, `
                                            : "") +
                                          (hdb?.address?.block?.value !== null
                                            ? `Block ${hdb?.address?.block?.value}, `
                                            : "") +
                                          (hdb?.address?.street?.value !== null
                                            ? `${hdb?.address?.street?.value}, `
                                            : "") +
                                          (hdb?.address?.postal?.value !== null
                                            ? `${hdb?.address?.postal?.value}, `
                                            : "") +
                                          (hdb?.address?.country?.desc !== null
                                            ? `${hdb?.address?.country?.desc}.`
                                            : "")
                                        : ""
                                    }
                                  />
                                  {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  display: "flex",
                                  marginBottom: 10,
                                }}
                              >
                                <div
                                  style={{
                                    width: "40%",
                                    color: "rgba(20, 44, 68, 0.8)",
                                    fontWeight: 800,
                                    fontFamily: "Manrope, sans-serif",
                                    fontSize: 14,
                                  }}
                                >
                                  HDB Ownership {index + 1} - Outstanding HDB
                                  Loan Balance
                                </div>
                                <div style={{ width: "60%" }}>
                                  <input
                                    style={{
                                      width: "100%",
                                      borderRadius: "10px",
                                      border: "none",
                                      outline: "none",
                                      padding: 7,
                                    }}
                                    disabled
                                    type="text"
                                    // required
                                    value={hdb?.outstandingloanbalance?.value?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "SGD",
                                      }
                                    )}
                                  />
                                  {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  display: "flex",
                                  marginBottom: 10,
                                }}
                              >
                                <div
                                  style={{
                                    width: "40%",
                                    color: "rgba(20, 44, 68, 0.8)",
                                    fontWeight: 800,
                                    fontFamily: "Manrope, sans-serif",
                                    fontSize: 14,
                                  }}
                                >
                                  HDB Ownership {index + 1} - Type of HDB
                                  Dwelling
                                </div>
                                <div style={{ width: "60%" }}>
                                  <input
                                    style={{
                                      width: "100%",
                                      borderRadius: "10px",
                                      border: "none",
                                      outline: "none",
                                      padding: 7,
                                    }}
                                    disabled
                                    type="text"
                                    // required
                                    value={hdb?.hdbtype?.desc}
                                  />
                                  {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  display: "flex",
                                  marginBottom: 10,
                                }}
                              >
                                <div
                                  style={{
                                    width: "40%",
                                    color: "rgba(20, 44, 68, 0.8)",
                                    fontWeight: 800,
                                    fontFamily: "Manrope, sans-serif",
                                    fontSize: 14,
                                  }}
                                >
                                  HDB Ownership {index + 1} - Monthly Loan
                                  Installment
                                </div>
                                <div style={{ width: "60%" }}>
                                  <input
                                    style={{
                                      width: "100%",
                                      borderRadius: "10px",
                                      border: "none",
                                      outline: "none",
                                      padding: 7,
                                    }}
                                    disabled
                                    type="text"
                                    // required
                                    value={hdb?.monthlyloaninstalment?.value?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "SGD",
                                      }
                                    )}
                                  />
                                  {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Vehicles - Vehicles Model
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={
                                liveUserData?.person?.vehicles[0]?.model?.value
                              }
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Vehicles - Vehicles Make
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={
                                liveUserData?.person?.vehicles[0]?.make?.value
                              }
                            />
                            {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                          </div>
                        </div>
                        {liveUserData?.person?.cpfhousingwithdrawal?.withdrawaldetails?.map(
                          (cpf, index) => (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexWrap: "wrap",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                CPF Usage{" "}
                                {(cpf.address.unit?.value !== undefined
                                  ? `Unit ${cpf.address.unit?.value}, `
                                  : "") +
                                  (cpf.address.floor?.value !== undefined
                                    ? `Floor ${cpf.address.floor?.value}, `
                                    : "") +
                                  (cpf.address.block?.value !== undefined
                                    ? `Block ${cpf.address.block?.value}, `
                                    : "") +
                                  (cpf.address.street?.value !== undefined
                                    ? `${cpf.address.street?.value}, `
                                    : "") +
                                  (cpf.address.postal?.value !== undefined
                                    ? `${cpf.address.postal?.value}, `
                                    : "") +
                                  (cpf.address.country?.desc !== undefined
                                    ? `${cpf.address.country?.desc}.`
                                    : "")}
                                :
                              </div>
                              <div
                                style={{
                                  width: "42%",
                                  display: "flex",
                                  marginBottom: 10,
                                }}
                              >
                                <div
                                  style={{
                                    width: "60%",
                                    color: "rgba(20, 44, 68, 0.8)",
                                    fontWeight: 800,
                                    fontFamily: "Manrope, sans-serif",
                                    fontSize: 14,
                                  }}
                                >
                                  Principal Withdrawal Amount:
                                </div>
                                <div style={{ width: "40%" }}>
                                  <input
                                    style={{
                                      width: "100%",
                                      borderRadius: "10px",
                                      border: "none",
                                      outline: "none",
                                      padding: 7,
                                    }}
                                    disabled
                                    type="text"
                                    // required
                                    value={cpf?.principalwithdrawalamt?.value?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "SGD",
                                      }
                                    )}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "42%",
                                  display: "flex",
                                  marginBottom: 10,
                                }}
                              >
                                <div
                                  style={{
                                    width: "60%",
                                    color: "rgba(20, 44, 68, 0.8)",
                                    fontWeight: 800,
                                    fontFamily: "Manrope, sans-serif",
                                    fontSize: 14,
                                  }}
                                >
                                  Accrued Interest Amount:
                                </div>
                                <div style={{ width: "40%" }}>
                                  <input
                                    style={{
                                      width: "100%",
                                      borderRadius: "10px",
                                      border: "none",
                                      outline: "none",
                                      padding: 7,
                                    }}
                                    disabled
                                    type="text"
                                    // required
                                    value={cpf?.accruedinterestamt?.value?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "SGD",
                                      }
                                    )}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "42%",
                                  display: "flex",
                                  marginBottom: 10,
                                }}
                              >
                                <div
                                  style={{
                                    width: "60%",
                                    color: "rgba(20, 44, 68, 0.8)",
                                    fontWeight: 800,
                                    fontFamily: "Manrope, sans-serif",
                                    fontSize: 14,
                                  }}
                                >
                                  Monthly Installment (CPF):
                                </div>
                                <div style={{ width: "40%" }}>
                                  <input
                                    style={{
                                      width: "100%",
                                      borderRadius: "10px",
                                      border: "none",
                                      outline: "none",
                                      padding: 7,
                                    }}
                                    disabled
                                    type="text"
                                    // required
                                    value={cpf?.monthlyinstalmentamt?.value?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "SGD",
                                      }
                                    )}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "42%",
                                  display: "flex",
                                  marginBottom: 10,
                                }}
                              >
                                <div
                                  style={{
                                    width: "60%",
                                    color: "rgba(20, 44, 68, 0.8)",
                                    fontWeight: 800,
                                    fontFamily: "Manrope, sans-serif",
                                    fontSize: 14,
                                  }}
                                >
                                  Total Amount of CPF Allowed For Property:
                                </div>
                                <div style={{ width: "40%" }}>
                                  <input
                                    style={{
                                      width: "100%",
                                      borderRadius: "10px",
                                      border: "none",
                                      outline: "none",
                                      padding: 7,
                                    }}
                                    disabled
                                    type="text"
                                    // required
                                    value={cpf?.totalamountofcpfallowedforproperty?.value?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "SGD",
                                      }
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ) : viewingTab === 1 ? (
                      <div
                        style={{
                          padding: "15px 15px",
                          borderRadius: "8px",
                          width: "100%",
                          background: "rgba(14, 90, 165, 0.1)",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "2px 20px",
                        }}
                      >
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            UEN
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={
                                liveUserData?.entity["basic-profile"]?.uen
                                  ?.value
                              }
                            />
                            {/* {uenError && <div style={{ color: 'red' }}>Please fill in the UEN</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Entity Name
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={
                                liveUserData?.entity["basic-profile"][
                                  "entity-name"
                                ]?.value
                              }
                            />
                            {/* {entityNameError && <div style={{ color: 'red' }}>Please fill in the Entity Name</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Entity Type
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={
                                liveUserData?.entity["basic-profile"][
                                  "entity-type"
                                ]?.value
                              }
                            />
                            {/* {entityTypeError && <div style={{ color: 'red' }}>Please fill in the Entity Type</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Entity Status
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={
                                liveUserData?.entity["basic-profile"][
                                  "entity-status"
                                ]?.value
                              }
                            />
                            {/* {entityStatusError && <div style={{ color: 'red' }}>Please fill in the Entity Status</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Registered Address
                          </div>
                          <div style={{ width: "60%" }}>
                            <textarea
                              rows="2"
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                                resize: "none",
                              }}
                              disabled
                              // required
                              value={
                                liveUserData?.entity?.addresses[
                                  "addresses-list"
                                ][0]
                                  ? (liveUserData?.entity?.addresses?.[
                                      "addresses-list"
                                    ]?.[0]?.unit?.value !== undefined
                                      ? `Unit ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                      : "") +
                                    (liveUserData?.entity?.addresses?.[
                                      "addresses-list"
                                    ]?.[0]?.floor?.value !== undefined
                                      ? `Floor ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                      : "") +
                                    (liveUserData?.entity?.addresses?.[
                                      "addresses-list"
                                    ]?.[0]?.block?.value !== undefined
                                      ? `Block ${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                      : "") +
                                    (liveUserData?.entity?.addresses?.[
                                      "addresses-list"
                                    ]?.[0]?.street?.value !== undefined
                                      ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                      : "") +
                                    (liveUserData?.entity?.addresses?.[
                                      "addresses-list"
                                    ]?.[0]?.postal?.value !== undefined
                                      ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                      : "") +
                                    (liveUserData?.entity?.addresses?.[
                                      "addresses-list"
                                    ]?.[0]?.country?.desc !== undefined
                                      ? `${liveUserData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                      : "")
                                  : ""
                              }
                            ></textarea>
                            {/* {registeredAddressEntityError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Registration Date
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="date"
                              // required
                              value={
                                liveUserData?.entity["basic-profile"][
                                  "registration-date"
                                ]?.value
                              }
                            />
                            {/* {registrationDateError && <div style={{ color: 'red' }}>Please fill in the Registered Date</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Primary SSIC
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={
                                liveUserData?.entity["basic-profile"][
                                  "primary-activity"
                                ]?.desc
                              }
                            />
                            {/* {primarySSICError && <div style={{ color: 'red' }}>Please fill in the Primary SSIC</div>} */}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Secondary SSIC
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              // required
                              value={
                                liveUserData?.entity["basic-profile"][
                                  "secondary-activity"
                                ]?.desc
                              }
                            />
                            {/* {secondarySSICError && <div style={{ color: 'red' }}>Please fill in the Secondary SSIC</div>} */}
                          </div>
                        </div>
                        {liveUserData?.entity?.appointments[
                          "appointments-list"
                        ]?.map((appointment, index) => (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "2px 20px",
                            }}
                            key={index}
                          >
                            <div
                              style={{
                                width: "40%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Appointment {index + 1} - Position
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  value={appointment.position.desc}
                                  // Add onChange logic if needed
                                />
                              </div>
                            </div>
                            {/* Similar structure for NRIC, Full Name, and Appointment Date */}
                            <div
                              style={{
                                width: "40%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Appointment {index + 1} - NRIC
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  value={
                                    appointment["person-reference"]
                                      ? appointment["person-reference"].idno
                                          .value
                                      : appointment["entity-reference"]
                                      ? appointment["entity-reference"].uen
                                          .value
                                      : null
                                  }
                                  // Add onChange logic if needed
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                width: "40%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Appointment {index + 1} - Full Name
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="text"
                                  value={
                                    appointment["person-reference"]
                                      ? appointment["person-reference"][
                                          "person-name"
                                        ].value
                                      : appointment["entity-reference"]
                                      ? appointment["entity-reference"][
                                          "entity-name"
                                        ].value
                                      : null
                                  }
                                  // Add onChange logic if needed
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                width: "40%",
                                display: "flex",
                                marginBottom: 10,
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "rgba(20, 44, 68, 0.8)",
                                  fontWeight: 800,
                                  fontFamily: "Manrope, sans-serif",
                                  fontSize: 14,
                                }}
                              >
                                Appointment {index + 1} - Appointment Date
                              </div>
                              <div style={{ width: "60%" }}>
                                <input
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    border: "none",
                                    outline: "none",
                                    padding: 7,
                                  }}
                                  disabled
                                  type="date"
                                  value={appointment["appointment-date"].value}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : viewingTab === 2 ? (
                      <table
                        style={{
                          width: "100%",
                          borderCollapse: "separate",
                          borderRadius: "10px",
                          overflow: "hidden",
                          padding: "10px",
                          borderSpacing: "0px",
                          background: "#0e5aa51A",
                        }}
                      >
                        <thead
                          style={{
                            borderRadius: "15px 15px 0px 0px",
                            border: "none",
                            borderRadius: "8px",
                          }}
                        >
                          <tr
                            style={{
                              border: "none",
                              borderRadius: "8px",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 12,
                              borderRadius: 8,
                            }}
                          >
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Amount
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Category
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Employment
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Interest
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Rent
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Tax Clearance
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Trade
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Years of Assessment
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {liveUserData?.person?.noahistory?.noas?.map(
                            (item, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.amount?.value?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "SGD",
                                    }
                                  )}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.category?.value}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.employment?.value?.toLocaleString(
                                    "en-US"
                                  )}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.interest?.value?.toLocaleString(
                                    "en-US"
                                  )}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.rent?.value?.toLocaleString("en-US")}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.taxclearance?.value}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.trade?.value?.toLocaleString("en-US")}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.yearofassessment?.value}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    ) : viewingTab === 3 ? (
                      <table
                        style={{
                          width: "100%",
                          borderCollapse: "separate",
                          borderRadius: "10px",
                          overflow: "hidden",
                          padding: "10px",
                          borderSpacing: "0px",
                          background: "#0e5aa51A",
                        }}
                      >
                        <thead
                          style={{
                            borderRadius: "15px 15px 0px 0px",
                            border: "none",
                            borderRadius: "8px",
                          }}
                        >
                          <tr
                            style={{
                              border: "none",
                              borderRadius: "8px",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 12,
                              borderRadius: 8,
                            }}
                          >
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Capital Type
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Currency
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Issued Capital Amount
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Paid Up Capital Amount
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Share Alloted Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {liveUserData?.entity?.capitals["capitals-list"]?.map(
                            (item, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.["capital-type"]?.desc}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.currency?.desc}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.[
                                    "issued-capital-amount"
                                  ]?.value?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "SGD",
                                  })}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.[
                                    "paid-up-capital-amount"
                                  ]?.value?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "SGD",
                                  })}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.[
                                    "share-allotted-amount"
                                  ]?.value?.toLocaleString("en-US")}
                                </td>
                                {/* Add other data cells */}
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    ) : viewingTab === 4 ? (
                      <table
                        style={{
                          width: "100%",
                          borderCollapse: "separate",
                          borderRadius: "10px",
                          overflow: "hidden",
                          padding: "10px",
                          borderSpacing: "0px",
                          background: "#0e5aa51A",
                        }}
                      >
                        <thead
                          style={{
                            borderRadius: "15px 15px 0px 0px",
                            border: "none",
                            borderRadius: "8px",
                          }}
                        >
                          <tr
                            style={{
                              border: "none",
                              borderRadius: "8px",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 12,
                              borderRadius: 8,
                            }}
                          >
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Company Profit Loss After Tax
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Company Profit Loss Before Tax
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Company Revenue
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Currency
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Current Period End Date
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Current Period Start Date
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Group Capital Paid Up Capital Amount
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Group Profit Loss After Tax
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Group Profit Loss Before Tax
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Group Revenue
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Is Audited
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {liveUserData?.entity?.financials?.highlights?.map(
                            (item, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {
                                    item?.["company-profit-loss-after-tax"]
                                      ?.value
                                  }
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {
                                    item?.["company-profit-loss-before-tax"]
                                      ?.value
                                  }
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.["company-revenue"]?.value}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.currency?.desc}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.["current-period-end-date"]?.value}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.["current-period-start-date"]?.value}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {
                                    item?.[
                                      "group-capital-paid-up-capital-amount"
                                    ]?.value
                                  }
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.["group-profit-loss-after-tax"]?.value}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {
                                    item?.["group-profit-loss-before-tax"]
                                      ?.value
                                  }
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.["group-revenue"]?.value}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.["is-audited"]?.value}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    ) : viewingTab === 5 ? (
                      <table
                        style={{
                          width: "100%",
                          borderCollapse: "separate",
                          borderRadius: "10px",
                          overflow: "hidden",
                          padding: "10px",
                          borderSpacing: "0px",
                          background: "#0e5aa51A",
                        }}
                      >
                        <thead
                          style={{
                            borderRadius: "15px 15px 0px 0px",
                            border: "none",
                            borderRadius: "8px",
                          }}
                        >
                          <tr
                            style={{
                              border: "none",
                              borderRadius: "8px",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 12,
                              borderRadius: 8,
                            }}
                          >
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Allocation
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Category
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Name
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              NRIC
                            </th>
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Currency
                            </th>
                            {/* <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference NRIC</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Nationality</th>
<th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Name</th> */}
                            <th
                              style={{
                                padding: "5px 10px",
                              }}
                            >
                              Share Type
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {liveUserData?.entity?.shareholders[
                            "shareholders-list"
                          ] ? (
                            liveUserData?.entity?.shareholders[
                              "shareholders-list"
                            ]?.map((item, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.allocation?.value?.toLocaleString(
                                    "en-US"
                                  )}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.category?.desc}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {
                                    item?.["person-reference"]?.["person-name"]
                                      ?.value
                                  }
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.["person-reference"]?.idno?.value}
                                </td>
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.currency?.desc}
                                </td>
                                {/* <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].idno.value}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"].nationality.desc}</td>
<td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item?["person-reference"]["person-name"].value}</td> */}
                                <td
                                  style={{
                                    borderTop: "1px solid rgba(0,0,0,0.6)",
                                    padding: "5px 10px",
                                  }}
                                >
                                  {item?.["share-type"]?.desc}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                style={{
                                  borderTop: "1px solid rgba(0,0,0,0.6)",
                                  textAlign: "center",
                                  padding: "5px 10px",
                                }}
                                colSpan="11"
                              >
                                No Data
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                        <PDFViewer style={{ width: "100%", height: "80vh" }}>
                          <Document>
                            <Page size="A4">
                              {/**Letterhead section */}
                              <View
                                style={{
                                  paddingVertical: 15,
                                }}
                              >
                                <View
                                  style={{
                                    paddingHorizontal: 40,
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    gap: 10,
                                    // borderBottom: 1,
                                    // borderBottomColor: "grey",
                                    paddingBottom: 10,
                                  }}
                                >
                                <Image
                                  source={logo}
                                  style={{ width: 80, objectFit: "contain" }}
                                />
                                  <View>
                                    <Text
                                      style={{
                                        fontSize: 18,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      SmartLend Financial Pte. Ltd.
                                    </Text>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        gap: 4,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: -3,
                                      }}
                                    >
                                      <Text style={{ fontSize: 7 }}>
                                        UEN: 202327568G
                                      </Text>
                                      <Text style={{ fontSize: 7 }}>
                                        Tel: +65 88288147
                                      </Text>
                                      <Text style={{ fontSize: 7 }}>
                                        Email: support@smart-lend.com
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                                <View
                                  style={{
                                    backgroundColor: "lightgrey",
                                    height: 2,
                                    width: "100%",
                                    marginTop: 20,
                                  }}
                                />
                                <View
                                  style={{
                                    backgroundColor: "#2881c4",
                                    height: 4,
                                    width: "76%",
                                    marginTop: -3,
                                    marginLeft: "12%",
                                    marginBottom: 15,
                                  }}
                                />
                                <View
                                  style={{
                                    marginTop: 15,
                                    paddingHorizontal: 40,
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: "#c7dcec",
                                      alignItems: "center",
                                      padding: 6,
                                      borderTopLeftRadius: 7,
                                      borderTopRightRadius: 7,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Company Profile
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      padding: 8,
                                      borderColor: "#c7dcec",
                                      borderLeftWidth: 2,
                                      borderRightWidth: 2,
                                      borderBottomWidth: 2,
                                    }}
                                  >
                                    <View style={{ width: "50%" }}>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Entity Name:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "65%" }}
                                        >
                                          {
                                            singpassData?.entity[
                                              "basic-profile"
                                            ]["entity-name"]?.value
                                          }
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          UEN:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "65%" }}
                                        >
                                          {
                                            singpassData?.entity[
                                              "basic-profile"
                                            ]?.uen?.value
                                          }
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Registration Date:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "65%" }}
                                        >
                                          {
                                            singpassData?.entity?.[
                                              "basic-profile"
                                            ]?.["registration-date"]?.value
                                          }
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Primary Activity:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "65%" }}
                                        >
                                          {
                                            singpassData?.entity[
                                              "basic-profile"
                                            ]["primary-activity"]?.desc
                                          }
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Ownership:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "65%" }}
                                        >
                                          {
                                            singpassData?.entity?.[
                                              "basic-profile"
                                            ]?.["ownership"]?.desc
                                          }
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Entity Status:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "65%" }}
                                        >
                                          {
                                            singpassData?.entity?.[
                                              "basic-profile"
                                            ]?.["entity-status"]?.value
                                          }
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Business Constitution:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "65%" }}
                                        >
                                          {
                                            singpassData?.entity?.[
                                              "basic-profile"
                                            ]?.["company-type"]?.desc
                                          }
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Business Verification By Smartlend:
                                        </Text>
                                        <Text style={{ fontSize: 7 }}>
                                          {badgeVerified
                                            ? "Verfied"
                                            : "Un-verified"}
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={{ width: "50%" }}>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          ACRA Address:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "60%" }}
                                        >
                                          {singpassData?.entity?.addresses[
                                            "addresses-list"
                                          ][0]
                                            ? (singpassData?.entity
                                                ?.addresses?.[
                                                "addresses-list"
                                              ]?.[0]?.unit?.value !== undefined
                                                ? `Unit ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                                                : "") +
                                              (singpassData?.entity
                                                ?.addresses?.[
                                                "addresses-list"
                                              ]?.[0]?.floor?.value !== undefined
                                                ? `Floor ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                                                : "") +
                                              (singpassData?.entity
                                                ?.addresses?.[
                                                "addresses-list"
                                              ]?.[0]?.block?.value !== undefined
                                                ? `Block ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                                                : "") +
                                              (singpassData?.entity
                                                ?.addresses?.[
                                                "addresses-list"
                                              ]?.[0]?.street?.value !==
                                              undefined
                                                ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                                                : "") +
                                              (singpassData?.entity
                                                ?.addresses?.[
                                                "addresses-list"
                                              ]?.[0]?.postal?.value !==
                                              undefined
                                                ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                                                : "") +
                                              (singpassData?.entity
                                                ?.addresses?.[
                                                "addresses-list"
                                              ]?.[0]?.country?.desc !==
                                              undefined
                                                ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                                                : "")
                                            : ""}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Capital Type:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "60%" }}
                                        >
                                          {
                                            singpassData?.entity?.[
                                              "capitals"
                                            ]?.["capitals-list"]?.[0]?.[
                                              "capital-type"
                                            ]?.desc
                                          }
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Paid Up Capital:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "60%" }}
                                        >
                                          {singpassData?.entity?.["capitals"]?.[
                                            "capitals-list"
                                          ]?.[0]?.[
                                            "paid-up-capital-amount"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Share Allocated Amount:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "60%" }}
                                        >
                                          {singpassData?.entity?.["capitals"]?.[
                                            "capitals-list"
                                          ]?.[0]?.[
                                            "share-allotted-amount"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Issued Capital Amount:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "60%" }}
                                        >
                                          {singpassData?.entity?.["capitals"]?.[
                                            "capitals-list"
                                          ]?.[0]?.[
                                            "issued-capital-amount"
                                          ]?.value?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "SGD",
                                          })}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Keyman Mobile:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "60%" }}
                                        >
                                          {getShareholderAddress(keyman)?.[0]
                                            ?.person?.mobileno?.nbr?.value !==
                                          undefined
                                            ? getShareholderAddress(keyman)?.[0]
                                                ?.person?.mobileno?.prefix
                                                ?.value +
                                              getShareholderAddress(keyman)?.[0]
                                                ?.person?.mobileno?.areacode
                                                ?.value +
                                              "-" +
                                              getShareholderAddress(keyman)?.[0]
                                                ?.person?.mobileno?.nbr?.value
                                            : ""}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          flexDirection: "row",
                                          gap: 10,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Keyman Email:
                                        </Text>
                                        <Text
                                          style={{ fontSize: 7, width: "60%" }}
                                        >
                                          {getShareholderAddress(keyman)?.[0]
                                            ?.person?.email?.value !== undefined
                                            ? getShareholderAddress(keyman)?.[0]
                                                ?.person?.email?.value
                                            : ""}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                                <View
                                  style={{
                                    marginTop: 15,
                                    paddingHorizontal: 40,
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: "#c7dcec",
                                      alignItems: "center",
                                      padding: 6,
                                      borderTopLeftRadius: 7,
                                      borderTopRightRadius: 7,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Financial Highlight
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      borderColor: "#c7dcec",
                                      borderLeftWidth: 2,
                                      borderRightWidth: 2,
                                      borderBottomWidth: 2,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        backgroundColor: "#c7dcec",
                                        opacity: 0.7,
                                        padding: 4,
                                      }}
                                    >
                                      <View
                                        style={{
                                          width: "19%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Revenue</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "19%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Profit pre-tax</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "19%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Profit post-tax</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "19%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Start date</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "19%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>End date</Text>
                                      </View>
                                    </View>
                                    {singpassData?.entity?.financials?.highlights?.map(
                                      (data, index) => (
                                        <View
                                          key={index}
                                          style={{
                                            flexDirection: "row",
                                            width: "100%",
                                            backgroundColor:
                                              index % 2
                                                ? "rgba(199, 220, 236, 0.2)"
                                                : "transparent",
                                            padding: 4,
                                          }}
                                        >
                                          <View
                                            style={{
                                              width: "19%",
                                              fontSize: 7,
                                            }}
                                          >
                                            <Text>
                                              {data?.[
                                                "company-revenue"
                                              ]?.value?.toLocaleString(
                                                "en-US",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "19%",
                                              fontSize: 7,
                                            }}
                                          >
                                            <Text>
                                              {data?.[
                                                "company-profit-loss-before-tax"
                                              ]?.value?.toLocaleString(
                                                "en-US",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "19%",
                                              fontSize: 7,
                                            }}
                                          >
                                            <Text>
                                              {data?.[
                                                "company-profit-loss-after-tax"
                                              ]?.value?.toLocaleString(
                                                "en-US",
                                                {
                                                  style: "currency",
                                                  currency: "SGD",
                                                }
                                              )}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "19%",
                                              fontSize: 7,
                                            }}
                                          >
                                            <Text>
                                              {
                                                data?.[
                                                  "current-period-start-date"
                                                ]?.value
                                              }
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              width: "19%",
                                              fontSize: 7,
                                            }}
                                          >
                                            <Text>
                                              {
                                                data?.[
                                                  "current-period-end-date"
                                                ]?.value
                                              }
                                            </Text>
                                          </View>
                                        </View>
                                      )
                                    )}
                                  </View>
                                </View>
                                <View
                                  style={{
                                    marginTop: 15,
                                    paddingHorizontal: 40,
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: "#c7dcec",
                                      alignItems: "center",
                                      padding: 6,
                                      borderTopLeftRadius: 7,
                                      borderTopRightRadius: 7,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Grants
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      borderColor: "#c7dcec",
                                      borderLeftWidth: 2,
                                      borderRightWidth: 2,
                                      borderBottomWidth: 2,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        backgroundColor: "#c7dcec",
                                        opacity: 0.7,
                                        padding: 4,
                                        gap: 10,
                                      }}
                                    >
                                      <View
                                        style={{
                                          width: "19%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Category</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "19%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Functional Area</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "19%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Grant Type</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "19%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Grant Status</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "19%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Approved Amount</Text>
                                      </View>
                                    </View>
                                    {singpassData?.entity?.grants?.[
                                      "grants-list"
                                    ]?.map((data, index) => (
                                      <View
                                        key={index}
                                        style={{
                                          flexDirection: "row",
                                          width: "100%",
                                          backgroundColor:
                                            index % 2
                                              ? "rgba(199, 220, 236, 0.2)"
                                              : "transparent",
                                          padding: 4,
                                          gap: 10,
                                        }}
                                      >
                                        <View
                                          style={{ width: "19%", fontSize: 7 }}
                                        >
                                          <Text>
                                            {
                                              data?.["development-category"]
                                                ?.desc
                                            }
                                          </Text>
                                        </View>
                                        <View
                                          style={{ width: "19%", fontSize: 7 }}
                                        >
                                          <Text>
                                            {data?.["functional-area"]?.desc}
                                          </Text>
                                        </View>
                                        <View
                                          style={{ width: "19%", fontSize: 7 }}
                                        >
                                          <Text>
                                            {data?.["grant-type"]?.desc}
                                          </Text>
                                        </View>
                                        <View
                                          style={{ width: "19%", fontSize: 7 }}
                                        >
                                          <Text>
                                            {data?.["grant-status"]?.desc}
                                          </Text>
                                        </View>
                                        <View
                                          style={{ width: "19%", fontSize: 7 }}
                                        >
                                          <Text>
                                            {data?.[
                                              "approved-amount"
                                            ]?.value?.toLocaleString("en-US", {
                                              style: "currency",
                                              currency: "SGD",
                                            })}
                                          </Text>
                                        </View>
                                      </View>
                                    ))}
                                  </View>
                                </View>
                                <View
                                  style={{
                                    marginTop: 15,
                                    paddingHorizontal: 40,
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: "#c7dcec",
                                      alignItems: "center",
                                      padding: 6,
                                      borderTopLeftRadius: 7,
                                      borderTopRightRadius: 7,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Officer(S) / Owners
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      borderColor: "#c7dcec",
                                      borderLeftWidth: 2,
                                      borderRightWidth: 2,
                                      borderBottomWidth: 2,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        backgroundColor: "#c7dcec",
                                        opacity: 0.7,
                                        padding: 4,
                                        gap: 10,
                                      }}
                                    >
                                      <View
                                        style={{
                                          width: "25%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Officer Name</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "13%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Identity No.</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "13%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Position</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "13%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Appointment Date</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "19%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Nationality</Text>
                                      </View>
                                    </View>
                                    {singpassData?.entity?.appointments?.[
                                      "appointments-list"
                                    ]?.map((data, index) => (
                                      <View
                                        key={index}
                                        style={{
                                          flexDirection: "row",
                                          width: "100%",
                                          backgroundColor:
                                            index % 2
                                              ? "rgba(199, 220, 236, 0.2)"
                                              : "transparent",
                                          padding: 4,
                                          gap: 10,
                                        }}
                                      >
                                        <View
                                          style={{ width: "25%", fontSize: 7 }}
                                        >
                                          <Text>
                                            {
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            }
                                          </Text>
                                        </View>
                                        <View
                                          style={{ width: "13%", fontSize: 7 }}
                                        >
                                          <Text>
                                            {
                                              data?.["person-reference"]?.[
                                                "idno"
                                              ]?.value
                                            }
                                          </Text>
                                        </View>
                                        <View
                                          style={{ width: "13%", fontSize: 7 }}
                                        >
                                          <Text>
                                            {data?.["position"]?.desc}
                                          </Text>
                                        </View>
                                        <View
                                          style={{ width: "13%", fontSize: 7 }}
                                        >
                                          <Text>
                                            {data?.["appointment-date"]?.value}
                                          </Text>
                                        </View>
                                        <View
                                          style={{ width: "19%", fontSize: 7 }}
                                        >
                                          <Text>
                                            {
                                              data?.["person-reference"]?.[
                                                "nationality"
                                              ]?.desc
                                            }
                                          </Text>
                                        </View>
                                      </View>
                                    ))}
                                  </View>
                                </View>
                                <View
                                  style={{
                                    marginTop: 15,
                                    paddingHorizontal: 40,
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: "#c7dcec",
                                      alignItems: "center",
                                      padding: 6,
                                      borderTopLeftRadius: 7,
                                      borderTopRightRadius: 7,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                      }}
                                    >
                                      Shareholders
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      borderColor: "#c7dcec",
                                      borderLeftWidth: 2,
                                      borderRightWidth: 2,
                                      borderBottomWidth: 2,
                                      width: "100%",
                                    }}
                                  >
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                        backgroundColor: "#c7dcec",
                                        opacity: 0.7,
                                        padding: 4,
                                        gap: 10,
                                      }}
                                    >
                                      <View
                                        style={{
                                          width: "25%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Shareholder Name / Address</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "13%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Identity No.</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "18%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Share Type</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "13%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>No of Share</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "19%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Nationality</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "15%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Date of birth</Text>
                                      </View>
                                      <View
                                        style={{
                                          width: "19%",
                                          fontSize: 7,
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Text>Corppass Contact</Text>
                                      </View>
                                    </View>
                                    {singpassData?.entity?.shareholders?.[
                                      "shareholders-list"
                                    ]?.map((data, index) => (
                                      <View
                                        key={index}
                                        style={{
                                          flexDirection: "row",
                                          width: "100%",
                                          backgroundColor:
                                            index % 2
                                              ? "rgba(199, 220, 236, 0.2)"
                                              : "transparent",
                                          padding: 4,
                                          gap: 10,
                                        }}
                                      >
                                        <View
                                          style={{
                                            width: "25%",
                                            fontSize: 7,
                                          }}
                                        >
                                          <Text>
                                            {data?.["person-reference"]?.[
                                              "person-name"
                                            ]?.value
                                              ? data?.["person-reference"]?.[
                                                  "person-name"
                                                ]?.value +
                                                " (" +
                                                getShareholderAddress(
                                                  data?.["person-reference"]?.[
                                                    "person-name"
                                                  ]?.value
                                                )?.[0]?.person?.sex?.code +
                                                ")"
                                              : data?.["entity-reference"]?.[
                                                  "entity-name"
                                                ]?.value + " (Corporate)"}
                                          </Text>
                                          <Text>
                                            {getShareholderAddress(
                                              data?.["person-reference"]?.[
                                                "person-name"
                                              ]?.value
                                            )?.[0]?.person?.regadd
                                              ? (getShareholderAddress(
                                                  data?.["person-reference"]?.[
                                                    "person-name"
                                                  ]?.value
                                                )?.[0]?.person?.regadd?.unit
                                                  ?.value !== null
                                                  ? `Unit ${
                                                      getShareholderAddress(
                                                        data?.[
                                                          "person-reference"
                                                        ]?.["person-name"]
                                                          ?.value
                                                      )?.[0]?.person?.regadd
                                                        ?.unit?.value
                                                    }, `
                                                  : "") +
                                                (getShareholderAddress(
                                                  data?.["person-reference"]?.[
                                                    "person-name"
                                                  ]?.value
                                                )?.[0]?.person?.regadd?.floor
                                                  ?.value !== null
                                                  ? `Floor ${
                                                      getShareholderAddress(
                                                        data?.[
                                                          "person-reference"
                                                        ]?.["person-name"]
                                                          ?.value
                                                      )?.[0]?.person?.regadd
                                                        ?.floor?.value
                                                    }, `
                                                  : "") +
                                                (getShareholderAddress(
                                                  data?.["person-reference"]?.[
                                                    "person-name"
                                                  ]?.value
                                                )?.[0]?.person?.regadd?.block
                                                  ?.value !== null
                                                  ? `Block ${
                                                      getShareholderAddress(
                                                        data?.[
                                                          "person-reference"
                                                        ]?.["person-name"]
                                                          ?.value
                                                      )?.[0]?.person?.regadd
                                                        ?.block?.value
                                                    }, `
                                                  : "") +
                                                (getShareholderAddress(
                                                  data?.["person-reference"]?.[
                                                    "person-name"
                                                  ]?.value
                                                )?.[0]?.person?.regadd?.street
                                                  ?.value !== null
                                                  ? `${
                                                      getShareholderAddress(
                                                        data?.[
                                                          "person-reference"
                                                        ]?.["person-name"]
                                                          ?.value
                                                      )?.[0]?.person?.regadd
                                                        ?.street?.value
                                                    }, `
                                                  : "") +
                                                (getShareholderAddress(
                                                  data?.["person-reference"]?.[
                                                    "person-name"
                                                  ]?.value
                                                )?.[0]?.person?.regadd?.postal
                                                  ?.value !== null
                                                  ? `${
                                                      getShareholderAddress(
                                                        data?.[
                                                          "person-reference"
                                                        ]?.["person-name"]
                                                          ?.value
                                                      )?.[0]?.person?.regadd
                                                        ?.postal?.value
                                                    }, `
                                                  : "") +
                                                (getShareholderAddress(
                                                  data?.["person-reference"]?.[
                                                    "person-name"
                                                  ]?.value
                                                )?.[0]?.person?.regadd?.country
                                                  ?.desc !== null
                                                  ? `${
                                                      getShareholderAddress(
                                                        data?.[
                                                          "person-reference"
                                                        ]?.["person-name"]
                                                          ?.value
                                                      )?.[0]?.person?.regadd
                                                        ?.country?.desc
                                                    }.`
                                                  : "")
                                              : ""}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "13%",
                                            fontSize: 7,
                                          }}
                                        >
                                          <Text>
                                            {data?.["person-reference"]?.[
                                              "idno"
                                            ]?.value
                                              ? data?.["person-reference"]?.[
                                                  "idno"
                                                ]?.value
                                              : data?.["entity-reference"]?.[
                                                  "uen"
                                                ]?.value + " (UEN)"}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "18%",
                                            fontSize: 7,
                                          }}
                                        >
                                          <Text>
                                            {data?.["share-type"]?.desc}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "13%",
                                            fontSize: 7,
                                          }}
                                        >
                                          <Text>
                                            {data?.["allocation"]?.value}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "19%",
                                            fontSize: 7,
                                          }}
                                        >
                                          <Text>
                                            {
                                              data?.["person-reference"]?.[
                                                "nationality"
                                              ]?.desc
                                            }
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "15%",
                                            fontSize: 7,
                                          }}
                                        >
                                          <Text>
                                            {
                                              getShareholderAddress(
                                                data?.["person-reference"]?.[
                                                  "person-name"
                                                ]?.value
                                              )?.[0]?.person?.dob?.value
                                            }
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            width: "19%",
                                            fontSize: 7,
                                          }}
                                        >
                                          <Text>
                                            {
                                              getShareholderAddress(
                                                data?.["person-reference"]?.[
                                                  "person-name"
                                                ]?.value
                                              )?.[0]?.person?.email?.value
                                            }
                                          </Text>
                                          <Text>
                                            {
                                              getShareholderAddress(
                                                data?.["person-reference"]?.[
                                                  "person-name"
                                                ]?.value
                                              )?.[0]?.person?.mobileno?.nbr
                                                ?.value
                                            }
                                          </Text>
                                        </View>
                                      </View>
                                    ))}
                                  </View>
                                </View>
                              </View>
                              <View></View>
                            </Page>
                            {combinedSingpassData
                              ? combinedSingpassData.map((data, index) => (
                                  <Page key={index} size="A4">
                                    {/**Letterhead section */}
                                    <View
                                      style={{
                                        paddingVertical: 15,
                                      }}
                                    >
                                      <View
                                        style={{
                                          marginTop: 15,
                                          paddingHorizontal: 40,
                                        }}
                                      >
                                        <View
                                          style={{
                                            backgroundColor: "#c7dcec",
                                            alignItems: "center",
                                            padding: 6,
                                            borderTopLeftRadius: 7,
                                            borderTopRightRadius: 7,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 10,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {data?.person?.name?.value +
                                              " | Personal Information"}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            padding: 8,
                                            borderColor: "#c7dcec",
                                            borderLeftWidth: 2,
                                            borderRightWidth: 2,
                                            borderBottomWidth: 2,
                                          }}
                                        >
                                          <View style={{ width: "50%" }}>
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                Ownership of Private Residential
                                                Property:
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                }}
                                              >
                                                {data?.person?.ownerprivate
                                                  ?.value
                                                  ? "Yes"
                                                  : "No"}
                                              </Text>
                                            </View>
                                            {data?.person?.hdbownership
                                              ? data?.person?.hdbownership
                                                  ?.length > 0
                                                ? data?.person?.hdbownership?.map(
                                                    (hdb, index3) => (
                                                      <View key={index3}>
                                                        <View
                                                          style={{
                                                            flexDirection:
                                                              "row",
                                                            gap: 10,
                                                            marginTop: 10,
                                                          }}
                                                        >
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              fontFamily:
                                                                "Poppins",
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            HDB {index3 + 1}
                                                          </Text>
                                                        </View>

                                                        <View
                                                          style={{
                                                            flexDirection:
                                                              "row",
                                                            gap: 10,
                                                          }}
                                                        >
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              fontFamily:
                                                                "Poppins",
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            HDB Ownership:
                                                          </Text>
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              width: "65%",
                                                            }}
                                                          >
                                                            {hdb?.address
                                                              ? (hdb?.address
                                                                  ?.unit
                                                                  ?.value !==
                                                                null
                                                                  ? `Unit ${hdb?.address?.unit?.value}, `
                                                                  : "") +
                                                                (hdb?.address
                                                                  ?.floor
                                                                  ?.value !==
                                                                null
                                                                  ? `Floor ${hdb?.address?.floor?.value}, `
                                                                  : "") +
                                                                (hdb?.address
                                                                  ?.block
                                                                  ?.value !==
                                                                null
                                                                  ? `Block ${hdb?.address?.block?.value}, `
                                                                  : "") +
                                                                (hdb?.address
                                                                  ?.street
                                                                  ?.value !==
                                                                null
                                                                  ? `${hdb?.address?.street?.value}, `
                                                                  : "") +
                                                                (hdb?.address
                                                                  ?.postal
                                                                  ?.value !==
                                                                null
                                                                  ? `${hdb?.address?.postal?.value}, `
                                                                  : "") +
                                                                (hdb?.address
                                                                  ?.country
                                                                  ?.desc !==
                                                                null
                                                                  ? `${hdb?.address?.country?.desc}.`
                                                                  : "")
                                                              : ""}
                                                          </Text>
                                                        </View>
                                                        <View
                                                          style={{
                                                            flexDirection:
                                                              "row",
                                                            gap: 10,
                                                          }}
                                                        >
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              fontFamily:
                                                                "Poppins",
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            Outstanding HDB Loan
                                                            Balance:
                                                          </Text>
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              width: "30%",
                                                            }}
                                                          >
                                                            {hdb?.outstandingloanbalance?.value?.toLocaleString(
                                                              "en-US",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "SGD",
                                                              }
                                                            )}
                                                          </Text>
                                                        </View>
                                                        <View
                                                          style={{
                                                            flexDirection:
                                                              "row",
                                                            gap: 10,
                                                          }}
                                                        >
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              fontFamily:
                                                                "Poppins",
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            Type of HDB:
                                                          </Text>
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              width: "65%",
                                                            }}
                                                          >
                                                            {hdb?.hdbtype?.desc}
                                                          </Text>
                                                        </View>
                                                        <View
                                                          style={{
                                                            flexDirection:
                                                              "row",
                                                            gap: 10,
                                                          }}
                                                        >
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              fontFamily:
                                                                "Poppins",
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            Monthly Loan
                                                            Installment:
                                                          </Text>
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              width: "65%",
                                                            }}
                                                          >
                                                            {hdb?.monthlyloaninstalment?.value?.toLocaleString(
                                                              "en-US",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "SGD",
                                                              }
                                                            )}
                                                          </Text>
                                                        </View>
                                                      </View>
                                                    )
                                                  )
                                                : "No"
                                              : "No"}

                                            <View>
                                              {data?.person
                                                ?.cpfhousingwithdrawal
                                                ?.withdrawaldetails?.length > 0
                                                ? data?.person?.cpfhousingwithdrawal?.withdrawaldetails.map(
                                                    (cpf, index) => (
                                                      <View
                                                        style={{
                                                          marginTop: 10,
                                                        }}
                                                        key={index}
                                                      >
                                                        <Text
                                                          style={{
                                                            fontSize: 7,
                                                            fontFamily:
                                                              "Poppins",
                                                            fontWeight: 700,
                                                          }}
                                                        >
                                                          CPF Usage{" "}
                                                          {(cpf.address.unit
                                                            ?.value !==
                                                          undefined
                                                            ? `Unit ${cpf.address.unit?.value}, `
                                                            : "") +
                                                            (cpf.address.floor
                                                              ?.value !==
                                                            undefined
                                                              ? `Floor ${cpf.address.floor?.value}, `
                                                              : "") +
                                                            (cpf.address.block
                                                              ?.value !==
                                                            undefined
                                                              ? `Block ${cpf.address.block?.value}, `
                                                              : "") +
                                                            (cpf.address.street
                                                              ?.value !==
                                                            undefined
                                                              ? `${cpf.address.street?.value}, `
                                                              : "") +
                                                            (cpf.address.postal
                                                              ?.value !==
                                                            undefined
                                                              ? `${cpf.address.postal?.value}, `
                                                              : "") +
                                                            (cpf.address.country
                                                              ?.desc !==
                                                            undefined
                                                              ? `${cpf.address.country?.desc}.`
                                                              : "")}
                                                          :
                                                        </Text>
                                                        <View
                                                          style={{
                                                            flexDirection:
                                                              "row",
                                                            gap: 10,
                                                          }}
                                                        >
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              fontFamily:
                                                                "Poppins",
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            Principal Withdrawal
                                                            Amount:
                                                          </Text>
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              width: "30%",
                                                            }}
                                                          >
                                                            {cpf?.principalwithdrawalamt?.value?.toLocaleString(
                                                              "en-US",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "SGD",
                                                              }
                                                            )}
                                                          </Text>
                                                        </View>
                                                        <View
                                                          style={{
                                                            flexDirection:
                                                              "row",
                                                            gap: 10,
                                                          }}
                                                        >
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              fontFamily:
                                                                "Poppins",
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            Accrued Interest
                                                            Amount:
                                                          </Text>
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              width: "30%",
                                                            }}
                                                          >
                                                            {cpf?.accruedinterestamt?.value?.toLocaleString(
                                                              "en-US",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "SGD",
                                                              }
                                                            )}
                                                          </Text>
                                                        </View>
                                                        <View
                                                          style={{
                                                            flexDirection:
                                                              "row",
                                                            gap: 10,
                                                          }}
                                                        >
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              fontFamily:
                                                                "Poppins",
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            Monthly Installment
                                                            (CPF):
                                                          </Text>
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              width: "30%",
                                                            }}
                                                          >
                                                            {cpf?.monthlyinstalmentamt?.value?.toLocaleString(
                                                              "en-US",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "SGD",
                                                              }
                                                            )}
                                                          </Text>
                                                        </View>
                                                        <View
                                                          style={{
                                                            flexDirection:
                                                              "row",
                                                            gap: 10,
                                                          }}
                                                        >
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              fontFamily:
                                                                "Poppins",
                                                              fontWeight: 700,
                                                            }}
                                                          >
                                                            Total Amount of CPF
                                                            Allowed For
                                                            Property:
                                                          </Text>
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              width: "30%",
                                                            }}
                                                          >
                                                            {cpf?.totalamountofcpfallowedforproperty?.value?.toLocaleString(
                                                              "en-US",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "SGD",
                                                              }
                                                            )}
                                                          </Text>
                                                        </View>
                                                      </View>
                                                    )
                                                  )
                                                : "None"}
                                            </View>
                                          </View>
                                          <View style={{ width: "50%" }}>
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                Contact:
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  width: "65%",
                                                }}
                                              >
                                                {
                                                  data?.person?.mobileno?.nbr
                                                    ?.value
                                                }
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                Email:
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  width: "65%",
                                                }}
                                              >
                                                {data?.person?.email?.value}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                Marital Status:
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  width: "65%",
                                                }}
                                              >
                                                {data?.person?.marital?.desc}
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                Vehicles:
                                              </Text>
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  width: "65%",
                                                }}
                                              >
                                                {data?.person?.vehicles
                                                  ?.length > 0
                                                  ? data?.person?.vehicles?.map(
                                                      (veh, index4) => (
                                                        <Text
                                                          key={index4}
                                                          style={{
                                                            fontSize: 7,
                                                            width: "65%",
                                                          }}
                                                        >
                                                          <Text
                                                            style={{
                                                              fontSize: 7,
                                                              width: "65%",
                                                            }}
                                                          >
                                                            {veh?.make?.value +
                                                              ": " +
                                                              veh?.model?.value}
                                                          </Text>
                                                        </Text>
                                                      )
                                                    )
                                                  : "None"}
                                              </Text>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                      <View
                                        style={{
                                          marginTop: 15,
                                          paddingHorizontal: 40,
                                        }}
                                      >
                                        <View
                                          style={{
                                            backgroundColor: "#c7dcec",
                                            alignItems: "center",
                                            padding: 6,
                                            borderTopLeftRadius: 7,
                                            borderTopRightRadius: 7,
                                          }}
                                        >
                                          <Text
                                            style={{
                                              fontSize: 10,
                                              fontFamily: "Poppins",
                                              fontWeight: 700,
                                            }}
                                          >
                                            {data?.person?.name?.value +
                                              " | Notice of Assessments"}
                                          </Text>
                                        </View>
                                        <View
                                          style={{
                                            borderColor: "#c7dcec",
                                            borderLeftWidth: 2,
                                            borderRightWidth: 2,
                                            borderBottomWidth: 2,
                                            width: "100%",
                                          }}
                                        >
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              width: "100%",
                                              backgroundColor: "#c7dcec",
                                              opacity: 0.7,
                                              padding: 4,
                                              gap: 10,
                                            }}
                                          >
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                              }}
                                            >
                                              <Text>Year of Assesment</Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                              }}
                                            >
                                              <Text>Amount</Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                              }}
                                            >
                                              <Text>Employment</Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                              }}
                                            >
                                              <Text>Interest</Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                              }}
                                            >
                                              <Text>Rent</Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                              }}
                                            >
                                              <Text>Trade</Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "14%",
                                                fontSize: 7,
                                                fontFamily: "Poppins",
                                              }}
                                            >
                                              <Text>Tax Clearance</Text>
                                            </View>
                                          </View>
                                          {data?.person?.noahistory?.noas?.map(
                                            (data2, index2) => (
                                              <View
                                                key={index2}
                                                style={{
                                                  flexDirection: "row",
                                                  width: "100%",
                                                  backgroundColor:
                                                    index2 % 2
                                                      ? "rgba(199, 220, 236, 0.2)"
                                                      : "transparent",
                                                  padding: 4,
                                                  gap: 10,
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    width: "14%",
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  <Text>
                                                    {
                                                      data2?.[
                                                        "yearofassessment"
                                                      ]?.value
                                                    }
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "14%",
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  <Text>
                                                    {data2?.[
                                                      "amount"
                                                    ]?.value?.toLocaleString(
                                                      "en-us",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "14%",
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  <Text>
                                                    {data2?.[
                                                      "employment"
                                                    ]?.value?.toLocaleString(
                                                      "en-us",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "14%",
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  <Text>
                                                    {data2?.[
                                                      "interest"
                                                    ]?.value?.toLocaleString(
                                                      "en-us",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "14%",
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  <Text>
                                                    {data2?.[
                                                      "rent"
                                                    ]?.value?.toLocaleString(
                                                      "en-us",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "14%",
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  <Text>
                                                    {data2?.[
                                                      "trade"
                                                    ]?.value?.toLocaleString(
                                                      "en-us",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                  </Text>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "14%",
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  <Text>
                                                    {
                                                      data2?.["taxclearance"]
                                                        ?.value
                                                    }
                                                  </Text>
                                                </View>
                                              </View>
                                            )
                                          )}
                                        </View>
                                      </View>
                                      {allCBSData?.filter(
                                        (dat2) =>
                                          dat2?.name ===
                                          data?.person?.name?.value
                                      )?.[0] ? (
                                        <View
                                          style={{
                                            marginTop: 15,
                                            paddingHorizontal: 40,
                                          }}
                                        >
                                          <View
                                            style={{
                                              backgroundColor: "#c7dcec",
                                              alignItems: "center",
                                              padding: 6,
                                              borderTopLeftRadius: 7,
                                              borderTopRightRadius: 7,
                                            }}
                                          >
                                            <Text
                                              style={{
                                                fontSize: 10,
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                              }}
                                            >
                                              {data?.person?.name?.value +
                                                " | Credit Bureau | Personal Commitement"}
                                            </Text>
                                          </View>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              padding: 8,
                                              borderColor: "#c7dcec",
                                              borderLeftWidth: 2,
                                              borderRightWidth: 2,
                                            }}
                                          >
                                            <View style={{ width: "50%" }}>
                                              <View
                                                style={{
                                                  flexDirection: "row",
                                                  gap: 10,
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Secured Credit Limit:
                                                </Text>
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  {allCBSData
                                                    ?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]
                                                    ?.cbs_data?.SecuredCreditLimit?.toLocaleString(
                                                      "en-US",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                </Text>
                                              </View>
                                              <View
                                                style={{
                                                  flexDirection: "row",
                                                  gap: 10,
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Credit Score:
                                                </Text>
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  {
                                                    allCBSData?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]?.cbs_data
                                                      ?.CreditScore
                                                  }
                                                </Text>
                                              </View>
                                              <View
                                                style={{
                                                  flexDirection: "row",
                                                  gap: 10,
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Probability of Default:
                                                </Text>
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  {
                                                    allCBSData?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]?.cbs_data
                                                      ?.ProbabilityofDefault
                                                  }
                                                </Text>
                                              </View>
                                              <View
                                                style={{
                                                  flexDirection: "row",
                                                  gap: 10,
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Unsecured Credit Utilized:
                                                </Text>
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  {allCBSData
                                                    ?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]
                                                    ?.cbs_data?.UnsecuredCreditUtilized?.toLocaleString(
                                                      "en-US",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                </Text>
                                              </View>
                                              <View
                                                style={{
                                                  flexDirection: "row",
                                                  gap: 10,
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Unsecured Credit Utilization
                                                  in percentage:
                                                </Text>
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  {Number(
                                                    allCBSData?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]?.cbs_data
                                                      ?.UnsecuredCreditUtilizationinpercentage
                                                  ) || 0 + "%"}
                                                </Text>
                                              </View>
                                            </View>
                                            <View style={{ width: "50%" }}>
                                              <View
                                                style={{
                                                  flexDirection: "row",
                                                  gap: 10,
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Unsecured Credit Limit:
                                                </Text>
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  {allCBSData
                                                    ?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]
                                                    ?.cbs_data?.UnsecuredCreditLimit?.toLocaleString(
                                                      "en-US",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                </Text>
                                              </View>
                                              <View
                                                style={{
                                                  flexDirection: "row",
                                                  gap: 10,
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Risk Grade:
                                                </Text>
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  {
                                                    allCBSData?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]?.cbs_data?.RiskGrade
                                                  }
                                                </Text>
                                              </View>
                                              <View
                                                style={{
                                                  flexDirection: "row",
                                                  gap: 10,
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Secured Credit Utilized:
                                                </Text>
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  {allCBSData
                                                    ?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]
                                                    ?.cbs_data?.SecuredCreditUtilized?.toLocaleString(
                                                      "en-US",
                                                      {
                                                        style: "currency",
                                                        currency: "SGD",
                                                      }
                                                    )}
                                                </Text>
                                              </View>
                                              <View
                                                style={{
                                                  flexDirection: "row",
                                                  gap: 10,
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Secured Credit Utilization in
                                                  percentage:
                                                </Text>
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  {Number(
                                                    allCBSData?.filter(
                                                      (dat2) =>
                                                        dat2?.name ===
                                                        data?.person?.name
                                                          ?.value
                                                    )?.[0]?.cbs_data
                                                      ?.SecuredCreditUtilizationinpercentage
                                                  ) || 0 + "%"}
                                                </Text>
                                              </View>
                                              <View
                                                style={{
                                                  flexDirection: "row",
                                                  gap: 10,
                                                }}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  Monthly
                                                  Commitment/Installment:
                                                </Text>
                                                <Text
                                                  style={{
                                                    fontSize: 7,
                                                  }}
                                                >
                                                  {(
                                                    Number(
                                                      allCBSData?.filter(
                                                        (dat2) =>
                                                          dat2?.name ===
                                                          data?.person?.name
                                                            ?.value
                                                      )?.[0]?.cbs_data
                                                        ?.MonthlyCommitment
                                                    ) || 0
                                                  )?.toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "SGD",
                                                  })}
                                                </Text>
                                              </View>
                                            </View>
                                          </View>
                                          <View
                                            style={{
                                              padding: 8,
                                              borderColor: "#c7dcec",
                                              borderLeftWidth: 2,
                                              borderRightWidth: 2,
                                            }}
                                          >
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                width: "100%",
                                                backgroundColor: "#c7dcec",
                                                opacity: 0.7,
                                                padding: 4,
                                                gap: 10,
                                              }}
                                            >
                                              <View
                                                style={{
                                                  width: "30%",
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                <Text>Loan / Product Type</Text>
                                              </View>
                                              <View
                                                style={{
                                                  width: "30%",
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                <Text>Grantor / Bank</Text>
                                              </View>
                                              <View
                                                style={{
                                                  width: "20%",
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                <Text>Outstanding Balance</Text>
                                              </View>
                                              <View
                                                style={{
                                                  width: "20%",
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                }}
                                              >
                                                <Text>Monthly Installment</Text>
                                              </View>
                                            </View>

                                            {allCBSData
                                              ?.filter(
                                                (dat2) =>
                                                  dat2?.name ===
                                                  data?.person?.name?.value
                                              )?.[0]
                                              ?.cbs_data?.NoteworthyOngoingLoans?.map(
                                                (imp, index10) => (
                                                  <View
                                                    key={index10}
                                                    style={{
                                                      flexDirection: "row",
                                                      width: "100%",
                                                      backgroundColor:
                                                        index10 % 2
                                                          ? "rgba(199, 220, 236, 0.4)"
                                                          : "rgba(199, 220, 236, 0.15)",
                                                      padding: 4,
                                                      gap: 10,
                                                    }}
                                                  >
                                                    <View
                                                      style={{
                                                        width: "30%",
                                                        fontSize: 7,
                                                        fontFamily: "Poppins",
                                                      }}
                                                    >
                                                      <Text>
                                                        {imp.includes(
                                                          "private residential purchase",
                                                          ""
                                                        )
                                                          ? "Private Residential Purchase"
                                                          : imp.includes(
                                                              "motor vehicle loan",
                                                              ""
                                                            )
                                                          ? "Motor Vehicle Loan"
                                                          : imp.includes(
                                                              "hdb loan",
                                                              ""
                                                            )
                                                          ? "HDB Loan"
                                                          : ""}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        width: "30%",
                                                        fontSize: 7,
                                                        fontFamily: "Poppins",
                                                      }}
                                                    >
                                                      <Text>
                                                        {imp
                                                          .replace(
                                                            "private residential purchase",
                                                            ""
                                                          )
                                                          .replace(
                                                            "motor vehicle loan",
                                                            ""
                                                          )
                                                          .replace(
                                                            "hdb loan",
                                                            ""
                                                          )
                                                          .trim()
                                                          .split(" ")[0]
                                                          .toUpperCase()}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        width: "20%",
                                                        fontSize: 7,
                                                        fontFamily: "Poppins",
                                                      }}
                                                    >
                                                      <Text>
                                                        {parseFloat(
                                                          imp
                                                            .split("   ")
                                                            [
                                                              imp.split("   ")
                                                                .length - 4
                                                            ].replace(/,/g, "")
                                                        ).toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={{
                                                        width: "20%",
                                                        fontSize: 7,
                                                        fontFamily: "Poppins",
                                                      }}
                                                    >
                                                      <Text>
                                                        {allCBSData?.filter(
                                                          (dat2) =>
                                                            dat2?.name ===
                                                            data?.person?.name
                                                              ?.value
                                                        )?.[0]?.cbs_data
                                                          ?.CBSImpLoanSupplementaryInstallment?.[
                                                          index10
                                                        ]
                                                          ? (
                                                              parseFloat(
                                                                allCBSData
                                                                  ?.filter(
                                                                    (dat2) =>
                                                                      dat2?.name ===
                                                                      data
                                                                        ?.person
                                                                        ?.name
                                                                        ?.value
                                                                  )?.[0]
                                                                  ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                    index10
                                                                  ].split("   ")
                                                                  [
                                                                    allCBSData
                                                                      ?.filter(
                                                                        (
                                                                          dat2
                                                                        ) =>
                                                                          dat2?.name ===
                                                                          data
                                                                            ?.person
                                                                            ?.name
                                                                            ?.value
                                                                      )?.[0]
                                                                      ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                        index10
                                                                      ].split(
                                                                        "   "
                                                                      ).length -
                                                                      4
                                                                  ]?.replace(
                                                                    /,/g,
                                                                    ""
                                                                  )
                                                              ) +
                                                              parseFloat(
                                                                allCBSData
                                                                  ?.filter(
                                                                    (dat2) =>
                                                                      dat2?.name ===
                                                                      data
                                                                        ?.person
                                                                        ?.name
                                                                        ?.value
                                                                  )?.[0]
                                                                  ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                    index10
                                                                  ].split("   ")
                                                                  [
                                                                    allCBSData
                                                                      ?.filter(
                                                                        (
                                                                          dat2
                                                                        ) =>
                                                                          dat2?.name ===
                                                                          data
                                                                            ?.person
                                                                            ?.name
                                                                            ?.value
                                                                      )?.[0]
                                                                      ?.cbs_data?.CBSImpLoanSupplementaryInstallment[
                                                                        index10
                                                                      ].split(
                                                                        "   "
                                                                      ).length -
                                                                      3
                                                                  ]?.replace(
                                                                    /,/g,
                                                                    ""
                                                                  )
                                                              )
                                                            ).toLocaleString(
                                                              "en-US",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "SGD",
                                                              }
                                                            )
                                                          : null}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )
                                              )}
                                          </View>
                                          <View
                                            style={{
                                              padding: 8,
                                              borderColor: "#c7dcec",
                                              borderLeftWidth: 2,
                                              borderRightWidth: 2,
                                              borderBottomWidth: 2,
                                            }}
                                          >
                                            <View
                                              style={{
                                                flexDirection: "row",
                                                gap: 10,
                                              }}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: 7,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 700,
                                                }}
                                              >
                                                Last 6 Months (Unsecured) Credit
                                                Utilization Graph:
                                              </Text>
                                            </View>
                                            <View
                                              style={{
                                                width: "100%",
                                                fontSize: 7,
                                              }}
                                            >
                                              <View
                                                style={{ flexDirection: "row" }}
                                              >
                                                <View
                                                  style={{
                                                    width: "5%",
                                                    position: "relative",
                                                  }}
                                                >
                                                  <View
                                                    style={{ height: "15px" }}
                                                  >
                                                    <Text
                                                      style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                      }}
                                                    >
                                                      {allCBSData
                                                        ?.filter(
                                                          (dat2) =>
                                                            dat2?.name ===
                                                            data?.person?.name
                                                              ?.value
                                                        )?.[0]
                                                        ?.cbs_data?.Last6MonthsGraphChartMaxData?.toLocaleString(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "SGD",
                                                          }
                                                        )}
                                                    </Text>
                                                  </View>
                                                  <View
                                                    style={{ height: "220px" }}
                                                  ></View>
                                                </View>
                                                <View
                                                  style={{
                                                    width: "95%",
                                                  }}
                                                >
                                                  <View
                                                    style={{
                                                      width: "100%",
                                                      flexDirection: "row",
                                                      marginTop: "25px",
                                                      borderBottom:
                                                        "1px solid lightgrey",
                                                      height: "200px",
                                                      alignItems: "flex-end",
                                                    }}
                                                  >
                                                    <View
                                                      style={{
                                                        height: "200px",
                                                        borderLeft:
                                                          "1px solid lightgrey",
                                                      }}
                                                    />
                                                    {allCBSData
                                                      ?.filter(
                                                        (dat2) =>
                                                          dat2?.name ===
                                                          data?.person?.name
                                                            ?.value
                                                      )?.[0]
                                                      ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                        (dat30, index30) => (
                                                          <View
                                                            key={index30}
                                                            style={{
                                                              marginLeft: "4%",
                                                              marginRight: "4%",
                                                              width: "8%",
                                                              borderTopLeftRadius:
                                                                "4px",
                                                              borderTopRightRadius:
                                                                "4px",
                                                              height: `${
                                                                (parseFloat(
                                                                  dat30.value.replace(
                                                                    /,/g,
                                                                    ""
                                                                  )
                                                                ) /
                                                                  allCBSData?.filter(
                                                                    (dat2) =>
                                                                      dat2?.name ===
                                                                      data
                                                                        ?.person
                                                                        ?.name
                                                                        ?.value
                                                                  )?.[0]
                                                                    ?.cbs_data
                                                                    ?.Last6MonthsGraphChartMaxData) *
                                                                100
                                                              }%`,
                                                              backgroundColor:
                                                                "rgba(217, 238, 255, 1)",
                                                            }}
                                                          ></View>
                                                        )
                                                      )}
                                                  </View>
                                                  <View
                                                    style={{
                                                      marginTop: "8px",
                                                      flexDirection: "row",
                                                      fontSize: 7,
                                                    }}
                                                  >
                                                    {allCBSData
                                                      ?.filter(
                                                        (dat2) =>
                                                          dat2?.name ===
                                                          data?.person?.name
                                                            ?.value
                                                      )?.[0]
                                                      ?.cbs_data?.Last6MonthsGraphChartData?.map(
                                                        (dat, index) => (
                                                          <View
                                                            key={index}
                                                            style={{
                                                              width: "16%",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            <Text
                                                              style={{
                                                                fontWeight: 600,
                                                                fontSize: 7,
                                                              }}
                                                            >
                                                              {dat.date
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                                dat.date.slice(
                                                                  1
                                                                )}
                                                            </Text>
                                                            <Text
                                                              style={{
                                                                fontWeight: 600,
                                                                fontSize: 7,
                                                              }}
                                                            >
                                                              {parseFloat(
                                                                dat.value.replace(
                                                                  /,/g,
                                                                  ""
                                                                )
                                                              ).toLocaleString(
                                                                "en-US",
                                                                {
                                                                  style:
                                                                    "currency",
                                                                  currency:
                                                                    "SGD",
                                                                }
                                                              )}
                                                            </Text>
                                                          </View>
                                                        )
                                                      )}
                                                  </View>
                                                </View>
                                              </View>
                                            </View>
                                          </View>
                                        </View>
                                      ) : null}
                                    </View>
                                  </Page>
                                ))
                              : null}
                          </Document>
                        </PDFViewer>
                      </div>
                    )
                  ) : null}
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </Modal>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
            <Box
              gridColumn="span 9"
              gridRow="span 2"
              height="100vh"
              width="100%"
              borderRadius="10px"
              border="0px solid "
              opacity="0.95"
              style={{
                overflowY: "scroll",
                overflowX: "scroll",
                background:
                  "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                borderRadius="10px"
                margin="20px 20px 20px 20px"
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  Requested Loan
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "38px",
                  }}
                >
                  <label
                    htmlFor="sort-by"
                    style={{
                      width: "50%",
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontSize: "13px",
                      fontFamily: "Manrope, sans-serif",
                      marginRight: "-5px",
                    }}
                  >
                    Sort by:
                  </label>
                  <div style={{ width: "80%", marginRight: "10px" }}>
                    <select
                      id="sort-by"
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                      style={{
                        width: "100%",
                        borderRadius: "30px",
                        color: "#0E5AA5",
                        backgroundColor: "#ECF0F5",
                        border: "1px solid #3E536733",
                        marginRight: "15px",
                        fontWeight: "600",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13px",
                      }}
                    >
                      <option
                        style={{
                          fontWeight: "600",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "13px",
                        }}
                        value="default"
                      >
                        Date & Time
                      </option>
                      <option
                        style={{
                          fontWeight: "600",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "13px",
                        }}
                        value="loan-amount"
                      >
                        Loan Amount
                      </option>
                      <option
                        style={{
                          fontWeight: "600",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "13px",
                        }}
                        value="date"
                      >
                        Date
                      </option>
                    </select>
                  </div>
                  {/* <button onClick={(event) => showModal(selectedBorrowerIds, event)} style={{ backgroundColor: "white", borderRadius: "10px", color: "#B13434", border: "1px solid #B13434", fontWeight: "600", fontFamily: "Poppins, sans-serif", fontSize: "13px", width:"80%" }}>Warn Borrower</button> */}
                </div>
              </Box>

              <Box>
                <TableAdminLoanRequest
                  onRowClick={handleRowClick}
                  onSelectionChange={handleSelectionChange}
                  removedLoan={removeLoan}
                />
              </Box>
            </Box>

            <Box
              gridColumn="span 3"
              gridRow="span 2"
              height="89vh"
              borderRadius="10px"
              border="0px solid white"
              style={{
                background:
                  "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // borderBottom={`4px solid ${colors.primary[500]}`}
                colors={colors.grey[910]}
                marginTop="-15px"
                marginBottom="10px"
                p="15px"
              >
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="18px"
                >
                  Requested Loan Details
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                backgroundColor="#FFFFFF"
                borderRadius="10px"
                margin="-10px 10px"
                style={{
                  overflowY: "scroll",
                }}
                p="15px"
                height="83.7vh"
              >
                {data && data.length > 0 ? (
                  data.map((item) => (
                    <Box
                      key={item.id}
                      display="flex"
                      justifyContent="center"
                      alignItems="flex-start" // Updated to align to the top
                      colors={colors.grey[910]}
                      // borderBottom={`1px solid ${colors.primary[500]}`}
                      width="100%"
                      textAlign="center"
                      margin="0px"
                      flexDirection="column"
                    >
                      <ContainerActivity
                        item={item}
                        icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg" //map to data laterwards
                        title={item.principal_name || "Hello World"}
                        subtitle={
                          item.category
                            ? item.category.charAt(0).toUpperCase() +
                              item.category.slice(1)
                            : "-"
                        }
                      />
                      <div
                        style={{
                          borderBottom: "2px solid black",
                          width: "100%",
                          opacity: "0.3",
                        }}
                      />
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    colors={colors.grey[910]}
                    width="100%"
                    textAlign="center"
                    margin="1px 1px 1px 0px"
                    flexDirection="column"
                    height="80vh"
                  >
                    <ContainerActivity
                      icon={warn_ico}
                      title="Please Click on Table Row to View Details"
                    />
                  </Box>
                )}
                {!data && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    colors={colors.grey[910]}
                    width="100%"
                    textAlign="center"
                    margin="0px"
                    flexDirection="column"
                  >
                    <ContainerActivity
                      icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg"
                      title="NO VALUE HERE"
                      subtitle="NO VALUE HERE"
                    />
                    <div
                      style={{
                        borderBottom: "2px solid black",
                        width: "100%",
                        opacity: "0.3",
                      }}
                    />
                  </Box>
                )}

                {/* <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Loan ID"
                        label={item.loan_id || "-"}
                      />
                    ))}
                </Box> */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Created At"
                        label={formatDate(item.created_at) || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Expired Date"
                        label={formatDate(item.expired_date) || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Amount"
                        label={
                          item.request_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Interest Rate"
                        label={
                          item.request_interest_rate
                            ? (item.request_interest_rate * 100).toFixed(1) +
                              "%"
                            : "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Total Interest"
                        label={
                          item.total_interest.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Period"
                        label={item.request_period + " Months" || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Admin Fee"
                        label={`${(item.admin_fee * 100).toFixed(1)}%` || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Request Monthly Installment"
                        label={
                          item.request_monthly_installment.toLocaleString(
                            "en-US",
                            { style: "currency", currency: "SGD" }
                          ) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Principal"
                        label={
                          item.request_principal.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Download Bank Statement"
                        label={
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(item?.bank_statement);
                            }}
                            style={{
                              background: "rgb(17, 92, 166)",
                              color: "white",
                              padding: "7px 30px",
                              border: "none",
                              borderRadius: "8px",
                              cursor: "pointer",
                              marginTop: "10px",
                            }}
                          >
                            Download
                          </div>
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Remove/Delete loan"
                        label={
                          <div style={{ display: "flex", gap: "10px" }}>
                            {!item.admin_removal ? (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAdminRemoval({
                                    loan_id: item?.loan_id,
                                    boolean: 1,
                                  });
                                }}
                                style={{
                                  background: "rgb(17, 92, 166)",
                                  color: "white",
                                  padding: "7px 30px",
                                  border: "none",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                  marginTop: "10px",
                                }}
                              >
                                Remove
                              </div>
                            ) : (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAdminRemoval({
                                    loan_id: item?.loan_id,
                                    boolean: 0,
                                  });
                                }}
                                style={{
                                  background: "rgb(17, 92, 166)",
                                  color: "white",
                                  padding: "7px 30px",
                                  border: "none",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                  marginTop: "10px",
                                }}
                              >
                                Push
                              </div>
                            )}
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                handleLoanDelete(item?.loan_id);
                              }}
                              style={{
                                background: "rgb(17, 92, 166)",
                                color: "white",
                                padding: "7px 30px",
                                border: "none",
                                borderRadius: "8px",
                                cursor: "pointer",
                                marginTop: "10px",
                              }}
                            >
                              Delete
                            </div>
                          </div>
                        }
                      />
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              padding: "0px 20px",
              height: "100vh",
              borderRadius: "20px",
              width: "95%",
              marginLeft: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "10px",
                width: "100%",
              }}
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="20px"
              >
                Requested Loan
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "33%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label
                    htmlFor="sort-by"
                    style={{
                      color: "#142C44CC",
                      fontWeight: "800",
                      fontSize: "10px",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    Sort by:
                  </label>

                  <select
                    id="sort-by"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "11px",
                    }}
                  >
                    <option value="default">Date & Time</option>
                    <option value="loan-amount">Loan Amount</option>
                    <option value="date">Date</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <TableAdminLoanRequest
                onRowClick={handleRowClick}
                onSelectionChange={handleSelectionChange}
              />
            </div>
            <div
              style={{
                height: "89vh",
                borderRadius: "20px",
                border: "0px solid white",
                background:
                  "transparent linear-gradient(0deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <div>
                <Typography
                  color={colors.grey[920]}
                  variant="h5"
                  fontWeight="800"
                  fontFamily="Manrope, sans-serif"
                  fontSize="20px"
                  marginTop="10px"
                >
                  Requested Loan Details
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "20px",
                  overflowY: "scroll",
                  height: "83.7vh",
                }}
              >
                {data && data.length > 0 ? (
                  data.map((item) => (
                    <Box
                      key={item.id}
                      display="flex"
                      justifyContent="center"
                      alignItems="flex-start" // Updated to align to the top
                      colors={colors.grey[910]}
                      // borderBottom={`1px solid ${colors.primary[500]}`}
                      width="45%"
                      textAlign="center"
                      margin="1px 1px 1px 30px"
                      flexDirection="column"
                    >
                      <ContainerActivity
                        icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg" //map to data laterwards
                        title={item.loan_id || "Hello World"}
                        subtitle={
                          item.category
                            ? item.category.charAt(0).toUpperCase() +
                              item.category.slice(1)
                            : "-"
                        }
                      />
                      <div
                        style={{
                          borderBottom: "2px solid black",
                          width: "190%",
                          opacity: "0.3",
                        }}
                      />
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    colors={colors.grey[910]}
                    width="100%"
                    textAlign="center"
                    margin="1px 1px 1px 0px"
                    flexDirection="column"
                    height="80vh"
                  >
                    <ContainerActivity
                      icon={warn_ico}
                      title="Please Click on Table Row to View Details"
                    />
                  </Box>
                )}
                {!data && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    colors={colors.grey[910]}
                    width="45%"
                    textAlign="center"
                    margin="1px 1px 1px 30px"
                    flexDirection="column"
                  >
                    <ContainerActivity
                      icon="https://www.shutterstock.com/image-photo/kiev-ukraine-march-31-2015-260nw-270200051.jpg"
                      title="NO VALUE HERE"
                      subtitle="NO VALUE HERE"
                    />
                    <div
                      style={{
                        borderBottom: "2px solid black",
                        width: "190%",
                        opacity: "0.3",
                      }}
                    />
                  </Box>
                )}

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Lender"
                        label={item.principal_name || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Type"
                        label={
                          item.type
                            ? item.type.charAt(0).toUpperCase() +
                              item.type.slice(1)
                            : "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Status"
                        label={
                          item.type
                            ? item.type.charAt(0).toUpperCase() +
                              item.type.slice(1)
                            : "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Created At"
                        label={formatDate(item.created_at) || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Expired Date"
                        label={formatDate(item.expired_date) || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Amount"
                        label={
                          item.request_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Interest Rate"
                        label={
                          item.request_interest_rate
                            ? item.request_interest_rate * 100 + "%"
                            : "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Total Interest"
                        label={
                          item.total_interest.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Period"
                        label={item.request_period + " Months" || "-"}
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Admin Fee"
                        label={
                          item.admin_fee.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Request Monthly Installment"
                        label={
                          item.request_monthly_installment.toLocaleString(
                            "en-US",
                            { style: "currency", currency: "SGD" }
                          ) || "-"
                        }
                      />
                    ))}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[910]}
                  marginLeft="0px"
                  marginTop="0px"
                >
                  {data &&
                    data.length > 0 &&
                    data.map((item) => (
                      <ContainerTransaction
                        title="Principal"
                        label={
                          item.request_principal.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          }) || "-"
                        }
                      />
                    ))}
                </Box>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestedLoan;
