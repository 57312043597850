import { Checkbox } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MobileStepper } from "@mui/material";
import OTPInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

import smartPhoneIcon from "../../assets/img/smartphone-icon.png";
function OTPCode() {
  const [otp, setOtp] = useState("");
  const session = Cookies.get("session");
  const [phoneNo, setPhoneNumber] = useState(Cookies.get("phoneNo") || 0);
  const [screenWidth, setScreenWidth] = useState("1920");
  const history = useHistory();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (Cookies.get("phoneNo")) {
      setPhoneNumber(Cookies.get("phoneNo"));
    }
  }, []);

  const handleOtpVerification = (otp) => {
    if (otp.length === 4 && otp !== "") {
      // Make API call with the OTP value as a parameter
      fetch("https://www.smart-lend.com/api/auth/register/mobile/verifyOtp", {
        method: "POST",
        body: JSON.stringify({ otp: otp, session: session }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
        .then((response) => {
          console.log("response value", response);
          if (response.status === 200) {
            Cookies.set("user_account_type", "borrower");
            Cookies.set("singpass_sync", null);
            history.push("/b-dashboard");
            return response.json();
          }
        })
        .then((data) => {
          if (data.success) {
            console.log("OTP verification successful!");
          } else {
            console.log("OTP verification failed.");
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    }
  };

  const handleResendOtp = async (event) => {
    event.preventDefault();
    // Your API endpoint
    const url = "https://www.smart-lend.com/api/register/mobile";
    // Data to send in the request body
    const data = { mobile_no: phoneNo };
    console.log("phoneNo value", phoneNo);

    // Fetch call to send the OTP
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
      .then((response) => {
        if (response.status === 302) {
          // Get the redirect location
          const redirectUrl = response.headers.get("Location");
          // Follow the redirect by making another fetch call to the new location
          return fetch(redirectUrl);
        }
        return response.json();
      })
      .then((data) => {
        // Store the new value from the API in the sessionStorage
        Cookies.set("session", data.data.session);
        console.log("value of data session", data.data.session);
      })
      .catch((error) => {
        console.log("error value", error);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [count, setCount] = useState(60);

  useEffect(() => {
    if (count > 0) {
      const timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [count]);

  return (
    <>
      {screenWidth > 1200 ? (
        <React.Fragment>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="auth-header">
              <h3 className="otp-header-title">
                <img
                  src={smartPhoneIcon}
                  alt=""
                  className="auth-header-otp-logo"
                />
                Enter OTP Code
              </h3>
              <p className="otp-header-subtitle">
                A verification has been sent to{" "}
                <span style={{ color: "blue" }}>+{phoneNo}</span>
              </p>
            </div>
            <div className="auth-body">
              <form className="auth-form-validation">
                <div className="otp-bx">
                  <OTPInput
                    numInputs={4}
                    value={otp}
                    onChange={(otp) => {
                      setOtp(otp);
                      console.log("validating OTP:", otp);
                      handleOtpVerification(otp);
                    }}
                    className="otp-bx-input"
                  />
                </div>
                <br />
                {count !== 0 ? (
                  <div>
                    Having trouble receiving your otp? You may
                    <br /> resend in {count} seconds
                  </div>
                ) : (
                  <div className="btn-position">
                    <button
                      type="submit"
                      className="btn-submit-otp"
                      onClick={handleResendOtp}
                    >
                      Resend OTP
                    </button>
                  </div>
                )}
                <p className="text-center-otp">
                  Need Help?{" "}
                  <span
                    style={{
                      color: "#0e5aa5",
                      textDecoration: "underline",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.location.href = "https://wa.me/6588288147";
                    }}
                  >
                    Contact Us
                  </span>{" "}
                  If you would like to change your phone number{" "}
                  <span
                    style={{
                      color: "#0e5aa5",
                      textDecoration: "underline",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push("/auth/phone-number");
                    }}
                  >
                    Click here
                  </span>
                </p>
              </form>
              <div className="flex-end">
                <div style={{}}>
                  <MobileStepper
                    variant="dots"
                    steps={6}
                    position="static"
                    activeStep={4}
                    sx={{
                      maxWidth: 400,
                      flexGrow: 1,
                      marginLeft: -24.8,
                      marginTop: -2.8,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div>
          <div
            style={{
              background:
                "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",

              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="auth-header">
              <h3 className="otp-header-title">
                <img
                  src={smartPhoneIcon}
                  alt=""
                  className="auth-header-otp-logo"
                />
                Enter OTP Code
              </h3>
              <p className="otp-header-subtitle">
                A verification has been sent to{" "}
                <span style={{ color: "blue" }}>+{phoneNo}</span>
              </p>
            </div>
            <div className="auth-body">
              <form className="auth-form-validation">
                <div className="otp-bx">
                  <OTPInput
                    numInputs={4}
                    value={otp}
                    onChange={(otp) => {
                      setOtp(otp);
                      console.log("validating OTP:", otp);
                      handleOtpVerification(otp);
                    }}
                    className="otp-bx-input"
                  />
                </div>
                <br />
                <div className="btn-position">
                  <button
                    type="submit"
                    className="btn-submit-otp"
                    onClick={handleResendOtp}
                  >
                    Resend OTP
                  </button>
                </div>
                <div
                  style={{ width: "70%", marginLeft: "15%", display: "flex", alignItems: "center", flexDirection: "column" }}
                >
                  <p style={{ marginLeft: "-30px" }}>
                    Need Help?{" "}
                    <span
                      style={{
                        color: "#0e5aa5",
                        textDecoration: "underline",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.location.href = "https://wa.me/6588288147";
                      }}
                    >
                      Contact Us
                    </span>.{" "}
                    If you would like to change your phone number{" "}
                    <span
                      style={{
                        color: "#0e5aa5",
                        textDecoration: "underline",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push("/auth/phone-number");
                      }}
                    >
                      Click here
                    </span>
                  </p>

                  <div style={{}}>
                    <MobileStepper
                      variant="dots"
                      steps={6}
                      position="static"
                      activeStep={4}
                      sx={{ maxWidth: 400, flexGrow: 1, marginLeft: "-12px" }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OTPCode;
