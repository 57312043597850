import React, { useEffect, useRef, useState } from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { CircularProgress } from "@mui/material";
import { RemoveCircleOutlineOutlined } from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
import { message } from "antd";
import Cookies from "js-cookie";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { Modal } from "antd";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import parse, { domToReact } from "html-react-parser";
import boldFont from "../assets/font/Poppins-SemiBold.ttf";
import italicFont from "../assets/font/Poppins-Italic.ttf";

Font.register({
  family: "Poppins",
  fonts: [
    { src: boldFont, fontWeight: "bold" }, // Bold (700)
    { src: italicFont, fontStyle: "italic" }, // Bold (700)
  ],
});

const DocumentBuilder = () => {
  const [tabIndex, setTabIndex] = useState(1);
  const [documentAdjusting, setDocumentAdjusting] = useState(null);
  const tabs = [
    {
      name: "New",
      icon: <AddCircleOutlineOutlinedIcon className="icon-normal" />,
      iconSelected: <AddCircleOutlineOutlinedIcon className="icon-selected" />,
    },
    {
      name: "Recent",
      icon: <AccessTimeFilledOutlinedIcon className="icon-normal" />,
      iconSelected: <AccessTimeFilledOutlinedIcon className="icon-selected" />,
    },
    {
      name: "Shared",
      icon: <GroupOutlinedIcon className="icon-normal" />,
      iconSelected: <GroupOutlinedIcon className="icon-selected" />,
    },
  ];
  const divRef = useRef(null);
  const [openSettings, setOpenSettings] = useState(null);
  const [deleteDocumentModalIsOpen, setDeleteDocumentModalIsOpen] =
    useState(false);
  const [shareDocumentModalIsOpen, setShareDocumentModalIsOpen] =
    useState(false);
  const [previewDocumentModalIsOpen, setPreviewDocumentModalIsOpen] =
    useState(false);

  useEffect(() => {
    // Function to check if the click is outside the div
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        // If click is outside the div, update state
        setOpenSettings(null);
      }
    };

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [openvariableIndex, setOpenVariableindex] = useState(null);

  const getFormattedDate = () => {
    const today = new Date();

    // Format: DD-MM-YYYY
    const format1 = today
      .toLocaleDateString("en-GB")
      .split("/")
      .reverse()
      .join("-");

    // Format: DD Month YYYY
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const format2 = today.toLocaleDateString("en-GB", options);

    return `${format1}, ${format2}`;
  };

  const typeOfDocumentVariables = {
    loanAgreement: [
      {
        name: "Borrower",
        sub: [
          { name: "Name", sample: "Alexander Anderson" },
          { name: "NRIC", sample: "SA12345678" },
          {
            name: "Registered Address",
            sample: "123, Sample Borrower Address Street 123, Singapore.",
          },
        ],
      },
      {
        name: "Keyman",
        sub: [
          { name: "Name", sample: "James Aspen" },
          { name: "NRIC", sample: "SA12345678" },
          {
            name: "Registered Address",
            sample: "123, Sample Keyman Address Street 123, Singapore.",
          },
        ],
      },
      {
        name: "Guarantor/Custom User",
        sub: [
          { name: "Name", sample: "Arthur Williams" },
          { name: "NRIC", sample: "SA12345678" },
          {
            name: "Registered Address",
            sample: "123, Sample Guarantor Address Street 123, Singapore.",
          },
        ],
      },
      {
        name: "Entity Details",
        sub: [
          { name: "UEN Number", sample: "A12345678D" },
          { name: "Company Name", sample: "Incorporated Pte. Ltd." },
          {
            name: "Nature of Business",
            sample: "Manufacturing of retail sample goods",
          },
          {
            name: "Registered Address",
            sample: "123, Sample Company Address Street 123, Singapore.",
          },
        ],
      },
      { name: "Loan Amount", sample: "SGD 30,000.00" },
      { name: "Disbursement Amount", sample: "SGD 28,500.00" },
      { name: "Interest Rate", sample: "3.5%" },
      { name: "Total Interest Payable", sample: "SGD 6,300.00" },
      { name: "Principal Amount", sample: "SGD 36,300.00" },
      { name: "Loan Tenor", sample: "6 Months" },
      { name: "Monthly Installment", sample: "SGD 6,050.00" },
      { name: "Processing Fee", sample: "5.0%" },
      { name: "Processing Fee Amount", sample: "SGD 1,500.00" },
      {
        name: "Payment Schedule Table",
        sample: [
          {
            repay_amount: "SGD 6,050.00",
            interest: "SGD 1,050.00",
            principal: "SGD 5,000.00",
            repay_due_date: "2024-10-04",
          },
          {
            repay_amount: "SGD 6,050.00",
            interest: "SGD 1,050.00",
            principal: "SGD 5,000.00",
            repay_due_date: "2024-11-04",
          },
          {
            repay_amount: "SGD 6,050.00",
            interest: "SGD 1,050.00",
            principal: "SGD 5,000.00",
            repay_due_date: "2024-12-04",
          },
          {
            repay_amount: "SGD 6,050.00",
            interest: "SGD 1,050.00",
            principal: "SGD 5,000.00",
            repay_due_date: "2025-01-04",
          },
          {
            repay_amount: "SGD 6,050.00",
            interest: "SGD 1,050.00",
            principal: "SGD 5,000.00",
            repay_due_date: "2025-02-04",
          },
          {
            repay_amount: "SGD 6,050.00",
            interest: "SGD 1,050.00",
            principal: "SGD 5,000.00",
            repay_due_date: "2025-03-04",
          },
        ],
      },
      { name: "Document Generate Date", sample: getFormattedDate() },
    ],
  };

  const styles = StyleSheet.create({
    tableContainer: {
      marginBottom: 10,
      fontSize: 8,
      borderWidth: 1,
      borderColor: "#dddddd",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#dddddd",
      paddingBottom: 4,
      paddingTop: 4,
      fontSize: 8,
    },
    tableHeader: {
      fontFamily: "Poppins",
      fontWeight: "bold",
      fontSize: 8,
    },
    tableCol: {
      width: "50%",
      textAlign: "left",
      paddingLeft: 5,
      fontSize: 8,
    },
  });

  const PaymentScheduleTable = ({ schedule }) => (
    <View style={styles.tableContainer}>
      {/* Table Header */}
      <View style={[styles.tableRow, styles.tableHeader]}>
        <Text style={styles.tableCol}>Payment Date</Text>
        <Text style={styles.tableCol}>
          Principal (Rounded to the nearest dollar)
        </Text>
        <Text style={styles.tableCol}>Interest</Text>
        <Text style={styles.tableCol}>Total Amount due</Text>
      </View>
      {/* Table Rows */}
      {schedule.map((item, index) => (
        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: schedule.length - 1 === index ? 0 : 1,
            borderBottomColor: "#dddddd",
            paddingBottom: 4,
            paddingTop: 4,
          }}
          key={index}
        >
          <Text style={styles.tableCol}>{item.repay_due_date}</Text>
          <Text style={styles.tableCol}>{item.principal}</Text>
          <Text style={styles.tableCol}>{item.interest}</Text>
          <Text style={styles.tableCol}>{item.repay_amount}</Text>
        </View>
      ))}
    </View>
  );

  const headerTemplates = [
    {
      id: 1,
      image:
        "https://static.vecteezy.com/system/resources/previews/040/250/046/non_2x/abstract-web-banner-template-design-with-different-geometric-elements-gradient-cover-design-website-header-background-social-media-business-cover-ads-banner-for-invitation-card-brochure-poster-vector.jpg",
    },
    {
      id: 2,
      image:
        "https://static.vecteezy.com/system/resources/previews/040/250/046/non_2x/abstract-web-banner-template-design-with-different-geometric-elements-gradient-cover-design-website-header-background-social-media-business-cover-ads-banner-for-invitation-card-brochure-poster-vector.jpg",
    },
  ];

  function formatDate(inputDate) {
    // Parse the input date string
    const date = new Date(inputDate);

    // Define month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    // Format the date as "DD Mon, YYYY"
    const formattedDate = `${day} ${monthNames[parseFloat(month)]} ${year}`;
    console.log(parseFloat(month) + 1);

    return formattedDate;
  }

  const [documents, setDocuments] = useState([]);

  const [documentCreationStep, setDocumentCreationStep] = useState(0);
  const [newDocumentData, setNewDocumentData] = useState({
    typeOfDocument: "Loan Agreement",
    headerTemplateId: 0,
  });
  const [error, setError] = useState(0);
  const [fileUploading, setFileUploading] = useState(false);
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(null);
  const [shareWith, setShareWith] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [userData, setUserData] = useState([]);

  const getAllUser = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/superAdminSl/getAllUser",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        setUserData(json.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUploadLogo = async (e) => {
    setFileUploading(true);
    console.log(e.target.files[0]);
    const files = e.target.files;

    const formData = new FormData();

    formData.append("file", files[0]);

    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/file/uploadUniversalFile",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        const imageUrl = data.url;

        const updatingCurrentDocument = [...documents];
        updatingCurrentDocument[currentDocumentIndex].document_data.logo =
          imageUrl;
        setDocuments(updatingCurrentDocument);
        setFileUploading(false);
      } else {
        // Handle non-OK response status
        console.error("Failed to upload files");
        setFileUploading(false);
      }
    } catch (error) {
      console.error(error);
      setFileUploading(false);
    }
  };

  const createOrUpdateDocument = async (data) => {
    try {
      let itemToSend = { document_data: JSON.stringify(data) };
      if (currentDocumentIndex !== null) {
        itemToSend.id = documents[currentDocumentIndex].id;
      }
      console.log(itemToSend);
      const response = await fetch(
        "https://www.smart-lend.com/api/documentModule/createOrUpdateDocument",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(itemToSend),
        }
      );

      if (response.ok) {
        const data = await response.json();
        message.success("Document saved!");
        const updatingDocument = [...documents];
        if (currentDocumentIndex !== null) {
          data.document.document_data = JSON.parse(data.document.document_data);
          updatingDocument[currentDocumentIndex] = data.document;
          console.log(data);
          setDocuments(updatingDocument);
        } else {
          data.document.document_data = JSON.parse(data.document.document_data);
          updatingDocument[currentDocumentIndex] = data.document;
          console.log([...documents, data.document]);
          setDocuments([...documents, data.document]);
        }
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDocument = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/documentModule/getDocument",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        for (const doc of data.document) {
          doc.document_data = JSON.parse(doc.document_data);
        }
        console.log(data);
        setDocuments(data.document);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteDocument = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/documentModule/deleteDocument",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({ id: documentAdjusting }),
        }
      );

      if (response.ok) {
        const updatingDocuments = [...documents];
        const documentIndex = documents.findIndex(
          (doc) => doc.id === documentAdjusting
        );
        if (documentIndex > -1) {
          updatingDocuments.splice(documentIndex, 1);
          setDocuments(updatingDocuments);
          message.success("Document deleted!");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const shareDocument = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/documentModule/shareDocument",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({ id: documentAdjusting, user_id: shareWith }),
        }
      );

      if (response.ok) {
        message.success("Document succesfully shared!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDocument();
    getAllUser();
  }, []);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
    // Intercept image insertion and modify image HTML
    clipboard: {
      matchVisual: false,
    },
  };

  // Modify HTML of the inserted image
  const handleImageInserted = (node) => {
    if (node.tagName === "IMG") {
      node.style.width = "100%";
    }
  };

  const quillRef = useRef(null); // Reference to access the editor

  const handleVariableInsert = (variable, subVariable = null) => {
    const quill = quillRef.current.getEditor();
    const range = quill.selection.savedRange;

    if (range) {
      let variableText = `{{${variable.name}}}`;

      if (subVariable) {
        variableText = `{{${variable.name}.${subVariable.name}}}`;
      }

      quill.insertText(range.index, variableText); // Insert variable at cursor

      // Move cursor to the end of the inserted text
      const newCursorPosition = range.index + variableText.length;
      quill.setSelection(newCursorPosition, 0);
    }
  };

  //   const convertHtmlToPdfRenderer = (htmlString) => {
  //     if (htmlString) {
  //       // Match all opening HTML tags, including ones with attributes
  //       const tagRegex = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  //       const matches = htmlString.match(tagRegex);

  //       console.log("Extracted Tags:", matches);
  //       return matches;
  //     }
  //   };

  const replaceVariables = (htmlString) => {
    typeOfDocumentVariables.loanAgreement.forEach((variable) => {
      // Handle the "Payment Schedule Table" separately
      if (variable.name === "Payment Schedule Table") {
        const variablePattern = new RegExp(`{{\\s*${variable.name}\\s*}}`, "g");
        htmlString = htmlString.replace(
          variablePattern,
          `<div class="payment-schedule"></div>`
        );
      } else if (variable.sub) {
        // If the variable has sub-variables
        variable.sub.forEach((subVariable) => {
          const subVariablePattern = new RegExp(
            `{{\\s*${variable.name}\\.${subVariable.name}\\s*}}`,
            "g"
          );
          htmlString = htmlString.replace(
            subVariablePattern,
            subVariable.sample
          );
        });
      } else {
        // Handle variables without sub-variables
        const variablePattern = new RegExp(`{{\\s*${variable.name}\\s*}}`, "g");
        htmlString = htmlString.replace(variablePattern, variable.sample);
      }
    });

    return htmlString;
  };

  const convertHtmlToPdfRenderer = (htmlString) => {
    if (!htmlString) return null;

    console.log(htmlString);

    const replacedHtmlString = replaceVariables(htmlString);

    const options = {
      replace: (domNode) => {
        const { name, children, attribs } = domNode;

        // Handle styling tags
        if (name === "strong") {
          return (
            <Text
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: 8,
              }}
            >
              {domToReact(children, options)}{" "}
              {/* Process children for nested tags */}
            </Text>
          );
        } else if (name === "em" || name === "i") {
          return (
            <Text
              style={{
                fontFamily: "Poppins",
                fontStyle: "italic",
                fontSize: 8,
              }}
            >
              {domToReact(children, options)}{" "}
              {/* Process children for nested tags */}
            </Text>
          );
        } else if (name === "u") {
          return (
            <Text style={{ textDecoration: "underline", fontSize: 8 }}>
              {domToReact(children, options)}{" "}
              {/* Process children for nested tags */}
            </Text>
          );
        } else if (name === "s" || name === "strike") {
          return (
            <Text style={{ textDecoration: "line-through", fontSize: 8 }}>
              {domToReact(children, options)}{" "}
              {/* Process children for nested tags */}
            </Text>
          );
        }

        // Now handle structural tags
        if (name === "p") {
          return (
            <Text style={{ fontSize: 8 }}>
              {domToReact(children, options)}{" "}
              {/* Process children for nested tags */}
            </Text>
          );
        } else if (name === "br") {
          return <Text>{"\n"}</Text>;
        } else if (name === "ul" || name === "ol") {
          return (
            <View style={{ paddingLeft: 10, fontSize: 8 }}>
              {domToReact(children, options)}{" "}
              {/* Process children for nested tags */}
            </View>
          );
        } else if (name === "li") {
          // Handle indentation based on class name
          const indentClass = attribs?.class;
          let indentLevel = 0;

          if (indentClass && indentClass.startsWith("ql-indent-")) {
            const match = indentClass.match(/ql-indent-(\d+)/);
            if (match && match[1]) {
              indentLevel = parseInt(match[1], 10);
            }
          }

          return (
            <Text
              style={{
                marginBottom: 5,
                fontSize: 8,
                paddingLeft: 8 * indentLevel, // Adjust padding based on indent level
              }}
            >
              • {domToReact(children, options)}{" "}
              {/* Process children for nested tags */}
            </Text>
          );
        }

        // Handle Payment Schedule Table
        if (name === "div" && domNode.attribs?.class === "payment-schedule") {
          const schedule = typeOfDocumentVariables.loanAgreement.find(
            (v) => v.name === "Payment Schedule Table"
          ).sample;
          return <PaymentScheduleTable schedule={schedule} />;
        }

        // Handle any other tags if needed
        return null;
      },
    };

    // Parse the HTML string
    return parse(replacedHtmlString, options);
  };

  const PdfDocument = () => (
    <Document>
      <Page
        size="A4"
        style={{
          padding: 40,
          position: "relative",
          borderLeftWidth:
            documents[currentDocumentIndex]?.document_data?.headerTemplateId ===
            1
              ? 10
              : 0,
          borderLeftColor: documents[currentDocumentIndex]?.document_data
            ?.docHighlightColor
            ? documents[currentDocumentIndex]?.document_data?.docHighlightColor
            : "rgb(14, 90, 165)",
        }}
      >
        <View>
          {documents[currentDocumentIndex]?.document_data?.headerTemplateId ===
          0 ? (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 20,
              }}
            >
              <View style={{ width: 100 }}>
                <Image
                  src={documents[currentDocumentIndex].document_data?.logo}
                  style={{
                    height: 80,
                    width: 80,
                    objectFit: "contain",
                  }}
                />
              </View>
              <View>
                <Text
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: 14,
                  }}
                >
                  {
                    documents[currentDocumentIndex].document_data
                      ?.docCompanyName
                  }
                </Text>
                <View style={{}}>
                  {documents[
                    currentDocumentIndex
                  ]?.document_data?.headerDescriptionLines?.map((desc, index) =>
                    desc.text !== "" ? (
                      <Text
                        key={index}
                        style={{
                          fontSize: 8,
                          marginTop: 1,
                        }}
                      >
                        {desc.text}
                      </Text>
                    ) : null
                  )}
                </View>
                {documents[currentDocumentIndex]?.document_data
                  ?.headerDescriptionLines?.length > 0 &&
                documents[currentDocumentIndex]?.document_data
                  ?.headerSubDescriptionLines?.length > 0 ? (
                  <View
                    style={{
                      borderTopWidth: 1,
                      borderTopColor: "#dddddd",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  />
                ) : null}
                <View
                  style={{ display: "flex", gap: "15px", flexDirection: "row" }}
                >
                  {documents[
                    currentDocumentIndex
                  ]?.document_data?.headerSubDescriptionLines?.map(
                    (desc, index) =>
                      desc.text !== "" ? (
                        <Text
                          key={index}
                          style={{
                            fontSize: 8,
                          }}
                        >
                          {desc.text}
                        </Text>
                      ) : null
                  )}
                </View>
              </View>
            </View>
          ) : documents[currentDocumentIndex]?.document_data
              ?.headerTemplateId === 1 ? (
            <View
              style={{
                position: "relative",
                width: "100%",
              }}
            >
              {/* <View
                style={{
                  width: "1.5vw",
                  background: documents[currentDocumentIndex]?.document_data
                    ?.docHighlightColor
                    ? documents[currentDocumentIndex]?.document_data
                        ?.docHighlightColor
                    : "rgb(14, 90, 165)",
                  position: "absolute",
                  top: "-4.5vw",
                  left: 0,
                }}
              /> */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  paddingBottom: 20,
                }}
              >
                <View style={{ width: 300 }}>
                  <Image
                    src={documents[currentDocumentIndex].document_data?.logo}
                    style={{
                      height: 80,
                      width: 80,
                      objectFit: "contain",
                    }}
                  />
                  <Text
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                  >
                    {
                      documents[currentDocumentIndex].document_data
                        ?.docCompanyName
                    }
                  </Text>
                  <View style={{}}>
                    {documents[
                      currentDocumentIndex
                    ]?.document_data?.headerDescriptionLines?.map(
                      (desc, index) =>
                        desc.text !== "" ? (
                          <Text
                            key={index}
                            style={{
                              fontSize: 8,
                              marginTop: 1,
                            }}
                          >
                            {desc.text}
                          </Text>
                        ) : null
                    )}
                  </View>
                </View>
                <View
                  style={{
                    width: 300,
                  }}
                >
                  <View style={{}}>
                    {documents[
                      currentDocumentIndex
                    ]?.document_data?.headerSubDescriptionLines?.map(
                      (desc, index) =>
                        desc.text !== "" ? (
                          <Text
                            key={index}
                            style={{
                              textAlign: "right",
                              fontSize: 8,
                              marginTop: 1,
                            }}
                          >
                            {desc.text}
                          </Text>
                        ) : null
                    )}
                  </View>
                </View>
              </View>
            </View>
          ) : null}

          <View
            style={{
              borderTopWidth: 1,
              borderTopColor: "#dddddd",
              marginBottom: 20,
            }}
          />
          {convertHtmlToPdfRenderer(
            documents?.[currentDocumentIndex]?.document_data?.content
          )}
        </View>
      </Page>
    </Document>
  );

  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background: "red",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        position: "relative",
      }}
    >
      <Modal
        width="70%"
        style={{ padding: "20px 20px 50px 20px" }}
        footer={null}
        open={previewDocumentModalIsOpen}
        // onOk={handleOk}
        onCancel={() => {
          setPreviewDocumentModalIsOpen(false);
        }}
      >
        <PDFViewer style={{ width: "100%", height: "80vh" }}>
          <PdfDocument />
        </PDFViewer>
      </Modal>
      <Modal
        width="30%"
        style={{ padding: "20px 20px 50px 20px" }}
        footer={null}
        open={deleteDocumentModalIsOpen}
        // onOk={handleOk}
        onCancel={() => {
          setDeleteDocumentModalIsOpen(false);
        }}
      >
        <div>Do you confirm you would like to delete this document?</div>
        <div style={{ display: "flex", marginTop: "10px", gap: "10px" }}>
          <div
            style={{
              padding: "5px 25px",
              borderRadius: "8px",
              border: "2px solid rgb(14, 90, 165)",
              color: "rgb(14, 90, 165)",
              cursor: "pointer",
              fontWeight: 500,
            }}
            onClick={() => {
              setDeleteDocumentModalIsOpen(false);
            }}
          >
            Cancel
          </div>
          <div
            style={{
              padding: "5px 25px",
              borderRadius: "8px",
              border: "2px solid rgb(14, 90, 165)",
              background: "rgb(14, 90, 165)",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => {
              deleteDocument();
              setDeleteDocumentModalIsOpen(false);
            }}
          >
            Delete
          </div>
        </div>
      </Modal>
      <Modal
        width="50%"
        style={{ padding: "20px 20px 50px 20px" }}
        footer={null}
        open={shareDocumentModalIsOpen}
        // onOk={handleOk}
        onCancel={() => {
          setShareDocumentModalIsOpen(false);
        }}
      >
        <div>
          Please search and select the user you would like to share this
          document with.
        </div>
        <div
          style={{
            padding: "5px",
            background: "rgba(14, 90, 165, 0.05)",
            borderRadius: "9px",
            marginTop: "10px",
          }}
        >
          <input
            style={{
              padding: "5px 20px",
              backgrond: "rgba(256,256,256,0.8)",
              border: "1px solid white",
              outline: "none",
              borderRadius: "8px",
              width: "100%",
            }}
            placeholder="Search lender base on email or name"
            value={searchTerm}
            onChange={(e) => {
              setShareWith(null);
              setSearchTerm(e.target.value);
            }}
          />
          <div
            style={{
              height: "150px",
              overflowX: "hidden",
              overflowY: "auto",
              marginTop: "8px",
            }}
          >
            {userData
              .filter(
                (userr) =>
                  userr.user_account_type === "lender" &&
                  (userr.principal_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                    userr.email
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()))
              )
              .map((userrr, index) => (
                <div
                  onClick={() => setShareWith(userrr.user_id)}
                  className="document-hover"
                  key={index}
                  style={{
                    padding: "5px 20px",
                    cursor: "pointer",
                    background:
                      shareWith === userrr.user_id
                        ? "rgb(14, 90, 165)"
                        : "transparent",
                    color: shareWith === userrr.user_id ? "white" : "grey",
                    borderRadius: "6px",
                  }}
                >
                  {userrr?.principal_name + " | " + userrr?.email}
                </div>
              ))}
          </div>
        </div>
        {shareWith ? (
          <div style={{ display: "flex", marginTop: "10px", gap: "10px" }}>
            <div
              style={{
                padding: "5px 25px",
                borderRadius: "8px",
                border: "2px solid rgb(14, 90, 165)",
                color: "rgb(14, 90, 165)",
                cursor: "pointer",
                fontWeight: 500,
              }}
              onClick={() => {
                setShareDocumentModalIsOpen(false);
              }}
            >
              Cancel
            </div>
            <div
              style={{
                padding: "5px 25px",
                borderRadius: "8px",
                border: "2px solid rgb(14, 90, 165)",
                background: "rgb(14, 90, 165)",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                shareDocument();
                setShareDocumentModalIsOpen(false);
              }}
            >
              Share
            </div>
          </div>
        ) : null}
      </Modal>
      {fileUploading ? (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "80%",
            zIndex: 2000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={120} style={{}} />
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: "5px",
          alignItems: "center",
          gap: "10px",
          marginBottom: "10px",
          height: "30px",
        }}
      >
        <div
          style={{
            color: "#142C44CC",
            fontSize: "20px",
            fontFamily: "Manrope,sans-serif",
            fontWeight: 800,
          }}
        >
          Document Builder
        </div>
      </div>
      <div
        style={{
          borderTop: "1px solid #142C443A",
          width: "100%",
          display: "flex",
          padding: "8px 0px",
          height: "calc(100% - 50px)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            color: "#142C44CC",
            padding: "3px 0px",
            height: "100%",
            background: "rgba(256,256,256,0.6)",
            borderRadius: "12px",
            padding: "6px",
            boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
            position: "relative",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              left: "6px",
              top: `${6 + tabIndex * 70}px`,
              borderRadius: "9px",
              background: "rgb(14, 90, 165)",
              width: "60px",
              height: "60px",
              transition: "0.3s all ease-in-out",
            }}
          />
          {tabs.map((tab, index) => (
            <div
              style={{
                color: tabIndex === index ? "white" : "#142C44",
                cursor: "pointer",
                width: "60px",
                height: "60px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "10px",
                transition: "0.4s all ease-in-out",
                position: "relative",
                zIndex: 2,
              }}
              onClick={() => {
                setTabIndex(index);
                setCurrentDocumentIndex(null);
                setDocumentCreationStep(0);
                setNewDocumentData({
                  typeOfDocument: "Loan Agreement",
                  headerTemplateId: 0,
                });
              }}
              key={index}
            >
              <div>{tabIndex === index ? tab.iconSelected : tab.icon}</div>
              <div>{tab.name}</div>
            </div>
          ))}
        </div>
        <div style={{ width: "100%", height: "100%", padding: "8px 20px" }}>
          {tabIndex === 0 ? (
            documentCreationStep === 0 ? (
              <div style={{ padding: "8px 20px" }}>
                <div
                  style={{
                    color: "#142C44CC",
                    fontSize: "17px",
                    fontFamily: "Manrope,sans-serif",
                    fontWeight: 800,
                  }}
                >
                  Create Document
                </div>
                <div style={{ marginTop: "15px" }}>
                  <div className="document-creation-input-label">
                    Document Name:
                  </div>
                  <input
                    value={newDocumentData.documentName}
                    onChange={(e) => {
                      setNewDocumentData({
                        ...newDocumentData,
                        documentName: e.target.value,
                      });
                    }}
                    placeholder="Document name"
                    className="document-creation-input"
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <div className="document-creation-input-label">
                    Type of document:
                  </div>
                  <select
                    value={newDocumentData.typeOfDocument}
                    onChange={(e) => {
                      setNewDocumentData({
                        ...newDocumentData,
                        typeOfDocument: e.target.value,
                      });
                    }}
                    placeholder="Document name"
                    className="document-creation-input"
                  >
                    <option value="Loan Agreement">Loan Agreement</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div
                    onClick={() => {
                      if (
                        !newDocumentData.documentName &&
                        !newDocumentData.typeOfDocument
                      ) {
                        setError(1);
                      } else {
                        setError(0);
                        const tempDocumentCreation = {
                          ...newDocumentData,
                        };
                        createOrUpdateDocument(tempDocumentCreation);
                        console.log(documents.length);
                        setCurrentDocumentIndex(documents.length || 0);
                        setDocumentCreationStep(1);
                      }
                    }}
                    className="document-builder-button"
                  >
                    Create
                  </div>
                </div>
                {error === 2 ? (
                  <div style={{ color: "rgb(189,0,0)" }}>
                    The Document name field is required to proceeed.
                  </div>
                ) : null}
              </div>
            ) : documentCreationStep === 1 ? (
              <div>
                <div
                  style={{
                    color: "#142C44CC",
                    fontSize: "17px",
                    fontFamily: "Manrope,sans-serif",
                    fontWeight: 800,
                  }}
                >
                  {documents[currentDocumentIndex]?.document_data?.documentName}
                </div>
                <div style={{ marginTop: "15px" }}>
                  <div className="document-creation-input-label">
                    Header template:
                  </div>
                  <div
                    style={{ marginTop: "10px", display: "flex", gap: "20px" }}
                  >
                    {headerTemplates.map((header, index1) => (
                      <div
                        key={index1}
                        className="dbuilder-header-map"
                        onClick={() => {
                          const updatingCurrentDocument = [...documents];
                          updatingCurrentDocument[
                            currentDocumentIndex
                          ].document_data.headerTemplateId = index1;
                          setDocuments(updatingCurrentDocument);
                        }}
                      >
                        <div
                          style={{
                            padding: "5px",
                            width: "100%",
                            border:
                              documents[currentDocumentIndex]?.document_data
                                ?.headerTemplateId === index1
                                ? "2px solid rgb(14, 90, 165)"
                                : "none",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            src={header.image}
                            className="dbuilder-header-map-image"
                          />
                        </div>
                        <div
                          style={{
                            height: "25px",
                            width: "25px",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                            position: "relative",
                            borderRadius: "50%",
                            marginTop: "15px",
                          }}
                        >
                          {documents[currentDocumentIndex]?.document_data
                            ?.headerTemplateId === index1 ? (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                background: "rgb(14, 90, 165)",
                                width: "15px",
                                height: "15px",
                                borderRadius: "50%",
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <div className="document-creation-input-label">
                      Header content:
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "25px",
                        marginTop: "15px",
                      }}
                    >
                      <div>
                        <div className="document-creation-input-label">
                          Company Name:
                        </div>
                        <input
                          value={
                            documents[currentDocumentIndex]?.document_data
                              ?.docCompanyName
                          }
                          onChange={(e) => {
                            const updatingCurrentDocument = [...documents];
                            updatingCurrentDocument[
                              currentDocumentIndex
                            ].document_data.docCompanyName = e.target.value;
                            setDocuments(updatingCurrentDocument);
                          }}
                          placeholder="Company name"
                          className="document-creation-input"
                        />
                      </div>
                      <div>
                        <div className="document-creation-input-label">
                          Upload logo:
                        </div>
                        <input
                          type="file"
                          onChange={(e) => {
                            handleUploadLogo(e);
                          }}
                          style={{
                            color: "#142c446a",
                            marginTop: "5px",
                          }}
                        />
                      </div>
                      {documents[currentDocumentIndex]?.document_data
                        ?.headerTemplateId === 1 ? (
                        <div>
                          <div className="document-creation-input-label">
                            Document Highlight Color:
                          </div>
                          <input
                            type="color"
                            value={
                              documents[currentDocumentIndex]?.document_data
                                ?.docHighlightColor
                            }
                            onChange={(e) => {
                              const updatingCurrentDocument = [...documents];
                              updatingCurrentDocument[
                                currentDocumentIndex
                              ].document_data.docHighlightColor =
                                e.target.value;
                              setDocuments(updatingCurrentDocument);
                            }}
                            style={{ width: "80px", padding: "6px" }}
                            className="document-creation-input"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{ marginTop: "10px", display: "flex", gap: "25px" }}
                  >
                    <div>
                      <div
                        className="document-creation-input-label"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const updatingCurrentDocument = [...documents];
                          if (
                            updatingCurrentDocument[currentDocumentIndex]
                              .document_data.headerDescriptionLines
                          ) {
                            updatingCurrentDocument[
                              currentDocumentIndex
                            ].document_data.headerDescriptionLines.push({
                              text: "",
                            });
                          } else {
                            updatingCurrentDocument[
                              currentDocumentIndex
                            ].document_data.headerDescriptionLines = [
                              { text: "" },
                            ];
                          }
                          setDocuments(updatingCurrentDocument);
                        }}
                      >
                        <div>Description lines: </div>
                        <div>
                          <AddCircleOutlineOutlinedIcon />
                        </div>
                      </div>
                      {documents[
                        currentDocumentIndex
                      ]?.document_data?.headerDescriptionLines?.map(
                        (headDes, index3) => (
                          <div
                            key={index3}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "15px",
                            }}
                          >
                            <input
                              value={headDes.text}
                              onChange={(e) => {
                                const updatingCurrentDocument = [...documents];
                                updatingCurrentDocument[
                                  currentDocumentIndex
                                ].document_data.headerDescriptionLines[
                                  index3
                                ].text = e.target.value;
                                setDocuments(updatingCurrentDocument);
                              }}
                              placeholder={`Description Line ${index3 + 1}`}
                              className="document-creation-input"
                            />
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const updatingCurrentDocument = [...documents];
                                updatingCurrentDocument[
                                  currentDocumentIndex
                                ].document_data?.headerDescriptionLines.splice(
                                  index3,
                                  1
                                );
                                setDocuments(updatingCurrentDocument);
                              }}
                            >
                              <RemoveCircleOutlineOutlined />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <div>
                      <div
                        className="document-creation-input-label"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const updatingCurrentDocument = [...documents];
                          if (
                            updatingCurrentDocument[currentDocumentIndex]
                              .document_data?.headerSubDescriptionLines
                          ) {
                            updatingCurrentDocument[
                              currentDocumentIndex
                            ].document_data?.headerSubDescriptionLines.push({
                              text: "",
                            });
                          } else {
                            updatingCurrentDocument[
                              currentDocumentIndex
                            ].document_data.headerSubDescriptionLines = [
                              { text: "" },
                            ];
                          }
                          setDocuments(updatingCurrentDocument);
                        }}
                      >
                        <div>Sub-Description lines: </div>
                        <div>
                          <AddCircleOutlineOutlinedIcon />
                        </div>
                      </div>
                      {documents[
                        currentDocumentIndex
                      ]?.document_data?.headerSubDescriptionLines?.map(
                        (headDes, index3) => (
                          <div
                            key={index3}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "15px",
                            }}
                          >
                            <input
                              value={headDes.text}
                              onChange={(e) => {
                                const updatingCurrentDocument = [...documents];
                                updatingCurrentDocument[
                                  currentDocumentIndex
                                ].document_data.headerSubDescriptionLines[
                                  index3
                                ].text = e.target.value;
                                setDocuments(updatingCurrentDocument);
                              }}
                              placeholder={`Sub Description Line ${index3 + 1}`}
                              className="document-creation-input"
                            />
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const updatingCurrentDocument = [...documents];
                                updatingCurrentDocument[
                                  currentDocumentIndex
                                ].document_data.headerSubDescriptionLines.splice(
                                  index3,
                                  1
                                );
                                setDocuments(updatingCurrentDocument);
                              }}
                            >
                              <RemoveCircleOutlineOutlined />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div style={{ display: "flex", marginTop: "20px" }}>
                    <div
                      onClick={() => {
                        if (
                          !documents[currentDocumentIndex].document_data
                            ?.docCompanyName ||
                          !documents[currentDocumentIndex].document_data
                            ?.logo ||
                          documents[currentDocumentIndex].document_data
                            ?.docCompanyName === "" ||
                          documents[currentDocumentIndex].document_data
                            ?.logo === ""
                        ) {
                          setError(2);
                        } else {
                          setError(0);
                          const tempDocumentCreation = {
                            ...documents[currentDocumentIndex].document_data,
                          };
                          createOrUpdateDocument(tempDocumentCreation);
                          setDocumentCreationStep(2);
                        }
                      }}
                      className="document-builder-button"
                    >
                      Next
                    </div>
                  </div>
                  {error === 2 ? (
                    <div style={{ color: "rgb(189,0,0)" }}>
                      The Company name & logo field is required to proceeed.
                    </div>
                  ) : null}
                  <div
                    style={{
                      marginTop: "15px",
                      height: "24vw",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <div className="document-creation-input-label">
                      Header preview:
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: "50%",
                        transform: "translate(-50%,0)",
                        width: "60vw",
                        height: "20vw",
                        background: "white",
                        borderRadius: "10px 10px 0px 0px",
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                        color: "rgba(0,0,0,0.85)",
                        fontWeight: "400",
                        fontSize: "13px",
                        overflow: "hidden",
                      }}
                    >
                      {documents[currentDocumentIndex]?.document_data
                        ?.headerTemplateId === 0 ? (
                        <div
                          style={{
                            width: "80%",
                            marginTop: "4.5vw",
                            marginLeft: "10%",
                            display: "flex",
                            gap: "3%",
                            alignItems: "center",
                            paddingBottom: "1.5vw",
                            borderBottom: "1px solid rgba(0,0,0,0.4)",
                          }}
                        >
                          <div style={{ width: "20%" }}>
                            <img
                              src={
                                documents[currentDocumentIndex].document_data
                                  ?.logo
                              }
                              style={{
                                height: "9.6vw",
                                width: "9.6vw",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                          <div>
                            <div
                              style={{ fontWeight: "700", fontSize: "25px" }}
                            >
                              {
                                documents[currentDocumentIndex].document_data
                                  ?.docCompanyName
                              }
                            </div>
                            <div style={{}}>
                              {documents[
                                currentDocumentIndex
                              ]?.document_data?.headerDescriptionLines?.map(
                                (desc, index) =>
                                  desc.text !== "" ? (
                                    <div
                                      key={index}
                                      style={{
                                        marginTop: index !== 0 ? "8px" : "12px",
                                        lineHeight: "13px",
                                      }}
                                    >
                                      {desc.text}
                                    </div>
                                  ) : null
                              )}
                            </div>
                            {documents[currentDocumentIndex]?.document_data
                              ?.headerDescriptionLines?.length > 0 &&
                            documents[currentDocumentIndex]?.document_data
                              ?.headerSubDescriptionLines?.length > 0 ? (
                              <div
                                style={{
                                  width: "40%",
                                  height: "1px",
                                  background: "rgba(0,0,0,0.4)",
                                  margin: "10px 0px",
                                }}
                              />
                            ) : null}
                            <div style={{ display: "flex", gap: "15px" }}>
                              {documents[
                                currentDocumentIndex
                              ]?.document_data?.headerSubDescriptionLines?.map(
                                (desc, index) =>
                                  desc.text !== "" ? (
                                    <div key={index}>{desc.text}</div>
                                  ) : null
                              )}
                            </div>
                          </div>
                        </div>
                      ) : documents[currentDocumentIndex]?.document_data
                          ?.headerTemplateId === 1 ? (
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              height: "100%",
                              width: "1.5vw",
                              background: documents[currentDocumentIndex]
                                ?.document_data?.docHighlightColor
                                ? documents[currentDocumentIndex]?.document_data
                                    ?.docHighlightColor
                                : "rgb(14, 90, 165)",
                              position: "absolute",
                              top: "-4.5vw",
                              left: 0,
                            }}
                          />
                          <div
                            style={{
                              width: "80%",
                              marginTop: "4.5vw",
                              marginLeft: "10%",
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "3%",
                              alignItems: "flex-end",
                              paddingBottom: "1.5vw",
                            }}
                          >
                            <div style={{ width: "30%" }}>
                              <img
                                src={
                                  documents[currentDocumentIndex].document_data
                                    ?.logo
                                }
                                style={{
                                  height: "6vw",
                                  width: "6vw",
                                  objectFit: "contain",
                                }}
                              />
                              <div
                                style={{
                                  fontWeight: "700",
                                  fontSize: "25px",
                                  lineHeight: "25px",
                                }}
                              >
                                {
                                  documents[currentDocumentIndex].document_data
                                    ?.docCompanyName
                                }
                              </div>
                              <div style={{}}>
                                {documents[
                                  currentDocumentIndex
                                ]?.document_data?.headerDescriptionLines?.map(
                                  (desc, index) =>
                                    desc.text !== "" ? (
                                      <div
                                        key={index}
                                        style={{
                                          marginTop:
                                            index !== 0 ? "8px" : "12px",
                                          lineHeight: "13px",
                                        }}
                                      >
                                        {desc.text}
                                      </div>
                                    ) : null
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "30%",
                                textAlign: "right",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              <div style={{}}>
                                {documents[
                                  currentDocumentIndex
                                ]?.document_data?.headerSubDescriptionLines?.map(
                                  (desc, index) =>
                                    desc.text !== "" ? (
                                      <div
                                        key={index}
                                        style={{
                                          marginTop:
                                            index !== 0 ? "8px" : "12px",
                                          lineHeight: "13px",
                                        }}
                                      >
                                        {desc.text}
                                      </div>
                                    ) : null
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : documentCreationStep === 2 ? (
              <div style={{ width: "100%", height: "100%", color: "#142C44" }}>
                <div style={{ marginBottom: "15px" }}>
                  <div>Document Variables</div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginTop: "5px",
                      flexWrap: "wrap",
                    }}
                  >
                    {documents[currentDocumentIndex].document_data
                      ?.typeOfDocument === "Loan Agreement"
                      ? typeOfDocumentVariables.loanAgreement.map(
                          (variab, index) => (
                            <div
                              onClick={() => {
                                if (!variab.sub) {
                                  setOpenVariableindex(null);
                                  handleVariableInsert(variab);
                                } else {
                                  if (openvariableIndex === index) {
                                    setOpenVariableindex(null);
                                  } else {
                                    setOpenVariableindex(index);
                                  }
                                }
                              }} // Handle variable click
                              key={index}
                              style={{
                                padding: "7px 25px",
                                background: "rgba(256,256,256,0.5)",
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                cursor: "pointer",
                                borderRadius: "8px",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                position: "relative",
                              }}
                            >
                              <AddCircleOutlineOutlinedIcon />
                              <div style={{ width: "120px" }}>
                                {variab.name}
                              </div>
                              {openvariableIndex === index ? (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "105%",
                                    left: "20%",
                                    zIndex: 50,
                                  }}
                                >
                                  {variab?.sub?.map((titute, index8) => (
                                    <div
                                      style={{
                                        padding: "7px 25px",
                                        background: "rgba(256,256,256,0.5)",
                                        boxShadow:
                                          "0px 0px 10px rgba(0,0,0,0.1)",
                                        cursor: "pointer",
                                        borderRadius: "8px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                        marginTop: "5px",
                                        backdropFilter: "blur(20px)",
                                      }}
                                      key={index8}
                                      onClick={() => {
                                        setOpenVariableindex(null);
                                        handleVariableInsert(variab, titute); // Pass both main variable and sub-variable
                                      }}
                                    >
                                      <AddCircleOutlineOutlinedIcon />
                                      <div style={{ width: "120px" }}>
                                        {variab.name + "." + titute.name}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                            </div>
                          )
                        )
                      : null}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "calc(100% - 70px)",
                    background: "rgba(256,256,256,0.5)",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    padding: "10px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      right: "15px",
                      top: "16px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        background: "rgb(14, 90, 165)",
                        color: "white",
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                        padding: "4px 25px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setPreviewDocumentModalIsOpen(true);
                      }}
                    >
                      Preview
                    </div>
                    <div
                      style={{
                        background: "rgb(14, 90, 165)",
                        color: "white",
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                        padding: "4px 25px",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const tempDocumentCreation = {
                          ...documents[currentDocumentIndex].document_data,
                        };
                        createOrUpdateDocument(tempDocumentCreation);
                      }}
                    >
                      Save
                    </div>
                  </div>
                  <ReactQuill
                    ref={quillRef} // Attach ref to ReactQuill
                    theme="snow"
                    onChange={(content, delta, source, editor) => {
                      const updatingCurrentDocument = [...documents];
                      updatingCurrentDocument[
                        currentDocumentIndex
                      ].document_data.content = content;
                      setDocuments(updatingCurrentDocument);
                    }}
                    style={{
                      height: "calc(100% - 40px",
                      color: "rgba(0,0,0,0.85)",
                    }}
                    value={
                      documents[currentDocumentIndex].document_data?.content ||
                      ""
                    }
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "indent",
                    ]}
                    modules={modules}
                    // Hook into image insertion event
                    onEditorCreate={(editor) => {
                      editor
                        .getModule("clipboard")
                        .on("paste-image", handleImageInserted);
                    }}
                  />
                </div>
              </div>
            ) : null
          ) : tabIndex === 1 ? (
            <div
              style={{
                padding: "8px 20px",
                color: "#142C44",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  color: "#142C44CC",
                  fontSize: "17px",
                  fontFamily: "Manrope,sans-serif",
                  fontWeight: 800,
                }}
              >
                My files
              </div>
              <div style={{ width: "100%", marginTop: "10px", height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    width: "100%",
                    justifyContent: "space-between",
                    padding: "3px 20px",
                    borderTop: "1px solid #142C442A",
                    borderBottom: "1px solid #142C442A",
                  }}
                >
                  <div>File name</div>
                  <div style={{ width: "20%", textAlign: "right" }}>
                    Last updated
                  </div>
                </div>
                <div
                  style={{
                    height: "calc(100% - 50px",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  {documents
                    .sort(
                      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
                    )
                    .filter((doc) => doc?.shared === 0)
                    .map((doc, index5) => (
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          width: "100%",
                          justifyContent: "space-between",
                          padding: "6px 20px",
                          borderBottom: "1px solid #142C441A",
                          cursor: "pointer",
                          position: "relative",
                        }}
                        className="document-hover"
                        onClick={() => {
                          setCurrentDocumentIndex(index5);
                          console.log(documents[index5]);
                          setTabIndex(0);
                          const documentData = doc?.document_data;
                          if (!documentData.documentName) {
                            setDocumentCreationStep(0);
                          } else if (
                            !documentData.docCompanyName ||
                            !documentData.logo ||
                            documentData.docCompanyName === "" ||
                            documentData.logo === ""
                          ) {
                            setDocumentCreationStep(1);
                          } else {
                            setDocumentCreationStep(2);
                          }
                        }}
                        key={index5}
                      >
                        <div>
                          <div>
                            {doc?.document_data?.documentName || "Unnamed"}
                          </div>
                          <div style={{ fontSize: "10px", opacity: 0.6 }}>
                            {doc?.document_data?.typeOfDocument || "Others"}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            textAlign: "right",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            gap: "15px",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              borderRadius: "3px",
                              height: "20px",
                              width: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="document-cursor"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (openSettings !== index5) {
                                setOpenSettings(index5);
                              } else {
                                setOpenSettings(null);
                              }
                            }}
                          >
                            <MoreVertOutlinedIcon
                              style={{
                                fontSize: "18px",
                                transform: "rotate(90deg)",
                              }}
                            />

                            {openSettings === index5 ? (
                              <div
                                onClick={(e) => e.stopPropagation()}
                                ref={divRef}
                                style={{
                                  position: "absolute",
                                  left: "0%",
                                  top: "100%",
                                  padding: "5px 5px 5px 5px",
                                  background: "rgba(256,256,256,0.85)",
                                  zIndex: 200,
                                  borderRadius: "8px",
                                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                  cursor: "default",
                                  backdropFilter: "blur(5px)",
                                  color: "grey",
                                  fontSize: "10px",
                                  textAlign: "left",
                                  width: "100px",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "5px 5px 5px 15px",
                                    cursor: "pointer",
                                  }}
                                  className="document-hover"
                                  onClick={() => {
                                    setOpenSettings(null);
                                    setCurrentDocumentIndex(index5);
                                    console.log(documents[index5]);
                                    setTabIndex(0);
                                    const documentData = doc?.document_data;
                                    if (!documentData.documentName) {
                                      setDocumentCreationStep(0);
                                    } else if (
                                      !documentData.docCompanyName ||
                                      !documentData.logo ||
                                      documentData.docCompanyName === "" ||
                                      documentData.logo === ""
                                    ) {
                                      setDocumentCreationStep(1);
                                    } else {
                                      setDocumentCreationStep(2);
                                    }
                                  }}
                                >
                                  Open
                                </div>
                                <div
                                  style={{
                                    padding: "5px 5px 5px 15px",
                                    cursor: "pointer",
                                  }}
                                  className="document-hover"
                                  onClick={() => {
                                    setOpenSettings(null);
                                    console.log(doc.id);
                                    setDocumentAdjusting(doc.id);
                                    setShareDocumentModalIsOpen(true);
                                  }}
                                >
                                  Share a copy
                                </div>
                                <div
                                  style={{
                                    padding: "5px 5px 5px 15px",
                                    cursor: "pointer",
                                  }}
                                  className="document-hover"
                                  onClick={() => {
                                    setOpenSettings(null);
                                    console.log(doc.id);
                                    setDocumentAdjusting(doc.id);
                                    setDeleteDocumentModalIsOpen(true);
                                  }}
                                >
                                  Delete
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div>{formatDate(doc.updated_at)}</div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                padding: "8px 20px",
                color: "#142C44",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  color: "#142C44CC",
                  fontSize: "17px",
                  fontFamily: "Manrope,sans-serif",
                  fontWeight: 800,
                }}
              >
                Shared with me
              </div>
              <div style={{ width: "100%", marginTop: "10px", height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    width: "100%",
                    justifyContent: "space-between",
                    padding: "3px 20px",
                    borderTop: "1px solid #142C442A",
                    borderBottom: "1px solid #142C442A",
                  }}
                >
                  <div>File name</div>
                  <div style={{ width: "20%", textAlign: "right" }}>
                    Last updated
                  </div>
                </div>
                <div
                  style={{
                    height: "calc(100% - 50px",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  {documents
                    .sort(
                      (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
                    )
                    .filter((doc) => doc?.shared === 1)
                    .map((doc, index5) => (
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          width: "100%",
                          justifyContent: "space-between",
                          padding: "6px 20px",
                          borderBottom: "1px solid #142C441A",
                          cursor: "pointer",
                          position: "relative",
                        }}
                        className="document-hover"
                        onClick={() => {
                          setCurrentDocumentIndex(index5);
                          console.log(documents[index5]);
                          setTabIndex(0);
                          const documentData = doc?.document_data;
                          if (!documentData.documentName) {
                            setDocumentCreationStep(0);
                          } else if (
                            !documentData.docCompanyName ||
                            !documentData.logo ||
                            documentData.docCompanyName === "" ||
                            documentData.logo === ""
                          ) {
                            setDocumentCreationStep(1);
                          } else {
                            setDocumentCreationStep(2);
                          }
                        }}
                        key={index5}
                      >
                        <div>
                          <div>
                            {doc?.document_data?.documentName || "Unnamed"}
                          </div>
                          <div style={{ fontSize: "10px", opacity: 0.6 }}>
                            {doc?.document_data?.typeOfDocument || "Others"}
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            textAlign: "right",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            gap: "15px",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              borderRadius: "3px",
                              height: "20px",
                              width: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="document-cursor"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (openSettings !== index5) {
                                setOpenSettings(index5);
                              } else {
                                setOpenSettings(null);
                              }
                            }}
                          >
                            <MoreVertOutlinedIcon
                              style={{
                                fontSize: "18px",
                                transform: "rotate(90deg)",
                              }}
                            />

                            {openSettings === index5 ? (
                              <div
                                onClick={(e) => e.stopPropagation()}
                                ref={divRef}
                                style={{
                                  position: "absolute",
                                  left: "0%",
                                  top: "100%",
                                  padding: "5px 5px 5px 5px",
                                  background: "rgba(256,256,256,0.85)",
                                  zIndex: 200,
                                  borderRadius: "8px",
                                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                  cursor: "default",
                                  backdropFilter: "blur(5px)",
                                  color: "grey",
                                  fontSize: "10px",
                                  textAlign: "left",
                                  width: "100px",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "5px 5px 5px 15px",
                                    cursor: "pointer",
                                  }}
                                  className="document-hover"
                                  onClick={() => {
                                    setOpenSettings(null);
                                    setCurrentDocumentIndex(index5);
                                    console.log(documents[index5]);
                                    setTabIndex(0);
                                    const documentData = doc?.document_data;
                                    if (!documentData.documentName) {
                                      setDocumentCreationStep(0);
                                    } else if (
                                      !documentData.docCompanyName ||
                                      !documentData.logo ||
                                      documentData.docCompanyName === "" ||
                                      documentData.logo === ""
                                    ) {
                                      setDocumentCreationStep(1);
                                    } else {
                                      setDocumentCreationStep(2);
                                    }
                                  }}
                                >
                                  Open
                                </div>
                                <div
                                  style={{
                                    padding: "5px 5px 5px 15px",
                                    cursor: "pointer",
                                  }}
                                  className="document-hover"
                                  onClick={() => {
                                    setOpenSettings(null);
                                    console.log(doc.id);
                                    setDocumentAdjusting(doc.id);
                                    setShareDocumentModalIsOpen(true);
                                  }}
                                >
                                  Share a copy
                                </div>
                                <div
                                  style={{
                                    padding: "5px 5px 5px 15px",
                                    cursor: "pointer",
                                  }}
                                  className="document-hover"
                                  onClick={() => {
                                    setOpenSettings(null);
                                    console.log(doc.id);
                                    setDocumentAdjusting(doc.id);
                                    setDeleteDocumentModalIsOpen(true);
                                  }}
                                >
                                  Delete
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div>{formatDate(doc.updated_at)}</div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentBuilder;
