import React, { useEffect, useState } from "react";
import { Progress, Button, Modal, message, Avatar, Collapse } from "antd";
import cookies from "js-cookie";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const VaultTagging = () => {
  const [documents, setDocuments] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [documentSelected, setDocumentSelected] = useState(null);
  const [userCoor, setUserCoor] = useState({ x: 20, y: 20 });
  const [userIsDragging, setUserIsDragging] = useState(false);
  const [docCoor, setDocCoor] = useState({ x: 200, y: 20 });
  const [docIsDragging, setDocIsDragging] = useState(false);
  const [startDrag, setStartDrag] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [allFinancialSummary, setAllFinancialSummary] = useState([]);
  const [openAsTab, setOpenAsTab] = useState(
    cookies.get("openastab") ? JSON.parse(cookies.get("openastab")) : false
  );
  const [selectedFinancialSummary, setSelectedFinancialSummary] =
    useState(null);

  const [viewingDocument, setViewingDocument] = useState(null);
  const [dataBorrower, setDataBorrower] = useState([]);

  useEffect(() => {
    setUserCoor({ x: window.innerWidth * 0.525, y: window.innerHeight * 0.05 });
    setDocCoor({ x: window.innerWidth * 0.025, y: window.innerHeight * 0.05 });
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/user/getAllUser",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + cookies.get("token"),
            Accept: "application/json",
          },
        }
      );
      const json = await response.json();
      setDataBorrower(json["Borrower"]);
      console.log(json["Borrower"]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function groupFilesByUserId(files) {
    return files.reduce((acc, file) => {
      if (!acc[file.user_id]) {
        acc[file.user_id] = [];
      }
      acc[file.user_id].push(file);
      return acc;
    }, {});
  }

  const fetchVaultDoc = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/admin/getUserDocumentVault`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      if (response.status === 201) {
        setDocuments(groupFilesByUserId(json.data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchallFinancialSummary = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/admin/getAllFinancialSummary`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );

      const json = await response.json();
      if (response.status === 200) {
        const looSplit = [...json.data];
        for (const fin of looSplit) {
          fin.financial_summary = JSON.parse(fin.financial_summary);
        }
        setAllFinancialSummary(looSplit);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updatedVaultDoc = async (data) => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/admin/updateDocumentVault",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();
      if (response.status === 200) {
        const updatingFileId = [...documents[data.fileId]];
        const theIndex = updatingFileId.findIndex((fil) => fil.id === data.id);
        if (theIndex > -1) {
          const updatingDocuments = { ...documents };
          updatingDocuments[data.fileId][theIndex].fin_sum = json.data.fin_sum;
          setDocuments(updatingDocuments);
          if (json.data.fin_sum) {
            message.success("Document tagged!");
          } else {
            message.success("Document untagged!");
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateFinancialSummary = async (data) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/admin/saveFinancialSummary`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const json = await response.json();
      if (response.status === 201) {
        const updatedDataToPush = { ...json.data };
        updatedDataToPush.financial_summary = JSON.parse(
          updatedDataToPush.financial_summary
        );
        setSelectedFinancialSummary(updatedDataToPush);
        setAllFinancialSummary([...allFinancialSummary, updatedDataToPush]);
      } else if (response.status === 200) {
        const updatedDataToPush = { ...json.data };
        updatedDataToPush.financial_summary = JSON.parse(
          updatedDataToPush.financial_summary
        );
        const selectedIndex = allFinancialSummary.findIndex(
          (doc) => doc.id === updatedDataToPush.id
        );
        if (selectedIndex > -1) {
          const updatingFinancial = [...allFinancialSummary];
          updatingFinancial[selectedIndex] = updatedDataToPush;
          setSelectedFinancialSummary(updatedDataToPush);
          setAllFinancialSummary(updatingFinancial);
        }
      }
      message.success("Financial Summary saved!");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchVaultDoc();
    fetchallFinancialSummary();
  }, []);

  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background: "red",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        gap: "5px",
        color: "black",
        overflowY: "auto",
        overflowX: "hidden",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: userCoor.y,
          left: userSelected ? userCoor.x : "100%",
          width: "45%",
          height: "90vh",
          background: "rgba(14, 90, 165, 0.1)",
          backdropFilter: "blur(10px)",
          borderRadius: "10px",
          overflow: "hidden",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        }}
      >
        <div
          style={{
            background: "rgba(256,256,256,0.5)",
            cursor: "move",
            width: "100%",
            height: "30px",
          }}
          draggable
          onDragStart={(e) => {
            setStartDrag({ x: e.clientX, y: e.clientY });
            setStartPosition({ x: userCoor.x, y: userCoor.y });
          }}
          onDrag={(e) => {
            if (e.clientX > 0 && e.clientY > 0) {
              setUserCoor({
                x: startPosition.x + (e.clientX - startDrag.x),
                y: startPosition.y + (e.clientY - startDrag.y),
              });
            }
          }}
          onDragEnd={(e) => {
            setStartDrag({ x: 0, y: 0 });
            setStartPosition({ x: 0, y: 0 });
          }}
        >
          <div
            style={{ padding: "5px 10px", color: "#142C44CC", opacity: 0.7 }}
          >
            {selectedFinancialSummary
              ? selectedFinancialSummary?.user_id + " Financial Summary"
              : null}
          </div>
          <div
            style={{
              background: "red",
              width: "10px",
              height: "10px",
              position: "absolute",
              top: 10,
              right: 10,
              borderRadius: "50%",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedFinancialSummary(null);
              setUserSelected(null);
            }}
          />
        </div>
        <div
          style={{
            position: "relative",
            marginTop: "10px",
            marginLeft: "10px",
            width: "calc(100% - 20px)",
            fontSize: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#142C447A",
              paddingBottom: "5px",
              borderBottom: "1px solid #142C447A",
              gap: "5px",
            }}
          >
            <div style={{ width: "12.5%" }}>Lender</div>
            <div style={{ width: "12.5%" }}>Type of Facility</div>
            <div style={{ width: "12.5%" }}>Facility Limit</div>
            <div style={{ width: "12.5%" }}>Monthly Inst.</div>
            <div style={{ width: "12.5%" }}>Tenor(Mths)</div>
            <div style={{ width: "12.5%" }}>Disb. Date</div>
            <div style={{ width: "12.5%" }}>Int. Rate (p.a/p.m)</div>
            <div style={{ width: "12.5%" }}>Processing Fee</div>
          </div>
          {selectedFinancialSummary?.financial_summary.map((fin, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#142C447A",
                paddingBottom: "5px",
                gap: "5px",
                marginTop: "5px",
              }}
            >
              <div style={{ width: "12.5%" }}>
                <input
                  style={{
                    border: "1px solid grey",
                    outline: "none",
                    padding: "4px 8px",
                    width: "100%",
                    background: "transparent",
                    borderRadius: "6px",
                    fontSize: "10px",
                  }}
                  value={fin.lender}
                  onChange={(e) => {
                    const updatingSelectedFinancialsum = {
                      ...selectedFinancialSummary,
                    };
                    updatingSelectedFinancialsum.financial_summary[
                      index
                    ].lender = e.target.value;
                    setSelectedFinancialSummary(updatingSelectedFinancialsum);
                  }}
                />
              </div>
              <div style={{ width: "12.5%" }}>
                <input
                  style={{
                    border: "1px solid grey",
                    outline: "none",
                    padding: "4px 8px",
                    width: "100%",
                    background: "transparent",
                    borderRadius: "6px",
                    fontSize: "10px",
                  }}
                  value={fin.typeoffacility}
                  onChange={(e) => {
                    const updatingSelectedFinancialsum = {
                      ...selectedFinancialSummary,
                    };
                    updatingSelectedFinancialsum.financial_summary[
                      index
                    ].typeoffacility = e.target.value;
                    setSelectedFinancialSummary(updatingSelectedFinancialsum);
                  }}
                />
              </div>
              <div style={{ width: "12.5%" }}>
                <input
                  style={{
                    border: "1px solid grey",
                    outline: "none",
                    padding: "4px 8px",
                    width: "100%",
                    background: "transparent",
                    borderRadius: "6px",
                    fontSize: "10px",
                  }}
                  value={fin.facilitylimit}
                  onChange={(e) => {
                    const updatingSelectedFinancialsum = {
                      ...selectedFinancialSummary,
                    };
                    updatingSelectedFinancialsum.financial_summary[
                      index
                    ].facilitylimit = e.target.value;
                    setSelectedFinancialSummary(updatingSelectedFinancialsum);
                  }}
                />
              </div>
              <div style={{ width: "12.5%" }}>
                <input
                  style={{
                    border: "1px solid grey",
                    outline: "none",
                    padding: "4px 8px",
                    width: "100%",
                    background: "transparent",
                    borderRadius: "6px",
                    fontSize: "10px",
                  }}
                  value={fin.monthylyinstallment}
                  onChange={(e) => {
                    const updatingSelectedFinancialsum = {
                      ...selectedFinancialSummary,
                    };
                    updatingSelectedFinancialsum.financial_summary[
                      index
                    ].monthylyinstallment = e.target.value;
                    setSelectedFinancialSummary(updatingSelectedFinancialsum);
                  }}
                />
              </div>
              <div style={{ width: "12.5%" }}>
                <input
                  style={{
                    border: "1px solid grey",
                    outline: "none",
                    padding: "4px 8px",
                    width: "100%",
                    background: "transparent",
                    borderRadius: "6px",
                    fontSize: "10px",
                  }}
                  value={fin.tenor}
                  onChange={(e) => {
                    const updatingSelectedFinancialsum = {
                      ...selectedFinancialSummary,
                    };
                    updatingSelectedFinancialsum.financial_summary[
                      index
                    ].tenor = e.target.value;
                    setSelectedFinancialSummary(updatingSelectedFinancialsum);
                  }}
                />
              </div>
              <div style={{ width: "12.5%" }}>
                <input
                  style={{
                    border: "1px solid grey",
                    outline: "none",
                    padding: "4px 8px",
                    width: "100%",
                    background: "transparent",
                    borderRadius: "6px",
                    fontSize: "10px",
                  }}
                  value={fin.disbursementdate}
                  onChange={(e) => {
                    const updatingSelectedFinancialsum = {
                      ...selectedFinancialSummary,
                    };
                    updatingSelectedFinancialsum.financial_summary[
                      index
                    ].disbursementdate = e.target.value;
                    setSelectedFinancialSummary(updatingSelectedFinancialsum);
                  }}
                />
              </div>
              <div style={{ width: "12.5%" }}>
                <input
                  style={{
                    border: "1px solid grey",
                    outline: "none",
                    padding: "4px 8px",
                    width: "100%",
                    background: "transparent",
                    borderRadius: "6px",
                    fontSize: "10px",
                  }}
                  value={fin.interestrate}
                  onChange={(e) => {
                    const updatingSelectedFinancialsum = {
                      ...selectedFinancialSummary,
                    };
                    updatingSelectedFinancialsum.financial_summary[
                      index
                    ].interestrate = e.target.value;
                    setSelectedFinancialSummary(updatingSelectedFinancialsum);
                  }}
                />
              </div>
              <div style={{ width: "12.5%" }}>
                <input
                  style={{
                    border: "1px solid grey",
                    outline: "none",
                    padding: "4px 8px",
                    width: "100%",
                    background: "transparent",
                    borderRadius: "6px",
                    fontSize: "10px",
                  }}
                  value={fin.processingfee}
                  onChange={(e) => {
                    const updatingSelectedFinancialsum = {
                      ...selectedFinancialSummary,
                    };
                    updatingSelectedFinancialsum.financial_summary[
                      index
                    ].processingfee = e.target.value;
                    setSelectedFinancialSummary(updatingSelectedFinancialsum);
                  }}
                />
              </div>
            </div>
          ))}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "15px",
            }}
          >
            <div
              className="mui_button"
              onClick={() => {
                const finToPUsh = {
                  bank: "",
                  account: "",
                  typeoffacility: "",
                  facilitylimit: "",
                  monthylyinstallment: "",
                  tenor: "",
                  disbursementdate: "",
                  processingfee: "",
                };
                setSelectedFinancialSummary({
                  ...selectedFinancialSummary,
                  financial_summary: [
                    ...selectedFinancialSummary.financial_summary,
                    finToPUsh,
                  ],
                });
              }}
              style={{
                width: "50%",
                color: "white",
                background: "#142C447A",
                textAlign: "center",
                borderRadius: "6px",
                fontWeight: 600,
                padding: "5px 20px",
                marginTop: "7px",
              }}
            >
              ADD ROW
            </div>
            <div
              className="mui_button"
              onClick={() => {
                const dataToUpdateToDatabase = { ...selectedFinancialSummary };
                dataToUpdateToDatabase.financial_summary = JSON.stringify(
                  dataToUpdateToDatabase.financial_summary
                );
                updateFinancialSummary(dataToUpdateToDatabase);
              }}
              style={{
                width: "50%",
                color: "white",
                background: "#142C447A",
                textAlign: "center",
                borderRadius: "6px",
                fontWeight: 600,
                padding: "5px 20px",
                marginTop: "7px",
              }}
            >
              SAVE
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          top: docCoor.y,
          left: documentSelected ? docCoor.x : "-45%",
          width: "45%",
          height: "90vh",
          background: "rgba(14, 90, 165, 0.1)",
          backdropFilter: "blur(10px)",
          //   transition: "0.3s top ease-in-out",
          borderRadius: "10px",
          overflow: "hidden",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        }}
      >
        <div
          style={{
            background: "rgba(256,256,256,0.5)",
            cursor: "move",
            width: "100%",
            height: "30px",
          }}
          draggable
          onDragStart={(e) => {
            setStartDrag({ x: e.clientX, y: e.clientY });
            setStartPosition({ x: docCoor.x, y: docCoor.y });
          }}
          onDrag={(e) => {
            if (e.clientX > 0 && e.clientY > 0) {
              setDocCoor({
                x: startPosition.x + (e.clientX - startDrag.x),
                y: startPosition.y + (e.clientY - startDrag.y),
              });
            }
          }}
          onDragEnd={(e) => {
            setStartDrag({ x: 0, y: 0 });
            setStartPosition({ x: 0, y: 0 });
          }}
        >
          <div
            style={{
              background: "red",
              width: "10px",
              height: "10px",
              position: "absolute",
              top: 10,
              right: 10,
              borderRadius: "50%",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setDocumentSelected(null);
            }}
          />
        </div>
        <iframe
          src={viewingDocument}
          width="100%"
          height="100%"
          title="Embedded Content"
        ></iframe>
      </div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "#142C44CC",
              fontSize: "20px",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 800,
              marginBottom: "15px",
            }}
          >
            Document Vault Tagging
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "0px" }}>
            <input
              type="checkbox"
              checked={openAsTab}
              style={{ marginTop: "10px" }}
              onChange={(e) => {
                setOpenAsTab(e.target.checked);
                if (e.target.checked) {
                  setDocumentSelected(null);
                }
                cookies.set("openastab", e.target.checked);
              }}
            />
            <div style={{ margin: "0px 0px 0px 0px" }}>Open as tabs</div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {Object.entries(documents).map(([fileId, fileGroup]) => (
          <div style={{ width: "100%" }}>
            <div
              className="hover_underline"
              style={{
                width: "100%",
                padding: "10px",
                background: "rgba(14, 90, 165, 0.1)",
                color: "#142C449A",
                cursor: "pointer",
                borderRadius: "8px",
              }}
              onClick={() => {
                if (userSelected === fileId) {
                  setUserSelected(null);
                  setSelectedFinancialSummary(null);
                } else {
                  setUserSelected(fileId);
                  const financialSummaryIndex = allFinancialSummary.findIndex(
                    (doc) => doc.user_id === fileId
                  );
                  if (financialSummaryIndex > -1) {
                    setSelectedFinancialSummary(
                      allFinancialSummary[financialSummaryIndex]
                    );
                  } else {
                    setSelectedFinancialSummary({
                      financial_summary: [],
                      user_id: fileId,
                    });
                  }
                }
              }}
            >
              {dataBorrower
                ? dataBorrower.filter((user) => user.user_id === fileId)?.[0]
                    ?.principal_name === null
                  ? fileId
                  : dataBorrower.filter((user) => user.user_id === fileId)?.[0]
                      ?.principal_name
                : fileId}
            </div>
            <div style={{ padding: "0px 10px" }}>
              {fileGroup.map((file, index) => (
                <div
                  style={{
                    padding: "4px 0px",
                    color: "#142C447A",
                    display: "flex",
                    alignItems: "center",
                  }}
                  key={index}
                >
                  {file.fin_sum ? (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const dataToPush = {
                          id: file.id,
                          fin_sum: 0,
                          fileId: fileId,
                        };
                        updatedVaultDoc(dataToPush);
                      }}
                    >
                      <CheckCircleIcon />
                    </div>
                  ) : (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const dataToPush = {
                          id: file.id,
                          fin_sum: 1,
                          fileId: fileId,
                        };
                        updatedVaultDoc(dataToPush);
                      }}
                    >
                      <CheckCircleOutlineIcon />
                    </div>
                  )}
                  <div
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    className="hover_underline"
                    onClick={() => {
                      if (!openAsTab) {
                        setViewingDocument(file.url);
                        setDocumentSelected(file.id);
                      } else {
                        window.open(file.url, "_blank");
                      }
                    }}
                  >
                    {file.file_name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VaultTagging;
