import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useState, useEffect } from "react";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import TinyLineChart from "../../components/TinyLineChart";
import HalfPieChart from "../../components/HalfPieChart";
import { Modal, Button } from "antd";
import Cookies from "js-cookie";
import { format } from "date-fns";
import warn_ico from "../../assets/img/exclamation.png";
import { useHistory } from "react-router-dom";
import TableOfferedLoan from "../../components/TableOfferedLoan";
import LoanDetails from "./loan-details";
import applyLoan from "../../assets/img/apply-loan.png";
import { message } from "antd";
import dayjs from "dayjs";
import defaultIcon from "../../assets/img/Profile.png";
import * as Icons from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContainerActivity2 = ({ noData }) => (
  <div
    style={{
      width: "100%",
      textAlign: "left",
      marginBottom: "12.5px",
      border: "1px solid none",
      borderRadius: "20px",
      backgroundColor: "#FFFFFFB3",
      height: "60px",
    }}
  >
    <p
      style={{
        fontFamily: "Poppins, sans-serif",
        fontWeight: "600",
        fontSize: "18px",
        color: "#142C44CC",
        textAlign: "center",
        paddingTop: "15px",
      }}
    >
      {noData}
    </p>
  </div>
);

const AcceptedLoans = () => {
  const iconLibrary = Icons;
  const iconNames = Object.keys(iconLibrary);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [data, setData] = useState([]);
  const [dataActivityLog, setActivityLog] = useState([]);
  const [dataClick, setDataClick] = useState(null);
  const [dataClickLender, setDataClickLender] = useState(null);
  const [offeredLoanDetail, setOfferedLoanDetail] = useState(null);
  const history = useHistory();
  const [lenderId, setLenderId] = useState(null);
  const [category, setCategory] = useState(null);
  const [type, setType] = useState(null);
  const [principal, setPrincipal] = useState(null);
  const [amount, setAmount] = useState(null);
  const [admin_fee, setAdminFee] = useState(null);
  const [interest_rate, setInterestRate] = useState(null);
  const [period, setPeriod] = useState(null);
  const [monthly_installment, setMonthlyInstallment] = useState(null);
  const [revenue, setRevenue] = useState(null);
  const [express, setExpress] = useState(null);
  const [total_admin_fee, setTotalAdminFee] = useState(null);
  const [statusMessage, setStatusMessage] = useState({
    message: "",
    color: "",
  });
  const [hovered3, setHovered3] = useState(false);

  const [refreshData, setRefreshData] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [statusNotesForClick, setStatusNotesForClick] = useState(null);

  const fetchReceipt = async (loan_id, lender_id) => {
    console.log("fetching loan agreement");
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/viewPaymentReceipt?loan_id=${loan_id}&lender_id=${lender_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("downloading receipt");
        const blob = await response.blob();

        // Create an anchor element
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "receipt.pdf"; // Set the desired filename here
        document.body.appendChild(a);

        // Trigger a click event on the anchor to start the download
        a.click();

        // Clean up the anchor element
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error fetching receipt:", error);
    }
  };

  const handleFileSelect = (event, loanId, lender_id) => {
    const file = event.target.files[0]; // Get the first selected file
    setFileUploading(true);
    console.log(loanId, file);

    if (file) {
      // Create a FormData object
      const formData = new FormData();
      formData.append("loan_id", loanId); // Replace loan_id with your actual loan ID
      formData.append("lender_id", lender_id); // Replace loan_id with your actual loan ID
      formData.append("agreement", file);

      // Send a POST request with FormData
      fetch("https://www.smart-lend.com/api/agreement/uploadAgreement", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 200) {
            message.success(data.message);
          } else if (data.status === 400) {
            message.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Error uploading agreement file:", error);
        });
      console.log(data);
      message.success("Agreement succesfully uploaded.");
      const changingData = [...data];
      const dataIndex = data.findIndex((loan) => loan.loan_id === loanId);
      changingData[dataIndex].borrower_uploaded_agreement = 1;
      setData(changingData);
      setFileUploading(false);
    }
  };

  const fetchLoanAgreement = async (loan_id, lender_id) => {
    console.log("fetching loan agreement");
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/agreement/getAgreementDocument?loan_id=${loan_id}&lender_id=${lender_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("downloading Agreement");
        const blob = await response.blob();

        // Create an anchor element
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "Agreement.pdf"; // Set the desired filename here
        document.body.appendChild(a);

        // Trigger a click event on the anchor to start the download
        a.click();

        // Clean up the anchor element
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error fetching loan agreement:", error);
    }
  };

  const handleApplyLoan = (event) => {
    event.preventDefault();
    history.push("/b-apply-loan");
  };

  useEffect(() => {
    if (dataClickLender) {
      if (dataClickLender[0]) {
        if (dataClickLender[0].lender_picture) {
          getProfilePicture(dataClickLender[0].lender_picture);
        }
      }
    }
  }, [dataClickLender]);

  const handleContainerClick = async (record) => {
    console.log("record value", record);
    const loan_id = record;
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/pendingLoan/viewPendingLoanDetail?loan_id=${loan_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      const loanDetail = json["Requested Loan Details"][0];
      setDataClick(json["Requested Loan Details"]);
      console.log(json["Offer Loan Details"]);
      setDataClickLender(json["Offer Loan Details"]);
      console.log(json["Requested Loan Details"].length > 0 ? true : false);
      setCategory(loanDetail.category);
    } catch (error) {
      console.error("Error retrieving Requested Loan Details:", error);
    }
  };

  const [dppicture, setdppicture] = useState([]);

  const fillAllLenderPpicture = async (index, lenderId) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getLenderPicture?lender_id=${lenderId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);

      setdppicture((prevDppicture) => {
        const updatedDppicture = [...prevDppicture]; // Create a copy of the array
        updatedDppicture[index] = { profilepicture: imageUrl }; // Update the specific index
        console.log(updatedDppicture);
        return updatedDppicture;
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getInitials = (name) => {
    const textToSplit = name.split(" ");
    const initials =
      textToSplit?.[0]?.[0].toUpperCase() +
      (textToSplit?.[1]?.[0].toUpperCase() || "");
    return initials;
  };
  const [index, setIndex] = useState(null);
  const [lenderProfileData, setLenderProfileData] = useState(null);

  const Container = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    label3,
    description3,
    label4,
    description4,
    label5,
    description5,
    index,
    loan_id,
    open_modal,
    cancel_modal,
    onClickModal,
    createdAt,
    item,
  }) => {
    const shouldAddMarginTop = index > 3;
    const [hovered, setHovered] = useState(false);

    const handleGetLenderProfile = async (lender_id) => {
      try {
        const response1 = await fetch(
          "https://www.smart-lend.com/api/user/getLenderProfileDesign",
          {
            method: "POST",
            body: JSON.stringify({ user_id: lender_id }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          }
        );

        const json = await response1.json();
        if (json.design !== null) {
          setLenderProfileData(JSON.parse(json.design));
          setIndex(index);
        } else {
          setLenderProfileData(null);
        }
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "23.5%" : "100%",
            textAlign: "center",
            border: "1px solid white",
            borderRadius: "10px",
            backgroundColor: "#FFFFFFB3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "15px",
            position: "relative",
          }}
        >
          {item ? (
            item.status_notes ? (
              <div
                style={{
                  position: "absolute",
                  width: "10px",
                  height: "10px",
                  background: "red",
                  borderRadius: "10px",
                  top: 15,
                  right: 15,
                  boxShadow: "0px 0px 5px red",
                }}
              ></div>
            ) : null
          ) : null}
          {title || subtitle ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <img src={icon} alt={title} style={{ width: "55px", height: "55px", borderRadius: "1000px", border: "2px solid #f0f0f0" }} /> */}

              <div style={{}}>
                <h3
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "600",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.4)",
                    textAlign: "left",
                    margin: "0px 0px 0px 0px",
                  }}
                >
                  {title}
                </h3>
                <p
                  style={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "800",
                    fontSize: "16px",
                    color: "rgba(0,0,0,0.5)",
                    textAlign: "left",
                    margin: "0px 0px 10px 0px",
                  }}
                >
                  {subtitle}
                </p>
              </div>
            </div>
          ) : null}
          <div
            style={{
              textAlign: "left",
              margin: "0px",
            }}
          >
            <div
              style={{
                width: "55px",
                height: "55px",
                borderRadius: "50%",
                background: "rgb(140,140,170)",
                marginLeft: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: "white",
                fontSize: 20,
                fontWeight: 600,
              }}
              onClick={() => {
                handleGetLenderProfile(item.lender_id);
              }}
            >
              {title ? getInitials(title) : "SL"}
            </div>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
                marginTop: "10px",
              }}
            >
              {label5}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {description5}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {label}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {description}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Loan Disbursement Amount
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {parseFloat(
                item.amount * (1 - item.processing_fee)
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {label1}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {description1}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {label4}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {description4}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Processing Fee
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {item.processing_fee
                ? `${(item.processing_fee * 100).toFixed(1)} %`
                : "0 %"}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {label2}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {description2}
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "600",
                fontSize: "11px",
                color: "rgba(0,0,0,0.4)",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Created At
            </p>
            <p
              style={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "800",
                fontSize: "16px",
                color: "rgba(0,0,0,0.5)",
                textAlign: "left",
                margin: "0px 0px 10px 0px",
              }}
            >
              {formatDate(createdAt)}
            </p>
            {item ? (
              !item.document ? (
                <>
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "600",
                      fontSize: "11px",
                      color: "rgba(0,0,0,0.4)",
                      textAlign: "left",
                      margin: "0px",
                    }}
                  >
                    {label3}
                  </p>
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "800",
                      fontSize: "16px",
                      color: "rgba(0,0,0,0.5)",
                      textAlign: "left",
                      margin: "0px 0px 10px 0px",
                    }}
                  >
                    {!item.document
                      ? "Pending Lender Generate Agreement"
                      : item.lender_sign && !item.borrower_sign
                      ? "You need to sign"
                      : item.lender_sign && item.borrower_sign
                      ? "Pending Loan Disbursement"
                      : null}
                  </p>
                </>
              ) : item.lender_sign &&
                !item.borrower_sign ? null : item.lender_sign &&
                item.borrower_sign ? (
                <>
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "600",
                      fontSize: "11px",
                      color: "rgba(0,0,0,0.4)",
                      textAlign: "left",
                      margin: "0px",
                    }}
                  >
                    {label3}
                  </p>
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "800",
                      fontSize: "16px",
                      color: !item.document
                        ? "#f5ce42"
                        : item.lender_sign && !item.borrower_sign
                        ? "#f54542"
                        : item.lender_sign && item.borrower_sign
                        ? item.receipt
                          ? "#32c748"
                          : "#f54542"
                        : "rgba(0,0,0,0.5)",
                      textAlign: "left",
                      margin: "0px 0px 10px 0px",
                    }}
                  >
                    {!item.document
                      ? "Pending Agreement"
                      : item.lender_sign && !item.borrower_sign
                      ? "You need to sign"
                      : item.lender_sign && item.borrower_sign
                      ? item.receipt
                        ? item.status === "ongoing"
                          ? "Ongoing"
                          : item.status === "complete"
                          ? "Completed"
                          : "Pending Receipt Validation"
                        : "Pending Loan Disbursement"
                      : null}
                  </p>
                </>
              ) : null
            ) : null}
            {item.status === "ongoing" ? (
              <div style={{ display: "flex" }}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    fetchReceipt(item.loan_id, item.lender_id);
                  }}
                  className="all_button"
                  style={{
                    background: "#1557a9",
                    color: "white",
                    width: "100%",
                    border: "none",
                    borderRadius: "50px",
                    padding: "6px 0px",
                    fontSize: "12px",
                    margin: "10px 0px 0px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  View Transfer slip
                </button>
              </div>
            ) : null}
          </div>
          <div>
            {item ? (
              !item.document ? null : item.lender_sign &&
                !item.borrower_sign ? (
                <>
                  <button
                    onClick={() =>
                      fetchLoanAgreement(item.loan_id, item.lender_id)
                    }
                    className="all_button"
                    style={{
                      background: "#1557a9",
                      color: "white",
                      width: "100%",
                      border: "none",
                      borderRadius: "50px",
                      padding: "6px 0px",
                      fontSize: "12px",
                      margin: "10px 0px 0px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {item.borrower_uploaded_agreement
                      ? "View Signed Agreement"
                      : "Download Agreement"}
                  </button>
                  {item.borrower_uploaded_agreement ? null : (
                    <label
                      htmlFor={`fileInputs${index}`}
                      className="all_button"
                      style={{
                        background: "transparent",
                        color: "grey",
                        width: "100%",
                        border: "2px solid lightgrey",
                        borderRadius: "50px",
                        padding: "4px 0px",
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "10px 0px 0px 0px",
                        cursor: "pointer",
                      }}
                    >
                      {item.status_notes
                        ? "Ammend Signed Agreement"
                        : "Upload Signed Agreement"}
                      <input
                        type="file"
                        id={`fileInputs${index}`}
                        accept=".pdf"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          console.log(e, loan_id);
                          handleFileSelect(e, loan_id, item.lender_id);
                        }}
                      />
                    </label>
                  )}
                </>
              ) : null
            ) : null}
          </div>
        </div>
      </>
    );
  };

  // const Container4 = ({
  //   icon,
  //   title,
  //   subtitle,
  //   label,
  //   description,
  //   label1,
  //   label2,
  //   description1,
  //   description2,
  //   label3,
  //   description3,
  //   label4,
  //   description4,
  //   index,
  //   loan_id,
  //   open_modal,
  //   cancel_modal,
  //   onClickModal,
  //   marginTop,

  // }) => {
  //   return (
  //     <>
  //       <div
  //         style={{
  //           width: "23.5%",
  //           textAlign: "center",
  //           marginRight: "0px",
  //           marginLeft: "15px",
  //           marginTop: marginTop, // add this line

  //           border: "1px solid white",
  //           borderRadius: "20px",
  //           backgroundColor: "#FFFFFFB3",
  //           display: "flex",
  //           flexDirection: "column",
  //           justifyContent: "center",
  //           alignItems: "center",
  //         }}
  //       >
  //         <img
  //           src={applyLoan}
  //           alt="Icon"
  //           style={{ width: "25%", height: "auto", margin: "20px 0" }}
  //         />
  //         <button
  //           style={{
  //             fontFamily: "Poppins, sans-serif",
  //             fontWeight: "500",
  //             fontSize: "16px",
  //             marginBottom: "4px",
  //             borderRadius: "20px",
  //             background: hovered3
  //               ? "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)"
  //               : "transparent",
  //             border: hovered3 ? "1px solid none" : "1px solid grey",
  //             padding: "5px 20px",
  //             color: hovered3 ? "white" : " grey",
  //           }}
  //           onMouseEnter={() => setHovered3(true)} // set the state hovered to true  when mouse enter
  //           onMouseLeave={() => setHovered3(false)} // set the state hovered to false  when mouse leave
  //           onClick={(event) => handleApplyLoan(event)}
  //         >
  //           Apply Loan
  //         </button>
  //       </div>
  //     </>
  //   );
  // };

  const ContainerTransaction = ({ title, label }) => (
    <div
      style={{
        width: screenWidth > 1200 ? "100%" : "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "10px",
      }}
    >
      <div style={{ flex: 1, textAlign: "left", marginLeft: "0px" }}>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            flex: 1,
            margin: "0px",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 600,
            fontSize: "15px",
            color: "#435669",
            margin: "0px",
          }}
        >
          {label}
        </p>
      </div>
    </div>
  );

  const handleRowClick = async (loan_id, offer_id) => {
    console.log("loan_id", loan_id);
    console.log("offer_id", offer_id);

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/pendingLoan/viewOfferedLoanDetail?loan_id=${loan_id}&offer_id=${offer_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      const loanDetail = json["Offered Loans Detail"][0];
      setOfferedLoanDetail(json["Offered Loans Detail"]);
      setLenderId(loanDetail.lender_id); // Set the lender_id in the state
      setType(loanDetail.type); // Set the type in the state
      setPrincipal(loanDetail.offer_principal); // Set the principal
      setAmount(loanDetail.offer_amount); // Set the amount in the
      setAdminFee(loanDetail.admin_fee); // Set the admin  fee
      setInterestRate(loanDetail.offer_interest_rate); // Set the interest rate
      setPeriod(loanDetail.offer_period); // Set the period
      setMonthlyInstallment(loanDetail.offer_monthly_installment);
      setRevenue(loanDetail.offer_revenue);
      setExpress(loanDetail.express);
      setTotalAdminFee(loanDetail.total_admin_fee);
    } catch (error) {
      console.error("Error retrieving Offered Loans Detail:", error);
    }
  };

  const handleAcceptOffer = async (loan_id) => {
    try {
      let item = {
        loan_id: loan_id,
        lender_id: lenderId,
        type: type,
        express: express,
        category: category,
        principal: principal,
        amount: amount,
        admin_fee: admin_fee,
        total_admin_fee: total_admin_fee,
        interest_rate: interest_rate,
        period: period,
        monthly_installment: monthly_installment,
        revenue: revenue,
      };

      const response = await fetch(
        `https://www.smart-lend.com/api/loan/pendingLoan/acceptLoan`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(item),
        }
      );
      const json = await response.json();

      if (response.status === 200) {
        // Here, when the response status is 200, close the modal
        setModalStates((prevStates) => ({
          ...prevStates,
          [loan_id]: false,
        }));

        // Display the message from the Ant Design library
        message.success(json.message);
      } else if (response.status === 400) {
        setStatusMessage({
          message: "You have already accepted this loan.",
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error retrieving Requested Loan Details:", error);
      if (error) {
        setStatusMessage({
          message: "Please click the table row.",
          color: "red",
        });
      }
    }
  };

  //   const handleSelectionChange = (selectedBorrowerIds) => {
  //     console.log('Selected loan IDs:', selectedBorrowerIds);
  //     setselectedBorrowerIds(selectedBorrowerIds);
  //   };
  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    }
  }, [history]);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/agreement/viewListAgreement",
          options
        );

        if (response.status === 403) {
          throw new Error("Document is not ready yet");
        }

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setData(data["Accepted Loan"]);
      } catch (error) {
        console.error("Error fetching download file data:", error);
        // setErrorMessage(error.message);
      }
    }
    fetchData();
  }, []);

  // useEffect(() => {
  //   const initialStates = {};
  //   data.forEach(item => {
  //     initialStates[item.loan_id] = false;
  //   });
  //   setModalStates(initialStates);
  // }, [data]);

  const resetLoanDetail = () => {
    setOfferedLoanDetail([]);
  };

  const [modalStates, setModalStates] = useState({});
  const [open, setOpen] = useState(false);
  const showModal = (loanId, event) => {
    event.preventDefault();
    console.log("loanId modal", loanId);
    setModalStates((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExtendLoan = async (loanId, expired_date, event) => {
    event.preventDefault();

    try {
      let item = {
        loan_id: loanId,
        expired_date: expired_date,
      };

      const response = await fetch(
        `https://www.smart-lend.com/api/loan/pendingLoan/extendLoanRequest`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(item),
        }
      );
      const json = await response.json();
    } catch (error) {
      console.error("Error retrieving Requested Loan Details:", error);
    }
  };

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        borderRadius: "10px",
        height: "60px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color: "grey",
          height: "60px",
          gap: "10px",
        }}
      >
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            background: "rgb(140,140,170)",
            marginLeft: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            color: "white",
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {title ? getInitials(title) : "SL"}
        </div>
        <div style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "18px",
              color: "#435669",
              margin: "0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "10px",
              fontWeight: "400",
              margin: "0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );
  const [imageUrl, setImageUrl] = useState("");

  const getProfilePicture = async (fileName) => {
    console.log(fileName);
    // try {
    //   const response = await fetch(
    //     `https://www.smart-lend.com/api/user/getProfilePicture?profile_picture=${fileName}`,
    //     {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${Cookies.get("token")}`,
    //       },
    //     }
    //   );

    //   const blob = await response.blob();
    //   const imageUrl = URL.createObjectURL(blob);
    //   console.log("Profile picture retrieved,asduysh", imageUrl);
    //   setImageUrl(imageUrl);
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided

    const date = new Date(dateString); // Create a Date object from the date string
    const formattedDate = format(date, "MMM dd, yyyy"); // Use date-fns to format the date in the desired format

    return formattedDate;
  };

  const ContainerLineChart = () => (
    <div
      style={{
        width: "100%",
        height: "200px",
        marginRight: "0px",
        borderRadius: "20px",
      }}
    >
      <TinyLineChart title="$12,000" subtitle="Net profit to date" />
    </div>
  );

  const ContainerPieChart = () => (
    <div
      style={{
        width: "100%",
        height: "200px",
        marginRight: "0px",
        color: "grey",
        borderRadius: "20px",
      }}
    >
      <HalfPieChart title="$802,000" subtitle="Forecast collection" />
    </div>
  );

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box m={isMobile ? "4px 0px 20px 0px" : "4px 20px 20px 20px"}>
      <Modal
        width={screenWidth > 1200 ? "400px" : "80vw"}
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={lenderProfileData ? true : false}
        onCancel={() => {
          setLenderProfileData(null);
        }}
        footer={null}
      >
        <div style={{}}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "15px",
            }}
          >
            <div>
              <img
                style={{
                  height: "80px",
                  width: "80px",
                  borderRadius: "50%",
                  background: "lightgrey",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
                  objectFit: "cover",
                }}
                src={lenderProfileData?.profile?.profile_photo}
              />
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  color: "rgb(14, 90, 165)",
                  fontSize: "20px",
                  fontFamily: "Manrope,sans-serif",
                  fontWeight: 800,
                  marginBottom: "5px",
                }}
              >
                {lenderProfileData?.profile?.company_name}
              </div>
              {lenderProfileData?.profile?.additionals?.map((addi, index) => (
                <div key={index} style={{ display: "flex", gap: "10px" }}>
                  <div
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      color: "rgba(0,0,0,0.6)",
                      marginTop: "-5px",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    {addi?.text}
                  </div>
                </div>
              ))}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginLeft: "-5px",
                  marginTop: "5px",
                }}
              >
                {lenderProfileData?.profile?.social?.map((soci, index) => (
                  <div
                    key={index}
                    style={{
                      padding: "5px",
                      position: "relative",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={iconLibrary[soci?.icon]}
                      style={{
                        fontSize: 20,
                        color: "rgb(14, 90, 165)",
                      }}
                      onClick={() => {
                        if (soci.link !== "" && soci.link !== null) {
                          window.location.href = soci.link;
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <hr
            style={{
              width: "90%",
              marginLeft: "5%",
              background: "grey",
              border: "none",
              outline: "none",
            }}
          />
          {lenderProfileData?.content?.map((cont, index) =>
            cont.type === "text" ? (
              <div
                key={index}
                style={{
                  position: "relative",
                  marginTop: cont.mt,
                  marginBottom: cont.mb,
                  textDecoration: cont.underline ? "underline" : "none",
                  fontWeight: cont.fontWeight === 400 ? 400 : 600,
                  fontSize: cont.fontWeight === 400 ? "12px" : "16px",
                  border: "none",
                  outline: "none",
                  color: "rgba(0,0,0,0.6)",
                  width: "100%",
                }}
              >
                {cont?.text}
              </div>
            ) : cont?.type === "button" ? (
              <div
                key={index}
                style={{
                  position: "relative",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (cont.url !== "" && cont.url !== null) {
                    window.location.href = cont.url;
                  }
                }}
              >
                <div
                  style={{
                    marginTop: cont.mt,
                    marginBottom: cont.mb,
                    fontWeight: 500,
                    fontSize: "14px",
                    border: "1px solid rgba(0,0,0,0.05)",
                    outline: "none",
                    color: "white",
                    background: "rgb(14, 90, 165)",
                    padding: "7px 30px",
                    borderRadius: "30px",
                  }}
                >
                  {cont?.text}
                </div>
              </div>
            ) : (
              <div
                key={index}
                style={{
                  position: "relative",
                  marginTop: cont.mt,
                  marginBottom: cont.mb,
                }}
              >
                <img
                  style={{
                    objectFit: "cover",
                    width: "80%",
                    height: "150px",
                    border: "none",
                    outline: "none",
                  }}
                  src={cont?.url}
                />
              </div>
            )
          )}
        </div>
      </Modal>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn={isMobile ? "span 12" : "span 12"}
          gridRow="span 2"
          height={isMobile ? `calc(${window.innerHeight}px - 80px)` : "88.7vh"}
          borderRadius="10px"
          border="0px solid "
          opacity="0.95"
          style={{
            overflowY: "scroll",
            background: !isMobile
              ? "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
              : "transparent",
          }}
        >
          <Box
            gridColumn="span 12"
            gridRow="span 2"
            background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
            border-radius="20px"
            opacity="0.95"
            style={{ width: "100%", padding: "20px" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{ width: "100%" }}
              borderRadius="10px"
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="18px"
              >
                {isMobile ? "Loans" : "Accepted Loans"}
              </Typography>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: isMobile ? "auto" : "250px",
                }}
              >
                <label
                  htmlFor="sort-by"
                  style={{
                    marginRight: "7px",
                    marginLeft: "10px",
                    color: "#142C44CC",
                    fontWeight: "800",
                    fontSize: "13px",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  Sort by:
                </label>
                <div style={{ width: "60%" }}>
                  <select
                    id="sort-by"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    <option value="loan-amount">Loan Amount</option>
                    <option value="date">Date</option>
                    <option value="loan-tenor">Loan Tenor</option>
                  </select>
                </div>
              </div>
            </Box>
            <Box gridColumn="span 4">
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  color: "grey",
                  gap: "15px",
                  marginTop: "15px",
                }}
              >
                {data && data.length > 0 ? (
                  data

                    .sort((a, b) => {
                      if (sortBy === "loan-amount") {
                        return a.offer_amount - b.offer_amount;
                      } else if (sortBy === "date") {
                        return new Date(a.created_at) - new Date(b.created_at);
                      } else {
                        // Default sorting option
                        return new Date(b.created_at) - new Date(a.created_at);
                      }
                    })
                    .map((item, index) => (
                      <Container
                        key={index}
                        index={index}
                        // icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                        // title={item.principal_name || "-"}
                        // subtitle={item.express === 1 ? "Express Applicant" : "Approved Borrower"}
                        label5="Loan ID"
                        description5={item.loan_id || "-"}
                        label="Loan Amount"
                        description={
                          item.amount
                            ? item.amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : "-"
                        }
                        label1="Monthly Amount"
                        description1={
                          item.monthly_installment
                            ? item.monthly_installment.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : "-"
                        }
                        label2="Period"
                        description2={item.period + " Months" || "-"}
                        label3="Loan Status"
                        description3={
                          item.status === "ongoing"
                            ? item.status === "pending"
                              ? "Pending Loan Approval"
                              : item.status
                              ? item.status.charAt(0).toUpperCase() +
                                item.status.slice(1)
                              : "-"
                            : "Ongoing"
                        }
                        label4="Interest Rate"
                        description4={
                          (item.interest_rate * 100).toFixed(1) + " %" || "-"
                        }
                        createdAt={item.created_at}
                        loan_id={item.loan_id} // pass the loan_id prop to each Container instance
                        open_modal={modalStates[item.loan_id]} // pass the modal state to each Container instance
                        onClickModal={(event) => showModal(item.loan_id, event)} // pass the showModal function to each Container instance
                        cancel_modal={() => handleClose(item.loan_id)} // pass the cancelModal function to each Container instance
                        item={item}
                      />
                    ))
                ) : (
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 0px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 15px",
                        background: "rgba(255,255,255,0.7)",
                        borderRadius: "10px",
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "0.8em",
                        fontWeight: "600",
                        color: "#00000066",
                      }}
                    >
                      You have no accepted loans
                    </div>
                  </div>
                )}{" "}
                {/* {data && data.length > 0 && <Container4 marginTop={data.length > 3 ? "10px" : "0px"}    />} */}
                {data &&
                  data.length > 0 &&
                  data
                    .sort((a, b) => {
                      if (sortBy === "loan-amount") {
                        return a.offer_amount - b.offer_amount;
                      } else if (sortBy === "date") {
                        return new Date(a.created_at) - new Date(b.created_at);
                      } else {
                        // Default sorting option
                        return new Date(b.created_at) - new Date(a.created_at);
                      }
                    })
                    .map((item, index) => (
                      <Modal
                        width="50vw"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
                        open={modalStates[item.loan_id]}
                        onCancel={() => {
                          setModalStates((prevStates) => ({
                            ...prevStates,
                            [item.loan_id]: false,
                          }));
                          resetLoanDetail();
                          setStatusMessage({ message: "", color: "" });
                        }}
                        footer={[
                          <React.Fragment key="footer-elements">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "25px",
                              }}
                            >
                              <Button
                                type="primary"
                                style={{
                                  background: "#0DC45C",
                                  borderRadius: "20px",
                                  width: "15%",
                                }}
                                onClick={() => {
                                  handleAcceptOffer(item.loan_id);
                                }}
                              >
                                Accept Offer
                              </Button>{" "}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                                color: statusMessage.color,
                              }}
                            >
                              <p>{statusMessage.message}</p>
                            </div>
                          </React.Fragment>,
                        ]}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 16,
                            borderRadius: "10px",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <label
                              htmlFor="offer_amount"
                              style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: "600",
                                fontSize: "40px",
                                color: "#0E5AA5",
                              }}
                            >
                              Offered Loan
                            </label>
                            <TableOfferedLoan
                              loan_id={item.loan_id}
                              onRowClick={(loan_id, offer_id) =>
                                handleRowClick(loan_id, offer_id)
                              }
                            />
                          </div>
                        </div>
                        <hr />
                        {offeredLoanDetail && offeredLoanDetail.length > 0 ? (
                          <LoanDetails offeredLoanDetail={offeredLoanDetail} />
                        ) : (
                          <h5>
                            Please click on the table row to display the Loan
                            Details
                          </h5>
                        )}
                      </Modal>
                    ))}
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AcceptedLoans;
