// import React from "react";
// import { useState, useEffect } from "react";
// import Cookies from "js-cookie";
// import { Box, useTheme } from "@mui/material";
// import adspot_ico from "../../../src/assets/img/businessman-receiving-money-after-contract-signing.png";
// import { Typography, notification, Upload } from "antd";
// import { tokens } from "../../../src/theme";
// import { Progress, Button, message, Avatar, Modal } from "antd";
// import {
//   UserOutlined,
//   UploadOutlined,
//   QuestionOutlined,
// } from "@ant-design/icons";

import { Card, Tabs, Button, Avatar, Modal, message, Spin } from "antd";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import BasicInformation from "./basic-information";
import Employment from "./employment";
import Property from "./property";
import EntityAddress from "./entity-address";
import EntityProfile from "./entity-profile";
import Grants from "./grants";
import PreviousUEN from "./previous-uen";
import Shareholders from "./shareholders";
import Capital from "./capital";
import FinanceHighlights from "./finance-highlights";
import Finance from "./finance";
import adspot_ico from "../../assets/img/businessman-receiving-money-after-contract-signing.png";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import Family from "./family";
import Vehicle from "./vehicle";
import Appointments from "./appointments";
import singlogo from "../../pages/smart-lend-frontend/src/img/singpass_logo.png";
import { useLocation } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";

const onChange = (key) => {
  console.log(key);
};

const ProfileStaging = () => {
  const history = useHistory();
  const location = useLocation();

  const redirectToUrl = (path) => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    let redirectTo = path;

    if (code && state) {
      redirectTo += `?code=${code}&state=${state}`;
    }

    history.push(redirectTo);
  };

  useEffect(() => {
    if (Cookies.get("token")) {
      console.log("Cookies exist");
      // } else {
      //   console.log("Cookies don't exist and redirect to www.gmail.com");
      //   redirectToUrl("/director-authorization");
      // }
    }
  }, [Cookies.get("token")]);
  const [dataActivityLog, setActivityLog] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeData, setActiveData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [displayAds, setDisplayAds] = useState([]);
  const [adImage, setAdImage] = useState(null);
  const [adLink, setAdLink] = useState(null);

  const { TabPane } = Tabs;
  const singpass_sync = Cookies.get("singpass_sync");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [singpassData, setSingpassData] = useState(null);
  const [urlCode, setUrlCode] = useState(null);
  const [sexDetail, setSexDetail] = useState(null);
  const [nric2, setNRIC2] = useState("");
  const [nric, setNRIC] = useState("");
  const [fullName2, setFullName2] = useState("");
  const [fullName, setFullName] = useState("");
  const [sex, setSex] = useState("");
  const [sex2, setSex2] = useState("");
  const [race, setRace] = useState("");
  const [race2, setRace2] = useState("");
  const [nationality, setNationality] = useState("");
  const [nationality2, setNationality2] = useState("");
  const [dob, setDOB] = useState("");
  const [dob2, setDOB2] = useState("");
  const [countryOfBirth, setCountryOfBirth] = useState("");
  const [countryOfBirth2, setCountryOfBirth2] = useState("");
  const [residentialStatus, setResidentialStatus] = useState("");
  const [residentialStatus2, setResidentialStatus2] = useState("");
  const [passType, setPassType] = useState("");
  const [passType2, setPassType2] = useState("");
  const [passStatus, setPassStatus] = useState("");
  const [passStatus2, setPassStatus2] = useState("");
  const [passExpiryDate, setPassExpiryDate] = useState("");
  const [passExpiryDate2, setPassExpiryDate2] = useState("");
  const [privatePropertyStatus, setPrivatePropertyStatus] = useState("");
  const [privatePropertyStatus2, setPrivatePropertyStatus2] = useState("");
  const [occupation, setOccupation] = useState("");
  const [occupation2, setOccupation2] = useState("");
  const [employerName, setEmployerName] = useState("");
  const [employerName2, setEmployerName2] = useState("");
  const [employmentSector, setEmploymentSector] = useState("");
  const [employmentSector2, setEmploymentSector2] = useState("");
  const [hdbType, setHDBType] = useState("");
  const [hdbType2, setHDBType2] = useState("");
  const [hdbAddress, setHDBAddress] = useState("");
  const [hdbAddress2, setHDBAddress2] = useState("");
  const [hdbOutstandingLoanBalance, setHDBOutstandingLoanBalance] =
    useState("");
  const [hdbOutstandingLoanBalance2, setHDBOutstandingLoanBalance2] =
    useState("");
  const [hdbMonthlyLoanInstallment, setHDBMonthlyLoanInstallment] =
    useState("");
  const [hdbMonthlyLoanInstallment2, setHDBMonthlyLoanInstallment2] =
    useState("");
  const [hdbTypeDwelling, setHDBTypeDwelling] = useState("");
  const [hdbTypeDwelling2, setHDBTypeDwelling2] = useState("");
  const [vehiclesModel, setVehiclesModel] = useState("");
  const [vehiclesModel2, setVehiclesModel2] = useState("");
  const [vehiclesMake, setVehiclesMake] = useState("");
  const [vehiclesMake2, setVehiclesMake2] = useState("");
  const [noaHistory, setNoaHistory] = useState([]);

  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [mobile_number2, setMobileNumber2] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [maritalStatus2, setMaritalStatus2] = useState("");
  const [uen, setUEN] = useState("");
  const [uen2, setUEN2] = useState("");
  const [entityName, setEntityName] = useState("");
  const [entityName2, setEntityName2] = useState("");
  const [entityType, setEntityType] = useState("");
  const [entityType2, setEntityType2] = useState("");
  const [entityStatus, setEntityStatus] = useState("");
  const [entityStatus2, setEntityStatus2] = useState("");
  const [registeredAddress, setRegisteredAddress] = useState("");
  const [registeredAddress2, setRegisteredAddress2] = useState("");
  const [registeredAddressEntity, setRegisteredAddressEntity] = useState("");
  const [registeredAddressEntity2, setRegisteredAddressEntity2] = useState("");
  const [housingType, setHousingType] = useState("");
  const [housingType2, setHousingType2] = useState("");
  const [highestEducationLevel, setHighestEducationLevel] = useState("");
  const [highestEducationLevel2, setHighestEducationLevel2] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [registrationDate2, setRegistrationDate2] = useState("");
  const [primarySSIC, setPrimarySSIC] = useState("");
  const [primarySSIC2, setPrimarySSIC2] = useState("");
  const [secondarySSIC, setSecondarySSIC] = useState("");
  const [secondarySSIC2, setSecondarySSIC2] = useState("");
  const [appointment1Position, setAppointment1Position] = useState("");
  const [appointment1Position2, setAppointment1Position2] = useState("");
  const [appointment1NRIC, setAppointment1NRIC] = useState("");
  const [appointment1NRIC2, setAppointment1NRIC2] = useState("");
  const [appointment1FullName, setAppointment1FullName] = useState("");
  const [appointment1FullName2, setAppointment1FullName2] = useState("");
  const [appointment1AppointmentDate, setAppointment1AppointmentDate] =
    useState("");
  const [appointment1AppointmentDate2, setAppointment1AppointmentDate2] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [capitals, setCapitals] = useState([]);
  const [financials, setFinancials] = useState([]);
  const [grants, setGrants] = useState([]);
  const [previousUens, setPreviousUens] = useState([]);
  const [shareholders, setShareholders] = useState([]);

  const [nricError, setNricError] = useState(false);
  const [fullNameError, setFullNameError] = useState(false);
  const [sexError, setSexError] = useState(false);
  const [raceError, setRaceError] = useState(false);
  const [nationalityError, setNationalityError] = useState(false);
  const [dobError, setDOBError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileNoError, setMobileNoError] = useState(false);
  const [registeredAddressError, setRegisteredAddressError] = useState(false);
  const [housingTypeError, setHousingTypeError] = useState(false);
  const [maritalStatusError, setMaritalStatusError] = useState(false);
  const [highestEducationLevelError, setHighestEducationLevelError] =
    useState(false);
  const [uenError, setUENError] = useState(false);
  const [entityNameError, setEntityNameError] = useState(false);
  const [entityTypeError, setEntityTypeError] = useState(false);
  const [entityStatusError, setEntityStatusError] = useState(false);
  const [registeredAddressEntityError, setRegisteredAddressEntityError] =
    useState(false);
  const [registrationDateError, setRegistrationDateError] = useState(false);
  const [primarySSICError, setPrimarySSICError] = useState(false);
  const [secondarySSICError, setSecondarySSICError] = useState(false);
  const [appointment1PositionError, setAppointment1PositionError] =
    useState(false);
  const [appointment1NRICError, setAppointment1NRICError] = useState(false);
  const [appointment1FullNameError, setAppointment1FullNameError] =
    useState(false);
  const [appointment1DateError, setAppointment1DateError] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [verifiedStatus, setVerifiedStatus] = useState(0);

  const updateUserVerificationFiles = async (data) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/updateUserVerificationFiles`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify({
            verification_files: JSON.stringify(data),
          }),
        }
      );

      if (response.ok) {
        const json = await response.json();
        console.log(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onFileSelected = async (files) => {
    try {
      let uploadedFileUrl = [];
      for (const file of files) {
        console.log(file);
        const formData = new FormData();
        formData.append("file", file);
        const response = await fetch(
          `https://www.smart-lend.com/api/file/uploadUniversalFile`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
            body: formData,
          }
        );
        if (response.ok) {
          const json = await response.json();
          uploadedFileUrl.push({ name: file.name, url: json.url });
        }
      }
      const updatedverificationfiles = [...uploadedFiles, ...uploadedFileUrl];
      setUploadedFiles([...uploadedFiles, ...uploadedFileUrl]);
      updateUserVerificationFiles(updatedverificationfiles);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserVerificationFiles = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/user/getUserVerificationFiles`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (response.ok) {
        const json = await response.json();
        if (JSON.parse(json.verification_files)) {
          setUploadedFiles(JSON.parse(json.verification_files));
        }
        if (json.verificationStatus) {
          setVerifiedStatus(json.verificationStatus);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getUserVerificationFiles();
  }, []);

  const [notSameEntityModalIsOpen, setNotSameEntityModalIsOpen] =
    useState(false);

  const [sameAsOtherEntityModalIsOpen, setSameAsOtherEntityModalIsOpen] =
    useState(false);
  function isEntityNameInList(entityName, allEntitiesName) {
    return allEntitiesName.includes(entityName);
  }

  useEffect(() => {
    // Get current URL
    const currentURL = new URL(window.location.href);

    // Extract 'code' query parameter value
    const code = currentURL.searchParams.get("code");
    const state = currentURL.searchParams.get("state");
    const expressAuth = Cookies.get("expressauth");

    if (code && !expressAuth) {
      setUrlCode(code);
      setIsModalVisible(true);

      // Define an async function for fetching data
      const fetchData = async () => {
        let previousEntityName = null;
        let entities = [];
        let json;

        try {
          const response = await fetch(
            `https://www.smart-lend.com/api/user/getUserEntityName`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
            }
          );
          const jsonnentityName = await response.json();
          entities = jsonnentityName.entity_names;
          previousEntityName = jsonnentityName.entity_name;
        } catch (err) {
          message.error(err);
        }

        try {
          setIsLoading(true); // Start loading
          const response = await fetch(
            `https://www.smart-lend.com/api/singpass/resolveTokens`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify({
                code: code,
                state: state,
              }),
            }
          );
          json = await response.json();

          setSingpassData(json);
          console.log("Response:", json);

          const personDetail = json.person;
          const entityDetail = json.entity;
          if (
            (previousEntityName === null ||
              previousEntityName === "" ||
              previousEntityName ===
                entityDetail["basic-profile"]["entity-name"].value) &&
            !isEntityNameInList(
              entityDetail["basic-profile"]["entity-name"].value,
              entities
            )
          ) {
            setRace(
              personDetail.race
                ? personDetail.race.desc
                  ? personDetail.race.desc
                  : ""
                : ""
            );
            setDOB(
              personDetail.dob
                ? personDetail.dob.value
                  ? personDetail.dob.value
                  : ""
                : ""
            );
            setNationality(
              personDetail.nationality
                ? personDetail.nationality.desc
                  ? personDetail.nationality.desc
                  : ""
                : ""
            );
            setNRIC2(
              personDetail.uinfin
                ? personDetail.uinfin.value
                  ? personDetail.uinfin.value
                  : ""
                : ""
            );
            setFullName2(
              personDetail.name
                ? personDetail.name.value
                  ? personDetail.name.value
                  : ""
                : ""
            );
            setSex2(
              personDetail.sex
                ? personDetail.sex.desc
                  ? personDetail.sex.desc
                  : ""
                : ""
            );
            setRace2(
              personDetail.race
                ? personDetail.race.desc
                  ? personDetail.race.desc
                  : ""
                : ""
            );
            setNationality2(
              personDetail.nationality
                ? personDetail.nationality.desc
                  ? personDetail.nationality.desc
                  : ""
                : ""
            );
            setDOB2(
              personDetail.dob
                ? personDetail.dob.value
                  ? personDetail.dob.value
                  : ""
                : ""
            );
            setMaritalStatus2(
              personDetail.marital
                ? personDetail.marital.desc
                  ? personDetail.marital.desc
                  : ""
                : ""
            );
            setEmail2(
              personDetail.email
                ? personDetail.email.value
                  ? personDetail.email.value
                  : ""
                : ""
            );
            setMobileNumber2(
              personDetail.mobileno
                ? personDetail.mobileno.nbr
                  ? personDetail.mobileno.nbr.value
                    ? personDetail.mobileno.nbr.value
                    : ""
                  : ""
                : ""
            );
            setCountryOfBirth2(
              personDetail.birthcountry
                ? personDetail.birthcountry.desc
                  ? personDetail.birthcountry.desc
                  : ""
                : ""
            );
            setResidentialStatus2(
              personDetail.residentialstatus
                ? personDetail.residentialstatus.desc
                  ? personDetail.residentialstatus.desc
                  : ""
                : ""
            );
            setPassType2(
              personDetail.passtype
                ? personDetail.passtype.desc
                  ? personDetail.passtype.desc
                  : ""
                : ""
            );
            setPassStatus2(
              personDetail.passstatus
                ? personDetail.passstatus.value
                  ? personDetail.passstatus.value
                  : ""
                : ""
            );
            setPassExpiryDate2(
              personDetail.passexpirydate
                ? personDetail.passexpirydate.value
                  ? personDetail.passexpirydate.value
                  : ""
                : ""
            );
            setPrivatePropertyStatus2(
              personDetail.ownerprivate
                ? personDetail.ownerprivate.value
                  ? personDetail.ownerprivate.value
                  : ""
                : ""
            );
            setOccupation2(
              personDetail.occupation
                ? personDetail.occupation.value
                  ? personDetail.occupation.value
                  : ""
                : ""
            );
            // setEmployerName2();
            // setEmploymentSector2();
            setHDBType2(
              personDetail.hdbtype
                ? personDetail.hdbtype.desc
                  ? personDetail.hdbtype.desc
                  : ""
                : ""
            );
            setHousingType2(
              personDetail.housingtype
                ? personDetail.housingtype.desc
                  ? personDetail.housingtype.desc
                  : ""
                : ""
            );

            setHDBOutstandingLoanBalance2(
              personDetail.hdbownership[0]
                ? personDetail.hdbownership[0].outstandingloanbalance
                  ? personDetail.hdbownership[0].outstandingloanbalance.value
                    ? personDetail.hdbownership[0].outstandingloanbalance.value
                    : ""
                  : ""
                : ""
            );
            setHDBMonthlyLoanInstallment2(
              personDetail.hdbownership[0]
                ? personDetail.hdbownership[0].monthlyloaninstalment
                  ? personDetail.hdbownership[0].monthlyloaninstalment.value
                    ? personDetail.hdbownership[0].monthlyloaninstalment.value
                    : ""
                  : ""
                : ""
            );
            setHDBTypeDwelling2(
              personDetail.hdbownership[0]
                ? personDetail.hdbownership[0].hdbtype
                  ? personDetail.hdbownership[0].hdbtype.desc
                    ? personDetail.hdbownership[0].hdbtype.desc
                    : ""
                  : ""
                : ""
            );
            setNoaHistory(
              personDetail.noahistory ? personDetail.noahistory.noas || [] : []
            );
            if (entityDetail["basic-profile"]) {
              setUEN2(
                entityDetail["basic-profile"].uen
                  ? entityDetail["basic-profile"].uen.value
                    ? entityDetail["basic-profile"].uen.value
                    : ""
                  : ""
              );
              setEntityName2(
                entityDetail["basic-profile"]["entity-name"]
                  ? entityDetail["basic-profile"]["entity-name"].value
                    ? entityDetail["basic-profile"]["entity-name"].value
                    : ""
                  : ""
              );
              setEntityType2(
                entityDetail["basic-profile"]["entity-type"]
                  ? entityDetail["basic-profile"]["entity-type"].desc
                    ? entityDetail["basic-profile"]["entity-type"].desc
                    : ""
                  : ""
              );
              setEntityStatus2(
                entityDetail["basic-profile"]["entity-status"]
                  ? entityDetail["basic-profile"]["entity-status"].value
                    ? entityDetail["basic-profile"]["entity-status"].value
                    : ""
                  : ""
              );
              setRegistrationDate2(
                entityDetail["basic-profile"]["registration-date"]
                  ? entityDetail["basic-profile"]["registration-date"].value
                    ? entityDetail["basic-profile"]["registration-date"].value
                    : ""
                  : ""
              );
              setPrimarySSIC2(
                entityDetail["basic-profile"]["primary-activity"]
                  ? entityDetail["basic-profile"]["primary-activity"].desc
                    ? entityDetail["basic-profile"]["primary-activity"].desc
                    : ""
                  : ""
              );
              setSecondarySSIC2(
                entityDetail["basic-profile"]["secondary-activity"]
                  ? entityDetail["basic-profile"]["secondary-activity"].desc
                    ? entityDetail["basic-profile"]["secondary-activity"].desc
                    : ""
                  : ""
              );
            }
            setAppointments(
              entityDetail.appointments
                ? entityDetail.appointments["appointments-list"]
                  ? entityDetail.appointments["appointments-list"]
                  : ""
                : ""
            );

            setCapitals(
              entityDetail.capitals
                ? entityDetail.capitals["capitals-list"] || []
                : []
            );
            setFinancials(
              entityDetail.financials
                ? entityDetail.financials.highlights || []
                : []
            );
            setGrants(
              entityDetail.grants
                ? entityDetail.grants["grants-list"] || []
                : []
            );
            setPreviousUens(
              entityDetail["previous-uens"]
                ? entityDetail["previous-uens"]["previous-uens-list"] || []
                : []
            );
            setShareholders(
              entityDetail.shareholders
                ? entityDetail.shareholders["shareholders-list"] || []
                : []
            );
            setVehiclesModel2(
              personDetail.vehicles
                ? personDetail.vehicles[0]
                  ? personDetail.vehicles[0].model
                    ? personDetail.vehicles[0].model.value
                      ? personDetail.vehicles[0].model.value
                      : ""
                    : ""
                  : ""
                : ""
            );
            setVehiclesMake2(
              personDetail.vehicles
                ? personDetail.vehicles[0]
                  ? personDetail.vehicles[0].make
                    ? personDetail.vehicles[0].make.value
                      ? personDetail.vehicles[0].make.value
                      : ""
                    : ""
                  : ""
                : ""
            );

            const formattedAddress = singpassData?.person?.regadd?.floor.value
              ? (singpassData?.person?.regadd?.unit?.value !== undefined
                  ? `Unit ${singpassData?.person?.regadd?.unit?.value}, `
                  : "") +
                (singpassData?.person?.regadd?.floor?.value !== undefined
                  ? `Floor ${singpassData?.person?.regadd?.floor?.value}, `
                  : "") +
                (singpassData?.person?.regadd?.block?.value !== undefined
                  ? `Block ${singpassData?.person?.regadd?.block?.value}, `
                  : "") +
                (singpassData?.person?.regadd?.street?.value !== undefined
                  ? `${singpassData?.person?.regadd?.street?.value}, `
                  : "") +
                (singpassData?.person?.regadd?.postal?.value !== undefined
                  ? `${singpassData?.person?.regadd?.postal?.value}, `
                  : "") +
                (singpassData?.person?.regadd?.country?.desc !== undefined
                  ? `${singpassData?.person?.regadd?.country?.desc}.`
                  : "")
              : (singpassData?.person?.regadd?.block?.value !== undefined
                  ? `${singpassData?.person?.regadd?.block?.value}, `
                  : "") +
                (singpassData?.person?.regadd?.street?.value !== undefined
                  ? `${singpassData?.person?.regadd?.street?.value}, `
                  : "") +
                (singpassData?.person?.regadd?.postal?.value !== undefined
                  ? `${singpassData?.person?.regadd?.postal?.value}, `
                  : "") +
                (singpassData?.person?.regadd?.country?.desc !== undefined
                  ? `${singpassData?.person?.regadd?.country?.desc}.`
                  : "");

            const formattedAddressEntity =
              (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit
                ?.value !== undefined
                ? `Unit ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
                : "") +
              (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor
                ?.value !== undefined
                ? `Floor ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
                : "") +
              (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block
                ?.value !== undefined
                ? `Block ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
                : "") +
              (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street
                ?.value !== undefined
                ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
                : "") +
              (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal
                ?.value !== undefined
                ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
                : "") +
              (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country
                ?.desc !== undefined
                ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
                : "");

            setRegisteredAddress2(formattedAddress ? formattedAddress : "");
            setHDBAddress2(formattedAddress ? formattedAddress : "");
            setRegisteredAddressEntity2(
              formattedAddressEntity ? formattedAddressEntity : ""
            );
          } else if (
            isEntityNameInList(
              entityDetail["basic-profile"]["entity-name"].value,
              entities
            )
          ) {
            setIsModalVisible(false);
            setSameAsOtherEntityModalIsOpen(true);
          } else {
            setIsModalVisible(false);
            setNotSameEntityModalIsOpen(true);
          }

          setIsLoading(false); // Stop loading after data is fetched
          // Remove 'code' and 'state' parameters from the URL
          currentURL.searchParams.delete("code");
          currentURL.searchParams.delete("state");
          console.log(currentURL.toString());

          // Replace the current URL in the browser's history
          window.history.replaceState(
            {},
            document.title,
            currentURL.toString()
          );
        } catch (err) {
          console.log("err:", err);
          setIsLoading(false); // Stop loading in case of error
          currentURL.searchParams.delete("code");
          currentURL.searchParams.delete("state");
          console.log(currentURL.toString());

          // Replace the current URL in the browser's history
          window.history.replaceState(
            {},
            document.title,
            currentURL.toString()
          );
        }

        if (
          previousEntityName === null ||
          previousEntityName === "" ||
          previousEntityName ===
            json.entity["basic-profile"]["entity-name"].value
        ) {
          try {
            let item = {
              singpassDataBackup: json,
            };
            const response = await fetch(
              `https://www.smart-lend.com/api/singpass/updateSingpassDataBackup`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + Cookies.get("token"),
                },
                body: JSON.stringify(item),
              }
            );
            const jsonSingpassData = await response.json();
            console.log("jsonSingpassData:", jsonSingpassData);
          } catch (err) {
            message.error(err);
          }
        }
      };

      // Call the async function
      fetchData();
    } else if (code && expressAuth) {
      redirectToUrl("/director-authorization");
    }
  }, []); // Empty dependency array to ensure the effect runs only once on component mount

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const [saveReminder, setSaveReminder] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
    setSaveReminder(false);
  };

  const fetchActiveData = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/getUserActive",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }
      const json = await response.json();
      console.log(json.data);
      setActiveData(json.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async () => {
    setIsModalVisible(false);
    setSaveReminder(false);

    try {
      let item3 = {};

      if (singpassData?.person?.uinfin?.value) {
        item3.nric = singpassData.person.uinfin.value;
      }
      if (singpassData?.person?.name?.value) {
        item3.principal_name = singpassData.person.name.value;
      }
      if (singpassData?.person?.sex?.desc) {
        item3.sex = singpassData.person.sex.desc;
      }
      if (singpassData?.person?.race?.desc) {
        item3.race = singpassData.person.race.desc;
      }
      if (singpassData?.person?.dob?.value) {
        item3.date_of_birth = singpassData.person.dob.value;
      }
      if (singpassData?.person?.residentialstatus?.desc) {
        item3.residential_status = singpassData.person.residentialstatus.desc;
      }
      if (singpassData?.person?.nationality?.desc) {
        item3.nationality = singpassData.person.nationality.desc;
      }

      if (singpassData?.entity?.["basic-profile"]?.uen?.value) {
        item3.uen = singpassData.entity["basic-profile"].uen.value;
      }
      if (singpassData?.entity?.["basic-profile"]?.["entity-name"]?.value) {
        item3.entity_name =
          singpassData.entity["basic-profile"]["entity-name"].value;
      }
      if (singpassData?.entity?.["basic-profile"]?.["entity-type"]?.desc) {
        item3.entity_type =
          singpassData.entity["basic-profile"]["entity-type"].desc;
      }
      if (singpassData?.entity?.["basic-profile"]?.["entity-status"]?.value) {
        item3.entity_status =
          singpassData.entity["basic-profile"]["entity-status"].value;
      }
      if (
        singpassData?.entity?.["basic-profile"]?.["country-of-incorporation"]
          ?.desc
      ) {
        item3.country_of_incorporation =
          singpassData.entity["basic-profile"]["country-of-incorporation"].desc;
      }
      if (
        singpassData?.entity?.["basic-profile"]?.["registration-date"]?.value
      ) {
        item3.registration_date =
          singpassData.entity["basic-profile"]["registration-date"].value;
      }
      if (singpassData?.entity?.["basic-profile"]?.["primary-activity"]?.desc) {
        item3.primary_activity =
          singpassData.entity["basic-profile"]["primary-activity"].desc;
      }
      if (singpassData?.entity?.addresses?.["addresses-list"]?.[0]) {
        const formattedAddressEntity =
          (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit
            ?.value !== undefined
            ? `Unit ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
            : "") +
          (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor
            ?.value !== undefined
            ? `Floor ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
            : "") +
          (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block
            ?.value !== undefined
            ? `Block ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
            : "") +
          (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street
            ?.value !== undefined
            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
            : "") +
          (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal
            ?.value !== undefined
            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
            : "") +
          (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country
            ?.desc !== undefined
            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
            : "");
        item3.address_sg = formattedAddressEntity;
      }

      const response3 = await fetch(
        `https://www.smart-lend.com/api/singpass/updateProfile`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item3),
        }
      );
      const jsonUpdateProfile = await response3.json();
      console.log("jsonUpdateProfile:", jsonUpdateProfile);
      console.log("second api call");
      let item2 = {
        singpassData,
      };

      console.log(item2);

      const response2 = await fetch(
        `https://www.smart-lend.com/api/singpass/updateSingpassData`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify(item2),
        }
      );
      const jsonSingpassDataBackup = await response2.json();
      console.log("jsonSingpassDataBackup:", jsonSingpassDataBackup);

      setIsModalVisible(false);
      if (Cookies.get("sgpasscbs")) {
        console.log(Cookies.get("sgpasscbs"));
      }
      if (Cookies.get("sgpasscbs")) {
        Cookies.remove("sgpasscbs");
        window.location.href = "https://smart-lend.com/b-cbs-campaign";
      } else {
        const currentURL = new URL(window.location.href);
        currentURL.searchParams.delete("code");
        currentURL.searchParams.delete("state");
        window.location.href = currentURL.toString();

        message.success("Successfully Synced With Singpass");
      }
    } catch (err) {
      message.error(err);
    }
  };

  function formatDate(inputDate) {
    // Parse the input date string
    const date = new Date(inputDate);

    // Define month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    // Format the date as "DD Mon, YYYY"
    const formattedDate = `${year}-${parseFloat(month) + 1}-${day}`;

    return formattedDate;
  }

  // const handleSave = async (event) => {
  //   event.preventDefault();

  //   setNricError(false);

  //   // Check for empty fields and set error states
  // if (!nric) {
  //   setNricError(true);
  //   return; // Exit the function if validation fails
  // }

  //   try {
  //     let item = {
  //       singpassDataBackup: singpassData,
  //     };
  //     const response = await fetch(
  //       `https://www.smart-lend.com/api/singpass/updateSingpassDataBackup`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + Cookies.get("token"),
  //         },
  //         body: JSON.stringify(item), // Removed wrapping of item in an array
  //       }
  //     );
  //     const jsonSingpassData = await response.json();
  //     console.log("jsonSingpassData:", jsonSingpassData);

  //     if (response.status === 200) {
  //       try {
  //         let item2 = {

  //             singpass_data: {
  //               "entity": {

  //               },
  //               "person": {

  //               }
  //             }

  //         };
  //         const response2 = await fetch(
  //           `https://www.smart-lend.com/api/singpass/updateSingpassData`,
  //           {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: "Bearer " + Cookies.get("token"),
  //             },
  //             body: JSON.stringify(item2),
  //           }
  //         );
  //         const jsonSingpassDataBackup = await response2.json();
  //         console.log("jsonSingpassDataBackup:", jsonSingpassDataBackup);

  //         setIsModalVisible(false);
  //         message.success("Successfully Sync With Singpass");
  //       } catch (err) {
  //         message.error(err);
  //       }
  //     }
  //   } catch (err) {
  //     message.error(err);
  //   }
  // };

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: `Basic Information`,
      children: <BasicInformation singpassData={singpassData} />,
    },
    {
      key: "2",
      label: `Finance`,
      children: <Finance />,
    },
    {
      key: "3",
      label: `Employement`,
      children: <Employment />,
    },
    {
      key: "4",
      label: `Property`,
      children: <Property />,
    },
    {
      key: "5",
      label: `Family`,
      children: <Family />,
    },
    {
      key: "6",
      label: `Vehicle`,
      children: <Vehicle />,
    },
  ];

  const onChange2 = (key) => {
    console.log(key);
  };
  const items2 = [
    {
      key: "1",
      label: `Entity Profile`,
      children: <EntityProfile />,
    },
    {
      key: "2",
      label: `Entity Address`,
      children: <EntityAddress />,
    },
    {
      key: "3",
      label: `Appointments`,
      children: <Appointments />,
    },
    {
      key: "4",
      label: `Previous UEN`,
      children: <PreviousUEN />,
    },
    {
      key: "5",
      label: `Shareholders`,
      children: <Shareholders />,
    },
    {
      key: "6",
      label: `Capital`,
      children: <Capital />,
    },
    {
      key: "7",
      label: `Finance Highlights`,
      children: <FinanceHighlights />,
    },
    {
      key: "8",
      label: `Grants`,
      children: <Grants />,
    },
  ];
  const items3 = [];

  const handleSyncWithSingpass = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/singpass/getAuthoriseApi`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      // Read the response as text since it's not JSON
      const url = await response.text();
      console.log("URL value: ", url);

      if (url.startsWith("http")) {
        // Simple check to ensure it looks like a URL
        window.location.href = url;
      }
    } catch (err) {
      console.error("Error fetching Singpass URL:", err);
    }
  };

  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    }
  }, [history]);

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getInitials = (name) => {
    const textToSplit = name.split(" ");
    const initials =
      textToSplit?.[0]?.[0].toUpperCase() +
      (textToSplit?.[1]?.[0].toUpperCase() || "");
    return initials;
  };

  const ContainerActivity = ({ title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "7px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "#FFFFFFB3",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px",
        }}
      >
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            background: "rgb(140,140,170)",
            marginLeft: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            color: "white",
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {title ? getInitials(title) : "SL"}
        </div>
        <div style={{ marginLeft: "5px", width: "calc(100% - 70px)" }}>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              fontSize: "13px",
              color: "#435669",
              margin: "0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              margin: "0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/profile/viewActivityLog",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        // const processedData = await Promise.all(
        //   data.map(async (item) => {
        //     const profilePictureResponse = await fetch(
        //       `https://www.smart-lend.com/api/loan/loanRequest/viewListProfilePicture?profile_picture=${item.profile_picture}`,
        //       options
        //     );

        //     if (!profilePictureResponse.ok) {
        //       throw new Error("Failed to fetch profile picture data");
        //     }

        //     const blob = await profilePictureResponse.blob();
        //     const imageUrl = URL.createObjectURL(blob);

        //     return {
        //       ...item,
        //       icon: imageUrl, // use the blob URL as the icon
        //     };
        //   })
        // );
        const reversedData = data.reverse();

        setActivityLog(reversedData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/ads/getAdsByScreen?screen=bProfile",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        setDisplayAds(data["ads"]);
        setAdImage(data.ads[0].image);
        setAdLink(data.ads[0].link);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (displayAds.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % displayAds.length);
      }, 3200);

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [displayAds]);

  useEffect(() => {
    if (displayAds.length > 0) {
      console.log(currentIndex);
      setAdImage(displayAds[currentIndex].image);
      setAdLink(displayAds[currentIndex].link);
    }
  }, [currentIndex, displayAds]);

  function formatDate(datetimeString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(datetimeString);
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const day = String(date.getDate()).padStart(2, "0");
    const month = months[monthIndex];
    return `${day} ${month} ${year}`;
  }

  const [profileLastUpdate, setProfileLastUpdate] = useState(null);

  const operations = (
    <>
      <div style={{ padding: "10px 0px", cursor: "pointer" }}>
        <div
          onClick={(event) => handleSyncWithSingpass(event)}
          style={{
            border: "1px solid lightgrey",
            borderRadius: "10px",
            padding: "10px 20px",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            gap: 5,
            cursor: "pointer",
          }}
        >
          Sync With
          <img
            src={singlogo}
            style={{
              objectFit: "cover",
              width: "100px",
              height: "20px",
              cursor: "pointer",
            }}
          />
        </div>
        {profileLastUpdate ? (
          <div
            style={{
              textAlign: "right",
              fontSize: 10,
              color: "grey",
              marginTop: 5,
            }}
          >
            Last synced on {formatDate(profileLastUpdate)}
          </div>
        ) : null}
      </div>
    </>
  );

  const [reminderModal, setReminderModal] = useState(false);

  useEffect(() => {
    const expressAuth = Cookies.get("expressauth");

    const currentURL = new URL(window.location.href);
    const code = currentURL.searchParams.get("code");
    const state = currentURL.searchParams.get("state");
    if (expressAuth) {
      redirectToUrl("/director-authorization");
    } else if (!code && !state) {
      const fetchSingpassData = async () => {
        try {
          const response = await fetch(
            "https://www.smart-lend.com/api/singpass/getSingpassData",
            {
              method: "GET",
              headers: {
                Authorization: "Bearer " + Cookies.get("token"),
                Accept: "application/json",
              },
            }
          );

          if (!response.ok) {
            // Handle HTTP error responses (e.g., 404, 500)
            throw new Error(`HTTP Error: ${response.status}`);
          }

          const json = await response.json();
          console.log(json.last_updated_at);

          // Compare dates
          const lastUpdateDate = new Date(json.last_updated_at);
          const currentDate = new Date();
          const oneMonthAgo = new Date();
          oneMonthAgo.setMonth(currentDate.getMonth() - 1);

          if (lastUpdateDate < oneMonthAgo) {
            setReminderModal(true);
          }

          setProfileLastUpdate(json.last_updated_at);
        } catch (error) {
          console.error(error);
        }
      };

      const fetchActiveData = async () => {
        try {
          const response = await fetch(
            "https://www.smart-lend.com/api/profile/getUserActive",
            {
              method: "GET",
              headers: {
                Authorization: "Bearer " + Cookies.get("token"),
                Accept: "application/json",
              },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status}`);
          }
          const json = await response.json();
          console.log(json.data);
          setActiveData(json.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchActiveData();
      fetchSingpassData();
    }
  }, []);

  const handleUpdateActiveData = async () => {
    try {
      const formData = new FormData();
      formData.append("active_email", activeData.active_email);
      formData.append("active_mobile_no", activeData.active_mobile_no);

      const response = await fetch(
        "https://www.smart-lend.com/api/profile/updateUserTable",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: formData,
        }
      );

      const json = await response.json();
      console.log(json);

      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const profilePictureUrl =
        (await loadprofilepicture()) || Cookies.get("profile_picture");
      if (profilePictureUrl) {
        setImageUrl(profilePictureUrl);
      }
    }
    fetchData();
  }, []);

  const handleUploadProfilePicture = async (file) => {
    console.log("uploading profile picture");
    try {
      const formData = new FormData();
      formData.append("profile_picture", file);
      console.log("uploading", formData);
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/uploadProfilePicture",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: formData,
        }
      );

      const data = await response.json();

      console.log("Profile picture created", data);

      // Update the imageUrl state with the new image URL
      if (data.profile_picture) {
        setImageUrl(data.profile_picture);
        Cookies.set("profile_picture", data.profile_picture);
        message.success("Profile picture updated");
        // notification.open({
        //   message: "Notification Title",
        //   description:
        //     "I will never close automatically. This is a purposely very very long description that has many many characters and words.",
        //   duration: 4,
        // });
      } else if (data.error && data.error.profile_picture) {
        // If the profile picture failed to upload
        throw new Error(data.error.profile_picture[0]);
      }
    } catch (error) {
      console.error("Error Uploading Picture", error);
      setErrorMessage(error.message);
    }
  };

  const [imageUrl, setImageUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleBeforeUpload = (file) => {
    const imageUrl = URL.createObjectURL(file);
    setImageUrl(imageUrl);
    console.log("Photo ready to upload", file);
    setSelectedFile(file);
    return false; // Prevent file from being uploaded automatically
  };

  const handleConfirm = () => {
    if (selectedFile) {
      handleUploadProfilePicture(selectedFile);
      console.log("selectedFile exists", selectedFile);
      setSelectedFile(null); // Reset the selected file after uploading
    }
  };

  const loadprofilepicture = async () => {
    console.log("getting profile picture");
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/viewInfoProfilePicture",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch profile picture");
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      console.log("Profile picture retrieved", imageUrl);
      return imageUrl;
    } catch (error) {
      console.error("Error retrieving profile picture", error);
      return null;
    }
  };

  const callToZoho = async (data) => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/creating/handleZohoRequest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
          body: JSON.stringify({ bodyToPass: data }),
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        console.log(json?.data?.data?.[0]?.details?.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {isLoading && (
        <div className="loading-overlay">
          <Spin size="large" />
        </div>
      )}

      <Box m="4px 20px 20px 20px">
        <form>
          <Modal
            width="60%"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={() => {
              setSaveReminder(true);
            }}
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%", // ensure the container takes up the full width
                }}
              >
                <button
                  className="all_button"
                  style={{
                    background: "#0a58ca",
                    cursor: "pointer",
                    padding: "8px 25px",
                    borderRadius: "20px",
                    textAlign: "center",
                    width: "10%",
                    marginTop: "10px",
                    color: "white",
                    border: "none",
                    outline: "none",
                    fontWeight: 600,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    let keymantoPush = [];
                    if (
                      singpassData?.entity?.shareholders?.["shareholders-list"]
                        ?.length > 0
                    ) {
                      for (const per of singpassData?.entity?.shareholders?.[
                        "shareholders-list"
                      ]) {
                        const dat = {
                          Email_Mobile:
                            per?.["corppass-email"]?.value +
                            " / " +
                            per?.["corppass-mobileno"]?.value,
                          Credit_Bureau_Rating: null,
                          Date_of_Birth: null,
                          Director_IC_Number:
                            per?.["person-reference"]?.idno?.value,
                          Directors_Name:
                            per?.["person-reference"]?.["person-name"]?.value,
                          Shareholders: per?.allocation?.value,
                          Notice_of_Assessments: null,
                          Director_Shareholder: ["Director", "Shareholder"],
                          $in_merge: false,
                          Credit_Bureau_Rating_MM_YY: null,
                          $zia_visions: null,
                          Yes_No: false,
                          Age: 2024,
                        };
                        keymantoPush.push(dat);
                      }
                    }
                    const dataToZoho = {
                      data: [
                        {
                          Owner: {
                            id: "5844778000000423001",
                          },
                          Smartlend: true,
                          Account_Holder: "SmartLend",
                          Ownership:
                            singpassData?.entity?.["basic-profile"]?.ownership
                              ?.desc,
                          Account_Name:
                            singpassData?.entity?.["basic-profile"]?.[
                              "entity-name"
                            ]?.value,
                          Description: "Account imported from Smart Lend",
                          Account_Type: "Smartlend Borrower",
                          SSIC_Code:
                            singpassData?.entity?.["basic-profile"]?.[
                              "primary-activity"
                            ]?.code,
                          Industry:
                            singpassData?.entity?.["basic-profile"]?.[
                              "primary-activity"
                            ]?.desc,
                          Phone:
                            "+" +
                            singpassData?.person?.mobileno?.areacode?.value +
                            " " +
                            singpassData?.person?.mobileno?.nbr?.value,
                          Keyman_Mobile:
                            "+" +
                            singpassData?.person?.mobileno?.areacode?.value +
                            " " +
                            singpassData?.person?.mobileno?.nbr?.value,
                          Keyman_Email: singpassData?.person?.email?.value,
                          Billing_Country:
                            singpassData?.person?.regadd?.country?.desc,
                          Acra_Address: singpassData?.entity?.addresses?.[
                            "addresses-list"
                          ]?.[0]
                            ? singpassData?.entity?.addresses?.[
                                "addresses-list"
                              ]?.[0]?.unit?.value +
                              " " +
                              singpassData?.entity?.addresses?.[
                                "addresses-list"
                              ]?.[0]?.street?.value +
                              " " +
                              "#" +
                              singpassData?.entity?.addresses?.[
                                "addresses-list"
                              ]?.[0]?.block?.value +
                              "-" +
                              singpassData?.entity?.addresses?.[
                                "addresses-list"
                              ]?.[0]?.floor?.value +
                              " " +
                              singpassData?.entity?.addresses?.[
                                "addresses-list"
                              ]?.[0]?.country?.desc +
                              " " +
                              "(" +
                              singpassData?.person?.regadd?.postal?.value +
                              ")"
                            : null,
                          UEN: singpassData?.entity?.["basic-profile"]?.["uen"]
                            ?.value,
                          Date_Of_Incorporation:
                            singpassData?.entity?.["basic-profile"]?.[
                              "registration-date"
                            ]?.value,
                          Mailing_Address:
                            singpassData?.person?.regadd?.unit?.value +
                            " " +
                            singpassData?.person?.regadd?.street?.value +
                            " " +
                            "#" +
                            singpassData?.person?.regadd?.block?.value +
                            "-" +
                            singpassData?.person?.regadd?.floor?.value +
                            " " +
                            singpassData?.person?.regadd?.country?.desc +
                            " " +
                            "(" +
                            singpassData?.person?.regadd?.postal?.value +
                            ")",
                          Last_Name: "Contact_test",
                          Email: singpassData?.person?.email?.value,
                          Keymans: keymantoPush,
                        },
                      ],
                    };
                    console.log(singpassData);
                    console.log(dataToZoho);
                    handleSave();
                    // callToZoho(dataToZoho);
                  }}
                >
                  Save
                </button>
              </div>,
            ]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                color: "rgba(0,0,0,0.8)",
              }}
            >
              <h2> Confirm your information</h2>
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "rgba(0,0,0,0.7)",
                textAlign: "center",
                margin: "-10px 0px 15px 0px",
              }}
            >
              By clicking save, you confirm that the provided information is
              indeed correct.
            </div>
            <div
              style={{
                display: "flex",
                fontFamily: "Manrope, sans-serif",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "50%",
                  height: "100%",
                  borderRadius: "10px",
                  flexDirection: "column",
                  padding: "10px 10px 10px 10px",
                  marginRight: "20px",
                  background: "#0e5aa51A",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    background: "white",
                    textAlign: "center",
                    marginBottom: "15px",
                    padding: "10px",
                    color: "rgba(20, 44, 68, 0.8)",
                    fontWeight: 800,
                    fontFamily: "Manrope, sans-serif",
                    fontSize: 20,
                    borderRadius: 10,
                  }}
                >
                  Applicant Information
                </div>
                <div style={{ padding: "0px 15px" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      NRIC
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        min={0}
                        // className={nricError ? "error-border" : ""}
                        value={singpassData?.person?.uinfin?.value}
                      />
                      {/* {nricError && <div style={{ color: 'red' }}>Please fill in the NRIC</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Full Name
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        type="text"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        value={singpassData?.person?.name?.value}
                        // required
                      />
                      {/* {fullNameError && <div style={{ color: 'red' }}>Please fill in the Full Name</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Sex
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        value={singpassData?.person?.sex?.desc}
                        // required
                      />
                      {/* {sexError && <div style={{ color: 'red' }}>Please fill in the Sex</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Race
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={singpassData?.person?.race?.desc}
                      />
                      {/* {raceError && <div style={{ color: 'red' }}>Please fill in the Race</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Nationality
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={singpassData?.person?.nationality?.desc}
                      />
                      {/* {nationalityError && <div style={{ color: 'red' }}>Please fill in the Nationality</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Date of Birth
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="date"
                        // required
                        value={singpassData?.person?.dob?.value}
                      />
                      {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Country of Birth
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={singpassData?.person?.birthcountry?.desc}
                      />
                      {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Residential Status
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={singpassData?.person?.residentialstatus?.desc}
                      />
                      {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Pass type
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={singpassData?.person?.passtype?.desc}
                      />
                      {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Pass Status
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={singpassData?.person?.passstatus?.value}
                      />
                      {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Pass Expiry Date
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="date"
                        // required
                        value={singpassData?.person?.passexpirydate?.value}
                      />
                      {/* {dobError && <div style={{ color: 'red' }}>Please fill in the Date Of Birth</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Ownership of Private Property Status
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={singpassData?.person?.ownerprivate?.value}
                      />
                      {/* {highestEducationLevelError && <div style={{ color: 'red' }}>Please fill in the Highest Education Level</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Email
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        type="email"
                        disabled
                        // required
                        value={singpassData?.person?.email?.value}
                      />
                      {/* {emailError && <div style={{ color: 'red' }}>Please fill in the Email</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Mobile Number
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="number"
                        // required
                        value={singpassData?.person?.mobileno?.nbr?.value}
                      />
                      {/* {mobileNoError && <div style={{ color: 'red' }}>Please fill in the Mobile Number</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Registered Address
                    </div>
                    <div style={{ width: "60%" }}>
                      <textarea
                        rows="2"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        // required
                        value={
                          singpassData?.person?.hdbownership?.[0]?.address
                            ? (singpassData?.person?.hdbownership?.[0]?.address
                                ?.unit?.value !== null
                                ? `Unit ${singpassData?.person?.hdbownership?.[0]?.address?.unit?.value}, `
                                : "") +
                              (singpassData?.person?.hdbownership?.[0]?.address
                                ?.floor?.value !== null
                                ? `Floor ${singpassData?.person?.hdbownership?.[0]?.address?.floor?.value}, `
                                : "") +
                              (singpassData?.person?.hdbownership?.[0]?.address
                                ?.block?.value !== null
                                ? `Block ${singpassData?.person?.hdbownership?.[0]?.address?.block?.value}, `
                                : "") +
                              (singpassData?.person?.hdbownership?.[0]?.address
                                ?.street?.value !== null
                                ? `${singpassData?.person?.hdbownership?.[0]?.address?.street?.value}, `
                                : "") +
                              (singpassData?.person?.hdbownership?.[0]?.address
                                ?.postal?.value !== null
                                ? `${singpassData?.person?.hdbownership?.[0]?.address?.postal?.value}, `
                                : "") +
                              (singpassData?.person?.hdbownership?.[0]?.address
                                ?.country?.desc !== null
                                ? `${singpassData?.person?.hdbownership?.[0]?.address?.country?.desc}.`
                                : "")
                            : ""
                        }
                      ></textarea>
                      {/* {registeredAddressError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Housing Type
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={singpassData?.person?.housingtype?.desc}
                      />
                      {/* {housingTypeError && <div style={{ color: 'red' }}>Please fill in the Housing Type</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Marital Status
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        value={singpassData?.person?.marital?.desc}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Occupation
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={singpassData?.person?.occupation?.value}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Employer's name
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={""}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Employment Sector
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={""}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      HDB Type
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={singpassData?.person?.hdbtype?.desc}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Housing Type
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={singpassData?.person?.housingtype?.desc}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  {singpassData?.person?.hdbownership?.map((hdb, index) => (
                    <div
                      key={index}
                      style={{
                        width: "83.5%",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          marginBottom: 10,
                        }}
                      >
                        <div
                          style={{
                            width: "40%",
                            color: "rgba(20, 44, 68, 0.8)",
                            fontWeight: 800,
                            fontFamily: "Manrope, sans-serif",
                            fontSize: 14,
                          }}
                        >
                          HDB Ownership {index + 1} - Address
                        </div>
                        <div style={{ width: "60%" }}>
                          <input
                            style={{
                              width: "100%",
                              borderRadius: "10px",
                              border: "none",
                              outline: "none",
                              padding: 7,
                            }}
                            disabled
                            type="text"
                            // required
                            value={
                              hdb?.address
                                ? (hdb?.address?.unit?.value !== null
                                    ? `Unit ${hdb?.address?.unit?.value}, `
                                    : "") +
                                  (hdb?.address?.floor?.value !== null
                                    ? `Floor ${hdb?.address?.floor?.value}, `
                                    : "") +
                                  (hdb?.address?.block?.value !== null
                                    ? `Block ${hdb?.address?.block?.value}, `
                                    : "") +
                                  (hdb?.address?.street?.value !== null
                                    ? `${hdb?.address?.street?.value}, `
                                    : "") +
                                  (hdb?.address?.postal?.value !== null
                                    ? `${hdb?.address?.postal?.value}, `
                                    : "") +
                                  (hdb?.address?.country?.desc !== null
                                    ? `${hdb?.address?.country?.desc}.`
                                    : "")
                                : ""
                            }
                          />
                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          marginBottom: 10,
                        }}
                      >
                        <div
                          style={{
                            width: "40%",
                            color: "rgba(20, 44, 68, 0.8)",
                            fontWeight: 800,
                            fontFamily: "Manrope, sans-serif",
                            fontSize: 14,
                          }}
                        >
                          HDB Ownership {index + 1} - Outstanding HDB Loan
                          Balance
                        </div>
                        <div style={{ width: "60%" }}>
                          <input
                            style={{
                              width: "100%",
                              borderRadius: "10px",
                              border: "none",
                              outline: "none",
                              padding: 7,
                            }}
                            disabled
                            type="text"
                            // required
                            value={hdb?.outstandingloanbalance?.value?.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "SGD",
                              }
                            )}
                          />
                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          marginBottom: 10,
                        }}
                      >
                        <div
                          style={{
                            width: "40%",
                            color: "rgba(20, 44, 68, 0.8)",
                            fontWeight: 800,
                            fontFamily: "Manrope, sans-serif",
                            fontSize: 14,
                          }}
                        >
                          HDB Ownership {index + 1} - Type of HDB Dwelling
                        </div>
                        <div style={{ width: "60%" }}>
                          <input
                            style={{
                              width: "100%",
                              borderRadius: "10px",
                              border: "none",
                              outline: "none",
                              padding: 7,
                            }}
                            disabled
                            type="text"
                            // required
                            value={hdb?.hdbtype?.desc}
                          />
                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          marginBottom: 10,
                        }}
                      >
                        <div
                          style={{
                            width: "40%",
                            color: "rgba(20, 44, 68, 0.8)",
                            fontWeight: 800,
                            fontFamily: "Manrope, sans-serif",
                            fontSize: 14,
                          }}
                        >
                          HDB Ownership {index + 1} - Monthly Loan Installment
                        </div>
                        <div style={{ width: "60%" }}>
                          <input
                            style={{
                              width: "100%",
                              borderRadius: "10px",
                              border: "none",
                              outline: "none",
                              padding: 7,
                            }}
                            disabled
                            type="text"
                            // required
                            value={hdb?.monthlyloaninstalment?.value?.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "SGD",
                              }
                            )}
                          />
                          {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Vehicles - Vehicles Model
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={singpassData?.person?.vehicles[0]?.model?.value}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Vehicles - Vehicles Make
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={singpassData?.person?.vehicles[0]?.make?.value}
                      />
                      {/* {maritalStatusError && <div style={{ color: 'red' }}>Please fill in the Marital Status</div>} */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "50%",
                  height: "100%",
                  borderRadius: "10px",
                  flexDirection: "column",
                  padding: "10px 10px 10px 10px",
                  background: "#0e5aa51A",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    background: "white",
                    textAlign: "center",
                    marginBottom: "15px",
                    padding: "10px",
                    color: "rgba(20, 44, 68, 0.8)",
                    fontWeight: 800,
                    fontFamily: "Manrope, sans-serif",
                    fontSize: 20,
                    borderRadius: 10,
                  }}
                >
                  Corporate Information
                </div>
                <div
                  style={{
                    padding: "0px 15px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      UEN
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          singpassData?.entity?.["basic-profile"]?.uen?.value
                        }
                      />
                      {/* {uenError && <div style={{ color: 'red' }}>Please fill in the UEN</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Entity Name
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          singpassData?.entity?.["basic-profile"]["entity-name"]
                            ?.value
                        }
                      />
                      {/* {entityNameError && <div style={{ color: 'red' }}>Please fill in the Entity Name</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Entity Type
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          singpassData?.entity?.["basic-profile"]["entity-type"]
                            ?.value
                        }
                      />
                      {/* {entityTypeError && <div style={{ color: 'red' }}>Please fill in the Entity Type</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Entity Status
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          singpassData?.entity?.["basic-profile"][
                            "entity-status"
                          ]?.value
                        }
                      />
                      {/* {entityStatusError && <div style={{ color: 'red' }}>Please fill in the Entity Status</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Registered Address
                    </div>
                    <div style={{ width: "60%" }}>
                      <textarea
                        rows="2"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                          resize: "none",
                        }}
                        disabled
                        // required
                        value={
                          singpassData?.person?.hdbownership?.[0]?.address
                            ? (singpassData?.person?.hdbownership?.[0]?.address
                                ?.unit?.value !== null
                                ? `Unit ${singpassData?.person?.hdbownership?.[0]?.address?.unit?.value}, `
                                : "") +
                              (singpassData?.person?.hdbownership?.[0]?.address
                                ?.floor?.value !== null
                                ? `Floor ${singpassData?.person?.hdbownership?.[0]?.address?.floor?.value}, `
                                : "") +
                              (singpassData?.person?.hdbownership?.[0]?.address
                                ?.block?.value !== null
                                ? `Block ${singpassData?.person?.hdbownership?.[0]?.address?.block?.value}, `
                                : "") +
                              (singpassData?.person?.hdbownership?.[0]?.address
                                ?.street?.value !== null
                                ? `${singpassData?.person?.hdbownership?.[0]?.address?.street?.value}, `
                                : "") +
                              (singpassData?.person?.hdbownership?.[0]?.address
                                ?.postal?.value !== null
                                ? `${singpassData?.person?.hdbownership?.[0]?.address?.postal?.value}, `
                                : "") +
                              (singpassData?.person?.hdbownership?.[0]?.address
                                ?.country?.desc !== null
                                ? `${singpassData?.person?.hdbownership?.[0]?.address?.country?.desc}.`
                                : "")
                            : ""
                        }
                      ></textarea>
                      {/* {registeredAddressEntityError && <div style={{ color: 'red' }}>Please fill in the Registered Address</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Registration Date
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="date"
                        // required
                        value={
                          singpassData?.entity?.["basic-profile"][
                            "registration-date"
                          ]?.value
                        }
                      />
                      {/* {registrationDateError && <div style={{ color: 'red' }}>Please fill in the Registered Date</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Primary SSIC
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          singpassData?.entity?.["basic-profile"][
                            "primary-activity"
                          ]?.desc
                        }
                      />
                      {/* {primarySSICError && <div style={{ color: 'red' }}>Please fill in the Primary SSIC</div>} */}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        color: "rgba(20, 44, 68, 0.8)",
                        fontWeight: 800,
                        fontFamily: "Manrope, sans-serif",
                        fontSize: 14,
                      }}
                    >
                      Secondary SSIC
                    </div>
                    <div style={{ width: "60%" }}>
                      <input
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          padding: 7,
                        }}
                        disabled
                        type="text"
                        // required
                        value={
                          singpassData?.entity?.["basic-profile"][
                            "secondary-activity"
                          ]?.desc
                        }
                      />
                      {/* {secondarySSICError && <div style={{ color: 'red' }}>Please fill in the Secondary SSIC</div>} */}
                    </div>
                  </div>
                  {singpassData?.entity?.appointments["appointments-list"]?.map(
                    (appointment, index) => (
                      <div style={{ width: "100%" }} key={index}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Position
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={appointment.position.desc}
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        {/* Similar structure for NRIC, Full Name, and Appointment Date */}
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - NRIC
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"].idno.value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"].uen.value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Full Name
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="text"
                              value={
                                appointment["person-reference"]
                                  ? appointment["person-reference"][
                                      "person-name"
                                    ].value
                                  : appointment["entity-reference"]
                                  ? appointment["entity-reference"][
                                      "entity-name"
                                    ].value
                                  : null
                              }
                              // Add onChange logic if needed
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              color: "rgba(20, 44, 68, 0.8)",
                              fontWeight: 800,
                              fontFamily: "Manrope, sans-serif",
                              fontSize: 14,
                            }}
                          >
                            Appointment {index + 1} - Appointment Date
                          </div>
                          <div style={{ width: "60%" }}>
                            <input
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                border: "none",
                                outline: "none",
                                padding: 7,
                              }}
                              disabled
                              type="date"
                              value={appointment["appointment-date"].value}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <h5>Noa History</h5>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "separate",
                  borderRadius: "10px",
                  overflow: "hidden",
                  padding: "10px",
                  borderSpacing: "0px",
                  background: "#0e5aa51A",
                }}
              >
                <thead
                  style={{
                    borderRadius: "15px 15px 0px 0px",
                    border: "none",
                    borderRadius: "8px",
                  }}
                >
                  <tr
                    style={{
                      border: "none",
                      borderRadius: "8px",
                      color: "rgba(20, 44, 68, 0.8)",
                      fontWeight: 800,
                      fontFamily: "Manrope, sans-serif",
                      fontSize: 12,
                      borderRadius: 8,
                    }}
                  >
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Amount
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Category
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Employment
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Interest
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Rent
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Tax Clearance
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Trade
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Years of Assessment
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {singpassData?.person?.noahistory?.noas?.map(
                    (item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item.amount.value?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          })}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item.category.value}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item.employment.value?.toLocaleString("en-US")}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item.interest.value?.toLocaleString("en-US")}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item.rent.value?.toLocaleString("en-US")}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item.taxclearance.value}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item.trade.value}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item.yearofassessment.value}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div style={{ marginTop: "10px" }}>
              <h5>Capitals</h5>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "separate",
                  borderRadius: "10px",
                  overflow: "hidden",
                  padding: "10px",
                  borderSpacing: "0px",
                  background: "#0e5aa51A",
                }}
              >
                <thead
                  style={{
                    borderRadius: "15px 15px 0px 0px",
                    border: "none",
                    borderRadius: "8px",
                  }}
                >
                  <tr
                    style={{
                      border: "none",
                      borderRadius: "8px",
                      color: "rgba(20, 44, 68, 0.8)",
                      fontWeight: 800,
                      fontFamily: "Manrope, sans-serif",
                      fontSize: 12,
                      borderRadius: 8,
                    }}
                  >
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Capital Type
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Currency
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Issued Capital Amount
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Paid Up Capital Amount
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Share Alloted Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {singpassData?.entity?.capitals["capitals-list"]?.map(
                    (item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item["capital-type"].desc}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item.currency.desc}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item["issued-capital-amount"].value?.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item["paid-up-capital-amount"].value?.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "SGD",
                            }
                          )}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item["share-allotted-amount"].value?.toLocaleString(
                            "en-US"
                          )}
                        </td>
                        {/* Add other data cells */}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div style={{ marginTop: "10px" }}>
              <h5>Financials</h5>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "separate",
                  borderRadius: "10px",
                  overflow: "hidden",
                  padding: "10px",
                  borderSpacing: "0px",
                  background: "#0e5aa51A",
                }}
              >
                <thead
                  style={{
                    borderRadius: "15px 15px 0px 0px",
                    border: "none",
                    borderRadius: "8px",
                  }}
                >
                  <tr
                    style={{
                      border: "none",
                      borderRadius: "8px",
                      color: "rgba(20, 44, 68, 0.8)",
                      fontWeight: 800,
                      fontFamily: "Manrope, sans-serif",
                      fontSize: 12,
                      borderRadius: 8,
                    }}
                  >
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Company Profit Loss After Tax
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Company Profit Loss Before Tax
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Company Revenue
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Currency
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Current Period End Date
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Current Period Start Date
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Group Capital Paid Up Capital Amount
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Group Profit Loss After Tax
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Group Profit Loss Before Tax
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Group Revenue
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Is Audited
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {singpassData?.entity?.financials?.highlights?.map(
                    (item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.["company-profit-loss-after-tax"]?.value}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.["company-profit-loss-before-tax"]?.value}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.["company-revenue"]?.value}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.currency?.desc}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.["current-period-end-date"]?.value}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.["current-period-start-date"]?.value}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {
                            item?.["group-capital-paid-up-capital-amount"]
                              ?.value
                          }
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.["group-profit-loss-after-tax"]?.value}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.["group-profit-loss-before-tax"]?.value}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.["group-revenue"]?.value}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.["is-audited"]?.value}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div style={{ marginTop: "10px" }}>
              <h5>Shareholders</h5>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "separate",
                  borderRadius: "10px",
                  overflow: "hidden",
                  padding: "10px",
                  borderSpacing: "0px",
                  background: "#0e5aa51A",
                }}
              >
                <thead
                  style={{
                    borderRadius: "15px 15px 0px 0px",
                    border: "none",
                    borderRadius: "8px",
                  }}
                >
                  <tr
                    style={{
                      border: "none",
                      borderRadius: "8px",
                      color: "rgba(20, 44, 68, 0.8)",
                      fontWeight: 800,
                      fontFamily: "Manrope, sans-serif",
                      fontSize: 12,
                      borderRadius: 8,
                    }}
                  >
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Allocation
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Category
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Name
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      NRIC
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Currency
                    </th>
                    {/* <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference NRIC</th>
            <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Nationality</th>
            <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Name</th> */}
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Share Type
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {singpassData?.entity?.shareholders["shareholders-list"] ? (
                    singpassData?.entity?.shareholders[
                      "shareholders-list"
                    ]?.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.allocation?.value?.toLocaleString("en-US")}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.category?.desc}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.["person-reference"]?.["person-name"]?.value}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.["person-reference"]?.idno?.value}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.currency?.desc}
                        </td>
                        {/* <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item["person-reference"].idno.value}</td>
              <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item["person-reference"].nationality.desc}</td>
              <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item["person-reference"]["person-name"].value}</td> */}
                        <td
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.6)",
                            padding: "5px 10px",
                          }}
                        >
                          {item?.["share-type"]?.desc}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        style={{
                          borderTop: "1px solid rgba(0,0,0,0.6)",
                          textAlign: "center",
                          padding: "5px 10px",
                        }}
                        colSpan="11"
                      >
                        No Data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div style={{ marginTop: "10px" }}>
              <h5>Grants</h5>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "separate",
                  borderRadius: "10px",
                  overflow: "hidden",
                  padding: "10px",
                  borderSpacing: "0px",
                  background: "#0e5aa51A",
                }}
              >
                <thead
                  style={{
                    borderRadius: "15px 15px 0px 0px",
                    border: "none",
                    borderRadius: "8px",
                  }}
                >
                  <tr
                    style={{
                      border: "none",
                      borderRadius: "8px",
                      color: "rgba(20, 44, 68, 0.8)",
                      fontWeight: 800,
                      fontFamily: "Manrope, sans-serif",
                      fontSize: 12,
                      borderRadius: 8,
                    }}
                  >
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Grant Type
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Grant Description
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Grant Status
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Functional Area Code
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Functional Area Description
                    </th>
                    {/* <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference NRIC</th>
        <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Nationality</th>
        <th style={{ border: '1px solid rgba(0,0,0,0.6)' }}>Person Reference Name</th> */}
                    {/* <th
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          Share Type
                        </th> */}
                  </tr>
                </thead>
                <tbody>
                  {singpassData?.entity?.grants["grants-list"] ? (
                    singpassData?.entity?.grants["grants-list"]?.map(
                      (item, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item
                              ? item["grant-type"]
                                ? item["grant-type"].code
                                  ? item["grant-type"].code
                                  : null
                                : null
                              : null}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item
                              ? item["grant-type"]
                                ? item["grant-type"].desc
                                  ? item["grant-type"].desc
                                  : null
                                : null
                              : null}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item
                              ? item["grant-status"]
                                ? item["grant-status"].desc
                                  ? item["grant-status"].desc
                                  : null
                                : null
                              : null}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item
                              ? item["functional-area"]
                                ? item["functional-area"].code
                                  ? item["functional-area"].code
                                  : null
                                : null
                              : null}
                          </td>
                          <td
                            style={{
                              borderTop: "1px solid rgba(0,0,0,0.6)",
                              padding: "5px 10px",
                            }}
                          >
                            {item
                              ? item["functional-area"]
                                ? item["functional-area"].desc
                                  ? item["functional-area"].desc
                                  : null
                                : null
                              : null}
                          </td>
                          {/* <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item["person-reference"].idno.value}</td>
          <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item["person-reference"].nationality.desc}</td>
          <td style={{ borderTop: '1px solid rgba(0,0,0,0.6)' }}>{item["person-reference"]["person-name"].value}</td> */}
                          {/* <td
                              style={{
                                borderTop: "1px solid rgba(0,0,0,0.6)",
                                padding: "5px 10px",
                              }}
                            >
                              {item
                                ? item["development-category"]
                                  ? item["development-category"].code
                                    ? item["development-category"].code
                                    : null
                                  : null
                                : null}
                            </td> */}
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td
                        style={{
                          borderTop: "1px solid rgba(0,0,0,0.6)",
                          textAlign: "center",
                          padding: "5px 10px",
                        }}
                        colSpan="11"
                      >
                        No Data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div style={{ marginTop: "10px" }}>
              <h5>Previous Uen</h5>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "separate",
                  borderRadius: "10px",
                  overflow: "hidden",
                  padding: "10px",
                  borderSpacing: "0px",
                  background: "#0e5aa51A",
                }}
              >
                <thead
                  style={{
                    borderRadius: "15px 15px 0px 0px",
                    border: "none",
                    borderRadius: "8px",
                  }}
                >
                  <tr
                    style={{
                      border: "none",
                      borderRadius: "8px",
                      color: "rgba(20, 44, 68, 0.8)",
                      fontWeight: 800,
                      fontFamily: "Manrope, sans-serif",
                      fontSize: 12,
                      borderRadius: 8,
                    }}
                  >
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Previous UEN
                    </th>
                    <th
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      Previous UEN Name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        borderTop: "1px solid rgba(0,0,0,0.6)",
                        textAlign: "center",
                        padding: "5px 10px",
                      }}
                      colSpan="11"
                    >
                      No Data
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal>
        </form>
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="140px"
          gap="20px"
        >
          <Box
            gridColumn={isMobile ? "span 12" : "span 9"}
            gridRow="span 2"
            // overflow="auto"
            backgroundColor={colors.grey[910]}
            background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
            borderRadius="10px"
            border="1px solid "
            opacity="0.95"
            style={{
              overflowY: "scroll",
              minHeight: "89.7vh",
            }}
          >
            <Box
              gridColumn="span 4"
              gridRow="span 2"
              background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
              border-radius="20px"
              opacity="0.95"
            >
              <Card>
                <Tabs tabBarExtraContent={operations}>
                  <TabPane type="card" tab="Personal" key="1">
                    <Tabs
                      defaultActiveKey="1"
                      items={items}
                      onChange={onChange}
                    />
                  </TabPane>
                  <TabPane type="card" tab="Business" key="2">
                    <Tabs
                      defaultActiveKey="1"
                      items={items2}
                      onChange={onChange2}
                    />
                  </TabPane>
                  <TabPane
                    type="card"
                    key="3"
                    tab={
                      <div
                        className={
                          verifiedStatus ? "" : "custom-tab-class blinking"
                        }
                      >
                        Verification
                      </div>
                    }
                  >
                    <div
                      style={{
                        fontFamily: "Manrope",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: "-5px",
                          marginTop: "5px",
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          fontSize: "12px",
                          fontWeight: 800,
                          marginTop: "0px",
                        }}
                      >
                        Verification Status:{" "}
                        {verifiedStatus ? (
                          <CheckCircleIcon
                            style={{ fontSize: "20px", color: "green" }}
                          />
                        ) : (
                          <CancelIcon
                            style={{ fontSize: "20px", color: "red" }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: 800,
                          marginTop: "0px",
                        }}
                      >
                        Get your account verified
                      </div>
                      <div style={{ fontSize: "11px", fontWeight: 400 }}>
                        Getting your account verified would increase the
                        credibility of your account to other lenders and also
                        provide you with a higher chance of receiving more
                        offers from lenders as lenders would be able to see that
                        your business operations have been verified by
                        SmartLend.
                      </div>
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: 800,
                          marginTop: "15px",
                        }}
                      >
                        How to get verified?
                      </div>
                      <div style={{ fontSize: "12px", fontWeight: 400 }}>
                        You can start by uploading the below documents to allow
                        our admins to verify your business opertations
                      </div>
                      <div
                        style={{
                          fontSize: "10px",
                          fontWeight: 400,
                          marginTop: "10px",
                        }}
                      >
                        - An image of your storefront.
                      </div>
                      <div style={{ fontSize: "10px", fontWeight: 400 }}>
                        - Receipt or bills to confirm your business operation
                        address/location.
                      </div>
                      <div style={{ fontSize: "10px", fontWeight: 400 }}>
                        - Tenancy agreement of your business operation.
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        {uploadedFiles && uploadedFiles.length > 0 ? (
                          <div
                            style={{
                              fontSize: "11px",
                              fontWeight: 500,
                              width: "calc(100% - 30px)",
                              background: "rgba(153, 178, 205, 0.4)",
                              padding: "15px 15px 10px 15px",
                              borderRadius: "8px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "18px",
                                fontWeight: 800,
                                marginBottom: "5px",
                              }}
                            >
                              Verification Files
                            </div>
                            {uploadedFiles.map((file, index) => (
                              <div
                                key={index}
                                style={{
                                  marginBottom: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "25px",
                                }}
                              >
                                <div
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() => {
                                    const newWindow = window.open(
                                      file.url,
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                    if (newWindow) newWindow.opener = null;
                                  }}
                                >
                                  {file.name}
                                </div>
                                <DeleteIcon
                                  style={{
                                    fontSize: "20px",
                                    color: "grey",
                                    cursor: "pointer",
                                    marginTop: "2px",
                                  }}
                                  onClick={() => {
                                    const updatingUploadedFiles = [
                                      ...uploadedFiles,
                                    ];
                                    updatingUploadedFiles.splice(index, 1);
                                    setUploadedFiles(updatingUploadedFiles);
                                    updateUserVerificationFiles(
                                      updatingUploadedFiles
                                    );
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        ) : null}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            style={{ marginTop: "15px" }}
                            type="file"
                            multiple
                            onChange={(e) => {
                              e.preventDefault();
                              onFileSelected(e.target.files);
                              console.log(e.target.files);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </Card>
            </Box>
          </Box>
          {!isMobile ? (
            <Box
              gridColumn="span 3"
              gridRow="span 2"
              // backgroundColor="rgba(153, 178, 205, 0)"
              overflow="auto"
              minHeight="90.5vh"
            >
              <Box
                gridColumn="span 2"
                opacity="1.00"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="34%"
                position="relative"
              >
                <img
                  src={adImage}
                  onClick={(e) => {
                    e.preventDefault();
                    if (adLink) {
                      let url = adLink;

                      // Check if adLink starts with http:// or https://
                      if (!/^https?:\/\//i.test(adLink)) {
                        url = `https://${adLink}`;
                      }

                      console.log(url);
                      window.open(url, "_blank");
                    }
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "1",
                    objectFit: "cover",
                    cursor: adLink ? "pointer" : "default",
                  }}
                />
              </Box>
              <Box
                gridColumn="span 4"
                gridRow="span 2"
                backgroundColor="rgba(153, 178, 205, 0)"
                overflow="auto"
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  colors={colors.grey[100]}
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "#142C44CC",
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "800",
                      margin: "0px",
                      margin: "0px 0px 10px 10px",
                    }}
                  >
                    Recent Activities
                  </Typography>
                </Box>
                {dataActivityLog && dataActivityLog.length > 0 ? (
                  dataActivityLog.slice(0, 6).map((item, index) => (
                    <div
                      style={{
                        opacity:
                          index === 4
                            ? 0.6
                            : index === 5
                            ? 0.2
                            : index === 3
                            ? 0.9
                            : 1,
                        transitionDelay: `${index * 0.1}s`,
                      }}
                    >
                      <ContainerActivity
                        key={index}
                        title={item.principal_name || "-"}
                        subtitle={item.description || "-"}
                        index={index}
                      />
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 0px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 15px",
                        background: "rgba(255,255,255,0.7)",
                        borderRadius: "10px",
                        height: "350px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "0.8em",
                        fontWeight: "600",
                        color: "#00000066",
                      }}
                    >
                      No recent activities
                    </div>
                  </div>
                )}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>

      <Modal
        width={window.innerWidth < 1200 ? "80%" : "30%"}
        visible={reminderModal}
        onCancel={() => setReminderModal(false)}
        footer={null}
        style={{ top: "50%", transform: "translate(0, -50%)" }}
      >
        <div style={{ fontWeight: 700, fontSize: 24, color: "rgb(60,60,60)" }}>
          Singpass Resync Required
        </div>
        <div style={{ fontWeight: 300, fontSize: 12, color: "grey" }}>
          Please take note that to ensure you can proceed to further apply any
          more new loans, your singpass synchronisation cannot be more then 1
          month ago, if you would like to proceed to resync your singpass
          account, click on the button below.
        </div>

        <button
          onClick={(event) => {
            event.preventDefault();
            handleSyncWithSingpass(event);
          }}
          style={{
            border: "1px solid lightgrey",
            borderRadius: "10px",
            padding: "10px 20px",
            display: "flex",
            alignItems: "center",
            gap: 5,
            cursor: "pointer",
            margin: "20px 0px 0px 0px",
          }}
        >
          Sync With
          <img
            src={singlogo}
            style={{
              objectFit: "cover",
              width: "100px",
              height: "20px",
              cursor: "pointer",
            }}
          />
        </button>
      </Modal>

      <Modal
        width="30%"
        visible={notSameEntityModalIsOpen}
        onCancel={() => setNotSameEntityModalIsOpen(false)}
        footer={null}
        style={{ top: "50%", transform: "translate(0, -50%)" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <div>
            <ErrorIcon style={{ color: "red", fontSize: "60px" }} />
          </div>
          <div>
            <div
              style={{ fontWeight: 700, fontSize: 24, color: "rgb(60,60,60)" }}
            >
              Entity Name Missmatch
            </div>
            <div style={{ fontWeight: 300, fontSize: 12, color: "grey" }}>
              You've tried to synchronise with a singpass account that doesnt
              match the entity name of the original synchronisation.
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        width="30%"
        visible={sameAsOtherEntityModalIsOpen}
        onCancel={() => setSameAsOtherEntityModalIsOpen(false)}
        footer={null}
        style={{ top: "50%", transform: "translate(0, -50%)" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <div>
            <ErrorIcon style={{ color: "red", fontSize: "60px" }} />
          </div>
          <div>
            <div
              style={{ fontWeight: 700, fontSize: 24, color: "rgb(60,60,60)" }}
            >
              Entity Name Registered
            </div>
            <div style={{ fontWeight: 300, fontSize: 12, color: "grey" }}>
              The Corppass Entity Name you synchronised with has been used by
              another account, please note that every entity is only allowed one
              account registration.
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        width="30%"
        visible={saveReminder}
        onCancel={() => setSaveReminder(false)}
        footer={null}
        style={{ top: "50%", transform: "translate(0, -50%)" }}
      >
        <div style={{ fontWeight: 700, fontSize: 24, color: "rgb(60,60,60)" }}>
          Save Reminder
        </div>
        <div style={{ fontWeight: 300, fontSize: 12, color: "grey" }}>
          Are you sure you want to exit without saving?
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
            gap: "15px",
          }}
        >
          <button
            onClick={(event) => {
              event.preventDefault();
              handleCancel();
            }}
            className="all_button"
            style={{
              background: "#0a58ca",
              cursor: "pointer",
              padding: "8px 25px",
              width: "40%",
              borderRadius: "20px",
              textAlign: "center",
              color: "white",
              border: "none",
              outline: "none",
              fontWeight: 600,
            }}
          >
            Cancel
          </button>
          <button
            className="all_button"
            style={{
              background: "#0a58ca",
              cursor: "pointer",
              padding: "8px 25px",
              width: "40%",
              borderRadius: "20px",
              textAlign: "center",
              color: "white",
              border: "none",
              outline: "none",
              fontWeight: 600,
            }}
            onClick={(event) => {
              event.preventDefault();
              let keymantoPush = [];
              if (
                singpassData?.entity?.shareholders?.["shareholders-list"]
                  ?.length > 0
              ) {
                for (const per of singpassData?.entity?.shareholders?.[
                  "shareholders-list"
                ]) {
                  const dat = {
                    Email_Mobile:
                      per?.["corppass-email"]?.value +
                      " / " +
                      per?.["corppass-mobileno"]?.value,
                    Credit_Bureau_Rating: null,
                    Date_of_Birth: null,
                    Director_IC_Number: per?.["person-reference"]?.idno?.value,
                    Directors_Name:
                      per?.["person-reference"]?.["person-name"]?.value,
                    Shareholders: per?.allocation?.value,
                    Notice_of_Assessments: null,
                    Director_Shareholder: ["Director", "Shareholder"],
                    $in_merge: false,
                    Credit_Bureau_Rating_MM_YY: null,
                    $zia_visions: null,
                    Yes_No: false,
                    Age: 2024,
                  };
                  keymantoPush.push(dat);
                }
              }
              const dataToZoho = {
                data: [
                  {
                    Owner: {
                      id: "5844778000000423001",
                    },
                    Smartlend: true,
                    Account_Holder: "SmartLend",
                    Ownership:
                      singpassData?.entity?.["basic-profile"]?.ownership?.desc,
                    Account_Name:
                      singpassData?.entity?.["basic-profile"]?.["entity-name"]
                        ?.value,
                    Description: "Account imported from Smart Lend",
                    Account_Type: "Smartlend Borrower",
                    SSIC_Code:
                      singpassData?.entity?.["basic-profile"]?.[
                        "primary-activity"
                      ]?.code,
                    Industry:
                      singpassData?.entity?.["basic-profile"]?.[
                        "primary-activity"
                      ]?.desc,
                    Phone:
                      "+" +
                      singpassData?.person?.mobileno?.areacode?.value +
                      " " +
                      singpassData?.person?.mobileno?.nbr?.value,
                    Keyman_Mobile:
                      "+" +
                      singpassData?.person?.mobileno?.areacode?.value +
                      " " +
                      singpassData?.person?.mobileno?.nbr?.value,
                    Keyman_Email: singpassData?.person?.email?.value,
                    Billing_Country:
                      singpassData?.person?.regadd?.country?.desc,
                    Acra_Address: singpassData?.entity?.addresses?.[
                      "addresses-list"
                    ]?.[0]
                      ? singpassData?.entity?.addresses?.["addresses-list"]?.[0]
                          ?.unit?.value +
                        " " +
                        singpassData?.entity?.addresses?.["addresses-list"]?.[0]
                          ?.street?.value +
                        " " +
                        "#" +
                        singpassData?.entity?.addresses?.["addresses-list"]?.[0]
                          ?.block?.value +
                        "-" +
                        singpassData?.entity?.addresses?.["addresses-list"]?.[0]
                          ?.floor?.value +
                        " " +
                        singpassData?.entity?.addresses?.["addresses-list"]?.[0]
                          ?.country?.desc +
                        " " +
                        "(" +
                        singpassData?.person?.regadd?.postal?.value +
                        ")"
                      : null,
                    UEN: singpassData?.entity?.["basic-profile"]?.["uen"]
                      ?.value,
                    Date_Of_Incorporation:
                      singpassData?.entity?.["basic-profile"]?.[
                        "registration-date"
                      ]?.value,
                    Mailing_Address:
                      singpassData?.person?.regadd?.unit?.value +
                      " " +
                      singpassData?.person?.regadd?.street?.value +
                      " " +
                      "#" +
                      singpassData?.person?.regadd?.block?.value +
                      "-" +
                      singpassData?.person?.regadd?.floor?.value +
                      " " +
                      singpassData?.person?.regadd?.country?.desc +
                      " " +
                      "(" +
                      singpassData?.person?.regadd?.postal?.value +
                      ")",
                    Last_Name: "Contact_test",
                    Email: singpassData?.person?.email?.value,
                    Keymans: keymantoPush,
                  },
                ],
              };
              console.log(singpassData);
              console.log(dataToZoho);
              // callToZoho(dataToZoho);
              handleSave(event);
            }}
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ProfileStaging;
