import React, { useState, useRef, useEffect } from "react";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import SendIcon from "@mui/icons-material/Send";
import SearchIcon from "@mui/icons-material/Search";
import Cookies from "js-cookie";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { cloneDeep, isEqual } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import moment from "moment-timezone";
import InboxIcon from "@mui/icons-material/Inbox";
import { Modal } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import { message } from "antd";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import * as Icons from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormatBoldOutlinedIcon from "@mui/icons-material/FormatBoldOutlined";
import FormatUnderlinedOutlinedIcon from "@mui/icons-material/FormatUnderlinedOutlined";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";

const MyProfile = () => {
  const iconLibrary = Icons;
  const iconNames = Object.keys(iconLibrary);
  const [iconsSearchTerm, setIconSearchTerm] = useState("");
  const [focusElement, setFocusElement] = useState(null);

  const [changed, setChanged] = useState(false);
  const [profileElement, setProfileElement] = useState({
    profile: {
      profile_photo: "",
      company_name: "",
      additionals: [],
      social: [],
    },
    content: [],
  });

  const [profileElementb4, setProfileElementb4] = useState({
    profile: {
      profile_photo: "",
      company_name: "",
      additionals: [],
      social: [],
    },
    content: [],
  });

  useEffect(() => {
    if (!isEqual(profileElement, profileElementb4)) {
      setChanged(true);
      console.log("different already");
    }
  }, [profileElement]);

  const handleFileUpload = async (selectedFile) => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const response1 = await fetch(
        "https://www.smart-lend.com/api/file/uploadUniversalFile",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: formData,
        }
      );

      const json = await response1.json();
      console.log(json.url);
      return json.url;
    } catch (error) {
      console.error(error);
    }
  };

  const getProfileDesign = async () => {
    try {
      const response1 = await fetch(
        "https://www.smart-lend.com/api/user/getProfileDesign",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response1.json();
      if (json.design !== null) {
        console.log("profile design updated with", JSON.parse(json.design));
        setProfileElement(JSON.parse(json.design));
        setProfileElementb4(JSON.parse(json.design));
      } else {
        getUserDetails();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateProfileDesign = async (data) => {
    try {
      const response1 = await fetch(
        "https://www.smart-lend.com/api/user/updateProfileDesign",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({
            profile_design: JSON.stringify(profileElement),
          }),
        }
      );

      const json = await response1.json();
      console.log(json);
      if (response1.status === 200) {
        setChanged(false);
        message.success("Profile succesfully updated");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProfileDesign();
  }, []);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref1.current &&
        !ref1.current.contains(event.target) &&
        ref2.current &&
        !ref2.current.contains(event.target)
      ) {
        setFocusElement(null);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref1, ref2]);

  const getUserDetails = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/getUserDetails",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        const adjustingProfileElement = { ...profileElement };
        adjustingProfileElement.profile.company_name = json.User.principal_name;
        setProfileElement(adjustingProfileElement);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function generateRandomStringWithDateTime(length = 10) {
    // Generate a random string
    const characters =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let randomString = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters[randomIndex];
    }

    // Get the current date and time (formatted as YYYYMMDDHHmmss)
    const date = new Date();
    const formattedDate = date.toISOString().slice(0, 10).replace(/-/g, "");
    const formattedTime = date.toTimeString().slice(0, 8).replace(/:/g, "");

    // Combine the random string with the date and time
    return randomString + formattedDate + formattedTime;
  }

  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background: "red",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: "5px",
          alignItems: "center",
          gap: "10px",
          marginBottom: "15px",
        }}
      >
        <div
          style={{
            color: "#142C44CC",
            fontSize: "20px",
            fontFamily: "Manrope,sans-serif",
            fontWeight: 800,
          }}
        >
          My Profile
        </div>{" "}
        <div
          onClick={() => {
            updateProfileDesign();
          }}
          style={{
            color: changed ? "white" : "rgb(14, 90, 165)",
            border: "2px solid rgb(14, 90, 165)",
            fontWeight: 600,
            padding: "5px 25px",
            cursor: "pointer",
            borderRadius: "12px",
            background: changed ? "rgb(14, 90, 165)" : "transparent",
          }}
        >
          Save
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
          color: "grey",
        }}
      >
        <div
          style={{
            width: "calc(30% - 10px)",
            background: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "flex-start", gap: "15px" }}
          >
            <div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={async (e) => {
                  const file = e.target.files[0];
                  if (file) {
                    // Pass the file to a function that uploads it to the database
                    const updatingUserProfilePhoto = { ...profileElement };
                    updatingUserProfilePhoto.profile.profile_photo =
                      await handleFileUpload(file);
                    setProfileElement(updatingUserProfilePhoto);
                  }
                }}
              />
              <img
                style={{
                  height: "80px",
                  width: "80px",
                  borderRadius: "50%",
                  background: "lightgrey",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
                  cursor: "pointer",
                  objectFit: "cover",
                }}
                onClick={() => {
                  if (fileInputRef.current) {
                    fileInputRef.current.click();
                  }
                }}
                src={profileElement?.profile?.profile_photo}
              />
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  color: "rgb(14, 90, 165)",
                  fontSize: "20px",
                  fontFamily: "Manrope,sans-serif",
                  fontWeight: 800,
                  marginBottom: "5px",
                }}
              >
                {profileElement?.profile?.company_name}
              </div>
              {profileElement.profile.additionals.map((addi, index) => (
                <div key={index} style={{ display: "flex", gap: "10px" }}>
                  <input
                    value={addi.text}
                    onChange={(e) => {
                      const updatinggProfileAdditionals = { ...profileElement };
                      updatinggProfileAdditionals.profile.additionals[
                        index
                      ].text = e.target.value;
                      setProfileElement(updatinggProfileAdditionals);
                    }}
                    style={{
                      width: "85%",
                      border: "1px solid rgba(0,0,0,0.05)",
                      outline: "none",
                      color: "rgba(0,0,0,0.6)",
                    }}
                  />
                  <RemoveCircleOutlineOutlinedIcon
                    style={{
                      color: "grey",
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      const updatinggProfileAdditionals = { ...profileElement };
                      updatinggProfileAdditionals.profile.additionals.splice(
                        index,
                        1
                      );
                      setProfileElement(updatinggProfileAdditionals);
                    }}
                  />
                </div>
              ))}
              <div
                style={{
                  height: "30px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  cursor: "pointer",
                  gap: "5px",
                  color: "grey",
                }}
                onClick={(e) => {
                  const updatinggProfileAdditionals = { ...profileElement };
                  const itemToPush = {
                    id: generateRandomStringWithDateTime(),
                    text: "",
                  };
                  updatinggProfileAdditionals.profile.additionals.push(
                    itemToPush
                  );
                  setProfileElement(updatinggProfileAdditionals);
                }}
              >
                Add sub-description
                <AddCircleOutlineOutlinedIcon
                  style={{
                    color: "grey",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {profileElement.profile.social.map((soci, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setIconSearchTerm("");
                      setFocusElement(soci.id);
                    }}
                    ref={ref1}
                    style={{
                      padding: "5px",
                      position: "relative",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={iconLibrary[soci.icon]}
                      style={{ fontSize: 20, color: "rgb(14, 90, 165)" }}
                    />
                    {focusElement === soci.id ? (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        ref={ref2}
                        style={{
                          position: "absolute",
                          top: "80%",
                          left: "80%",
                          padding: "15px",
                          borderRadius: "10px",
                          background: "rgba(256,256,256, 0.4)",
                          backdropFilter: "blur(15px)",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          width: "450px",
                          zIndex: 100,
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 600,
                            color: "grey",
                            fontSize: "14px",
                            margin: "0px 0px 4px 0px",
                          }}
                        >
                          URL Link
                        </div>
                        <input
                          style={{
                            width: "85%",
                            border: "1px solid rgba(0,0,0,0.05)",
                            outline: "none",
                            color: "rgba(0,0,0,0.6)",
                          }}
                          placeholder="https://www."
                          value={soci.link}
                          onChange={(e) => {
                            const updatingSocialIcon = {
                              ...profileElement,
                            };
                            updatingSocialIcon.profile.social[index].link =
                              e.target.value;
                            setProfileElement(updatingSocialIcon);
                          }}
                        />
                        <div
                          style={{
                            fontWeight: 600,
                            color: "grey",
                            fontSize: "14px",
                            margin: "10px 0px 4px 0px",
                          }}
                        >
                          Social Icon
                        </div>
                        <input
                          className="module_text_field"
                          style={{
                            width: "85%",
                            border: "1px solid rgba(0,0,0,0.05)",
                            outline: "none",
                            color: "rgba(0,0,0,0.6)",
                            marginBottom: "5px",
                          }}
                          placeholder="Search icons"
                          value={iconsSearchTerm}
                          onChange={(e) => {
                            setIconSearchTerm(e.target.value);
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "95%",
                            maxHeight: "300px",
                            overflowY: "auto",
                          }}
                        >
                          {iconsSearchTerm === ""
                            ? iconNames.slice(0, 153).map((iconName) => {
                                const Icon = iconLibrary[iconName];
                                return (
                                  <div
                                    onClick={() => {
                                      console.log(iconName);
                                      const updatingSocialIcon = {
                                        ...profileElement,
                                      };
                                      updatingSocialIcon.profile.social[
                                        index
                                      ].icon = iconName;
                                      setProfileElement(updatingSocialIcon);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      padding: "5px",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={Icon}
                                      style={{
                                        fontSize: 25,
                                        color: "rgba(65,65,65)",
                                      }}
                                    />
                                  </div>
                                );
                              })
                            : iconNames
                                .filter((iconName) =>
                                  iconName
                                    .toLowerCase()
                                    .includes(iconsSearchTerm.toLowerCase())
                                )
                                .map((iconName) => {
                                  const Icon = iconLibrary[iconName];
                                  return (
                                    <div
                                      onClick={() => {
                                        console.log(iconName);
                                        const updatingSocialIcon = {
                                          ...profileElement,
                                        };
                                        updatingSocialIcon.profile.social[
                                          index
                                        ].icon = iconName;
                                        setProfileElement(updatingSocialIcon);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        padding: "5px",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={Icon}
                                        style={{
                                          fontSize: 25,
                                          color: "rgba(65,65,65)",
                                        }}
                                      />
                                    </div>
                                  );
                                })}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "10px",
                          }}
                        >
                          <div
                            onClick={(e) => {
                              setFocusElement(null);
                              const updatingSocialIcon = {
                                ...profileElement,
                              };
                              updatingSocialIcon.profile.social.splice(
                                index,
                                1
                              );
                              setProfileElement(updatingSocialIcon);
                            }}
                            style={{
                              color: "#142C44CC",
                              border: "2px solid #142C44CC",
                              fontWeight: 600,
                              padding: "5px 25px",
                              cursor: "pointer",
                              borderRadius: "12px",
                            }}
                          >
                            Delete
                          </div>
                          <div
                            onClick={(e) => {
                              setFocusElement(null);
                            }}
                            style={{
                              color: "#142C44CC",
                              border: "2px solid #142C44CC",
                              fontWeight: 600,
                              padding: "5px 25px",
                              cursor: "pointer",
                              borderRadius: "12px",
                            }}
                          >
                            Save
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
              <div
                style={{
                  height: "30px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  cursor: "pointer",
                  gap: "5px",
                  color: "grey",
                }}
                onClick={(e) => {
                  const updatinggProfileAdditionals = { ...profileElement };
                  const itemToPush = {
                    id: generateRandomStringWithDateTime(),
                    icon: "faFacebook",
                    link: "",
                  };
                  updatinggProfileAdditionals.profile.social.push(itemToPush);
                  console.log(updatinggProfileAdditionals);
                  setIconSearchTerm("");
                  setFocusElement(itemToPush.id);
                  setProfileElement(updatinggProfileAdditionals);
                }}
              >
                Add social
                <AddCircleOutlineOutlinedIcon
                  style={{
                    color: "grey",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <hr
            style={{
              width: "90%",
              marginLeft: "5%",
              background: "grey",
              border: "none",
              outline: "none",
            }}
          />
          {profileElement.content.map((cont, index) =>
            cont.type === "text" ? (
              <div
                key={index}
                style={{
                  position: "relative",
                }}
              >
                <input
                  ref={ref1}
                  onFocus={() => {
                    setFocusElement(cont.id);
                  }}
                  style={{
                    marginTop: cont.mt,
                    marginBottom: cont.mb,
                    textDecoration: cont.underline ? "underline" : "none",
                    fontWeight: cont.fontWeight === 400 ? 400 : 600,
                    fontSize: cont.fontWeight === 400 ? "12px" : "16px",
                    border: "1px solid rgba(0,0,0,0.05)",
                    outline: "none",
                    color: "rgba(0,0,0,0.6)",
                    width: "100%",
                  }}
                  value={cont.text}
                  onChange={(e) => {
                    const updatingProfileContent = { ...profileElement };
                    updatingProfileContent.content[index].text = e.target.value;
                    setProfileElement(updatingProfileContent);
                  }}
                />
                {focusElement === cont.id ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    ref={ref2}
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: "100%",
                      padding: "15px",
                      borderRadius: "10px",
                      background: "rgba(256,256,256, 0.4)",
                      backdropFilter: "blur(15px)",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                      zIndex: 100,
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 600,
                        color: "grey",
                        fontSize: "14px",
                        margin: "0px 0px 4px 0px",
                      }}
                    >
                      Text Styling
                    </div>
                    <div
                      style={{ display: "flex", gap: "20px", width: "300px" }}
                    >
                      <div style={{ width: "45%" }}>
                        <div>Margin Top ({cont.mt}px)</div>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <RemoveCircleOutlineOutlinedIcon
                            onClick={(e) => {
                              const updatingProfileContent = {
                                ...profileElement,
                              };
                              updatingProfileContent.content[index].mt -= 2;
                              setProfileElement(updatingProfileContent);
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                          <AddCircleOutlineOutlinedIcon
                            onClick={(e) => {
                              const updatingProfileContent = {
                                ...profileElement,
                              };
                              updatingProfileContent.content[index].mt += 2;
                              setProfileElement(updatingProfileContent);
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ width: "50%" }}>
                        <div>Margin Bottom ({cont.mb}px)</div>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <RemoveCircleOutlineOutlinedIcon
                            onClick={(e) => {
                              const updatingProfileContent = {
                                ...profileElement,
                              };
                              updatingProfileContent.content[index].mb -= 2;
                              setProfileElement(updatingProfileContent);
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                          <AddCircleOutlineOutlinedIcon
                            onClick={(e) => {
                              const updatingProfileContent = {
                                ...profileElement,
                              };
                              updatingProfileContent.content[index].mb += 2;
                              setProfileElement(updatingProfileContent);
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        width: "300px",
                        margin: "10px 0px 5px 0px",
                      }}
                    >
                      <div style={{ width: "45%" }}>
                        <div style={{}}>Text Decoration</div>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <div
                            style={{
                              padding: "1px",
                              borderRadius: "4px",
                              border: "2px solid grey",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FormatBoldOutlinedIcon
                              onClick={() => {
                                const updatingProfileContent = {
                                  ...profileElement,
                                };
                                if (
                                  updatingProfileContent.content[index]
                                    .fontWeight === 400
                                ) {
                                  updatingProfileContent.content[
                                    index
                                  ].fontWeight = 600;
                                } else {
                                  updatingProfileContent.content[
                                    index
                                  ].fontWeight = 400;
                                }
                                setProfileElement(updatingProfileContent);
                              }}
                              style={{
                                color:
                                  cont.fontWeight === 400 ? "grey" : "white",
                                background:
                                  cont.fontWeight === 400
                                    ? "transparent"
                                    : "grey",
                                fontSize: "25px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              padding: "1px",
                              borderRadius: "4px",
                              border: "2px solid grey",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FormatUnderlinedOutlinedIcon
                              onClick={() => {
                                const updatingProfileContent = {
                                  ...profileElement,
                                };
                                if (
                                  updatingProfileContent.content[index]
                                    .underline === false
                                ) {
                                  updatingProfileContent.content[
                                    index
                                  ].underline = true;
                                } else {
                                  updatingProfileContent.content[
                                    index
                                  ].underline = false;
                                }
                                console.log(updatingProfileContent);
                                setProfileElement(updatingProfileContent);
                              }}
                              style={{
                                color:
                                  cont.underline === false ? "grey" : "white",
                                background:
                                  cont.underline === false
                                    ? "transparent"
                                    : "grey",
                                fontSize: "25px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "45%" }}>
                        <div style={{}}>Element Position</div>

                        <div style={{ display: "flex", gap: "8px" }}>
                          <ArrowCircleDownOutlinedIcon
                            onClick={(e) => {
                              // Create a copy of the current profileElement object
                              const updatingProfileContent = {
                                ...profileElement,
                              };

                              const fromIndex = index;
                              const toIndex = fromIndex + 1;

                              // Ensure the target index is within bounds
                              if (
                                toIndex >= 0 &&
                                toIndex < updatingProfileContent.content.length
                              ) {
                                // Create a copy of the array
                                const newArray = [
                                  ...updatingProfileContent.content,
                                ];

                                // Remove the item from the original position
                                const [item] = newArray.splice(fromIndex, 1);

                                // Insert the item at the new position
                                newArray.splice(toIndex, 0, item);

                                // Update the content with the reordered array
                                updatingProfileContent.content = newArray;

                                // Set the updated profileElement
                                setProfileElement(updatingProfileContent);
                              }
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                          <ArrowCircleUpOutlinedIcon
                            onClick={(e) => {
                              // Create a copy of the current profileElement object
                              const updatingProfileContent = {
                                ...profileElement,
                              };

                              const fromIndex = index;
                              const toIndex = fromIndex - 1;

                              // Ensure the target index is within bounds
                              if (
                                toIndex >= 0 &&
                                toIndex < updatingProfileContent.content.length
                              ) {
                                // Create a copy of the array
                                const newArray = [
                                  ...updatingProfileContent.content,
                                ];

                                // Remove the item from the original position
                                const [item] = newArray.splice(fromIndex, 1);

                                // Insert the item at the new position
                                newArray.splice(toIndex, 0, item);

                                // Update the content with the reordered array
                                updatingProfileContent.content = newArray;

                                // Set the updated profileElement
                                setProfileElement(updatingProfileContent);
                              }
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        onClick={(e) => {
                          setFocusElement(null);
                          const updatingSocialIcon = {
                            ...profileElement,
                          };
                          updatingSocialIcon.content.splice(index, 1);
                          setProfileElement(updatingSocialIcon);
                        }}
                        style={{
                          color: "#142C44CC",
                          border: "2px solid #142C44CC",
                          fontWeight: 600,
                          padding: "5px 25px",
                          cursor: "pointer",
                          borderRadius: "12px",
                        }}
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : cont.type === "button" ? (
              <div
                key={index}
                style={{
                  position: "relative",
                  display: "flex",
                }}
              >
                <div
                  key={index}
                  ref={ref1}
                  onClick={() => {
                    setFocusElement(cont.id);
                  }}
                  style={{
                    marginTop: cont.mt,
                    marginBottom: cont.mb,
                    fontWeight: 500,
                    fontSize: "14px",
                    border: "1px solid rgba(0,0,0,0.05)",
                    outline: "none",
                    color: "white",
                    background: "rgb(14, 90, 165)",
                    padding: "7px 30px",
                    borderRadius: "30px",
                  }}
                  value={cont.text}
                  onChange={(e) => {
                    const updatingProfileContent = { ...profileElement };
                    updatingProfileContent.content[index].text = e.target.value;
                    setProfileElement(updatingProfileContent);
                  }}
                >
                  {cont.text}
                </div>
                {focusElement === cont.id ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    ref={ref2}
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: "100%",
                      padding: "15px",
                      borderRadius: "10px",
                      background: "rgba(256,256,256, 0.4)",
                      backdropFilter: "blur(15px)",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                      zIndex: 100,
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 600,
                        color: "grey",
                        fontSize: "14px",
                        margin: "0px 0px 4px 0px",
                      }}
                    >
                      Button Styling
                    </div>
                    <div
                      style={{ display: "flex", gap: "20px", width: "300px" }}
                    >
                      <div style={{ width: "45%" }}>
                        <div>Margin Top ({cont.mt}px)</div>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <RemoveCircleOutlineOutlinedIcon
                            onClick={(e) => {
                              const updatingProfileContent = {
                                ...profileElement,
                              };
                              updatingProfileContent.content[index].mt -= 2;
                              setProfileElement(updatingProfileContent);
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                          <AddCircleOutlineOutlinedIcon
                            onClick={(e) => {
                              const updatingProfileContent = {
                                ...profileElement,
                              };
                              updatingProfileContent.content[index].mt += 2;
                              setProfileElement(updatingProfileContent);
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ width: "50%" }}>
                        <div>Margin Bottom ({cont.mb}px)</div>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <RemoveCircleOutlineOutlinedIcon
                            onClick={(e) => {
                              const updatingProfileContent = {
                                ...profileElement,
                              };
                              updatingProfileContent.content[index].mb -= 2;
                              setProfileElement(updatingProfileContent);
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                          <AddCircleOutlineOutlinedIcon
                            onClick={(e) => {
                              const updatingProfileContent = {
                                ...profileElement,
                              };
                              updatingProfileContent.content[index].mb += 2;
                              setProfileElement(updatingProfileContent);
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        width: "300px",
                        margin: "10px 0px 5px 0px",
                      }}
                    >
                      <div style={{ width: "45%" }}>
                        <div style={{}}>Element Position</div>

                        <div style={{ display: "flex", gap: "8px" }}>
                          <ArrowCircleDownOutlinedIcon
                            onClick={(e) => {
                              // Create a copy of the current profileElement object
                              const updatingProfileContent = {
                                ...profileElement,
                              };

                              const fromIndex = index;
                              const toIndex = fromIndex + 1;

                              // Ensure the target index is within bounds
                              if (
                                toIndex >= 0 &&
                                toIndex < updatingProfileContent.content.length
                              ) {
                                // Create a copy of the array
                                const newArray = [
                                  ...updatingProfileContent.content,
                                ];

                                // Remove the item from the original position
                                const [item] = newArray.splice(fromIndex, 1);

                                // Insert the item at the new position
                                newArray.splice(toIndex, 0, item);

                                // Update the content with the reordered array
                                updatingProfileContent.content = newArray;

                                // Set the updated profileElement
                                setProfileElement(updatingProfileContent);
                              }
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                          <ArrowCircleUpOutlinedIcon
                            onClick={(e) => {
                              // Create a copy of the current profileElement object
                              const updatingProfileContent = {
                                ...profileElement,
                              };

                              const fromIndex = index;
                              const toIndex = fromIndex - 1;

                              // Ensure the target index is within bounds
                              if (
                                toIndex >= 0 &&
                                toIndex < updatingProfileContent.content.length
                              ) {
                                // Create a copy of the array
                                const newArray = [
                                  ...updatingProfileContent.content,
                                ];

                                // Remove the item from the original position
                                const [item] = newArray.splice(fromIndex, 1);

                                // Insert the item at the new position
                                newArray.splice(toIndex, 0, item);

                                // Update the content with the reordered array
                                updatingProfileContent.content = newArray;

                                // Set the updated profileElement
                                setProfileElement(updatingProfileContent);
                              }
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ margin: "10px 0px 5px 0px" }}>
                      Button Text
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <input
                        style={{
                          width: "85%",
                          border: "1px solid rgba(0,0,0,0.05)",
                          outline: "none",
                          color: "rgba(0,0,0,0.6)",
                        }}
                        placeholder="Button text"
                        value={cont.text}
                        onChange={(e) => {
                          const updatingProfileContent = {
                            ...profileElement,
                          };
                          updatingProfileContent.content[index].text =
                            e.target.value;
                          setProfileElement(updatingProfileContent);
                        }}
                      />
                    </div>
                    <div style={{ margin: "10px 0px 5px 0px" }}>Button Url</div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <input
                        style={{
                          width: "85%",
                          border: "1px solid rgba(0,0,0,0.05)",
                          outline: "none",
                          color: "rgba(0,0,0,0.6)",
                        }}
                        placeholder="https://www."
                        value={cont.url}
                        onChange={(e) => {
                          const updatingProfileContent = {
                            ...profileElement,
                          };
                          updatingProfileContent.content[index].url =
                            e.target.value;
                          setProfileElement(updatingProfileContent);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        onClick={(e) => {
                          setFocusElement(null);
                          const updatingSocialIcon = {
                            ...profileElement,
                          };
                          updatingSocialIcon.content.splice(index, 1);
                          setProfileElement(updatingSocialIcon);
                        }}
                        style={{
                          color: "#142C44CC",
                          border: "2px solid #142C44CC",
                          fontWeight: 600,
                          padding: "5px 25px",
                          cursor: "pointer",
                          borderRadius: "12px",
                        }}
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div
                key={index}
                style={{
                  position: "relative",
                  marginTop: cont.mt,
                  marginBottom: cont.mb,
                }}
              >
                <img
                  ref={ref1}
                  style={{
                    objectFit: "cover",
                    width: "80%",
                    height: "150px",
                    border: "none",
                    outline: "none",
                  }}
                  onClick={() => {
                    setFocusElement(cont.id);
                  }}
                  src={cont.url}
                />
                {focusElement === cont.id ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    ref={ref2}
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: "100%",
                      padding: "15px",
                      borderRadius: "10px",
                      background: "rgba(256,256,256, 0.4)",
                      backdropFilter: "blur(15px)",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                      zIndex: 100,
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 600,
                        color: "grey",
                        fontSize: "14px",
                        margin: "0px 0px 4px 0px",
                      }}
                    >
                      Image Styling
                    </div>
                    <div
                      style={{ display: "flex", gap: "20px", width: "300px" }}
                    >
                      <div style={{ width: "45%" }}>
                        <div>Margin Top ({cont.mt}px)</div>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <RemoveCircleOutlineOutlinedIcon
                            onClick={(e) => {
                              const updatingProfileContent = {
                                ...profileElement,
                              };
                              updatingProfileContent.content[index].mt -= 2;
                              setProfileElement(updatingProfileContent);
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                          <AddCircleOutlineOutlinedIcon
                            onClick={(e) => {
                              const updatingProfileContent = {
                                ...profileElement,
                              };
                              updatingProfileContent.content[index].mt += 2;
                              setProfileElement(updatingProfileContent);
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ width: "50%" }}>
                        <div>Margin Bottom ({cont.mb}px)</div>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <RemoveCircleOutlineOutlinedIcon
                            onClick={(e) => {
                              const updatingProfileContent = {
                                ...profileElement,
                              };
                              updatingProfileContent.content[index].mb -= 2;
                              setProfileElement(updatingProfileContent);
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                          <AddCircleOutlineOutlinedIcon
                            onClick={(e) => {
                              const updatingProfileContent = {
                                ...profileElement,
                              };
                              updatingProfileContent.content[index].mb += 2;
                              setProfileElement(updatingProfileContent);
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        width: "300px",
                        margin: "10px 0px 5px 0px",
                      }}
                    >
                      <div style={{ width: "45%" }}>
                        <div style={{}}>Element Position</div>

                        <div style={{ display: "flex", gap: "8px" }}>
                          <ArrowCircleDownOutlinedIcon
                            onClick={(e) => {
                              // Create a copy of the current profileElement object
                              const updatingProfileContent = {
                                ...profileElement,
                              };

                              const fromIndex = index;
                              const toIndex = fromIndex + 1;

                              // Ensure the target index is within bounds
                              if (
                                toIndex >= 0 &&
                                toIndex < updatingProfileContent.content.length
                              ) {
                                // Create a copy of the array
                                const newArray = [
                                  ...updatingProfileContent.content,
                                ];

                                // Remove the item from the original position
                                const [item] = newArray.splice(fromIndex, 1);

                                // Insert the item at the new position
                                newArray.splice(toIndex, 0, item);

                                // Update the content with the reordered array
                                updatingProfileContent.content = newArray;

                                // Set the updated profileElement
                                setProfileElement(updatingProfileContent);
                              }
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                          <ArrowCircleUpOutlinedIcon
                            onClick={(e) => {
                              // Create a copy of the current profileElement object
                              const updatingProfileContent = {
                                ...profileElement,
                              };

                              const fromIndex = index;
                              const toIndex = fromIndex - 1;

                              // Ensure the target index is within bounds
                              if (
                                toIndex >= 0 &&
                                toIndex < updatingProfileContent.content.length
                              ) {
                                // Create a copy of the array
                                const newArray = [
                                  ...updatingProfileContent.content,
                                ];

                                // Remove the item from the original position
                                const [item] = newArray.splice(fromIndex, 1);

                                // Insert the item at the new position
                                newArray.splice(toIndex, 0, item);

                                // Update the content with the reordered array
                                updatingProfileContent.content = newArray;

                                // Set the updated profileElement
                                setProfileElement(updatingProfileContent);
                              }
                            }}
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ margin: "10px 0px 5px 0px" }}>
                      Image Source
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <input
                        style={{
                          width: "85%",
                          border: "1px solid rgba(0,0,0,0.05)",
                          outline: "none",
                          color: "rgba(0,0,0,0.6)",
                        }}
                        type="file"
                        placeholder="Button text"
                        value={cont.text}
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          const updatingUserContent = {
                            ...profileElement,
                          };
                          updatingUserContent.content[index].url =
                            await handleFileUpload(file);
                          setProfileElement(updatingUserContent);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        onClick={(e) => {
                          setFocusElement(null);
                          const updatingSocialIcon = {
                            ...profileElement,
                          };
                          updatingSocialIcon.content.splice(index, 1);
                          setProfileElement(updatingSocialIcon);
                        }}
                        style={{
                          color: "#142C44CC",
                          border: "2px solid #142C44CC",
                          fontWeight: 600,
                          padding: "5px 25px",
                          cursor: "pointer",
                          borderRadius: "12px",
                        }}
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
              gap: "8px",
            }}
          >
            <div
              onClick={(e) => {
                const itemToPush = {
                  id: generateRandomStringWithDateTime(),
                  type: "text",
                  text: "Type your text here",
                  fontWeight: 400,
                  underline: false,
                  mt: 0,
                  mb: 0,
                };
                const updatingProfileContent = { ...profileElement };
                updatingProfileContent.content.push(itemToPush);
                setProfileElement(updatingProfileContent);
              }}
              style={{
                color: "rgb(14, 90, 165)",
                border: "2px solid rgb(14, 90, 165)",
                fontWeight: 600,
                padding: "5px 25px",
                cursor: "pointer",
                borderRadius: "12px",
              }}
            >
              Text
            </div>
            <div
              onClick={(e) => {
                const itemToPush = {
                  id: generateRandomStringWithDateTime(),
                  type: "button",
                  text: "Button text",
                  url: "",
                  mt: 0,
                  mb: 0,
                };
                const updatingProfileContent = { ...profileElement };
                updatingProfileContent.content.push(itemToPush);
                setProfileElement(updatingProfileContent);
              }}
              style={{
                color: "rgb(14, 90, 165)",
                border: "2px solid rgb(14, 90, 165)",
                fontWeight: 600,
                padding: "5px 25px",
                cursor: "pointer",
                borderRadius: "12px",
              }}
            >
              Button
            </div>
            <div
              onClick={(e) => {
                const itemToPush = {
                  id: generateRandomStringWithDateTime(),
                  type: "image",
                  url: "",
                  mt: 0,
                  mb: 0,
                };
                const updatingProfileContent = { ...profileElement };
                updatingProfileContent.content.push(itemToPush);
                setProfileElement(updatingProfileContent);
              }}
              style={{
                color: "rgb(14, 90, 165)",
                border: "2px solid rgb(14, 90, 165)",
                fontWeight: 600,
                padding: "5px 25px",
                cursor: "pointer",
                borderRadius: "12px",
              }}
            >
              Image
            </div>
          </div>
        </div>
        <div style={{ width: "60%" }}>
          <div
            style={{
              color: "#142C44CC",
              fontSize: "20px",
              fontFamily: "Manrope,sans-serif",
              fontWeight: 800,
              marginBottom: "15px",
            }}
          >
            Preview
          </div>
          <div
            style={{
              width: "400px",
              background: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "flex-start", gap: "15px" }}
            >
              <div>
                <img
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "50%",
                    background: "lightgrey",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
                    objectFit: "cover",
                  }}
                  src={profileElement?.profile?.profile_photo}
                />
              </div>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    color: "rgb(14, 90, 165)",
                    fontSize: "20px",
                    fontFamily: "Manrope,sans-serif",
                    fontWeight: 800,
                    marginBottom: "5px",
                  }}
                >
                  {profileElement?.profile?.company_name}
                </div>
                {profileElement.profile.additionals.map((addi, index) => (
                  <div key={index} style={{ display: "flex", gap: "10px" }}>
                    <div
                      style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        color: "rgba(0,0,0,0.6)",
                        marginTop: "-5px",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {addi.text}
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    marginLeft: "-5px",
                    marginTop: "5px",
                  }}
                >
                  {profileElement.profile.social.map((soci, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "5px",
                        position: "relative",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={iconLibrary[soci.icon]}
                        style={{ fontSize: 20, color: "rgb(14, 90, 165)" }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <hr
              style={{
                width: "90%",
                marginLeft: "5%",
                background: "grey",
                border: "none",
                outline: "none",
              }}
            />
            {profileElement.content.map((cont, index) =>
              cont.type === "text" ? (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    marginTop: cont.mt,
                    marginBottom: cont.mb,
                    textDecoration: cont.underline ? "underline" : "none",
                    fontWeight: cont.fontWeight === 400 ? 400 : 600,
                    fontSize: cont.fontWeight === 400 ? "12px" : "16px",
                    border: "none",
                    outline: "none",
                    color: "rgba(0,0,0,0.6)",
                    width: "100%",
                  }}
                >
                  {cont.text}
                </div>
              ) : cont.type === "button" ? (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      marginTop: cont.mt,
                      marginBottom: cont.mb,
                      fontWeight: 500,
                      fontSize: "14px",
                      border: "1px solid rgba(0,0,0,0.05)",
                      outline: "none",
                      color: "white",
                      background: "rgb(14, 90, 165)",
                      padding: "7px 30px",
                      borderRadius: "30px",
                    }}
                  >
                    {cont.text}
                  </div>
                </div>
              ) : (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    marginTop: cont.mt,
                    marginBottom: cont.mb,
                  }}
                >
                  <img
                    style={{
                      objectFit: "cover",
                      width: "80%",
                      height: "150px",
                      border: "none",
                      outline: "none",
                    }}
                    src={cont.url}
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
