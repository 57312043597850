import React, { useEffect, useState } from "react";
import bgvideo from "../../pages/smart-lend-frontend/src/img/bgvideo.mp4";
import RepeatIcon from "@mui/icons-material/Repeat";
import Checkbox from "@mui/material/Checkbox";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaidIcon from "@mui/icons-material/Paid";
import styled from "styled-components";
import { message, Select } from "antd";
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";
import { CircularProgress } from "@mui/material";

const SuperAdmin = () => {
  const [employeeList, setEmployeeList] = useState([]);

  const getEmployee = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/employee/getEmployee`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      setEmployeeList(json.employees);
    } catch (error) {
      console.error(error);
    }
  };

  const updatePassword = async () => {
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/employee/updateEmployeePassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: employeeList[loggedInEmployeeIndex].id,
            passwordd: passwordSet,
          }),
        }
      );
      if (response.status === 201) {
        const json = await response.json();
        message.success("Password succesfully updated!");
        setEmployeeList(json.all_employees);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getEmployee();
  }, []);
  const [passwordSet, setPasswordSet] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [userField, setUserField] = useState("");
  const [passwordField, setPasswordField] = useState("");
  const [loggedInEmployeeIndex, setLoggedInEmployeeIndex] = useState(null);
  const [error, setError] = useState(false);
  function getFormattedDate() {
    const date = new Date();

    const day = date.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  const getFormattedwithDate = (providedDate) => {
    const date = new Date(providedDate);

    const day = date.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const [timerDone, setTimerDone] = useState(0);
  const [inputSearch, setInputSearch] = useState("");
  const [postalCodeSearching, setPostalCodeSearching] = useState("");
  const [valuerForm, setValuerForm] = useState({
    propertyTypeCode: "hdb",
    postalCode: "",
    addressUnit: "",
    addressNumber: "",
    addressStreet: "",
    projectName: "",
    valuationType: "sale",
  });
  const [retreivedData, setRetreivedData] = useState([]);
  const [searching, setSearching] = useState(false);

  const cleanedInput = inputSearch
    .replace(postalCodeSearching, "") // Remove postal code from the input
    .replace("Unit", "") // Remove postal code from the input
    .replace("Floor", "") // Remove postal code from the input
    .replace("Block", "") // Remove postal code from the input
    .replace("Blk", "") // Remove postal code from the input
    .replace("unit", "") // Remove postal code from the input
    .replace("floor", "") // Remove postal code from the input
    .replace("block", "") // Remove postal code from the input
    .replace("blk", "") // Remove postal code from the input
    .replace(/[^a-zA-Z0-9\s]/g, "")
    .trim()
    .split(/\s+/);

  const filteredSearch = retreivedData?.filter((address) =>
    cleanedInput.every((term) =>
      address.fullAddress.toLowerCase().includes(term.toLowerCase())
    )
  );

  const capitalizeWords = (str) => {
    if (!str) return ""; // Check if the string exists (handles null or undefined)

    return str
      .split(" ") // Split the string by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(" "); // Join the words back into a string
  };

  const submitValuation = async (postalCode, propertyTypeCode) => {
    setSearching(true);
    try {
      let item = {};
      if (
        valuerForm.propertyTypeCode !== null &&
        valuerForm.propertyTypeCode !== ""
      ) {
        item.propertyTypeCode = valuerForm.propertyTypeCode;
      }

      if (propertyTypeCode !== null && propertyTypeCode !== "") {
        item.propertyTypeCode = propertyTypeCode;
      }

      if (postalCode !== null && postalCode !== "") {
        item.postalCode = postalCode;
      }
      if (valuerForm.addressUnit !== null && valuerForm.addressUnit !== "") {
        item.addressUnit = valuerForm.addressUnit;
      }
      if (
        valuerForm.addressNumber !== null &&
        valuerForm.addressNumber !== ""
      ) {
        item.addressNumber = valuerForm.addressNumber;
      }
      if (
        valuerForm.addressStreet !== null &&
        valuerForm.addressStreet !== ""
      ) {
        item.addressStreet = valuerForm.addressStreet;
      }
      if (valuerForm.projectName !== null && valuerForm.projectName !== "") {
        item.projectName = valuerForm.projectName;
      }
      if (
        valuerForm.valuationType !== null &&
        valuerForm.valuationType !== ""
      ) {
        item.valuationType = valuerForm.valuationType;
      }

      const response = await fetch(
        `https://www.smart-lend.com/api/property/getPropertyValuation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item),
        }
      );

      const json = await response.json();

      if (json.code === 200) {
        console.log("call was succesful");
        message.success("API call was succesful!");
        if (Array.isArray(json.data)) {
          const adjustingData = [...json.data];
          for (const data of adjustingData) {
            data.fullAddress =
              data?.addressUnit +
              " " +
              data?.addressNumber +
              ", " +
              data?.addressStreet +
              ", " +
              data?.postalCode +
              ", " +
              data?.country;
          }
          setRetreivedData(json.data);
        }
        setSearching(false);
      }
    } catch (error) {
      console.error(error);
      setSearching(false);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      setTimerDone(1);
      // Set a timer for 3 seconds (3000 ms)
      const timer = setTimeout(() => {
        setTimerDone(2); // Change the state to true
      }, 3000);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [loggedIn]);

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setInputSearch(userInput);

    // Extract the 6-digit postal code
    const postalCodeMatch = userInput.match(/\b\d{6}\b/);
    const postalCode = postalCodeMatch ? postalCodeMatch[0] : null;
    console.log(
      postalCodeMatch,
      postalCode && postalCode !== postalCodeSearching
    );

    if (postalCode && postalCode !== postalCodeSearching) {
      submitValuation(postalCode, valuerForm.propertyTypeCode);
      setPostalCodeSearching(postalCode);
    }
  };

  return (
    <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
          filter: "blur(2px)",
          background: "rgb(37, 97, 153)",
        }}
      >
        <div
          style={{
            width: "100%",
            background:
              "transparent linear-gradient(180deg, rgba(256,256,256,0.5) 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
            height: "100%",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: 5,
          background: "rgba(256,256,256, 0.05)",
          backdropFilter: "blur(30px)",
          height:
            window.innerWidth < 1200
              ? "calc(100% - 30px)"
              : "calc(100% - 100px)",
          width:
            window.innerWidth < 1200
              ? "calc(100% - 30px)"
              : "calc(100% - 100px)",
          margin: window.innerWidth < 1200 ? "15px" : "50px 50px",
          border: "1px solid rgba(256,256,256,0.7)",
          borderRadius: window.innerWidth < 1200 ? "10px" : "30px",
          boxShadow:
            "0px 0px 20px rgba(0,0,0,0.2), inset 0px 0px 15px rgba(256,256,256,0.5)",
          padding: window.innerWidth < 1200 ? "15px 20px" : "30px 45px",
          color: "white",
          fontWeight: 400,
          fontSize: "13px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 10,
            opacity: timerDone === 0 ? 1 : 0,
            transform: "translate(-50%,-50%)",
            left: "50%",
            top: "50%",
          }}
        >
          <div
            style={{
              marginBottom: "10px",
              fontWeight: 700,
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            Welcome, please select user
          </div>
          <Select
            value={userField}
            onChange={(e) => {
              console.log(e);
              setUserField(e);
            }}
            style={{
              background: "rgba(256,256,256,0.1)",
              border: "1px solid rgba(256,256,256,0.7)",
              // padding: "8px 20px",
              borderRadius: "6px",
              width: window.innerWidth < 1200 ? "70vw" : "350px",
              textAlign: "center",
              color: "white",
              outline: "none",
            }}
          >
            {employeeList.map((emp, index) => (
              <Select.Option key={index} value={emp.name}>
                {emp.name}
              </Select.Option>
            ))}
          </Select>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const employeeIndex = employeeList.findIndex(
                (emp) => emp.name === userField
              );
              if (employeeIndex > -1) {
                console.log(
                  passwordField,
                  employeeList[employeeIndex].passwordd
                );
                if (
                  employeeList[employeeIndex].passwordd === null &&
                  passwordField === "sl123456"
                ) {
                  console.log("1");
                  setError(false);
                  setLoggedIn(true);
                  setLoggedInEmployeeIndex(employeeIndex);
                } else if (
                  employeeList[employeeIndex].passwordd !== null &&
                  passwordField === employeeList[employeeIndex].passwordd
                ) {
                  console.log("2");
                  setError(false);
                  setLoggedIn(true);
                  setLoggedInEmployeeIndex(employeeIndex);
                } else {
                  console.log("3");
                  setError(true);
                }
              } else {
                message.error("Employee not found, please try again later");
              }
            }}
            style={{ textAlign: "center" }}
          >
            <input
              value={passwordField}
              onChange={(e) => {
                setPasswordField(e.target.value);
              }}
              type="password"
              style={{
                background: "rgba(256,256,256,0.1)",
                border: "1px solid rgba(256,256,256,0.7)",
                padding: "8px 20px",
                borderRadius: "6px",
                width: window.innerWidth < 1200 ? "70vw" : "350px",
                textAlign: "center",
                color: "white",
                outline: "none",
                marginTop: "10px",
              }}
              placeholder="password"
            />
            {error ? (
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "12px",
                  color: "#ba0e02",
                }}
              >
                The password you've entered is invalid.
              </div>
            ) : null}
            <div>
              <button
                type="submit"
                style={{
                  padding: "7px 30px",
                  textAlign: "center",
                  color: "white",
                  background: "rgba(256,256,256,0.1)",
                  borderRadius: "7px",
                  marginTop: "15px",
                  border: "1px solid white",
                }}
              >
                Login
              </button>
            </div>
          </form>
        </div>
        <div
          style={{
            opacity: timerDone === 1 ? 1 : 0,
            transition: "1s all ease-in-out",
            transitionDelay: "0.5s",
            position: "absolute",
            zIndex: 6,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ fontWeight: 700, fontSize: "30px" }}>
            Welcome to your Dashboard,
          </div>
          <div style={{ fontSize: "20px" }}>How is your day {userField}?</div>
        </div>
        <div
          style={{
            opacity: timerDone === 2 ? 1 : 0,
            transition: "0.5s all ease-in-out",
            transitionDelay: "0.5s",
            position: "relative",
            zIndex: 7,
            width: "100%",
            height: "100%",
            overflow: window.innerWidth < 1200 ? "auto" : "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "40px",
            }}
          >
            <div>
              <div style={{ fontWeight: 800, fontSize: "20px" }}>
                Welcome {userField},
              </div>
            </div>
            <div>{getFormattedDate()}</div>
          </div>
          {loggedInEmployeeIndex !== null &&
          employeeList[loggedInEmployeeIndex].passwordd === null ? (
            <div>
              <div style={{ fontSize: "16px" }}>
                Please set your password for future logins
              </div>
              <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                <input
                  type="password"
                  style={{
                    background: "rgba(256,256,256,0.1)",
                    border: "1px solid rgba(256,256,256,0.7)",
                    padding: "8px 20px",
                    borderRadius: "6px",
                    width: "350px",
                    textAlign: "center",
                    color: "white",
                    outline: "none",
                  }}
                  placeholder="password"
                  value={passwordSet}
                  onChange={(e) => setPasswordSet(e.target.value)}
                />
                <div
                  style={{
                    background: "white",
                    color: "grey",
                    fontWeight: 600,
                    padding: "8px 30px",
                    textAlign: "center",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    updatePassword();
                  }}
                >
                  Confirm
                </div>
              </div>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection:
                window.innerWidth < 1200 ? "column-reverse" : "row",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
              marginTop: "15px",
            }}
          >
            <div
              style={{
                width: window.innerWidth < 1200 ? "100%" : "50%",
                marginTop: window.innerWidth < 1200 ? "15px" : "0px",
              }}
            >
              <div style={{ width: "100%", marginBottom: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      fontSize: "20px",
                      fontFamily: "Manrope,sans-serif",
                      fontWeight: 800,
                    }}
                  >
                    Property Valuation
                  </div>
                </div>
                <div>Start by inserting the postcode of the property and subsequently you may enter the unit number and block number to filter your search.</div>
              </div>
              <div style={{ width: "90%" }}>
                <div>General Search:</div>
                <input
                  value={inputSearch}
                  onChange={(e) => {
                    console.log(valuerForm.propertyTypeCode);
                    handleInputChange(e, valuerForm.propertyTypeCode);
                  }}
                  placeholder="Search by postal code"
                  style={{
                    borderRadius: "8px",
                    border: "1px solid lightgrey",
                    width: "90%",
                    padding: "5px 8px",
                  }}
                />
              </div>
              <div style={{ width: "90%", marginTop: "10px" }}>
                <div>Property Type:</div>
                <select
                  value={valuerForm.propertyTypeCode}
                  onChange={(e) => {
                    setValuerForm({
                      ...valuerForm,
                      propertyTypeCode: e.target.value,
                    });
                    submitValuation(postalCodeSearching, e.target.value);
                  }}
                  style={{
                    borderRadius: "8px",
                    border: "1px solid lightgrey",
                    width: "90%",
                    padding: "5px 8px",
                  }}
                >
                  <option value={"hdb"}>HDB</option>
                  <option value={"condo"}>Condo</option>
                  <option value={"land"}>Landed</option>
                  <option value={"office"}>Office</option>
                </select>
              </div>

              <div style={{ width: "100%", marginBottom: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      fontSize: "20px",
                      fontFamily: "Manrope,sans-serif",
                      fontWeight: 800,
                    }}
                  >
                    Search Results
                  </div>
                </div>
                <div>Start typing in the search bar to get results here.</div>
              </div>

              <div
                style={{
                  height: window.innerWidth < 1200 ? "auto" : "50vh",
                  overflowY: "auto",
                  width: "97%"
                }}
              >
                {!searching ? (
                  filteredSearch && filteredSearch.length > 0 ? (
                    filteredSearch.map((addr, index) => (
                      <div
                        key={index}
                        style={{
                          border: "1px solid rgba(256,256,256,0.7)",
                          padding: "10px",
                          borderRadius: "8px",
                          cursor: "pointer",
                          marginBottom: "10px"
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: "18px",
                            color: "white",
                          }}
                        >
                          Full Address
                        </div>
                        <div>
                          {"Unit " +
                            addr?.addressUnit +
                            ", BLK " +
                            addr?.addressNumber +
                            ", " +
                            capitalizeWords(addr?.addressStreet) +
                            ", " +
                            addr?.postalCode +
                            ", " +
                            capitalizeWords(addr?.country) +
                            "."}
                        </div>
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: "14px",
                            color: "white",
                            marginTop: "10px",
                          }}
                        >
                          Valuation Details
                        </div>
                        <div
                          style={{ display: "flex", gap: "15px", opacity: 0.4 }}
                        >
                          <div>{addr?.sizeSqft + " Sqft"}</div>
                          <div>
                            {addr?.saleValuationPsf?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            }) + " / Sqft"}
                          </div>
                          <div>
                            {addr?.saleValuation?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "SGD",
                            })}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        width: "90%",
                        height: "40vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <SearchOffOutlinedIcon
                        style={{
                          fontSize: "120px",
                          color: "rgba(256,256,256,0.3)",
                        }}
                      />
                      <div
                        style={{
                          color: "rgba(256,256,256,0.3)",
                          fontWeight: 700,
                          fontSize: "24px",
                        }}
                      >
                        No Data Found
                      </div>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      width: "90%",
                      height: "40vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <CircularProgress
                      style={{ color: "rgba(41, 75, 110, 0.5)" }}
                      size={150}
                      thickness={5} // Adjust thickness as needed
                    />
                  </div>
                )}
              </div>
              {/* <div style={{ fontSize: "16px" }}>Users under management</div>
              <div style={{ fontSize: "10px", marginTop: "10px" }}>
                {loggedInEmployeeIndex !== null
                  ? employeeList[loggedInEmployeeIndex].created_users.map(
                      (userr, index) => (
                        <div key={index} style={{ marginBottom: "5px" }}>
                          <div style={{ marginLeft: "10px" }}>
                            {index +
                              1 +
                              ") " +
                              (userr.user?.entity_name || "") +
                              " . " +
                              (userr.user?.email || "")}
                          </div>
                          <div style={{ marginLeft: "30px" }}>
                            {userr.loans.length > 0
                              ? userr.loans.map((loans, index1) => (
                                  <div key={index1}>
                                    {index1 +
                                      1 +
                                      ") " +
                                      loans.request_amount?.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "SGD",
                                        }
                                      ) +
                                      " applied on " +
                                      getFormattedwithDate(loans.created_at) +
                                      " with " +
                                      loans.loan_offer_count +
                                      " offers."}
                                  </div>
                                ))
                              : "No loans applied yet"}
                          </div>
                        </div>
                      )
                    )
                  : null}
              </div> */}
            </div>
            <iframe
              src="https://chatbot.getmindpal.com/678882e5f7391600b80358b5"
              allow="clipboard-read; clipboard-write; microphone"
              style={{
                marginTop: window.innerWidth < 1200 ? "15px" : "0px",
                width: window.innerWidth < 1200 ? "100%" : "50%",
                height: window.innerWidth < 1200 ? "100vh" : "85%",
                overflow: "none",
                borderRadius: "7px",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdmin;
