import React, { useEffect, useState } from "react";
import imgBurst from "../src/img/splashColab.png";
import cbsLogo from "../src/img/CBSlogo.png";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ima1 from "../src/img/cbspart1.png";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { Typography, IconButton } from "@mui/material";
import { ChevronRight, ExpandMore } from "@mui/icons-material";

const CBSPartner = () => {
  const history = useHistory();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const [screenWidth, setScreenWidth] = useState(1920);
  const [screenHeight, setScreenHeight] = useState(1920);

  const [isCollapsed, setIsCollapsed] = useState(null);
  const faqs = [
    {
      id: 9,
      title: "What is the SmartLend Platform?",
      answer: (
        <div>
          <p>
            SmartLend was designed to streamline the financing journey for SMEs
            in Singapore, addressing common challenges like high interest rates
            and limited options. Many SMEs unknowingly settle for costly loans,
            unaware of better alternatives. Our platform empowers borrowers to
            make informed decisions by comparing loan offers from multiple
            lenders—all without incurring fees from brokers or consultants.
            We’ve partnered with Singapore’s top alternative lenders, providing
            SMEs access to a wide network with just one application.{" "}
          </p>
          <p>
            With a seamless 4-step process, applying for a loan takes less than
            5 minutes, giving you fast, transparent, and cost-effective access
            to financing
          </p>
        </div>
      ),
    },
    {
      id: 10,
      title: "Why Specific Alternative Lenders Only? What About Bank Loans?",
      answer: (
        <div>
          <p>
            SmartLend focuses on alternative lenders because they provide
            faster, more flexible financing solutions compared to traditional
            banks. Alternative lenders are often more accommodating to SMEs with
            non- traditional credit profiles or urgent funding needs, offering a
            wide range of tailored loan options.
          </p>
          <p>
            While bank loans can be a good option for businesses with
            established credit histories and longer timelines, they often come
            with stringent requirements, lengthy approval processes, and less
            flexibility. Our goal is to simplify and speed up access to
            financing, which aligns with the strengths of alternative lenders.
          </p>
          <p style={{fontWeight: 800}}>
            With our advanced SMART credit profiling engine, eligible borrowers
            who meet the criteria for bank loans will be automatically
            identified. Our dedicated team will then reach out to present
            tailored banking options for your consideration.
          </p>
        </div>
      ),
    },
    {
      id: 1,
      title: "How do I apply for a Business Loan?",
      answer: (
        <p>
          Get started by clicking on the sign up button above and it will take
          you through a step by step process to sign up for a smartlend account.
          Once you’re done with your sign up, you can head on into the dashboard
          and click on the apply loan button located on the menu bar on the
          left.
        </p>
      ),
    },
    {
      id: 2,
      title: "Can anyone apply for a Business Loan?",
      answer: (
        <p>
          Currently, only Singapore locally incorporated LLP (Limited Liability
          Partnership) or Pte. Ltd. (Private Limited) entities are eligible to
          apply for a business loan. However, if you have a different type of
          corporate entity, please reach out to us at support@smart-lend.com,
          and we'll explore how we might be able to assist you.
        </p>
      ),
    },
    {
      id: 3,
      title: "Why can’t I apply for a loan?",
      answer: (
        <p>
          If you're unable to apply for a loan, it usually means that you
          haven't synchronized your Corp Pass account with your Singpass yet.
          This synchronization process is necessary for accessing and applying
          for loans.
        </p>
      ),
    },
    {
      id: 4,
      title: "Can I speak to the lenders of the platform?",
      answer: (
        <p>
          Yes, you can speak to the lenders on our platform, but only if the
          lender has expressed interest in your loan request. In such cases, a
          chatbot request feature will be enabled on our platform. However,
          please note that we do not provide users with the private contact
          information of our lenders due to our privacy policies.
        </p>
      ),
    },
    {
      id: 5,
      title: "How can I talk to my private lender?",
      answer: (
        <p>
          If you would like to reach your lender on the loan offer that they
          have offered, you can click on the offer in the requested loan page,
          select the lender, and proceed to click on the negotiation button
          where you’ll be taken to the messaging page where you can start a
          conversation with your lender.
        </p>
      ),
    },
    {
      id: 6,
      title: "How long does it take for me to get a loan approved?",
      answer: (
        <div>
          <p>
            The approval time for your loan depends on several factors. First,
            you need to receive an offer from a lender. At Smart-Lend, we have a
            wide network of lenders who review your financial profile and the
            documents you've provided before making an offer. This process
            usually takes between 1 to 5 working days.
          </p>
          <p>
            Keep an eye out for our upcoming Service Guarantee feature! Soon,
            borrowers will enjoy even faster turnaround times, with approvals
            possible within 24 hours. Stay tuned for more details when we
            introduce this new feature.
          </p>
        </div>
      ),
    },
  ];

  const FAQItem = ({ id, title, answer, index }) => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "0.01rem solid #d5dee0",
            cursor: "pointer",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
          onClick={() => {
            console.log(index);
            if (isCollapsed === index) {
              setIsCollapsed(null);
            } else {
              setIsCollapsed(index);
            }
          }}
          className="mui-button"
        >
          <div
            variant="subtitle1"
            style={{
              fontWeight: "600",
              fontSize: screenWidth > 1200 ? "17px" : "14px",
            }}
          >
            {title}
          </div>
          <IconButton
            onClick={() => {
              console.log(index);
              if (isCollapsed === index) {
                setIsCollapsed(null);
              } else {
                setIsCollapsed(index);
              }
            }}
          >
            <ChevronRight
              style={{
                rotate: isCollapsed !== index ? "0deg" : "90deg",
                transition: "all 0.3s ease-in-out",
              }}
            />
          </IconButton>
        </div>
        {isCollapsed === index ? (
          <div
            style={{
              marginLeft: "40px",
              paddingTop: "8px",
              fontSize: screenWidth > 1200 ? "13px" : "12px",
              fontWeight: "400",
              paddingBottom: "10px",
            }}
          >
            {answer}
          </div>
        ) : null}
      </div>
    );
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: window.innerHeight,
        overflowX: "hidden",
        overflowY: "auto",
        position: "relative",
      }}
      onMouseMove={(e) => {
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        // console.log(`Mouse X: ${mouseX}, Mouse Y: ${mouseY}`);
        setMousePosition({ x: mouseX, y: mouseY });
      }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 0,
          top: "10%",
          left: 0,
          width: `calc(50vh + ${mousePosition.x * 1.5}px)`,
          height: `calc(50vh + ${mousePosition.x * 1.5}px)`,
          background:
            "radial-gradient(circle, rgba(45,128,195, 0.4) 0%, rgba(45,128,195, 0.2) 20%, rgba(45,128,195, 0.05) 40%, rgba(256,256,256,0) 60%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <div
        style={{
          position: "fixed",
          zIndex: 0,
          top: "100%",
          left: "30%",
          width: `calc(50vh + ${mousePosition.x * 1.5}px)`,
          height: `calc(50vh + ${mousePosition.x * 1.5}px)`,
          background:
            "radial-gradient(circle, rgba(237,182,0, 0.4) 0%, rgba(237,182,0, 0.2) 20%, rgba(237,182,0, 0.05) 40%, rgba(256,256,256,0) 60%",
          transform: "translate(-50%, -50%)",
          opacity: 0.6,
        }}
      />
      <div
        style={{
          position: "fixed",
          zIndex: 0,
          top: "80%",
          left: "100%",
          width: `calc(300vh - ${mousePosition.x * 1}px)`,
          height: `calc(300vh - ${mousePosition.x * 1}px)`,
          background:
            "radial-gradient(circle, rgba(45,128,195, 0.4) 0%, rgba(45,128,195, 0.2) 20%, rgba(45,128,195, 0.05) 40%, rgba(256,256,256,0) 60%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <div
        style={{
          position: "fixed",
          zIndex: 0,
          top: "0%",
          left: "70%",
          width: `calc(300vh - ${mousePosition.x * 1}px)`,
          height: `calc(300vh - ${mousePosition.x * 1}px)`,
          background:
            "radial-gradient(circle, rgba(237,182,0, 0.4) 0%, rgba(237,182,0, 0.2) 20%, rgba(237,182,0, 0.05) 40%, rgba(256,256,256,0) 60%",
          transform: "translate(-50%, -50%)",
          opacity: 0.6,
        }}
      />
      <div
        style={{
          position: "relative",
          transform: "scale(0.4)",
          marginTop: window.innerWidth > 1000 ? "20vh" : "10vh",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            fontSize: "60px",
            textAlign: "center",
            transform: "translate(-120%, -50%)",
            color: "rgb(37, 97, 153)",
            fontWeight: 700,
            opacity: 0.7,
          }}
        >
          SmartLend
        </div>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            fontSize: "100px",
            textAlign: "center",
            transform: "translate(-50%, -50%)",
            color: "rgba(37, 97, 153, 0.7)",
            opacity: 0.3,
            fontWeight: 800,
          }}
        >
          X
        </div>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            fontSize: "60px",
            textAlign: "center",
            transform: "translate(25%, -48%)",
            color: "rgb(37, 97, 153)",
            fontWeight: 700,
          }}
        >
          <img src={cbsLogo} style={{ height: "100px" }} />
        </div>
      </div>
      <div
        style={{
          fontWeight: 800,
          width: window.innerWidth > 1000 ? "66%" : "95%",
          marginLeft: window.innerWidth > 1000 ? "17%" : "2.5%",
          fontSize: window.innerWidth > 1000 ? "50px" : "35px",
          lineHeight: window.innerWidth > 1000 ? "50px" : "35px",
          textAlign: "center",
          color: "rgb(37, 97, 153)",
          marginTop: window.innerWidth > 1000 ? "22.5vh" : "13.5vh",
          zIndex: 20,
          position: "relative",
        }}
      >
        A Joint Collaboration to Empowering You with Financial Insights
      </div>
      <div
        style={{
          fontWeight: 500,
          width: window.innerWidth > 1000 ? "50%" : "90%",
          fontSize: "14px",
          lineHeight: "14px",
          textAlign: "center",
          color: "rgb(37, 97, 153)",
          zIndex: 20,
          position: "relative",
          margin:
            window.innerWidth > 1000 ? "15px 25% 5px 25%" : "15px 5% 5px 5%",
          textShadow: "0px 0px 4px white",
        }}
      >
        For a limited time, SmartLend offers you the chance to retrieve your
        official CBS Credit Report at no cost—complete with an in-depth analysis
        to uncover your best loan options.
      </div>
      <div
        style={{
          position: "relative",
          height: window.innerHeight * 0.5,
          width: "100%",
          marginTop: "-60px",
          zIndex: 18,
        }}
      >
        <img
          src={imgBurst}
          style={{
            height: "100%",
            width: window.innerWidth > 1000 ? "80%" : "200vw",
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translate(-50%,0)",
          }}
        />

        <div
          className="hoverbuttonStart"
          style={{
            width: "130px",
            height: "130px",
            borderRadius: "50%",
            background: "transparent",
            outline: "3px solid rgb(37, 97, 153)",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "0.3s all ease-in-out",
            cursor: "pointer",
          }}
        >
          <div
            className="hoverbuttonStartchild"
            style={{
              width: "120px",
              height: "120px",
              borderRadius: "50%",
              background: "rgb(37, 97, 153)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
              lineHeight: "20px",
              fontSize: "20px",
              fontWeight: 800,
              transition: "0.3s all ease-in-out",
            }}
            onClick={() => {
              Cookies.set("signupthroughcbs", 1);
              window.location.href =
                "https://smart-lend.com/auth/signup?ecid=cbs";
            }}
          >
            Get
            <br />
            Started
          </div>
        </div>
      </div>
      <div style={{ width: "100%", paddingTop: "60px" }}>
        <div
          style={{
            background: "rgba(220, 235, 247, 0.3)",
            width: "100%",
            position: "relative",
            zIndex: 20,
          }}
        >
          <div
            style={{
              paddingTop: window.innerWidth > 1000 ? "100px" : "100px",
              paddingBottom: "50px",
              width: "85%",
              marginLeft: "7.5%",
              fontWeight: 800,
              fontSize: window.innerWidth > 1000 ? "35px" : "32px",
              lineHeight: window.innerWidth > 1000 ? "35px" : "35px",
              color: "rgb(37, 97, 153)",
              textAlign: "center",
              position: "relative",
              zIndex: 20,
            }}
          >
            <div
              style={{
                fontSize: window.innerWidth > 1000 ? "14px" : "12px",
                color: "rgb(150,150,150)",
                fontWeight: 400,
                lineHeight: "20px",
              }}
            >
              Get an Expert Opnion and Insights on your Finances
            </div>
            <div>Why Access Your CBS Credit Report Through SmartLend?</div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  background: "yellow",
                  color: "white",
                  background: "rgb(37, 97, 153)",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "center",
                  fontSize: "12px",
                  padding: "5px 20px",
                  borderRadius: "30px",
                  marginTop: "20px",
                }}
                className="mui_button"
                onClick={() => {
                  Cookies.set("signupthroughcbs", 1);
                  window.location.href =
                    "https://smart-lend.com/auth/signup?ecid=cbs";
                }}
              >
                <AutoAwesomeIcon style={{ color: "white" }} />
                Retrieve your Free CBS Report
              </div>
            </div>
          </div>
          <div
            style={{
              paddingBottom: "130px",
              display: "flex",
              alignItems: "flex-start",
              width: "85%",
              marginLeft: "7.5%",
              gap: "10px",
              justifyContent: "space-between",
              position: "relative",
              zIndex: 20,
              fontSize: "12px",
              color: "rgb(130,130,130)",
              flexWrap: "wrap",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            <div
              style={{
                width: window.innerWidth > 1000 ? "24%" : "calc(50% - 5px)",
                borderRadius: "20px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
              }}
            >
              <div
                style={{
                  fontWeight: 800,
                  fontSize: "20px",
                  lineHeight: "20px",
                  color: "rgb(37, 97, 153)",
                  marginBottom: "10px",
                }}
              >
                Zero Cost
              </div>
              <div>
                Retrieve Your Official CBS Credit Report At No Cost (On Us!).
                Instantly check your credit health and stay ahead of lender
                criteria for smarter financial decisions.
              </div>
            </div>
            <div
              style={{
                width: window.innerWidth > 1000 ? "24%" : "calc(50% - 5px)",
                borderRadius: "20px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
              }}
            >
              <div
                style={{
                  fontWeight: 800,
                  fontSize: "20px",
                  lineHeight: "20px",
                  color: "rgb(37, 97, 153)",
                  marginBottom: "10px",
                }}
              >
                BTI Ratio Insights
              </div>
              <div>
                Upload your CBS Credit Report to instantly analyze your credit
                utilization and Balance-to-Income (BTI) ratio. Discover your
                tailored loan eligibility profile—no guesswork, just clarity.
              </div>
            </div>
            <div
              style={{
                width: window.innerWidth > 1000 ? "24%" : "calc(50% - 5px)",
                borderRadius: "20px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
              }}
            >
              <div
                style={{
                  fontWeight: 800,
                  fontSize: "20px",
                  lineHeight: "20px",
                  color: "rgb(37, 97, 153)",
                  marginBottom: "10px",
                }}
              >
                Expert Opninions
              </div>
              <div>
                From selecting the right financial products to understanding
                complex lending terms, our Experienced Relationship Managers
                offer free consultations. We simplify the process and help you
                chart a clear path to better financing terms.
              </div>
            </div>
            <div
              style={{
                width: window.innerWidth > 1000 ? "24%" : "calc(50% - 5px)",
                borderRadius: "20px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
              }}
            >
              <div
                style={{
                  fontWeight: 800,
                  fontSize: "20px",
                  lineHeight: "20px",
                  color: "rgb(37, 97, 153)",
                  marginBottom: "10px",
                }}
              >
                Security
              </div>
              <div>
                Your privacy and data security are non-negotiable. Uploading
                your CBS Credit Report is optional—and if you do, it’s stored
                securely, used solely to provide personalized insights, and
                never shared without your consent
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingBottom: "130px",
          display: "flex",
          flexDirection: window.innerWidth > 1000 ? "row" : "column-reverse",
          alignItems: "center",
          justifyContent: "space-between",
          width: "70%",
          marginLeft: "15%",
          gap: "40px",
          position: "relative",
          zIndex: 20,
          paddingTop: "80px",
        }}
      >
        <div
          style={{
            width: window.innerWidth > 1000 ? "40%" : "80vw",
            fontSize: "14px",
            lineHeight: "20px",
            color: "rgb(130,130,130)",
          }}
        >
          <div
            style={{
              fontWeight: 800,
              fontSize: screenWidth > 1200 ? "55px" : "40px",
              lineHeight: screenWidth > 1200 ? "60px" : "45px",
              color: "rgb(37, 97, 153)",
              marginBottom: "20px",
            }}
          >
            Why Does Your CBS Credit Report Matter?
          </div>
          <div style={{ marginBottom: "10px" }}>
            Your CBS Credit Report shapes lender decisions—impacting interest
            rates, credit limits, and loan terms. A strong profile unlocks
            better deals, while a weak one can hold you back. Know your standing
            to negotiate and secure financing for growth.
          </div>
        </div>
        <div style={{ width: window.innerWidth > 1000 ? "50%" : "80vw" }}>
          <img
            src={ima1}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: screenWidth > 1200 ? "row" : "column",
          padding:
            screenWidth > 1200 ? "60px 15% 180px 15%" : "60px 10% 180px 10%",
          position: "relative",
          zIndex: 201,
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: screenWidth > 1200 ? "45%" : "100%" }}>
          <div
            style={{
              color: "rgb(37, 97, 153)",
              fontWeight: 800,
              fontSize: screenWidth > 1200 ? "55px" : "40px",
              lineHeight: screenWidth > 1200 ? "60px" : "45px",
              marginTop: screenWidth > 1200 ? "15px" : "5px",
            }}
          >
            You asked, we answered.
          </div>
          <div
            style={{
              color: "rgb(120,120,120)",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 400,
              marginTop: "15px",
            }}
          >
            Can't find your question? View all our{" "}
            <span
              onClick={() => {
                history.push("/frequentlyaskedquestions");
                window.scrollTo(0, 0);
              }}
              style={{
                textDecoration: "underline",
                color: "#9bc7eb",
                fontWeight: 600,
                cursor: "pointer",
              }}
            >
              FAQ’s
            </span>
            , chat with us on
            <span
              onClick={() => {
                window.location.href = "https://wa.me/6588288147";
              }}
              style={{
                textDecoration: "underline",
                color: "#9bc7eb",
                fontWeight: 600,
                cursor: "pointer",
                marginLeft: "5px",
              }}
            >
              WhatsApp
            </span>
            , or email us via:
            <span
              onClick={() => {
                window.location.href = "mailto:hello@smart-lend.com";
              }}
              style={{
                textDecoration: "underline",
                color: "#9bc7eb",
                fontWeight: 600,
                cursor: "pointer",
                marginLeft: "5px",
              }}
            >
              hello@smart-lend.com
            </span>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "25px",
            }}
          >
            <div
              className="mui_button"
              onClick={() => {
                window.location.href = "https://wa.me/6588288147";
              }}
              style={{
                background: "rgb(37, 97, 153)",
                padding: "8px 25px",
                fontWeight: 600,
                color: "white",
                borderRadius: "40px",
              }}
            >
              Whatsapp us
            </div>
          </div>
        </div>
        <div
          style={{
            width: screenWidth > 1200 ? "45%" : "100%",
            marginTop: screenWidth > 1200 ? "0px" : "15px",
          }}
        >
          {" "}
          {faqs.map((faq, index) => (
            <FAQItem
              key={faq.id}
              id={faq.id}
              title={faq.title}
              answer={faq.answer}
              index={index}
            />
          ))}
        </div>
      </div>
      {/* Partner with us */}
      <div
        style={{
          background: "rgb(37, 97, 153)",
          position: "relative",
          zIndex: 20,
          padding: "100px 30px",
        }}
      >
        <div
          style={{
            fontWeight: 800,
            width: window.innerWidth > 1000 ? "66%" : "95%",
            marginLeft: window.innerWidth > 1000 ? "17%" : "2.5%",
            fontSize: "40px",
            lineHeight: "40px",
            textAlign: "center",
            color: "white",
            zIndex: 20,
            position: "relative",
          }}
        >
          Partner with Us
        </div>
        <div
          style={{
            fontWeight: 300,
            width: window.innerWidth > 1000 ? "66%" : "95%",
            marginLeft: window.innerWidth > 1000 ? "17%" : "2.5%",
            fontSize: "14px",
            lineHeight: window.innerWidth > 1000 ? "20px" : "16px",
            textAlign: "center",
            color: "white",
            zIndex: 20,
            position: "relative",
            marginTop: "15px",
          }}
        >
          Are you a Non-Banking Financial Company, Peer to Peer Platform, Family
          offices, Fintech Lenders or Alternative Debt Funds looking to amplify
          your reach and impact? SmartLend invites you to collaborate and
          redefine the future of finance.
          <br />
          <br />
          By partnering with us, you gain access to a dynamic ecosystem that
          offers cutting -edge technology, a vast network of borrowers, and
          innovative solutions. Partner with us
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <div
            className="mui_button"
            onClick={() => {
              window.location.href = "mailto:hello@smart-lend.com";
            }}
            style={{
              background: "rgb(242, 193, 32)",
              padding: "8px 25px",
              fontWeight: 600,
              color: "white",
              borderRadius: "40px",
            }}
          >
            Contact us
          </div>
        </div>
      </div>
    </div>
  );
};

export default CBSPartner;
